/**
 * Created by ilya on 6/9/17.
 */
import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import store from 'store';
import axios from 'axios';

import getFirebaseConfig from '../config/firebase';
import { sendEvent } from 'analytics';

const firebaseProjectId = getFirebaseConfig().projectId;

export const CREATE_VOTE = 'app/vote/CREATE_VOTE';
export const CREATE_VOTE_SUCCESS = 'app/vote/CREATE_VOTE_SUCCESS';
export const ERROR = 'app/vote/ERROR';
export const CANCEL = 'app/vote/CANCEL';

// Initial State
export const initialState = {
  creating: false,
  loading: false,
  updating: false,
  success: false,
  error: '',
};

// data - Array same length of checkpoints - each one is 0, 1, 2, (Red, Amber, Green) empty index is unvoted
export function createVote(ballotId, checkpointId, vote, oneByOne = false) {
  return {
    type: CREATE_VOTE,
    payload: { ballotId, checkpointId, vote, oneByOne },
  };
}

export function cancelVote() {
  return {
    type: CANCEL,
  };
}

export const voteCreate = createLogic({
  latest: true,
  type: CREATE_VOTE,
  cancelType: CANCEL,
  warnTimeout: 0,
  // processOptions: {
  //   successType: CREATE_VOTE_SUCCESS,
  //   failType: ERROR,
  // },
  process({ action }, dispatch, done) {
    const { ballotId, checkpointId, vote } = action.payload;
    const key = `${ballotId}-${store.get('user')}`;
    const userid = store.get('user');
    const vote_start = new Date().getTime();

    if (action.payload.oneByOne) {
      axios
        .post(
          `https://us-central1-${firebaseProjectId}.cloudfunctions.net/voteOneByOne`,
          {
            ballotId: action.payload.ballotId, // pass ballot id
            checkpointId: checkpointId,
            ragValue: vote[checkpointId],
          },
          {
            headers: {
              Authorization: store.get('Authorization'),
              'Content-Type': 'application/json',
            },
          }
        )
        .then(response => {
          if (response.status === 200) {
            const vote_end = new Date().getTime();
            sendEvent(
              'checkin',
              'vote',
              checkpointId,
              (vote_end - vote_start) / 1000,
              {
                userid,
                ballotId: action.payload.ballotId,
              }
            );
            dispatch({
              type: CREATE_VOTE_SUCCESS,
              payload: {
                data: {
                  ballot: response.data.ballot,
                },
              },
            });
            done();
          } else {
            dispatch({
              type: ERROR,
            });
            done();
          }
        })
        .catch(e => {
          console.error(e);
          Raven.captureExceptionEx(e);
          if (e && e.response && e.response.data) {
            const { data } = e.response;
            if (
              data &&
              data.error &&
              data.error.message &&
              data.message.startsWith('Firebase ID token has expired')
            ) {
              firebase
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(idToken => {
                  // Refresh token
                  store.set('Authorization', idToken);
                  dispatch(action);
                });
            } else {
              dispatch({
                type: ERROR,
              });
              done();
            }
          } else {
            dispatch({
              type: ERROR,
            });
            done();
          }
        });
    } else {
      firebase
        .database()
        .ref('votes')
        .child(key)
        .set({
          userid,
          ballot: ballotId,
          checkpoints: vote,
        })
        .then(() => {
          /** should return ballot: now-return checkpoint*/
          dispatch({
            type: CREATE_VOTE_SUCCESS,
            payload: {
              data: {
                id: key,
                ballot: ballotId,
                checkpoints: vote,
              },
            },
          });
          done();
        })
        .catch(e => {
          Raven.captureExceptionEx(e);
          dispatch({
            type: ERROR,
          });
          done();
        });
    }
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_VOTE:
      return {
        ...state,
        creating: true,
        success: false,
        error: '',
      };
    case CREATE_VOTE_SUCCESS:
      return {
        ...state,
        creating: false,
        success: true,
        error: '',
      };

    case ERROR:
      return {
        ...state,
        creating: false,
        success: false,
        error: 'Failed to vote!',
      };

    default:
      return state;
  }
}
