import store from 'store';
import { fetchInitiative, cancelInitiative } from 'logic/initiative';
import decorateInitiative from 'decorators/initiative';
import views from 'constants/wallboards';
import { injectCheckpoints } from 'utils';
import { each, find, isEmpty } from 'lodash';
import { injectKpis } from 'utils/index';
import { getRemovedMarkdownString } from 'utils';

export default {
  template: `
    <main class="wallboard w-100 h-100">
      <div ng-show="$ctrl.fetching || $ctrl.fetchError">
        <div class="mt-5 text-center">
          <h4 ng-if="$ctrl.fetching"><i class='fa fa-spinner fa-spin' ></i> Loading initiative...</h4>
          <h5 ng-if="$ctrl.fetchError" class="text">{{$ctrl.fetchError}}</h5>
        </div>
      </div>
      <div class="d-flex flex-column h-100" ng-if="!$ctrl.fetching && !$ctrl.fetchError">
        <wallboard-header
          loading="!$ctrl.paused"
          paused="$ctrl.paused"
          header-title="$ctrl.items[0].name"
          views="$ctrl.views"
          on-click="$ctrl.changeLocation(name)"
          on-toggle="$ctrl.toggle()"
          is-show="true">
        </wallboard-header>
        <section class="wallboard-show-section row m-0">
          <div ng-hide="$ctrl.name == 'pitch'" class="wallboard-card-wrapper">
            <div class="wallboard-card">
              <p class="m-0 row"><strong>Pitch:&nbsp;</strong><span marked="$ctrl.getRemovedMarkdownString($ctrl.items[0].pitch)" class="markdownContainer"></span></p>
            </div>
          </div>
          <div
            class="h-100 wallboard-card-wrapper col-12"
            ng-repeat="item in $ctrl.items"
          >
            <wallboard-card
              item="item"
              header-title="Pitch"
              ng-show="$ctrl.name == 'pitch'">
              <wallboard-card-pitch
                pitch="item.pitch"
                class="wallboard-card-fill">
              </wallboard-card-pitch>
            </wallboard-card>
            <wallboard-card
              item="item"
              header-title="Checkpoints"
              ng-show="$ctrl.name == 'checkpoints'">
              <div ng-init="$ctrl.onLoad()" id="show-body-content" class="d-flex align-items-center justify-content-center flex-wrap" style="flex: 1">
                <div class="d-flex" ng-style="{ 'width': '100%', 'height': $ctrl.checkpointHeight * 0.6}">
                  <wallboard-card-checkpoints
                    graph-data="item.graphData"
                    class="wallboard-card-fill"
                    is-individual="true">
                  </wallboard-card-checkpoints>
                </div>
                <div class="caption">
                  <p class="text-muted">The team checkpoints are a set of eight attributes of healthy teams.</p>
                  <p class="text-muted">The service checkpoints are a set of eleven criteria to help teams create and run good digital services.</p>
                </div>
              </div>
            </wallboard-card>
            <wallboard-card
              item="item"
              header-title="Mobilisation tasks"
              ng-show="$ctrl.name == 'mobilisation-tasks'">
              <div class="d-flex" style="flex: 1;padding: 10%">
                <wallboard-card-awards
                  graph-data="item.graphData"
                  class="wallboard-card-fill"
                  is-individual="true">
                </wallboard-card-awards>
              </div>
            </wallboard-card>
            <wallboard-card
              item="item"
              header-title="Performance"
              ng-show="$ctrl.name == 'performance'">
              <div ng-init="$ctrl.onLoad()" id="show-body-content" class="d-flex align-items-center justify-content-center" style="flex: 1">
                <div class="d-flex justify-content-center" ng-style="{ 'width': $ctrl.checkpointHeight * 2 * 0.8, 'height': $ctrl.checkpointHeight }">
                  <wallboard-card-performance
                    class="wallboard-card-performance"
                    style="flex: 1"
                    kpis="item.kpis"
                    is-individual="true">
                  </wallboard-card-performance>
                </div>
              </div>
            </wallboard-card>
            <wallboard-card
              item="item"
              header-title="Badges"
              ng-show="$ctrl.name == 'badges'">
              <wallboard-card-badge
                awards="$ctrl.awards.badges">
              </wallboard-card-badge>
            </wallboard-card>
            <wallboard-card
              item="item"
              header-title="Team Member"
              ng-show="$ctrl.name == 'team-member'">
              <wallboard-card-team-member
                members="$ctrl.teamMembers"
                class="wallboard-card-fill">
              </wallboard-card-team-member>
            </wallboard-card>
            <wallboard-card-problem
              item="item"
              header-title="['Problem statement', 'Impact of problem']"
              description="[item.summary, item.problem]"
              ng-show="$ctrl.name == 'problem'">
            </wallboard-card-problem>
          </div>
        </section>
      </div>
    </main>`,

  controller($location, $ngRedux, $routeParams, $timeout, $window, $rootScope) {
    `ngInject`;

    const RESIZE_DEBOUNCE = 0.5 * 1000;
    const NEXT_PAGE_TIMEOUT = 30 * 1000;

    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
      fetch,
      toggle,
      changeLocation,
      onResize,
      onLoad,
      nextPage,
      resize,
      $w: angular.element($window),
      getRemovedMarkdownString
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );

      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.name = $routeParams.name || views[0].name;
      // $ctrl.page = 1;
      $ctrl.paused = store.get('show.paused');

      if (!$ctrl.paused) {
        $ctrl.next_promise = $timeout($ctrl.nextPage, NEXT_PAGE_TIMEOUT);
      }

      // if ($routeParams.name === 'awards') {
      //   $rootScope.title = 'Init - Wallboard - Mobilisation';
      // } else if ($routeParams.name === 'performance') {
      //   $rootScope.title = 'Init - Wallboard - Service Performance';
      // }

      $ctrl.views = views.map(view => {
        const active = view.name === $ctrl.name;
        return { ...view, active };
      });

      $ctrl.fetch();

      $ctrl.$w.on('resize.wallboard.show', $ctrl.onResize);
    }

    function onLoad() {
      const elContent = document.getElementById('show-body-content');
      $ctrl.checkpointHeight = Math.min(
        elContent.clientWidth / 2,
        elContent.clientHeight
      );
    }

    function $onDestroy() {
      $ctrl.cancelInitiative();
      $ctrl.unsubscribe();
      if ($ctrl.next_promise) {
        $timeout.cancel($ctrl.next_promise);
      }
      $ctrl.$w.off('resize.wallboard.show');
    }

    function mapState(state) {
      const awards = {
        mobilisations: state.awards.mobilisations,
        badges: state.awards.badges,
      };

      const initiative = angular.copy(state.initiative.data);
      const checkpoints = angular.copy(state.checkpoints.data);

      injectCheckpoints(initiative, checkpoints);

      const mobilisations = { ...state.awards.mobilisations };

      const props = decorateInitiative(initiative, mobilisations);
      injectKpis(props, state.kpiTypes.data);

      each(awards, (award, type) => {
        each(award, (item, key) => {
          if (item) {
            const { id, name } = item;
            const data = { ...find(props[type], { name }) };
            const active = !isEmpty(data);

            if (data.completionDate) {
              data.completionDate = new Date(data.completionDate);
            } else {
              data.completionDate = new Date();
            }

            awards[type][key] = { ...data, id, name, key: type, active };
          }
        });
      });

      let team = angular.copy(props.team);
      if (typeof team === 'string') {
        team.replace(', ', ',');
        let members = team.split(',');
        team = [];
        for (let i = 0; i < members.length; i++) {
          team.push({ name: members[i] });
        }
      }

      return {
        fetching: state.initiative.fetching,
        fetchError: state.initiative.fetchError,
        items: [props],
        awards,
        teamMembers: team,
      };
    }

    function mapDispatch() {
      return {
        fetchInitiative,
        cancelInitiative,
      };
    }

    function fetch() {
      $ctrl.fetchInitiative($routeParams.id);
    }

    function toggle() {
      $ctrl.paused = !$ctrl.paused;

      if ($ctrl.paused) {
        if ($ctrl.next_promise) {
          $timeout.cancel($ctrl.next_promise);
        }
      } else {
        $ctrl.next_promise = $timeout($ctrl.nextPage, NEXT_PAGE_TIMEOUT);
      }

      store.set('show.paused', $ctrl.paused);
    }

    function nextPage() {
      let currentIndex = views.findIndex(
        view => view.name === $routeParams.name
      );
      currentIndex = (currentIndex + 1) % views.length;
      $ctrl.changeLocation(views[currentIndex].name);
    }

    function changeLocation(name) {
      $location.path(`/wallboard/${$routeParams.id}/${name}`);
    }

    function onResize() {
      if ($ctrl.resizeTimeout) {
        $timeout.cancel($ctrl.resizeTimeout);
      }

      $ctrl.resizeTimeout = $timeout($ctrl.resize, RESIZE_DEBOUNCE);
    }

    function resize() {
      const windowWidth = $ctrl.$w.width();

      if (windowWidth !== $ctrl.windowWidth) {
        $ctrl.windowWidth = windowWidth;
        $ctrl.fetch();
      }
    }
  },
};
