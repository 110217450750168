import './tagsInput.css';

import $ from 'jquery';
import 'jquery-ui/themes/base/base.css';
import 'jquery-ui/themes/base/theme.css';

export default function($parse, $timeout) {
  'ngInject';

  return {
    restrict: 'E',
    scope: {
      inputTags: '=',
      onchange: '&',
    },
    link: function(scope, element, attrs) {
      scope.defaultWidth = 200;
      scope.tagText = '';
      scope.placeholder = attrs.placeholder;
      scope.tagArray = function() {
        if (!scope.inputTags) {
          return [];
        }
        var res = scope.inputTags.split(',').filter(function(tag) {
          return tag !== '' && tag.length > 0;
        });

        return res;
      };
      scope.addTag = function() {
        var tagArray;
        if (scope.tagText.length === 0) {
          return;
        }
        tagArray = scope.tagArray();
        var newTags = scope.tagText.split(',');
        for (var i = 0; i < newTags.length; i++) {
          if (tagArray.indexOf(newTags[i]) < 0 && newTags[i].length > 0) {
            tagArray.push(newTags[i]);
            scope.inputTags = tagArray.join(',');
          }
        }
        scope.onchange({ tags: tagArray });
        return (scope.tagText = '');
      };
      scope.deleteTag = function(key) {
        var tagArray;
        tagArray = scope.tagArray();
        if (
          tagArray.length > 0 &&
          scope.tagText.length === 0 &&
          key === undefined
        ) {
          tagArray.pop();
        } else {
          if (key !== undefined) {
            tagArray.splice(key, 1);
          }
        }
        scope.onchange({ tags: tagArray });
        return (scope.inputTags = tagArray.join(','));
      };
      scope.$watch('tagText', function(newVal, oldVal) {
        var tempEl;
        if (!(newVal === oldVal && newVal === undefined)) {
          tempEl = $('<span>' + newVal + '</span>').appendTo('body');
          scope.inputWidth = tempEl.width() + 5;
          if (scope.inputWidth < scope.defaultWidth) {
            scope.inputWidth = scope.defaultWidth;
          }
          return tempEl.remove();
        }
      });
      element.bind('keydown', function(e) {
        var key;
        key = e.which;
        if (key === 9 || key === 13) {
          e.preventDefault();
        }
        if (key === 8) {
          return scope.$apply('deleteTag()');
        }
      });
      return element.bind('keyup', function(e) {
        var key;
        key = e.which;
        if (key === 9 || key === 13 || key === 188) {
          e.preventDefault();
          return scope.$apply('addTag()');
        }
      });
    },
    template:
      "<div class='tagGroup'><input id='tagInput' class='tagGroup__input' name='tagInput' type='text' data-ng-model='tagText' placeholder='{{placeholder}}'/><button type='button' class='tagGroup__tag tagGroup__tag--selected' data-ng-click='deleteTag($index)' data-ng-repeat=\"tag in tagArray()\">{{tag}}</button></div>",
  };
}
