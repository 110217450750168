import { getRemovedMarkdownString } from 'utils';

export default {
  bindings: {
    pitch: '<',
  },

  template: `
    <div class="wallboard-card-fill justify-content-center">
      <span class="mb-0 font-weight-lighter mt-5 markdownContainer" marked="$ctrl.getRemovedMarkdownString($ctrl.pitch)" style="font-size: 35px">
      </span>
    </div>`,

  controller() {
    const $ctrl = {
      getRemovedMarkdownString
    }
    return $ctrl;
  }
};
