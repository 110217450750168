import { getRemovedMarkdownString } from 'utils';

export default {
  bindings: {
    item: '<',
    headerTitle: '<',
    description: '<',
  },

  template: `
    <div class="h-100 wallboard-card d-flex flex-column justify-content-between">
      <div class="row">
        <div class="col-6" ng-repeat="headerTitle in $ctrl.headerTitle track by $index">
          <div class="wallboard-card-header border-bottom pb-3">
            <h4 class="h4 mb-0 font-weight-lighter ellipsize">
              {{ headerTitle || $ctrl.item.name }}
            </h4>
          </div>
          <div class="wallboard-card-body d-flex">
            <h3 class="mt-5 markdownContainer" marked="$ctrl.getRemovedMarkdownString($ctrl.description[$index])"></h3>
          </div>
        </div>
      </div>
      <div class="wallboard-card-footer border-top d-flex">
        <badge-status class="mr-2" status="$ctrl.item.status"></badge-status>
        <span class="pl-3 ml-auto text-muted text-right font-weight-lighter ellipsize">
          {{ $ctrl.item.owner.name }}
        </span>
        <span class="pl-2 text-muted text-nowrap font-weight-lighter">
          +{{ $ctrl.item.teamMembers }} Members
        </span>
      </div>
    </div>`,

  controller() {
    const $ctrl = {
      getRemovedMarkdownString
    };
    return $ctrl;
  }
};
