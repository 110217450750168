// Basic wrapper to implement the default bootstrap toggle elements.
// Currently only applies to tooltip
export default function($parse) {
  'ngInject';

  return {
    restrict: 'A',

    link: (scope, element, attrs) => {
      var model = $parse(attrs.userPhotoModel),
        modelSetter = model.assign;
      element.bind('change', function() {
        //Call apply on scope, it checks for value changes and reflect them on UI
        scope.$apply(function() {
          //set the model value
          modelSetter(scope, element[0].files[0]);
        });
      });
    },
  };
}
