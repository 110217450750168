/*
Created by Adam on 27/7/17
*/

import store from 'store';
import html from './landing.html';

import { fetchUser, logout, setTeamName } from 'logic/user';
import { createInitiative, initInitiative } from 'logic/initiative';
import { initBallot } from 'logic/ballot';

const isProd = process.env.NODE_ENV === 'production';

export default {
  template: `
    <div class="landing" id="landing-container">
      <div class="main text-center">
        <h4>Your team check-in is being created...</h4>
      </div>
    </div>
  `,

  controller($location, $ngRedux, $timeout) {
    `ngInject`;

    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onStartTeamCheckin,
      isProd,
      createTeamCheckin,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.teamName = $location.$$search.teamName;
      $ctrl.initInitiative();
      $ctrl.initBallot();
      if ($ctrl.teamName) {
        $ctrl.onStartTeamCheckin($ctrl.teamName);
      } else {
        $location.path('/initiatives');
      }
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.teamCheckinSuccess && !$ctrl.redirectedCheckin) {
        $ctrl.redirectedCheckin = true;
        $ctrl.teamName = '';
        $timeout(() => {
          $location
            .path(`initiatives/${$ctrl.initiative.id}/checkin/1`)
            .search('teamName', null);
        });
      }
      if ($ctrl.planTeamCheckin && $ctrl.checkpointsLoaded) {
        $ctrl.createTeamCheckin();
        $ctrl.planTeamCheckin = false;
      }
    }

    function onStartTeamCheckin(teamName) {
      if ($ctrl.checkpointsLoaded) {
        $ctrl.createTeamCheckin();
      } else {
        $ctrl.planTeamCheckin = true;
      }
    }

    function createTeamCheckin() {
      const userId = store.get('user');
      $ctrl.createInitiative({
        name: $ctrl.teamName,
        type: 'lite',
        creator: userId,
        owner: userId,
      });
    }

    function mapState(state) {
      return {
        isLoggedIn: !!state.user.data.id,
        teamName_: state.user.teamName,
        props: state.user.data,
        initiative: state.initiative.data,
        creatingTeamCheckin: state.initiative.loading || state.ballot.updating,
        teamCheckinSuccess: state.ballot.opening_lite_success,
        initiativeError: state.initiative.error,
        ballotError: state.ballot.error,
        checkpointsLoaded: state.checkpoints.success,
      };
    }

    function mapDispatch() {
      return {
        //landing,
        createInitiative,
        logout,
        setTeamName,
        initInitiative,
        initBallot,
      };
    }
  },
};
