export default {
  bindings: {
    awards: '<',
  },

  template: `
    <div class="p-4 pb-0">
      <div class="row">
        <div class="col-sm-4 col-md-3 col-lg-2 mb-2" ng-repeat="(key, item) in $ctrl.awards" ng-show="item.active">
          <award
            type="badge"
            name="item.name"
            active="item.active"
            class="card-badge-award">
          </award>
        </div>
      </div>
    </div>
        `,
};
