import { createImpediment, initImpediment } from '../../logic/impediment';

export default {
  template: `
    <mobile-nav ng-model="$ctrl.mobileMenuChecked"></mobile-nav>
    <div class="page-wrapper" ng-class="{'slided-wrapper': $ctrl.mobileMenuChecked}">
      <header-site menu-opened="$ctrl.mobileMenuChecked"></header-site>
      <div class="container impediments-new content-wrapper">
        <main class="main">
          <header>
            <h2>New impediment</h2>
          </header>
          <hr class="my-4">
          <section>
            <impediments-form
              name="Create"
              props="$ctrl.props"
              creating="$ctrl.creating"
              on-submit="$ctrl.onCreate(props)"
              on-cancel="$ctrl.onCancel()">
            </impediments-form>
          </section>
        </main>
      </div>
    </div>`,

  controller($ngRedux, $location, $scope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onCancel,
      onCreate,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.initImpediment();
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.success) {
        $location.path(`/impediments`);
      }
    }

    function mapState(state) {
      return {
        props: state.impediment.data,
        success: state.impediment.createSuccess,
        creating: state.impediment.creating,
      };
    }

    function mapDispatch() {
      return {
        createImpediment,
        initImpediment,
      };
    }

    function onCancel() {
      $location.path(`/impediments`);
    }

    function onCreate(props) {
      $ctrl.createImpediment(props);
    }
  },
};
