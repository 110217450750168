import { fetchImpediment, removeImpediment } from '../../logic/impediment';
import html from './show.html';
import { fetchInitiatives, cancelInitiatives } from 'logic/initiatives';
import { each, find, isEmpty, map, some, pickBy, identity } from 'lodash';

export default {
  template: html,

  controller($ngRedux, $location, $routeParams) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onUpdate,
      onRemove,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.fetchImpediment($routeParams.id);
      $ctrl.fetchInitiatives({});
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.removeSuccess) {
        $location.path(`/impediments`);
      }
    }

    function mapState(state) {
      let initiatives = state.initiatives.data;
      let impediments = state.impediment.data;
      let linkedObjects = impediments.objects;

      if (!linkedObjects) {
        linkedObjects = [];
      }

      let numberOfLinked = 0;

      each(initiatives, (initiative, key) => {
        initiative.active = false;
        linkedObjects.forEach(item => {
          if (item.object.id === initiative.id && item.type === 'Initiative') {
            initiative.active = true;
            numberOfLinked++;
          }
        });
      });

      let missedInitatives = linkedObjects.length - numberOfLinked;

      return {
        initiatives,
        props: impediments,
        loading: state.impediment.loading,
        fetchSuccess: state.impediment.fetchSuccess,
        removing: state.impediment.removing,
        removeSuccess: state.impediment.removeSuccess,
        missedInitatives,
        loadingInitiatives:
          state.initiatives.data.length > 0 ? false : state.initiatives.loading,
      };
    }

    function mapDispatch() {
      return {
        fetchImpediment,
        fetchInitiatives,
        removeImpediment,
      };
    }

    function onUpdate() {
      $location.path(`/impediments/${$routeParams.id}/edit`);
    }

    function onRemove() {
      $ctrl.removeImpediment($ctrl.props);
    }
  },
};
