import {
  logout,
  signUp,
  loginWithGoogle,
  loginWithGitHub,
  loginWithFacebook,
  setTeamName,
} from 'logic/user';
import { fetchInvite, initInvite } from 'logic/invite';

import store from 'store';

export default {
  template: `
    <main class="container-flex login-container">
      <section class="login-box">
        <a href="/" class="login-logo">
          <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          init
        </a>
        <small
          ng-if="$ctrl.referrer"
        >
          You are invited here by <strong>{{$ctrl.referrer.name || $ctrl.referrer.email}}</strong>
        </small>

        <div class="login-extras">
          <button class="btn btn-red btn-social btn-google mb-2"
             ng-click="$ctrl.onLoginWithGoogle()"
          >
            <span class="fa fa-google"></span>
            <span class="ml-4">Sign up with Google</span>
          </button>
          <button class="btn btn-red btn-social btn-github mb-2"
               ng-click="$ctrl.onLoginWithGitHub()"
            >
              <span class="fa fa-github"></span>
              <span class="ml-4">Sign up with GitHub</span>
            </button>
            <button class="btn btn-red btn-social btn-facebook mb-4"
               ng-click="$ctrl.onLoginWithFacebook()"
            >
              <span class="fa fa-facebook"></span>
              <span class="ml-4">Sign up with Facebook</span>
            </button>
        </div>
        <div class="alert alert-danger text-center" ng-show="$ctrl.props.error">
          {{ $ctrl.props.message }}
        </div>
        <small class="text-danger error-msg mt-2">{{$ctrl.props.signupError}}</small>
        <small class="mb-2 text-center d-block">Or sign up with email</small>
        <div class="login-form">
      <form
        name="$ctrl.form" 
        ng-submit="$ctrl.signUp(username, password, name)"
      >
        <div class="login-fieldset">
          <input
            id="name"
            class="login-textinput"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            spellcheck="false"
            ng-model="name"
            ng-init="name=$ctrl.name"
            required />
          <label class="login-label" for="name">
            Name
          </label>
        </div>
        
        <div>
          <div class="login-fieldset">
            <input
              id="email"
              name="email"
              class="login-textinput"
              type="email"
              autocorrect="off"
              autocapitalize="none"
              spellcheck="false"
              ng-model="username"
              required />
            <label class="login-label" for="email">
              Email address
            </label>
          </div>
        </div>
          
        <div class="login-fieldset">
          <input
            id="password"
            class="login-textinput"
            type="password"
            ng-model="password"
            required />
          <label class="login-label" for="password">
            Password
          </label>
        </div>
        <button type="submit" class="btn btn-primary btn-lg btn-block" ng-disabled="$ctrl.props.signupLoading" id='signup_button'>
          <i class='fa fa-spinner fa-spin ' ng-if="$ctrl.props.signupLoading"></i>
          Sign up
        </button>
      </form>
    </div>
        <div class="text-small mt-2 text-center">
          Already have an account? <a ng-click="$ctrl.signIn()">Sign in now!</a>
        </div>
        <small class="login-terms" ng-if="$ctrl.teamName">
          After signing up, we will create a checkin for your team: {{$ctrl.teamName}}
          <button ng-click="$ctrl.onIgnoreTeamCheckin()">No</button>
        </small>
        <small class="login-terms">
          By signing up you are agreeing to our <a href="/legal/terms" target="_blank">Terms &amp; Conditions</a> and <a href="/legal/privacy" target="_blank">Privacy Policy</a>
        </small>
      </section>
    </main>`,

  controller($scope, $rootScope, $location, $ngRedux, $timeout, $routeParams) {
    `ngInject`;

    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onLoginWithGoogle,
      onLoginWithGitHub,
      onLoginWithFacebook,
      signIn,
      onIgnoreTeamCheckin,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      const { teamName } = $location.$$search;
      $ctrl.teamName = teamName;

      // Prevent Logout for anonymous user
      if (!$ctrl.props.data.isAnonymous) {
        $ctrl.logout();
      } else {
        $ctrl.isAnonymous = true;
      }

      // Fetch Invite
      if ($routeParams.invite_code) {
        $ctrl.fetchInvite($routeParams.invite_code);
      }
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      // Anonymous user does not trigger the auth change
      if (
        $ctrl.isAnonymous &&
        $ctrl.props.signupSuccess &&
        !$ctrl.teamCheckin
      ) {
        $rootScope.currentUser = $ctrl.props;
        $ctrl.teamCheckin = true;
        $location.path('/team-checkin');
      }
      /*if (store.get('user')) {
        $timeout(() => $location.path(`/initiatives`));
      }*/

      if ($ctrl.invite.success) {
        $ctrl.referrer = {
          name: $ctrl.invite.data.from_name,
          email: $ctrl.invite.data.from,
        };
        $scope.username = $ctrl.invite.data.to;
        $ctrl.initInvite();
      }
    }

    function signIn() {
      $location
        .path('/login')
        .search('teamName', $routeParams.teamName)
        .search('invite_code', null);
    }

    function onLoginWithGoogle() {
      console.info('Login With Google');
      $ctrl.loginWithGoogle();
    }

    function onLoginWithGitHub() {
      console.info('Login With GitHub');
      $ctrl.loginWithGitHub();
    }

    function onLoginWithFacebook() {
      $ctrl.loginWithFacebook();
    }

    function onIgnoreTeamCheckin() {
      $location.path('/register').search('teamName', null);
    }

    function mapState(state) {
      return {
        props: state.user,
        invite: state.invite,
      };
    }

    function mapDispatch() {
      return {
        logout,
        signUp,
        loginWithGoogle,
        loginWithGitHub,
        loginWithFacebook,
        setTeamName,
        fetchInvite,
        initInvite,
      };
    }
  },
};
