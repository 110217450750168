export default {
  bindings: {
    onClick: '&',
    props: '<',
    size: '@',
    compact: '@',
    type: '@',
  },

  template: `
    <div
      class="d-flex justify-content-between mb-2 edit-kpi-container"
      ng-repeat="(key, kpi) in $ctrl.props"
      ng-if="kpi.type === $ctrl.type"
      ng-show="!$ctrl.compact || ($ctrl.compact && $first || $ctrl.expanded)"
      id="{{kpi.id}}"
    >
      <span class="mr-auto">
        {{ kpi.name }}
      </span>
      <button
        type="button"
        class="btn btn-primary btn-sm ml-2"
        ng-if="$ctrl.onClick"
        ng-click="$ctrl.onClick({ kpi: kpi })">
        Modify
      </button>
    </span>
    <span
      ng-if="$ctrl.props.length > 1 && $ctrl.compact"
      ng-hide="$ctrl.expanded"
      ng-click="$ctrl.expanded = true"
      class="tag btn btn-{{ $ctrl.size || 'sm' }} btn-secondary mr-1 mb-1">
      <button
        type="button"
        class="btn btn-link p-0">
        + {{ $ctrl.props.length - 1 }} more
      </button>
    </div>`,
};
