export const sendEvent = (
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
  fieldsObject
) => {
  if (!ga) {
    console.error('GA not loaded!');
    return;
  }

  ga(
    'send',
    'event',
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    fieldsObject
  );
};
