export default {
  transclude: true,

  bindings: {
    for: '@',
    helpText: '@',
    label: '@',
    hasError: '<',
  },

  template: `
    <div class="form-group row" ng-class="{ 'has-danger': $ctrl.hasError }">
      <label for="{{ $ctrl.for }}" class="col-sm-4 col-form-label text-gray-dark">
        {{ $ctrl.label }}
        <small class="form-text text-muted" ng-bind-html="$ctrl.helpTextHtml" />
      </label>
      <div class="col-sm-8" ng-transclude></div>
    </div>`,

  controller($sce) {
    'ngInject';
    const $ctrl = {
      $onInit,
    };

    return $ctrl;

    function $onInit() {
      $ctrl.helpTextHtml = $sce.trustAsHtml($ctrl.helpText);
    }
  },
};
