/**
 * Created by ilya on 7/14/17.
 */
import mobileNav from './mobileNav';

import './mobileNav.scss';

export default angular
  .module('App.mobileNav', [])
  .component('mobileNav', mobileNav).name;
