export default {
  transclude: true,

  bindings: {
    item: '<',
    headerTitle: '@',
  },

  template: `
    <div class="h-100 wallboard-card d-flex flex-column justify-content-between">
      <div class="wallboard-card-header border-bottom pb-3">
        <a ng-href="/initiatives/{{$ctrl.item.id}}" class="wallboard-name">
          <h4 class="h4 mb-0 font-weight-lighter ellipsize">
            {{ $ctrl.headerTitle || $ctrl.item.name }}
          </h4>
        </a>
      </div>
      <div class="wallboard-card-body d-flex" ng-transclude></div>
      <div class="wallboard-card-footer border-top d-flex">
        <badge-status class="mr-2" status="$ctrl.item.status"></badge-status>
        <span class="pl-3 ml-auto text-muted text-right font-weight-lighter ellipsize">
          {{ $ctrl.item.owner.name }}
        </span>
        <span class="pl-2 text-muted text-nowrap font-weight-lighter">
          +{{ $ctrl.item.teamMembers }} Members
        </span>
      </div>
    </div>`,
};
