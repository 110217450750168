// Style needs to come before components
import 'jquery';
import 'tether';
import 'bootstrap';
import 'clipboard';

import angular from 'angular';
import 'app.scss';
import 'date.css';

// Bootstrap dependencies

//import 'angularjs-datepicker';

import 'marked';
import 'angular-marked';
import angularRoute from 'angular-route';
import ngRedux from 'ng-redux';
import ngClipboard from 'ngclipboard';
import angularDateTimeInput from 'angular-date-time-input';
import * as firebase from 'firebase';
import 'firebase/firestore'; // Firestore Side Effect
import store from 'store';

import appConfig from './config';
import firebaseConfig, {
  production,
  productionDomain,
} from './config/firebase';
import sentryConfig from './config/sentry.io';
import appComponents from './components';
import appDirectives from './directives';
import appFilters from './filters';
import { TOKEN_REFRESH_TIME } from './config/consts';
import { watchActivities } from './logic/activities';
import './components/widgets/date_picker/datepicker';
import { initIntercom, initIntercomLoggedOut } from './utils';

import 'autotrack/autotrack';

if (window.Raven) {
  const DSN = sentryConfig();
  console.info('DSN:', DSN);
  window.Raven.config(DSN).install();
  const disableSubmit = false;
  if (disableSubmit) {
    Raven.captureExceptionEx = () => {};
  } else {
    Raven.captureExceptionEx = Raven.captureException;
  }
} else {
  window.Raven = {
    captureException: () => {},
    captureExceptionEx: () => {},
  };
}

const config = firebaseConfig();
console.info('Firebase Config:', config);
console.info('Env:', process.env.NODE_ENV);
firebase.initializeApp(config);
console.info('Firebase App', firebase.app());

// Allow date object for Firestore
const firestore = firebase.firestore();
const settings = { /* your settings... */ timestampsInSnapshots: true };
firestore.settings(settings);

window.addEventListener('load', () => {
  if (window.ga) {
    window.ga('require', 'eventTracker');
    window.ga('require', 'outboundLinkTracker');
    window.ga('require', 'pageVisibilityTracker');
    window.ga('require', 'urlChangeTracker', {
      shouldTrackUrlChange: function(newPath, oldPath) {
        newPath = newPath.split('?')[0];
        oldPath = oldPath.split('?')[0];
        return newPath != oldPath;
      },
    });
  }
});

export const authCallback = ($location, $rootScope, $ngRedux, user) => {
  if (user) {
    // Update User Info
    if (Array.isArray(user.providerData) && user.providerData.length > 0) {
      // user is linked to other social providers
      const userData = user.providerData[user.providerData.length - 1];
      firebase
        .database()
        .ref(`users/${user.uid}`)
        .on('value', snapshot => {
          let name = userData.displayName;
          let photoURL = userData.photoURL;
          if (snapshot.exists()) {
            name = snapshot.val().name ? snapshot.val().name : name;
            photoURL = snapshot.val().photoURL
              ? snapshot.val().photoURL
              : photoURL;
          }
          if (
            userData.providerId === 'google.com' ||
            userData.providerId === 'github.com' ||
            userData.providerId === 'facebook.com'
          ) {
            firebase
              .database()
              .ref('users')
              .child(user.uid)
              .update({
                name,
                photoURL,
                email: user.email,
              });
          }

          user.updateProfile({
            displayName: userData.displayName,
            photoURL: photoURL,
          });
        });
    }

    if (
      !$rootScope.anonymousUser &&
      firebase.auth().currentUser &&
      firebase.auth().currentUser.isAnonymous
    ) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(idToken => {
          // console.info('Auth Token:', idToken);
          // refresh token every 30 mins
          if (window.tokenTimer) {
            clearInterval(window.tokenTimer);
          }

          window.tokenTimer = setInterval(() => {
            firebase
              .auth()
              .currentUser.getIdToken(true)
              .then(refresthedToken => {
                store.set('Authorization', refresthedToken);
              });
          }, TOKEN_REFRESH_TIME);

          /**
           * TODO: recursive import
           * */
          $ngRedux.dispatch({
            type: 'app/user/LOGIN_SUCCESS',
            payload: {
              isAnonymous: true,
              id: user.uid,
            },
          });

          $rootScope.anonymousUser = user;
          store.set('Authorization', idToken);
          store.set('user', user.uid);
          initIntercom(idToken, 'eprk78wq', null, user.displayName, user.uid);

          $ngRedux.dispatch({
            type: 'app/awards/FETCH',
          });

          $ngRedux.dispatch({
            type: 'app/checkpoints/FETCH',
          });

          $ngRedux.dispatch({
            type: 'app/kpis/FETCH',
          });

          $ngRedux.dispatch({
            type: 'app/groups/WATCH',
          });

          if (
            $location.$$path !== '/survey' &&
            $location.$$path !== '/' &&
            (!$location.$$path || !$location.$$path.startsWith('/checkin')) && // /checkin && /checkin/{code} pages
            (!$location.$$path || !$location.$$path.startsWith('/ballots/'))
          ) {
            $location.path('/login');
            window.prevPath = null;
          }
          const phase = $rootScope.$root.$$phase;
          if (phase !== '$apply' || phase !== '$digest') {
            $rootScope.$apply();
          }
          // else {} // user is logged in and another provider is added
        })
        .catch(function(error) {
          Raven.captureExceptionEx(error);
          $location.path('/');
        });
    }

    if (
      !$rootScope.currentUser &&
      firebase.auth().currentUser &&
      !firebase.auth().currentUser.isAnonymous
    ) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(idToken => {
          // console.info('Auth Token:', idToken);
          // refresh token every 30 mins
          if (window.tokenTimer) {
            clearInterval(window.tokenTimer);
          }

          window.tokenTimer = setInterval(() => {
            firebase
              .auth()
              .currentUser.getIdToken(true)
              .then(refresthedToken => {
                if (store.get('Authorization')) {
                  // valid auth token exists
                  // console.info('Token Refreshed:', refresthedToken);
                  store.set('Authorization', refresthedToken);
                }
              });
          }, TOKEN_REFRESH_TIME);

          /**
           * TODO: recursive import
           * */
          $ngRedux.dispatch({
            type: 'app/user/LOGIN_SUCCESS',
            payload: {
              name: user.displayName,
              username: user.email,
              email: user.email,
              // photoURL: userData.photoURL,
              id: user.uid,
            },
          });

          if (window.ga) {
            window.ga('set', 'userId', user.uid);
            window.ga('send', 'pageview');
          }

          $ngRedux.dispatch({
            type: 'app/awards/FETCH',
          });

          $ngRedux.dispatch({
            type: 'app/checkpoints/FETCH',
          });

          $ngRedux.dispatch({
            type: 'app/kpis/FETCH',
          });

          $ngRedux.dispatch({
            type: 'app/groups/WATCH',
          });

          $ngRedux.dispatch(watchActivities());

          $rootScope.currentUser = user;
          store.set('Authorization', idToken);
          store.set('user', user.uid);
          initIntercom(
            idToken,
            'eprk78wq',
            user.email,
            user.displayName,
            user.uid
          );
          const { teamName } = $location.$$search;
          if (
            ($location.$$path === '/register' ||
              $location.$$path === '/login' ||
              $location.$$path === '/') &&
            (!window.prevPath ||
              window.prevPath === '/' ||
              window.prevPath === '/register' ||
              window.prevPath === '/login')
          ) {
            if (teamName) {
              $location.path('/team-checkin');
            } else {
              $location.path('/home');
            }
            window.prevPath = null;
          } else {
            $location.path(window.prevPath);
            window.prevPath = null;
          }
          $rootScope.$apply();

          // else {} // user is logged in and another provider is added
        })
        .catch(function(error) {
          Raven.captureExceptionEx(error);
          const log = {
            timestamp: firebase.database.ServerValue.TIMESTAMP,
            uid: user.uid,
          };

          if (error.code) {
            log.code = error.code;
          }

          if (error.message) {
            log.message = error.message;
          }

          firebase
            .database()
            .ref('logs/login/' + user.uid)
            .push(log);
          $location.path('/');
        });
    }
  } else {
    initIntercomLoggedOut('eprk78wq');
    // Kill token refresh timer
    if (window.tokenTimer) {
      clearInterval(window.tokenTimer);
    }

    if ($ngRedux) {
      $ngRedux.dispatch({
        type: 'app/user/LOGOUT_SUCCESS',
      });
    }

    if (!$rootScope.currentUser) {
      window.prevPath = `${$location.$$path}`;
    }

    $rootScope.currentUser = null;
    $rootScope.anonymousUser = null;
    // if it is not the login, register, signup
    if (
      window.prevPath !== '/' &&
      window.prevPath !== '/legal/terms' &&
      window.prevPath !== '/legal/privacy' &&
      window.prevPath !== '/login' &&
      window.prevPath !== '/register' &&
      window.prevPath !== '/forgot-password' &&
      window.prevPath !== '/survey' &&
      (!window.prevPath || !window.prevPath.startsWith('/checkin')) && // /checkin && /checkin/ pages
      (!window.prevPath || !window.prevPath.startsWith('/ballots/'))
    ) {
      $location.path('/login');
      $rootScope.$apply();
    }
    if (
      window.prevPath === '/' ||
      window.prevPath === '/survey' ||
      (window.prevPath && window.prevPath.startsWith('/checkin')) || // checkin && /checkin/ pages
      (window.prevPath && window.prevPath.startsWith('/ballots/'))
      // || /initiatives\/.*\/checkin\//.test(window.prevPath) // checkin url
    ) {
      firebase
        .auth()
        .signInAnonymously()
        .then(user => {});
    }
  }
};

// Quick redirect implementation
function checkAuth($location, $rootScope, $ngRedux) {
  'ngInject';
  if (window.location.hostname == `${production.projectId}.firebaseapp.com`) {
    window.location.href = `https://${productionDomain}${
      window.location.pathname
    }`;
  }

  $rootScope.currentUser = null;
  firebase
    .auth()
    .onAuthStateChanged(
      authCallback.bind(null, $location, $rootScope, $ngRedux)
    );
}

// Quick hack to add a class name on the body
function bodyClassName($location, $rootScope) {
  'ngInject';
  $rootScope.$on('$locationChangeSuccess', (a, b) => {
    const paths = $location
      .path()
      .split('/')
      .filter(Boolean);
    $rootScope.bodyClass = `section-${paths[0]}`;
  });
}

// Set SPA page title
function spaPageTitle($route, $rootScope) {
  'ngInject';
  $rootScope.$on('$routeChangeSuccess', function() {
    $rootScope.title = $route.current.title;
  });
}

// Core modules
const modules = [
  angularRoute,
  ngRedux,
  'hc.marked',
  'ui.dateTimeInput',
  '720kb.datepicker',
  ngClipboard,
  appConfig,
  appComponents,
  appDirectives,
  appFilters,
];

export default angular
  .module('App', modules)
  .run(checkAuth)
  .run(bodyClassName)
  .run(spaPageTitle).name;
