import { combineReducers } from 'redux';

import checkpoints, { checkpointsFetch } from './constants/checkpoints';
import awards, { awardsFetch } from './constants/awards';
import questions, { questionsFetch } from './constants/questions';
import impedimentTypes, { impedimentTypesFetch } from './constants/impediments';
import kpiTypes, { kpiTypesFetch } from './constants/kpis';
import questionSet, {
  saveQuestionSetLogic,
  fetchQuestionSetsLogic,
} from './questionSet';

import survey, { surveyFetch, surveyUpdate } from './morale-survey';
import surveyResult, { surveyResultFetch } from './morale-survey-result';
import initiative, {
  initiativeCreate,
  initiativeFetch,
  initiativeUpdate,
  initiativeDelete,
  initiativeFetchWithBallot,
  initiativeWatch,
  initiativeUpdateKpi,
  initiativeStar,
  removeCheckinLogic,
} from './initiative';
import impediment, {
  impedimentCreate,
  impedimentFetch,
  impedimentUpdate,
  impedimentRemove,
} from './impediment';
import impediments, { impedimentsFetch } from './impediments';
import initiatives, {
  initiativesFetch,
  watchInitiativesLogic,
  initiativesStar,
} from './initiatives';
import user, {
  userLogin,
  userLogout,
  userFetch,
  updateProfile,
  loginWithGoogleLogic,
  loginWithGitHubLogic,
  loginWithFacebookLogic,
  forgotPasswordLogic,
  confirmCodeLogic,
  signUpLogic,
  updateProfileLogic,
  updateAnonymousUserDataLogic,
} from './user';
import ballot, {
  ballotUpdate,
  votingOpenClose,
  ballotFetch,
  ballotWatch,
  ballotJoin,
  nextCheckpointLogic,
  setVoteVisibilityLogic,
  ballotFetchWithCode,
} from './ballot';
import vote, { voteCreate } from './vote';
import activities, {
  watchActivitiesLogic,
  activitiesLogic,
  fetchMoreActivitiesLogic,
  initRecentActivitiesLogic,
} from './activities';
import groups, { groupsFetch } from './groups';
import group, {
  groupFetch,
  updateGroupUserLogic,
  groupCreateLogic,
  groupRemoveLogic,
  groupUpdateLogic,
  updateGroupPriorityLogic,
} from './group';
import users, { usersFetchLogic, usersGetProfile } from './users';
import invite, { inviteCreate, inviteFetch, groupInvitesFetch } from './invite';

// Combine logic
export const rootLogic = [
  checkpointsFetch,
  awardsFetch,
  questionsFetch,
  surveyFetch,
  surveyUpdate,
  surveyResultFetch,
  initiativeCreate,
  initiativeFetch,
  initiativeUpdate,
  initiativeDelete,
  initiativesFetch,
  initiativeFetchWithBallot,
  initiativeUpdateKpi,
  initiativeStar,
  removeCheckinLogic,
  watchInitiativesLogic,
  initiativesStar,
  initiativeWatch,
  signUpLogic,
  userLogin,
  userLogout,
  userFetch,
  updateProfile,
  loginWithGoogleLogic,
  loginWithGitHubLogic,
  loginWithFacebookLogic,
  forgotPasswordLogic,
  confirmCodeLogic,
  updateProfileLogic,
  votingOpenClose,
  ballotFetch,
  ballotWatch,
  voteCreate,
  ballotJoin,
  nextCheckpointLogic,
  setVoteVisibilityLogic,
  watchActivitiesLogic,
  activitiesLogic,
  initRecentActivitiesLogic,
  fetchMoreActivitiesLogic,
  groupsFetch,
  groupFetch,
  groupCreateLogic,
  groupRemoveLogic,
  groupUpdateLogic,
  updateGroupUserLogic,
  updateGroupPriorityLogic,
  usersFetchLogic,
  usersGetProfile,
  impedimentCreate,
  impedimentUpdate,
  impedimentRemove,
  impedimentFetch,
  impedimentsFetch,
  impedimentTypesFetch,
  kpiTypesFetch,
  ballotFetchWithCode,
  inviteCreate,
  inviteFetch,
  groupInvitesFetch,
  updateAnonymousUserDataLogic,
  saveQuestionSetLogic,
  fetchQuestionSetsLogic,
];

// Combine reducers
export const rootReducer = combineReducers({
  checkpoints,
  awards,
  questions,
  survey,
  surveyResult,
  initiative,
  ballot,
  initiatives,
  user,
  users,
  vote,
  activities,
  groups,
  group,
  impediment,
  impediments,
  impedimentTypes,
  kpiTypes,
  invite,
  questionSet,
});
