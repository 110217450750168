import html from './up-vote-button.html';
import { getImpedimentVoteCount } from '../../utils';

export default {
  bindings: {
    onUpVote: '&',
    onDownVote: '&',
    props: '<',
    userId: '<',
  },

  template: html,

  controller() {
    const $ctrl = {
      getImpedimentVoteCount,
    };

    return $ctrl;
  },
};
