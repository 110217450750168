export default {
  bindings: {
    divider: '<',
  },

  transclude: {
    left: 'columnLeft',
    right: 'columnRight',
  },

  template: `
    <section class="row">
      <div class="col-md-4 mb-3 mb-md-0" ng-transclude="left"></div>
      <div class="col-md-8" ng-transclude="right"></div>
    </section>
    <hr class="my-4" ng-hide="$ctrl.divider === false">`,
};
