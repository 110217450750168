export default {
  bindings: {
    action: '&',
    onClick: '&',
    isEditing: '<',
    props: '<',
    size: '@',
    compact: '@',
    margins: '@',
  },

  template: `
    <div class="tagGroup" ng-class="{'tagGroup--margins' : $ctrl.margins}">
      <button
        class="tagGroup__tag"
        ng-repeat="tag in $ctrl.props track by $index"
        ng-show="!$ctrl.compact || ($ctrl.compact && $first || $ctrl.expanded)"
        ng-click="$ctrl.onClick({ tag: tag })"
        ng-if="$ctrl.onClick"
        type="button">
        {{ tag }}
      </button>
      <button
        type="button"
        class="tagGroup__tag tagGroup__tag--selected"
        ng-if="$ctrl.isEditing"
        ng-click="$ctrl.action({ tag: tag })"
        ng-show="!$ctrl.compact || ($ctrl.compact && $first || $ctrl.expanded)">
        {{ tag }}
      </button>
      <button
        ng-if="$ctrl.props.length > 1 && $ctrl.compact"
        ng-hide="$ctrl.expanded"
        ng-click="$ctrl.expanded = true"
        class="tagGroup__tag"
        type="button"
        data-toggle="tooltip"
        data-animation="false"
        data-placement="bottom"
        title="click to show all tags"
        >
        + {{ $ctrl.props.length - 1 }} more
        </button>
    </div>`,
};
