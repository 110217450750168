import { forgotPassword, submitConfirmCode, initUser } from 'logic/user';
import store from 'store';

export default {
  template: `
    <main class="container-flex login-container">
      <section class="login-box">
          <a href="/" class="login-logo">
            <i class="fa fa-check-circle-o" aria-hidden="true"></i>
            init
          </a>
          <div class="alert alert-danger text-center" ng-show="$ctrl.props.error">
            {{ $ctrl.props.message }}
          </div>
          <forgot-form 
            on-submit="$ctrl.forgotPassword(username)"
            submitting="$ctrl.props.forgotLoading"
            ng-if="!$ctrl.props.forgotSuccess"
          >
          </forgot-form>
          <div ng-if="$ctrl.props.forgotSuccess && !$ctrl.props.confirmSuccess">
            <p class="text-success">Password reset link sent to {{$ctrl.props.forgotEmail}}</p>
            <a href="/">Login</a>
          </div>
          <p class="text-success" ng-if="$ctrl.props.confirmSuccess">Password reset successfully</p>
          <p class="text-danger">{{$ctrl.props.forgotError}}</p>
      </section>
    </main>`,

  controller($ngRedux) {
    `ngInject`;

    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.initUser();
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      return {
        props: state.user,
      };
    }

    function mapDispatch() {
      return {
        forgotPassword,
        initUser,
      };
    }
  },
};
