/**
 * Created by ilya on 6/9/17.
 */
import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import store from 'store';
import axios from 'axios';

import getFirebaseConfig from '../config/firebase';
import { sendEvent } from 'analytics';

const firebaseProjectId = getFirebaseConfig().projectId;

export const INIT_INVITE = 'app/invite/INIT_INVITE';
export const CREATE_INVITE = 'app/invite/CREATE_INVITE';
export const CREATE_INVITE_SUCCESS = 'app/invite/CREATE_INVITE_SUCCESS';
export const FETCH_INVITE = 'app/invite/FETCH_INVITE';
export const FETCH_INVITE_SUCCESS = 'app/invite/FETCH_INVITE_SUCCESS';
export const FETCH_GROUP_INVITES = 'app/invite/FETCH_GROUP_INVITES';
export const FETCH_GROUP_INVITES_SUCCESS =
  'app/invite/FETCH_GROUP_INVITES_SUCCESS';

export const ERROR = 'app/invite/ERROR';
export const CANCEL = 'app/invite/CANCEL';

// Initial State
export const initialState = {
  creating: false,
  loading: false,
  updating: false,
  success: false,
  error: '',
  email: '',
  data: null,
  invited: [],
};

export function inviteUserByEmail( { email, group, initiative } ) {
  return {
    type: CREATE_INVITE,
    payload: { email, group, initiative },
  };
}

export function cancelInvite() {
  return {
    type: CANCEL,
  };
}

export function initInvite() {
  return {
    type: INIT_INVITE,
  };
}

export function fetchInvite(code) {
  return {
    type: FETCH_INVITE,
    payload: {
      code,
    },
  };
}

export function fetchGroupInvites(groupId) {
  return {
    type: FETCH_GROUP_INVITES,
    payload: {
      groupId,
    },
  };
}

export const inviteCreate = createLogic({
  latest: true,
  type: CREATE_INVITE,
  cancelType: CANCEL,
  warnTimeout: 0,
  processOptions: {
    successType: CREATE_INVITE_SUCCESS,
    failType: ERROR,
  },
  process({ action }) {
    const { email } = action.payload;
    const userid = store.get('user');
    return axios
      .post(
        `https://us-central1-${firebaseProjectId}.cloudfunctions.net/sendInvitation`,
        action.payload,
        {
          headers: {
            Authorization: store.get('Authorization'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        return response.data;
      });
  },
});

export const inviteFetch = createLogic({
  latest: true,
  type: FETCH_INVITE,
  cancelType: CANCEL,
  warnTimeout: 0,
  processOptions: {
    successType: FETCH_INVITE_SUCCESS,
    failType: ERROR,
  },
  process({ action }) {
    const { email } = action.payload;
    const userid = store.get('user');

    return firebase
      .database()
      .ref(`invites`)
      .orderByChild('code')
      .equalTo(action.payload.code)
      .once('value')
      .then(snapshot => {
        const invite = snapshot.val();
        if (invite) {
          return invite[Object.keys(invite)[0]];
        } else {
          const error = {
            message: 'Invalid Invitation Code',
          };
          throw error;
        }
      });
  },
});

export const groupInvitesFetch = createLogic({
  latest: true,
  type: FETCH_GROUP_INVITES,
  cancelType: CANCEL,
  warnTimeout: 0,
  processOptions: {
    successType: FETCH_GROUP_INVITES_SUCCESS,
    failType: ERROR,
  },
  process({ action }) {
    const { groupId } = action.payload;
    const userid = store.get('user');

    return firebase
      .database()
      .ref(`invites`)
      .orderByChild('group')
      .equalTo(groupId)
      .once('value')
      .then(snapshot => {
        const invitesObj = snapshot.val();
        if (typeof invitesObj === 'object') {
          const invites = [];
          for (let key in invitesObj) {
            invites.push({ ...invitesObj[key], id: key });
          }
          return invites;
        } else {
          return [];
        }
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  const { invited } = state;

  switch (type) {
    case CREATE_INVITE:
      return {
        ...state,
        creating: true,
        success: false,
        email: action.payload.email,
        error: '',
      };
    case CREATE_INVITE_SUCCESS:
      return {
        ...state,
        creating: false,
        success: true,
        invited: [...invited, payload.data],
        error: '',
      };
    case FETCH_GROUP_INVITES:
      return {
        ...state,
        groupId: payload.groupId,
        loadingGroup: true,
        loadingGroupSuccess: false,
        error: '',
      };

    case FETCH_GROUP_INVITES_SUCCESS:
      return {
        ...state,
        loadingGroup: false,
        loadingGroupSuccess: true,
        invited: payload,
        error: '',
      };

    case FETCH_INVITE:
      return {
        ...state,
        loading: true,
        success: false,
        code: action.payload.code,
        error: '',
      };

    case FETCH_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: '',
      };

    case ERROR:
      return {
        ...state,
        creating: false,
        success: false,
        error: 'Failed to invite user!',
      };

    case INIT_INVITE:
      return initialState;

    default:
      return state;
  }
}
