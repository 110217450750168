import checkpoint from './checkpoint';
import checkpointRadio from './radio';
import checkpointTable from './table';

import './checkpoint.scss';

export default angular
  .module('App.checkpoint', [])
  .component('checkpoint', checkpoint)
  .component('checkpointRadio', checkpointRadio)
  .component('checkpointTable', checkpointTable).name;
