import { memoize } from 'lodash';

export default () => {
  return memoize(str => {
    if (str === 'Development') {
      return 'Dev';
    } else {
      return str;
    }
  });
};
