import { each, compact, pickBy, identity } from 'lodash';
import { fetchInitiatives, cancelInitiatives } from 'logic/initiatives';
import { starInitiatives } from 'logic/initiatives';
import decorateInitiative from 'decorators/initiative';
import { getUsersProfile } from 'logic/users';

import statuses from 'constants/statuses';
import sortings from 'constants/sortings';
import views from 'constants/views';
import * as Utils from '../../utils';
import { fetchGroups } from 'logic/groups';

import qs from 'qs';

export default {
  bindings: {
    view: '@',
  },

  template: `
    <mobile-nav ng-model="$ctrl.mobileMenuChecked"></mobile-nav>
    <div class="page-wrapper" ng-class="{'slided-wrapper': $ctrl.mobileMenuChecked}">
      <header-site
        wallboard-url="{{ $ctrl.getWallboardUrl($ctrl.params, $ctrl.active.name) }}"
        menu-opened="$ctrl.mobileMenuChecked"
      >
      </header-site>
      <div class="container initiatives-list content-wrapper" ng-class="$ctrl.active.name">
        <main class="main">
          <initiatives-list-header
            props="$ctrl.active"
            views="$ctrl.views"
            statuses="$ctrl.statuses"
            sortings="$ctrl.sortings"
            title="$ctrl.title"
            toggle-view="$ctrl.setView(view)"
            toggle-status="$ctrl.setStatus(status)"
            sort="$ctrl.sort(sorting)"
            on-submit="$ctrl.setSearch(q)"
            search="$ctrl.params.q"
            status="$ctrl.params.status"
            group="$ctrl.params.group"
            sorting="$ctrl.params.sort"
            loading="$ctrl.loading"
            groups="$ctrl.groups"
            sort-by-group="$ctrl.sortByGroup(group)"
          >
          </initiatives-list-header>
          <initiatives-report-header
            props="$ctrl.tasks"
            ng-if="$ctrl.active.name === 'report'">
          </initiatives-report-header>
          <div ng-repeat="item in $ctrl.props track by $index">
            <columns divider="!$last">
              <column-left>
                <initiatives-item-header
                  on-click-tag="$ctrl.setTag(tag)"
                  props="item"
                  shorter-name="$ctrl.getShortName"
                  on-star-initiative="$ctrl.onStarInitiative(id,isStared)"
                  show-tags="true"
                >
                </initiatives-item-header>
              </column-left>
              <column-right>
                <initiatives-list-checkpoints
                  checkpoints="item.checkpointsGrouped"
                  checkpoints-all="$ctrl.checkpoints"
                  checkin="item.checkin.current"
                  completion="item.completion"
                  timespan="item.timespan"
                  rags="item.rags"
                  type="item.type"
                  ng-if="$ctrl.active.name === 'checkpoints'"
                >
                </initiatives-list-checkpoints>
                <initiatives-list-awards
                  item="item"
                  badges="$ctrl.badges"
                  ng-if="$ctrl.active.name === 'awards'">
                </initiatives-list-awards>
                <initiatives-list-performance
                  props="item"
                  ng-if="$ctrl.active.name === 'performance'">
                </initiatives-list-performance>
                <initiatives-list-report
                  props="$ctrl.tasks"
                  item="item"
                  ng-if="$ctrl.active.name === 'report'">
                </initiatives-list-report>
              </column-right>
            </columns>
          </div>
          <div class="text-center" ng-show="$ctrl.props.length === 0">
            <h4 ng-hide="$ctrl.loading">No results found</h4>
            <h6 class="font-weight-normal" ng-hide="$ctrl.loading"><i class="fa fa-info-circle" aria-hidden="true"></i> If you are a new user, an example initiative will be automatically created for you in a few minutes!
              <i class='fa fa-spinner fa-spin'/>
            </h6>
            <h4 ng-show="$ctrl.loading"><i class='fa fa-spinner fa-spin' ></i> Loading initiatives...</h4>
          </div>
        </main>
      </div>
    </div>`,
  reloadOnSearch: false,
  controller($ngRedux, $httpParamSerializer, $location, $timeout) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
      setView,
      setStatus,
      setSearch,
      sort,
      getWallboardUrl,
      mobileMenuChecked: false,
      getShortName: Utils.getShortName,
      onStarInitiative,
      sortByGroup,
      fetch,
      setTag,
      setPath,
      taskParamsFallback,
      taskParams,
    };

    return $ctrl;

    function $onInit() {
      $ctrl.statuses = [...statuses];
      $ctrl.sortings = [...sortings];
      $ctrl.params = { status: '', q: '', sort: '', group: '' };

      let activeView;
      $ctrl.views = views.map(view => {
        if (view.name === $ctrl.view) {
          activeView = view;
        }
        return { ...view };
      });

      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);

      $ctrl.setView(activeView ? activeView : views[0]);

      $ctrl.fetchInitiatives($ctrl.params);
      $ctrl.fetchGroups();
      $ctrl.fetch();

      document.body.scrollTop = 0;
    }

    function $onDestroy() {
      $ctrl.cancelInitiatives();
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      const mobilisations = { ...state.awards.mobilisations };

      const params = taskParams(mobilisations);

      $ctrl.tasks = [];
      each(mobilisations, (task, key) => {
        if (params.indexOf(task.id) > -1) {
          $ctrl.tasks.push(task);
        }
      });

      // mobilisations
      //   .map(task => {
      //     return { ...task };
      //   })
      //   .filter(task => {
      //     return params.indexOf(task.id) > -1;
      //   });

      const checkpoints = angular.copy(state.checkpoints.data);
      console.info('Dic Checkpoints:', checkpoints);

      const props = [...state.initiatives.data].map((initiative, index) => {
        Utils.injectCheckpoints(initiative, checkpoints);
        Utils.injectKpis(initiative, state.kpiTypes.data);
        const prps = decorateInitiative(initiative, mobilisations);

        Utils.injectStars(prps, state);

        $(`#${prps.id}staredUserNames`).attr(
          'data-original-title',
          prps.staredUserNames
        );

        const originalProp = $ctrl.props
          ? $ctrl.props.filter(prop => prop.id === prps.id)
          : [];

        if (
          ((originalProp.length > 0 &&
            originalProp[0].starCount !== prps.starCount) ||
            originalProp.length === 0) &&
          $ctrl.getUsersProfile &&
          prps.stars
        ) {
          const dicUsers = {};
          for (let userId of Object.keys(prps.stars)) {
            if (!state.users.dic[userId]) {
              dicUsers[userId] = true;
            }
          }
          if (Object.keys(dicUsers).length > 0) {
            $timeout(() => {
              $ctrl.getUsersProfile(dicUsers);
            }, 50);
          }
        }

        return prps;
      });

      let query = $location.search();
      if (typeof query !== 'object') {
        query = {};
      }

      const { group } = query;
      if (state.groups.data.length > 0) {
        $ctrl.params.group = group;
      } else {
        $ctrl.params.group = '';
      }
      return {
        users: state.users.dic,
        loading: state.initiatives.loading,
        props,
        checkpoints,
        groups: state.groups.data,
        badges: { ...state.awards.badges },
      };
    }

    function mapDispatch() {
      return {
        fetchInitiatives,
        cancelInitiatives,
        starInitiatives,
        getUsersProfile,
        fetchGroups,
      };
    }

    function fetch() {
      let query = $location.search();
      if (typeof query !== 'object') {
        query = {};
      }
      const { status, q, sorting, order, group } = query;
      const params = {
        status,
        sort: [sorting, order],
        q,
        group,
      };

      $ctrl.fetchInitiatives(params);

      delete params.sort;
      for (let i = 0; i < sortings.length; i++) {
        if (
          sortings[i].params[0] === sorting &&
          sortings[i].params[1] === order
        ) {
          params.sort = sortings[i].value;
        }
      }

      $ctrl.params = params;
    }

    function setView(view) {
      if (typeof view === 'object') {
        view = view.name;
      }

      $ctrl.views = $ctrl.views.map(item => {
        item.active = item.name === view;
        return item;
      });

      console.info('views:', views.find);
      $ctrl.active = $ctrl.views.find(item => item.active);
      if ($ctrl.active) {
        $ctrl.title = $ctrl.active.title || 'Initiatives';
      } else {
        $ctrl.title = 'Initiatives';
      }
    }

    function setStatus(value) {
      if (value) {
        $ctrl.params.status = value;
      } else {
        delete $ctrl.params.status;
      }
      // $location.path('/initiatives').search($ctrl.params);
      // $ctrl.fetchInitiatives($ctrl.params);
      $ctrl.setPath();
    }

    function setSearch(q = '') {
      if (!q) {
        $ctrl.params.q = '';
      } else {
        $ctrl.params.q = q;
      }

      // if (q.length > 2) {
      //   $ctrl.fetchInitiatives($ctrl.params);
      // } else if (q.length == 0) {
      // setPath();
      // }
      $ctrl.setPath();
    }

    function setTag(tag) {
      $ctrl.setSearch(tag);
      $ctrl.setPath();
    }

    function sort(sorting) {
      // if (sorting) {
      //   $ctrl.params.sort = sorting;
      // } else {
      //   $ctrl.params.sort = ['created_date', 'DESC'];
      // }
      $ctrl.params.sort = sorting;
      $ctrl.setPath();
      // $ctrl.fetchInitiatives($ctrl.params);
    }

    function sortByGroup(group) {
      if (group && group.length > 0) {
        $ctrl.params.group = group;
      } else {
        delete $ctrl.params.group;
      }

      // $ctrl.fetchInitiatives($ctrl.params);
      $ctrl.setPath();
    }

    function taskParams(constMobilisations) {
      console.info('$location:', $location);
      let query = $location.search();
      if (typeof query !== 'object') {
        query = {};
      }
      let { tasks } = query;

      if (tasks) {
        tasks = compact(tasks.split(',').map(t => parseInt(t, 10)));
      }

      return tasks && tasks.length > 0
        ? tasks
        : $ctrl.taskParamsFallback(constMobilisations);
    }

    function taskParamsFallback(constMobilisations) {
      const mobilisations = [];
      each(constMobilisations, (mobilisation, key) => {
        mobilisations.push(mobilisation);
      });
      return mobilisations.slice(0, 4).map(a => a.id);
    }

    function getWallboardUrl(p) {
      let name = 'checkpoints';
      let params = angular.copy(p);
      if (params.sort != null && params.sort >= 0) {
        params.sorting = sortings[params.sort].params[0];
        params.order = sortings[params.sort].params[1];
        delete params.sort;
      } else {
        params.sorting = 'created_date';
        params.order = 'DESC';
        delete params.sort;
      }

      let queryString = $httpParamSerializer(pickBy(params));

      if ($ctrl.active && $ctrl.active.wallboard) {
        name = $ctrl.active.name;
      }

      if (queryString) {
        queryString = `?${queryString}`;
      }

      return `/wallboard/${name}${queryString}`;
    }

    function onStarInitiative(id, isStared) {
      $ctrl.starInitiatives(id, isStared);
    }

    function setPath() {
      let params = { ...$ctrl.params };
      if (params.q && params.q.length === 0) {
        delete params.q;
      }
      if (params.status && params.status.length === 0) {
        delete params.status;
      }
      if (params.group && params.group.length === 0) {
        delete params.group;
      }

      if (params.sort != null && params.sort >= 0) {
        params.sorting = sortings[params.sort].params[0];
        params.order = sortings[params.sort].params[1];
        delete params.sort;
      } else {
        params.sorting = 'created_date';
        params.order = 'DESC';
        delete params.sort;
      }
      $location.search(params);
      $ctrl.fetch();
    }
  },
};
