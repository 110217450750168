export default {
  bindings: {
    target: '@',
    props: '<',
    loading: '<',
    add: '&',
    remove: '&',
  },

  template: `
    <div class="modal fade {{ $ctrl.target }}" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header p-4">
                    <h3 class="modal-title"> {{$ctrl.loading ? 'Loading' : 'Select'}} Initiatives <i class='fa fa-spinner fa-spin ' ng-if="$ctrl.loading"/></h3>
                    <button type="button" class="btn btn-primary" data-dismiss="modal">
                        Done
                    </button>
                </div>
                <div class="modal-body p-4 pb-0">
                    <div class="row">
                        <div class="col-sm-6 col-lg-4 mb-2" ng-repeat="(key, item) in $ctrl.props">
                            <initiative
                              props="item"
                              active="item.active">
                              <a
                                  href="#"
                                  ng-show="!item.active"
                                  ng-click="$ctrl.add({ item: item })">
                                  Select
                              </a>
                              <a
                                  href="#"
                                  ng-show="item.active"
                                  ng-click="$ctrl.remove({ item: item })">
                                  Remove
                              </a>
                            </initiative>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>`,
};
