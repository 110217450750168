import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import store from 'store';
import axios from 'axios';
import getFirebaseConfig from '../config/firebase';
const firebaseProjectId = getFirebaseConfig().projectId;
// Action Types
export const FETCH_SURVEY = 'app/survey/FETCH';
export const UPDATE_SURVEY = 'app/survey/UPDATE';
export const SUCCESS_SURVEY = 'app/survey/SUCCESS';
export const ERROR_SURVEY = 'app/survey/ERROR';
export const CANCEL_SURVEY = 'app/survey/CANCEL';

// Initial State
export const initialState = {
  data: {},
  loading: false,
};

// Actions
export function fetchSurvey(initiativeId, userId) {
  return { type: FETCH_SURVEY, payload: { initiativeId, userId } };
}

export function updateSurvey(initiativeId, userId, survey) {
  return { type: UPDATE_SURVEY, payload: { initiativeId, userId, survey } };
}

export function cancelSurvey() {
  return { type: CANCEL_SURVEY };
}

// Observable Logic
export const surveyFetch = createLogic({
  latest: true,
  type: FETCH_SURVEY,
  cancelType: CANCEL_SURVEY,
  process({ action }, dispatch, done) {
    const { payload } = action;
    // axios({
    //   method: 'POST',
    //   url: `https://us-central1-${firebaseProjectId}.cloudfunctions.net/getSurvey`,
    //   headers: {
    //     Authorization: store.get('Authorization'),
    //   },
    //   data: action.payload,
    //   json: true,
    // })
    // .then(response => {
    //   dispatch({
    //     type: SUCCESS_SURVEY,
    //     payload: response.data,
    //   });
    //   done();
    // })
    // .catch(e => {
    //   dispatch({
    //     type: ERROR_SURVEY
    //   });
    //   done();
    // })

    firebase
      .database()
      .ref(`morale-survey/${payload.userId}`)
      .once('value')
      .then(snapshot => {
        let survey = snapshot.val();
        if (!survey) {
          survey = [0, 0, 0, 0, 0, 0, 0, 0];
        }
        dispatch({
          type: SUCCESS_SURVEY,
          payload: { survey },
        });
        done();
      });
  },
});

export const surveyUpdate = createLogic({
  latest: true,
  type: UPDATE_SURVEY,
  cancelType: CANCEL_SURVEY,
  process({ action, getState }, dispatch, done) {
    const { payload } = action;
    const survey = payload.survey;
    const updateData = {};
    // updateData[payload.initiativeId] = {};
    updateData[payload.userId] = survey;
    // updateData[payload.initiativeId][payload.userId] = survey;
    firebase
      .database()
      .ref('morale-survey')
      .update(updateData)
      .then(() => {
        dispatch({
          type: SUCCESS_SURVEY,
          payload: { survey },
        });
        done();
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SURVEY:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        saving: false,
      };

    case UPDATE_SURVEY:
      return {
        ...state,
        data: payload.survey,
        error: false,
        success: false,
        saving: true,
      };

    case SUCCESS_SURVEY:
      return {
        ...state,
        data: payload.survey,
        loading: false,
        error: false,
        success: true,
        saving: false,
      };

    case ERROR_SURVEY:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        saving: false,
      };

    default:
      return state;
  }
}
