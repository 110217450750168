import * as Utils from '../../utils';

export default {
  bindings: {
    props: '<',
    views: '<',
    statuses: '<',
    sortings: '<',
    title: '<',
    toggleView: '&',
    toggleStatus: '&',
    sortByGroup: '&',
    sort: '&',
    onSubmit: '&',
    search: '<',
    status: '<',
    group: '<',
    sorting: '<',
    loading: '<',
    groups: '<',
  },

  template: `
    <header class="initiatives-list-header">
      <nav class="navbar navbar-toggleable-lg navbar-light bg-transparent p-0">
        <button
          class="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-list"
          aria-controls="navbar-list"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <i class="fa fa-caret-down"></i>
        </button>

        <h1 class="h2 text-nowrap mb-0">
          {{ $ctrl.title }}
        </h1>

        <div class="collapse navbar-collapse" id="navbar-list">
          <div class="ml-auto d-xl-flex">
            <div class="initiatives-list-views btn-group my-2 my-xl-0">
              <button
                type="button"
                class="btn btn-secondary"
                ng-repeat="view in $ctrl.views"
                ng-click="$ctrl.toggleView({ view: view })"
                ng-class="{ 'active': view.active }"
                ng-if="!view.hidden">
                {{ view.label }}
              </button>
            </div>

            <!--form ng-submit="$ctrl.onSubmit({ q: $ctrl.search })" class="form-inline ml-xl-3">
              <select
                  class="form-control mr-lg-1 mb-2 mb-lg-0"
                  ng-init="(!$ctrl.group || $ctrl.group.length === 0) ? $ctrl.group = null : ''"
                  ng-model="$ctrl.group"
                  ng-change="$ctrl.sortByGroup({group: $ctrl.group})"
                  ng-options="i.id as i.name for i in $ctrl.groups">
                  <option value="">All groups</option>
              </select>
              <select
                class="form-control mr-lg-1 mb-2 mb-lg-0"
                ng-init="(!$ctrl.status || $ctrl.status.length === 0) ? $ctrl.status = null : ''"
                ng-model="$ctrl.status"
                ng-change="$ctrl.toggleStatus({ status: $ctrl.status })"
                ng-options="v for v in $ctrl.statuses">
                <option value="">Any Status</option>
              </select>
              <select
                class="form-control mr-lg-1 mb-2 mb-lg-0"
                ng-model="$ctrl.sorting"
                ng-change="$ctrl.sort({ sorting: $ctrl.sorting })"
                ng-options="item.value as item.text for (index, item) in $ctrl.sortings">
                <option value="">Most Recent</option>
              </select>

              <div class="initiatives-list-search">
                <input
                  class="form-control mb-2 mb-lg-0"
                  type="text"
                  placeholder="Search"
                  ng-model="$ctrl.search"
                  ng-change="$ctrl.onSubmit({ q: $ctrl.search })"
                >
                <i
                  class='fa fa-spinner fa-spin search-loading'
                  ng-if="$ctrl.loading && $ctrl.search.length > 2"
                ></i>
                </input>

                <button
                  type="button"
                  class="btn-link"
                  ng-if="$ctrl.search.length > 2"
                  ng-click="$ctrl.search='';$ctrl.onSubmit({ q: '' })">
                  <i class="fa fa-times-circle text-danger"></i>
                </button>
              </div>
            </form-->
          </div>
        </div>
      </nav>
      <hr class="mt-md-4 mb-md-5 border-2 border-black">
    </header>`,

  controller() {
    'ngInject';
    const $ctrl = {
      isValidGroup: Utils.getGroupFromArray,
    };

    return $ctrl;
  },
};
