export default {
  bindings: {
    target: '@',
    onSubmit: '&',
    onCancel: '&',
  },

  template: `
    <div ng-attr-id="{{$ctrl.target}}" class="modal fade {{ $ctrl.target }}" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header p-4">
            <h3 class="modal-title">Enter your team name</h3>
          </div>
          <form class="modal-body p-4 pb-0">
            <input
              ng-attr-id="{{$ctrl.target}}userName"
              name="userName"
              class="form-control form-control-danger"
              ng-model="$ctrl.props.name"
            >
            <div style="text-align: right; margin-top: 1em;">
              <button type="button" class="btn" ng-click="$ctrl.props.name='';$ctrl.onCancel({
                name: $ctrl.props.name
              })">Cancel</button>
              <button type="submit" class="btn btn-primary"ng-click="$ctrl.onSubmit($ctrl.props)">Create</button>
            </div>            
          </form>
        </div>
    </div>
</div>`,

  controller($scope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      props: {
        name: '',
      },
    };

    return $ctrl;

    function $onInit() {
      $(document).ready(function() {
        $(`#${$ctrl.target}`).on('hide.bs.modal', function() {
          $ctrl.name = undefined;
        });

        $(`#${$ctrl.target}`).on('shown.bs.modal', function() {
          $(`#${$ctrl.target}userName`).focus();
        });
      });
    }
  },
};
