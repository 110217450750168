import capitalize from './capitalize';
import linkify from './linkify';
import statusName from './status-name';
import range from './range';

export default angular
  .module('App.filters', [])
  .filter('capitalize', capitalize)
  .filter('linkify', linkify)
  .filter('statusName', statusName)
  .filter('range', range).name;
