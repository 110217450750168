import landing from './landing';
import checkpoint from './checkpoint';
import header from './header';
import mobileNav from './mobileNav';
import initiatives from './initiatives';
import impediments from './impediments';

import login from './login';
import signup from './signup';
import forgotPassword from './forgotPassword';
import wallboard from './wallboard';
import widgets from './widgets';
import activities from './activities';
import profile from './profile';
import groups from './group';
import legal from './legal';
import morale from './moraleSurvey';
import home from './home';
import checkin from './checkin';
import invitation from './invitation';

const components = [
  landing,
  checkpoint,
  header,
  mobileNav,
  initiatives,
  impediments,
  login,
  signup,
  forgotPassword,
  wallboard,
  widgets,
  activities,
  profile,
  legal,
  groups,
  morale,
  home,
  checkin,
  invitation,
];

export default angular.module('App.components', components).name;
