export default [
  {
    name: 'checkpoints',
    label: 'Checkpoints',
    active: false,
    wallboard: true,
  },
  {
    name: 'awards',
    label: 'Badges',
    active: false,
    wallboard: true,
  },
  {
    name: 'performance',
    label: 'Performance',
    active: false,
    wallboard: true,
  },
  {
    name: 'report',
    title: 'Report',
    label: 'Report',
    active: false,
    hidden: true,
    wallboard: false,
  },
];
