import {
  login,
  loginWithGoogle,
  loginWithGitHub,
  loginWithFacebook,
  setTeamName,
} from 'logic/user';
import store from 'store';

export default {
  template: `
    <main class="container-flex login-container">
        <h1 class="display-1 text-center font-weight-bold text-white" ng-if="!$ctrl.props.authChecked">
          <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          init
        </h1>
      <section class="login-box" ng-if="$ctrl.props.authChecked">
          <a href="/" class="login-logo">
            <i class="fa fa-check-circle-o" aria-hidden="true"></i>
            init
          </a>
          <div class="login-extras">
            <button class="btn btn-red btn-social btn-google mb-2"
               ng-click="$ctrl.onLoginWithGoogle()"
            >
              <span class="fa fa-google"></span>
              <span class="ml-4">Sign in with Google</span>
            </button>
            <button class="btn btn-red btn-social btn-github mb-2"
               ng-click="$ctrl.onLoginWithGitHub()"
            >
              <span class="fa fa-github"></span>
              <span class="ml-4">Sign in with GitHub</span>
            </button>
            <button class="btn btn-red btn-social btn-facebook mb-4"
               ng-click="$ctrl.onLoginWithFacebook()"
            >
              <span class="fa fa-facebook"></span>
              <span class="ml-4">Sign in with Facebook</span>
            </button>
          </div>
          <div class="alert alert-danger text-center" ng-show="$ctrl.props.error">
            {{ $ctrl.props.message }}
          </div>
          <login-form on-submit="$ctrl.login(username, password)" loading="$ctrl.props.loading"></login-form>
          <small class="login-terms" ng-if="$ctrl.teamName">
            After signing up, we will create a checkin for your team: {{$ctrl.teamName}}
          <button ng-click="$ctrl.onIgnoreTeamCheckin()">No</button>
        </small>
          <div class="text-small mt-2 text-center">
            Don't have an account? <a ng-click="$ctrl.onRegister()">Sign up now!</a>
          </div>
          <small class="login-terms">
            By signing up you are agreeing to our <a href="/legal/terms" target="_blank">Terms &amp; Conditions</a> and <a href="/legal/privacy" target="_blank">Privacy Policy</a>
          </small>
      </section>
    </main>`,

  controller($location, $ngRedux, $timeout, $routeParams) {
    `ngInject`;

    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onLoginWithGoogle,
      onLoginWithGitHub,
      onLoginWithFacebook,
      onIgnoreTeamCheckin,
      onRegister,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      const { teamName } = $location.$$search;
      $ctrl.teamName = teamName;
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      /*if (store.get('user')) {
        $timeout(() => $location.path(`/initiatives`));
      }*/
    }

    function onLoginWithGoogle() {
      console.info('Login With Google');
      $ctrl.loginWithGoogle();
    }

    function onLoginWithGitHub() {
      console.info('Login With GitHub');
      $ctrl.loginWithGitHub();
    }

    function onLoginWithFacebook() {
      $ctrl.loginWithFacebook();
    }

    function onRegister() {
      $location.path('/register').search('teamName', $routeParams.teamName);
    }

    function onIgnoreTeamCheckin() {
      $location.path('/login').search('teamName', null);
    }

    function mapState(state) {
      return {
        props: state.user,
      };
    }

    function mapDispatch() {
      return {
        login,
        loginWithGoogle,
        loginWithGitHub,
        loginWithFacebook,
        setTeamName,
      };
    }
  },
};
