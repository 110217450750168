import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';

// Action Types
export const FETCH = 'app/constants/impediments/FETCH';
export const SUCCESS = 'app/constants/impediments/SUCCESS';
export const ERROR = 'app/constants/impediments/ERROR';
export const CANCEL = 'app/constants/impediments/CANCEL';

// Initial State
export const initialState = {
  data: {},
  loading: false,
  success: false,
};

// Actions
export function fetchImpedimentTypes() {
  return { type: FETCH };
}

export function cancelImpedimentTypes() {
  return { type: CANCEL };
}

// Observable Logic
export const impedimentTypesFetch = createLogic({
  latest: true,
  type: FETCH,
  cancelType: CANCEL,
  processOptions: {
    successType: SUCCESS,
    failType: ERROR,
  },
  process(/*{ $http }*/) {
    //return $http.get(`/impediments`);
    return firebase
      .database()
      .ref('constants/impediments')
      .once('value')
      .then(snapshot => {
        let impediments = snapshot.val();
        if (!impediments) {
          impediments = {};
        }

        let types = {};
        let benefits = {};

        for (let id in impediments.type) {
          if (impediments.type[id]) {
            types[id] = { ...impediments.type[id] };
          }
        }

        for (let id in impediments.benefit) {
          if (impediments.type[id]) {
            benefits[id] = { ...impediments.benefit[id] };
          }
        }

        return {
          data: {
            types,
            benefits,
          },
        };
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH:
      return {
        ...state,
        loading: true,
      };

    case SUCCESS:
      return {
        ...state,
        data: { ...payload.data },
        loading: false,
        success: true,
      };

    case ERROR:
      return {
        ...state,
        ...payload,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
}
