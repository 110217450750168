export default function($routeProvider) {
  'ngInject';
  $routeProvider.when('/', {
    template: '<landing/>',
    title: 'Init - Agile Team Heath Platform',
  });

  $routeProvider.when('/home', {
    template: '<home-page ng-if="currentUser"/>',
    title: 'Init - Agile Team Heath Platform',
  });

  $routeProvider.when('/team-checkin', {
    template: '<team-checkin/>',
    title: 'Init - Agile Team Heath Platform',
  });

  $routeProvider.when('/login', {
    template: '<login/>',
    title: 'Init - Agile Team Heath Platform',
  });

  $routeProvider.when('/register', {
    template: '<signup/>',
    title: 'Init - Agile Team Heath Platform',
  });

  $routeProvider.when('/forgot-password', {
    template: '<forgot/>',
    title: 'Init - Agile Team Heath Platform',
  });

  $routeProvider.when('/wallboard/:name?', {
    template: `<wallboard ng-if="currentUser"/>`,
    title: 'Init - Wallboard',
  });
  $routeProvider.when('/wallboard/:id/:name', {
    template: `<wallboard-show ng-if="currentUser"/>`,
    title: 'Init - Wallboard',
  });

  $routeProvider.when('/initiatives?', {
    template: `<initiatives-list view="checkpoints" ng-if="currentUser"/>`,
    title: 'Init - Initiatives',
    reloadOnSearch: false,
  });
  $routeProvider.when('/initiatives/report', {
    template: `<initiatives-list view="report" ng-if="currentUser"/>`,
    title: 'Init - Agile Team Heath Platform',
  });
  $routeProvider.when('/initiatives/new', {
    template: `<initiatives-new ng-if="currentUser"/>`,
    title: 'Init - Agile Team Heath Platform',
  });
  $routeProvider.when('/initiatives/:id?', {
    template: `<initiatives-show ng-if="currentUser"/>`,
    title: 'Init - Initiative',
  });
  $routeProvider.when('/initiatives/:id/edit', {
    template: `<initiatives-edit ng-if="currentUser"/>`,
    title: 'Init - Edit Initiative',
  });
  $routeProvider.when('/initiatives/:id/checkin/:checkin', {
    template: `<initiatives-checkin ng-if="currentUser"/>`,
    title: 'Init - Checkin Initiative',
  });
  // Impediments
  $routeProvider.when('/impediments/new', {
    template: `<impediments-new ng-if="currentUser"/>`,
    title: 'Init - Agile Team Heath Platform',
  });

  $routeProvider.when('/impediments/:id/edit', {
    template: `<impediments-edit ng-if="currentUser"/>`,
    title: 'Init - Agile Team Heath Platform',
  });

  $routeProvider.when('/impediments', {
    template: `<impediments-list ng-if="currentUser"/>`,
    title: 'Init - Impediments',
  });

  $routeProvider.when('/impediments/:id', {
    template: `<impediments-show ng-if="currentUser"/>`,
    title: 'Init - Impediment',
  });

  $routeProvider.when('/survey', {
    template: `<morale-survey ng-if="anonymousUser || currentUser"/>`,
    title: 'Init - Morale Survey',
  });

  $routeProvider.when('/survey/result', {
    template: `<morale-survey-result ng-if="anonymousUser || currentUser"/>`,
    title: 'Init - Morale Survey Result',
  });
  $routeProvider.when('/profile', {
    template: `<profile ng-if="currentUser"/>`,
    title: 'Init - Agile Team Heath Platform',
  });

  $routeProvider.when('/ballots/:id', {
    template: `<ballot-checkin ng-if="anonymousUser || currentUser">`,
    title: 'Init - Initiative',
  });

  $routeProvider.when('/checkin/:code', {
    template: `<ballot-checkin ng-if="anonymousUser || currentUser">`,
    title: 'Init - Initiative',
  });

  //activitiesList
  $routeProvider.when('/activity', {
    template: `<activity-list ng-if="currentUser"/>`,
    title: 'Init - Activities',
  });

  //activitiesList
  $routeProvider.when('/activity/:id', {
    template: `<activity-list ng-if="currentUser"/>`,
    title: 'Init - Agile Team Heath Platform',
  });

  //groupList
  $routeProvider.when('/groups', {
    template: `<group-list ng-if="currentUser"/>`,
    title: 'Init - Groups',
  });
  $routeProvider.when('/groups/new', {
    template: `<group-new ng-if="currentUser"/>`,
    title: 'Init - Agile Team Heath Platform',
  });
  $routeProvider.when('/groups/:name/members', {
    template: `<group-show ng-if="currentUser"/>`,
    title: 'Init - Group',
  });

  $routeProvider.when('/groups/:name', {
    template: `<group-dashboard ng-if="currentUser"/>`,
    title: 'Init - Group',
  });

  $routeProvider.when('/groups/:name/edit', {
    template: `<group-edit ng-if="currentUser"/>`,
    title: 'Init - Group',
  });

  $routeProvider.when('/groups/:name/initiatives/:id', {
    template: `<initiatives-show-summary ng-if="currentUser"/>`,
    title: 'Init - Initiative Show Summary',
  });

  $routeProvider.when('/legal/terms', {
    template: `<terms/>`,
    title: 'Init - Terms and Conditions',
  });

  $routeProvider.when('/legal/privacy', {
    template: `<privacy/>`,
    title: 'Init - Privacy Policy',
  });

  $routeProvider.otherwise({
    redirectTo: '/login',
  });

  $routeProvider.when('/checkin', {
    template: `<checkin-page ng-if="anonymousUser || currentUser"/>`,
    title: 'Init - Checkin',
  });

  $routeProvider.when('/invite', {
    template: `<invitation-page ng-if="currentUser"/>`,
    title: 'Init - Invite New User',
  });
}
