import initiativesAwardsModal from './awards-modal';
import initiativesAwardPopover from './award-popover';
import initiativesAwardPopoverForm from './award-popover-form';
import initiativesCheckin from './checkin';
import initiativesDeleteButton from './delete-button';
import checkinDeleteButton from './delete-checkin-button';
import initiativesEdit from './edit';
import initiativesForm from './form';
import initiativesFormGroup from './form-group';
import initiativesItemHeader from './item-header';
import initiativesList from './list';
import initiativesListAwards from './list-awards';
import initiativesListCheckpoints from './list-checkpoints';
import initiativesListHeader from './list-header';
import initiativesListPerformance from './list-performance';
import initiativesListReport from './list-report';
import initiativesNew from './new';
import initiativesReportHeader from './report-header';
import initiativesShow from './show';
import ballotCheckIn from './ballot-checkin';
import changeOwnerModal from './change-owner-modal';
import addChkModal from './add-checkpoint-modal';

import kpisModal from './kpis-modal';
import enterNameModal from './enter-name-modal';
import selectPriorityModal from './select-priority-modal';
import initiativesShowSummary from './show-summary';

import './initiatives.scss';

export default angular
  .module('App.initiatives', [])
  .component('initiativesAwardsModal', initiativesAwardsModal)
  .component('initiativesAwardPopover', initiativesAwardPopover)
  .component('initiativesAwardPopoverForm', initiativesAwardPopoverForm)
  .component('initiativesCheckin', initiativesCheckin)
  .component('ballotCheckin', ballotCheckIn)
  .component('initiativesDeleteButton', initiativesDeleteButton)
  .component('checkinDeleteButton', checkinDeleteButton)
  .component('initiativesEdit', initiativesEdit)
  .component('initiativesForm', initiativesForm)
  .component('initiativesFormGroup', initiativesFormGroup)
  .component('initiativesItemHeader', initiativesItemHeader)
  .component('initiativesList', initiativesList)
  .component('initiativesListAwards', initiativesListAwards)
  .component('initiativesListCheckpoints', initiativesListCheckpoints)
  .component('initiativesListHeader', initiativesListHeader)
  .component('initiativesListPerformance', initiativesListPerformance)
  .component('initiativesListReport', initiativesListReport)
  .component('initiativesNew', initiativesNew)
  .component('initiativesReportHeader', initiativesReportHeader)
  .component('initiativesShow', initiativesShow)
  .component('initiativesShowSummary', initiativesShowSummary)
  .component('changeOwnerModal', changeOwnerModal)
  .component('kpisModal', kpisModal)
  .component('selectPriorityModal', selectPriorityModal)
  .component('enterNameModal', enterNameModal)
  .component('addChkModal', addChkModal).name;
