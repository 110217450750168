/**
 * Created by ilya on 8/4/17.
 */
import list from './list';
import activityListItem from './list/item';

export default angular
  .module('App.activities', [])
  .component('activityList', list)
  .component('activityListItem', activityListItem).name;
