import { createLogic } from 'redux-logic';
import axios from 'axios';
import { queryLocalImpediments } from '../utils';
import store from 'store';
import { REMOVE_SUCCESS, UPDATE_SUCCESS } from './impediment';

export const INIT = 'app/impediments/INIT';
export const FETCH = 'app/impediments/FETCH';
export const SUCCESS = 'app/impediments/SUCCESS';
export const APPLY_FILTER = 'app/impediments/apply/filter';
export const SORT = 'app/impediments/SORT';
export const CLEAR_FILTER = 'app/impediments/CLEAR/FILTER';
export const ERROR = 'app/impediments/ERROR';
export const CANCEL = 'app/impediments/CANCEL';

import getFirebaseConfig from '../config/firebase';

const firebaseProjectId = getFirebaseConfig().projectId;

export const initialState = {
  loading: false,
  success: false,
  redirect: false, // This needs to move to middleware
  error: false,
  data: [],
  dic: {},
  filters: { benefits: [], initiatives: [] },
};

// Actions

export function initAndFetchImpediments() {
  return { type: INIT };
}

export function fetchImpediments(payload) {
  return { type: FETCH, payload };
}

export function sortImpediments(payload) {
  return { type: SORT, payload };
}

export function applyFilter(payload) {
  return { type: APPLY_FILTER, payload };
}

export function clearFilter() {
  return { type: CLEAR_FILTER };
}

export const impedimentsFetch = createLogic({
  latest: true,
  type: [INIT, FETCH, APPLY_FILTER, SORT],
  cancelType: CANCEL,
  processOptions: {
    successType: SUCCESS,
    failType: ERROR,
  },

  process({ $http, action, getState }) {
    let params = { filters: getState().impediments.filters };
    return axios
      .post(
        `https://us-central1-${firebaseProjectId}.cloudfunctions.net/queryImpediments`,
        params,
        {
          headers: {
            Authorization: store.get('Authorization'),
          },
        }
      )
      .then(response => {
        return response.data;
      })
      .then(impediments => {
        return {
          data: impediments,
        };
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case INIT: {
      return {
        ...state,
        data: [],
        filters: {},
        dic: {},
        loading: true,
      };
    }

    case FETCH: {
      return {
        ...state,
        data: queryLocalImpediments(state.dic, state.filters),
        loading: true,
      };
    }

    case SUCCESS: {
      return {
        ...state,
        ...payload,
        dic: getImpedimentDic(state, payload.data),
        success: true,
        loading: false,
      };
    }

    case CLEAR_FILTER: {
      return {
        ...state,
        filters: {},
        data: queryLocalImpediments(state.dic, {}),
      };
    }

    case SORT: {
      const filters = { ...state.filters };
      filters.sorting = payload;

      return {
        ...state,
        filters,
        data: queryLocalImpediments(state.dic, filters),
      };
    }

    case APPLY_FILTER: {
      const filters = { ...state.filters };
      const { type, object } = payload;
      let { benefits } = filters;
      let { initiatives } = filters;

      if (!benefits) {
        benefits = [];
      }

      if (!initiatives) {
        initiatives = [];
      }

      if (type === 'benefit') {
        let exists = false;
        for (let i = 0; i < benefits.length; i++) {
          if (benefits[i].id === object.id) {
            exists = true;
            break;
          }
        }
        if (exists) {
          benefits = benefits.filter(item => item.id !== object.id);
        } else {
          benefits.push(object);
        }
      } else if (type === 'initiative') {
        let exists = false;
        for (let i = 0; i < initiatives.length; i++) {
          if (initiatives[i].id === object.id) {
            exists = true;
            break;
          }
        }
        if (exists) {
          initiatives = initiatives.filter(item => item.id !== object.id);
        } else {
          initiatives.push(object);
        }
      }
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
        filters: { benefits, initiatives },
        data: queryLocalImpediments(state.dic, { benefits, initiatives }),
      };
    }

    case UPDATE_SUCCESS: {
      const data = state.data;
      const updatedData = action.payload;
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === updatedData.id) {
          data[i] = updatedData;
          break;
        }
      }

      return {
        ...state,
        data,
        dic: getImpedimentDic(state, data),
      };
    }

    case REMOVE_SUCCESS: {
      const data = state.data;

      return {
        ...state,
        data: data.filter(
          impediment => impediment.id !== action.payload.data.id
        ),
        dic: queryLocalImpediments(state.dic, state.filters),
      };
    }

    case ERROR: {
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
        data: null,
      };
    }

    default:
      return state;
  }
}

function getImpedimentDic(state, data, isRemoved = false) {
  let { dic } = state;

  if (!dic) {
    dic = {};
  }

  if (isRemoved) {
  } else {
    if (Array.isArray(data)) {
      for (let impediment of data) {
        dic[impediment.id] = impediment; // register initiative
      }
    } else if (data && data.id) {
      dic[data.id] = data;
    }
  }
  return { ...dic };
}
