import { each, flatten, filter, pickBy, identity } from 'lodash';
import store from 'store';
import { gray, red, yellow, green, white } from 'constants/colors';
import { fetchInitiatives, cancelInitiatives } from 'logic/initiatives';
import decorateInitiative from 'decorators/initiative';
import views from 'constants/views';
import { injectKpis } from 'utils/index';

export default {
  template: `
    <main class="wallboard w-100 h-100">
      <wallboard-header
        loading="$ctrl.loading"
        paused="$ctrl.paused"
        header-title="$ctrl.title"
        views="$ctrl.views"
        hide-toggle="$ctrl.hideActions"
        on-click="$ctrl.changeLocation(name)"
        on-toggle="$ctrl.toggle()"
        quering="$ctrl.quering">
      </wallboard-header>
      <section class="wallboard-section row m-0">
        <wallboard-actions
          on-next="$ctrl.next()"
          on-previous="$ctrl.previous()"
          ng-if="!$ctrl.hideActions">
        </wallboard-actions>
        <div
          class="h-50 wallboard-card-wrapper"
          ng-repeat="item in $ctrl.items"
          ng-class="{
            'col-lg-3 col-md-6': $ctrl.name != 'performance',
            'col-lg-6 col-md-12': $ctrl.name == 'performance',
          }">
          <wallboard-card
            item="item"
            ng-show="$ctrl.name == 'checkpoints'">
            <wallboard-card-checkpoints
              graph-data="item.graphData"
              class="wallboard-card-fill"
              is-individual="false">
            </wallboard-card-checkpoints>
          </wallboard-card>
          <wallboard-card
            item="item"
            ng-show="$ctrl.name == 'awards'">
            <wallboard-card-awards
              graph-data="item.graphData"
              class="wallboard-card-fill"
              is-individual="false">
            </wallboard-card-awards>
          </wallboard-card>
          <wallboard-card
            item="item"
            ng-show="$ctrl.name == 'performance'">
            <wallboard-card-performance
              class="wallboard-card-performance"
              kpis="item.kpis"
              is-individual="false">
            </wallboard-card-performance>
          </wallboard-card>
        </div>
      </section>
    </main>`,

  controller($location, $ngRedux, $routeParams, $timeout, $window, $rootScope) {
    `ngInject`;

    const NEXT_PAGE_TIMEOUT = 30 * 1000;
    const NEXT_FETCH_TIMEOUT = 120 * 1000;
    const RESIZE_DEBOUNCE = 0.5 * 1000;
    const PER_PAGE = {
      general: 8,
      performance: 4,
    };
    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
      fetch,
      autofetch,
      autoload,
      cancelAutoload,
      previous,
      next,
      toggle,
      getTitle,
      changeLocation,
      onResize,
      onLoading,
      load,
      itemsForPage,
      checkIfSingleWallboard,
      resize,
      $w: angular.element($window),
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );

      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.name = $routeParams.name || views[0].name;
      $ctrl.page = 1;
      $ctrl.paused = store.get('paused');

      if ($routeParams.name === 'awards') {
        $rootScope.title = 'Init - Wallboard - Mobilisation';
      } else if ($routeParams.name === 'performance') {
        $rootScope.title = 'Init - Wallboard - Service Performance';
      }

      if ($routeParams.name === 'performance') {
        $ctrl.per_page = PER_PAGE.performance;
      } else {
        $ctrl.per_page = PER_PAGE.general;
      }

      $ctrl.views = views.map(view => {
        const active = view.name === $ctrl.name;
        return { ...view, active };
      });

      $ctrl.checkIfSingleWallboard();

      $ctrl.fetch();

      $ctrl.$w.on('resize.wallboard', $ctrl.onResize);
    }

    function $onDestroy() {
      $ctrl.cancelInitiatives();
      $ctrl.unsubscribe();
      $ctrl.$w.off('resize.wallboard');
    }

    function mapState(state) {
      const awards = {
        mobilisations: state.awards.mobilisations,
        badges: state.awards.badges,
      };

      const checkpoints = angular.copy(state.checkpoints.data);

      const data = [...state.initiatives.data].map((initiative, index) => {
        if (initiative && Array.isArray(initiative.checkpoints)) {
          for (let i = 0; i < initiative.checkpoints.length; i++) {
            if (checkpoints[initiative.checkpoints[i].id]) {
              initiative.checkpoints[i].type =
                checkpoints[initiative.checkpoints[i].id].type;
            }
          }
        }
        const _initiative = decorateInitiative(
          initiative,
          awards.mobilisations
        );
        injectKpis(_initiative, state.kpiTypes.data);
        return _initiative;
      });

      // Pause if we only have enough to fill a page.
      if (data.length && data.length <= $ctrl.per_page) {
        $ctrl.paused = true;
        $ctrl.hideActions = true;

        $ctrl.cancelAutoload();
      }

      console.info('Wallboard Items:', data);
      return {
        quering: state.initiatives.loading,
        items: $ctrl.itemsForPage(data, $ctrl.page),
        props: data,
      };
    }

    function mapDispatch() {
      return {
        fetchInitiatives,
        cancelInitiatives,
      };
    }

    function fetch() {
      let query = $location.search();
      if (typeof query !== 'object') {
        query = {};
      }
      const { status, q, sorting, order, group } = query;
      const params = {
        status,
        sort: [sorting, order],
        q,
        group,
      };

      $ctrl.title = $ctrl.getTitle(status, q);

      $ctrl.fetchInitiatives(params);

      // Kick off autofetch and autoload
      $ctrl.autofetch();
      $ctrl.autoload();
    }

    function autofetch() {
      if ($ctrl.fetch_promise) {
        $timeout.cancel($ctrl.fetch_promise);
      }

      $ctrl.fetch_promise = $timeout($ctrl.fetch, NEXT_FETCH_TIMEOUT);
    }

    function autoload() {
      if ($ctrl.paused) {
        return;
      }

      $ctrl.onLoading();

      if ($ctrl.next_promise) {
        $timeout.cancel($ctrl.next_promise);
      }

      $ctrl.next_promise = $timeout($ctrl.next, NEXT_PAGE_TIMEOUT);
    }

    function cancelAutoload() {
      $ctrl.loading = false;

      if ($ctrl.next_promise) {
        $timeout.cancel($ctrl.next_promise);
      }
    }

    function onLoading() {
      $ctrl.loading = false;

      $timeout(() => {
        $ctrl.loading = true;
      });
    }

    function previous() {
      if ($ctrl.page === 1) {
        return;
      }

      $ctrl.page = $ctrl.page - 1;
      $ctrl.load();
    }

    function next() {
      $ctrl.page = $ctrl.page + 1;
      $ctrl.load();
    }

    function load() {
      $ctrl.items = $ctrl.itemsForPage($ctrl.props, $ctrl.page);

      // Have we reached the final page?
      if ($ctrl.page * $ctrl.per_page > $ctrl.props.length) {
        $ctrl.page = 0;
      }

      $ctrl.autoload();
    }

    function toggle() {
      $ctrl.paused = !$ctrl.paused;

      if ($ctrl.paused) {
        $ctrl.loading = false;

        if ($ctrl.next_promise) {
          $timeout.cancel($ctrl.next_promise);
        }
      } else {
        $ctrl.autoload();
      }

      store.set('paused', $ctrl.paused);
    }

    function itemsForPage(data, page) {
      const start = (page - 1) * $ctrl.per_page;
      const end = page * $ctrl.per_page;

      return [...data.slice(start, end)];
    }

    function getTitle(status, search) {
      let heading = status || 'All';

      let title;
      if (search) {
        title = `${heading} - ${search}`;
      } else {
        title = heading;
      }

      let typeStr = '';

      if ($ctrl.name === 'checkpoints') {
        typeStr = 'Health - ';
      } else if ($ctrl.name === 'awards') {
        typeStr = 'Mobilisation - ';
      } else if ($ctrl.name === 'performance') {
        typeStr = 'Service Performance - ';
      }

      return `${typeStr}${title} Initiatives`;
    }

    function changeLocation(name) {
      $location.path(`/wallboard/${name}`);
    }

    function onResize() {
      if ($ctrl.resizeTimeout) {
        $timeout.cancel($ctrl.resizeTimeout);
      }

      $ctrl.resizeTimeout = $timeout($ctrl.resize, RESIZE_DEBOUNCE);
    }

    function resize() {
      const windowWidth = $ctrl.$w.width();

      if (windowWidth !== $ctrl.windowWidth) {
        $ctrl.windowWidth = windowWidth;
        $ctrl.fetch();
      }
    }

    function checkIfSingleWallboard() {
      if (!$routeParams.name) {
        return;
      }
      const views = $ctrl.views.filter(c => c.name === $routeParams.name);
      if (views.length === 0) {
        $location.path(`/wallboard/${$routeParams.name}/pitch`);
      }
    }
  },
};
