export default {
  bindings: {
    item: '<',
    props: '<',
  },

  template: `
    <div class="row">
      <div
        ng-repeat="task in $ctrl.props track by $index"
        class="col text-center">
        <initiatives-award-popover
          props="$ctrl.getItem(task)">
        </initiatives-award-popover>
      </div>
    </div>`,

  controller: function() {
    const $ctrl = {
      getItem,
    };

    return $ctrl;

    function getItem(task) {
      if (Array.isArray($ctrl.item.mobilisations)) {
        return $ctrl.item.mobilisations.find(i => i.name === task.name);
      }
    }
  },
};
