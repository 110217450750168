import * as Utils from '../../utils';
import { fetchBallotWithCode, cancelBallot } from 'logic/ballot';
import { createInitiative } from 'logic/initiative';
import { setTeamName } from 'logic/user';

import './checkin.scss';
import html from './checkin.html';

export default {
  bindings: {
    view: '@',
  },

  template: html,

  controller($ngRedux, $location, $timeout) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $doCheck,
      $onDestroy,
      mapState,
      mapDispatch,
      onClickSubmit,
      code: '',
      onCreateCheckin,
      onTeamNameEntered,
      onTeamNameCancel,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.cancelBallot();
      $ctrl.submitted = false;
    }

    function $doCheck() {
      if ($ctrl.success && $ctrl.submitted) {
        const id = $ctrl.ballot.id;
        $timeout(() => {
          $location.path(`/ballots/${id}`);
        });
        $ctrl.cancelBallot();
        console.info('Redirect to Ballot:', id);
      } else if ($ctrl.error && !$ctrl.errorChecked) {
        $ctrl.code = '';
        $ctrl.errorChecked = true;
      }

      if ($ctrl.opening_lite_success) {
        $timeout(() => {
          $location.path(`/initiatives/${$ctrl.initiative.id}/checkin/1`);
        });
      }
    }

    function $onDestroy() {
      $ctrl.success = false;
      $ctrl.cancelBallot();
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      const user = state.user.data;
      return {
        user: state.user.data,
        auth: !!(user && user.id && !user.isAnonymous),
        ballot: state.ballot.data,
        error: state.ballot.error,
        success: state.ballot.success,
        opening_lite_success: state.ballot.opening_lite_success,
        opening_lite: state.ballot.opening_lite,
        initiative: state.initiative.data,
      };
    }

    function mapDispatch() {
      return {
        fetchBallotWithCode,
        cancelBallot,
        createInitiative,
        setTeamName,
      };
    }

    function onClickSubmit(code) {
      if (code) {
        $ctrl.submitted = true;
        $ctrl.errorChecked = false;
        $ctrl.fetchBallotWithCode(code.toUpperCase());
      }
    }

    function onCreateCheckin() {
      $('#modal-enter-name').modal('show');
      $('#modal-enter-name').appendTo('body');
    }

    function onTeamNameEntered(teamName) {
      if (teamName) {
        $('#modal-enter-name').modal('hide');
        if ($ctrl.user.isAnonymous) {
          // Anonymous user should signup to create a checkin
          $timeout(() => {
            $location.path(`/register`).search('teamName', teamName);
          });
        } else {
          $ctrl.createInitiative({
            name: teamName,
            type: 'lite',
            creator: $ctrl.user.id,
            owner: $ctrl.user.id,
          });
        }
      }
    }

    function onTeamNameCancel() {
      $('#modal-enter-name').modal('hide');
    }
  },
};
