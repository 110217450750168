import toggle from './toggle';
import userPhotoModel from './userPhotoModel';
import surveySlider from './surveySlider';
import tagsInput from './tagsInput';
import elasticTextArea from './elastic_textarea';

export default angular
  .module('App.directives', [])
  .directive('toggle', toggle)
  .directive('userPhotoModel', userPhotoModel)
  .directive('tagsInput', tagsInput)
  .directive('elastic', elasticTextArea)
  .directive('surveySlider', surveySlider).name;
