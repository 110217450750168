/**
 * Created by ilya on 7/31/17.
 */
export default function() {
  return function(input, total) {
    total = parseInt(total);

    for (var i = 1; i <= total; i++) {
      input.push(i);
    }

    return input;
  };
}
