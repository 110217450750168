import {
  fetchCheckpoints,
  cancelCheckpoints,
} from 'logic/constants/checkpoints';
import { fetchUsers } from 'logic/users';
import { getShortName, toTitleCase } from '../../utils';
import * as InivteActions from 'logic/invite';

import {
  changeInitiative,
  addInitiativeCheckpoint,
  removeInitiativeCheckpoint,
  addInitiativeTag,
  removeInitiativeTag,
  updateInitiativeKpi,
} from 'logic/initiative';
import { fetchQuestionSets, saveQuestionSet } from 'logic/questionSet';

import statuses from 'constants/statuses';
import types from 'constants/initiative_types';

import _ from 'lodash';

import './form.scss';

export default {
  bindings: {
    onSubmit: '&',
    onCancel: '&',
    name: '@',
    props: '<',
  },

  template: `
    <form
      name="$ctrl.form"
      novalidate>
      <initiatives-form-group
        for="name"
        label="Name"
        help-text="A simple name the customer will understand."
        has-error="$ctrl.form.name.$touched && $ctrl.form.name.$invalid">
        <input
          id="name"
          name="name"
          class="form-control form-control-danger"
          type="text"
          ng-model="$ctrl.props.name"
          ng-blur="$ctrl.changeInitiative($ctrl.props)"
          ng-disabled="$ctrl.loading"
          required>
        <div ng-show="$ctrl.form.name.$touched">
          <small ng-show="$ctrl.form.name.$error.required" class="form-control-feedback">Required field</small>
        </div>
      </initiatives-form-group>
      <initiatives-form-group
        for="pitch"
        label="Pitch"
        help-text="In one sentence, who the market for the product is and what they get."
        ng-if="$ctrl.props.type !== 'lite'">
        <textarea
          id="pitch"
          class="form-control markdown__textarea"
          rows="5"
          ng-model="$ctrl.props.pitch"
          ng-blur="$ctrl.changeInitiative($ctrl.props)"
          ng-disabled="$ctrl.loading"
        >
        </textarea>
        <small class="text-muted markdown__description"><strong>**bold**</strong> <i>*italics*</i> ~~<del>strike</del>~~ <code>&#39;code&#39;</code> &gt;quotes <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">more markdown</a></small>
      </initiatives-form-group>
      <initiatives-form-group
        for="tags"
        label="Tags"
        ng-if="$ctrl.props.type !== 'lite'">
        <div class="row">
          <div class="col-sm-10" ng-hide="showTagInput || !$ctrl.props.id">
            <tags
              props="$ctrl.props.tags"
              action="$ctrl.removeInitiativeTag(tag)"
              on-click="showTagInput = !showTagInput"/>
          </div>
          <div
            ng-show="!$ctrl.props.id || showTagInput"
            ng-class="{
              'col-sm-10': $ctrl.props.id,
              'col-sm-12': !$ctrl.props.id
            }">
            <input
              id="tags"
              class="form-control"
              type="text"
              ng-value="$ctrl.props.tags.join(', ')"
              placeholder="Comma seperated list of tags"
              ng-model="tags"
              ng-blur="$ctrl.props.name && tags.length && $ctrl.addInitiativeTag(tags, $ctrl.props)"
              ng-keyup="$event.keyCode === 13 && (showTagInput = !showTagInput)"
            >
          </div>
          <div class="col-sm-2" ng-show="$ctrl.props.id">
            <button
              type="button"
              class="btn btn-primary float-right"
              ng-click="showTagInput = !showTagInput">
              <span ng-show="showTagInput">Save</span>
              <span ng-hide="showTagInput">Add/remove</span>
            </button>
          </div>
        </div>
      </initiatives-form-group>
      <initiatives-form-group
        for="owner"
        label="Product owner"
        ng-if="$ctrl.props.type !== 'lite'">
        <span class="mr-1" style="user-select: none;"><strong>{{ $ctrl.props.owner.name }}</strong></span>
        <button type="button" class="btn-link" ng-click="$ctrl.onClickChangeOwner()">
          <i class="fa fa-pencil"></i> Change
        </button>
      </initiatives-form-group>
      <initiatives-form-group
        for="team"
        label="Team"
        ng-if="$ctrl.props.type !== 'lite'">
        <div class="row" style="margin-left:0; margin-right:0">
          <members
              members="$ctrl.props.teamMembers"
              creator="$ctrl.props.creator"
              on-remove-user="$ctrl.removeTeamMember(userId)"
          >
          </members>
          <button type="button" class="btn-link" ng-click="$ctrl.onClickAddTeamMember()">
            <i class="fa fa-pencil"></i> Add
          </button>
        </div>
      </initiatives-form-group>
      <initiatives-form-group
        for="status"
        label="Status"
        ng-if="$ctrl.props.type !== 'lite'">
        <select
          id="mobilisation-time"
          class="form-control col-sm-6"
          ng-model="$ctrl.props.status"
          ng-options="v for v in $ctrl.statuses"
          ng-change="$ctrl.changeInitiative($ctrl.props)">
        </select>
      </initiatives-form-group>
      <initiatives-form-group
        for="type"
        label="Initiative Type"
        help-text="Choose if this a long lived persistent initiative or a short term proejct."
        ng-if="$ctrl.props.type !== 'lite'">
        <select
          id="mobilisation-time"
          class="form-control col-sm-6"
          ng-model="$ctrl.props.type"
          ng-change="$ctrl.changeInitiative($ctrl.props)">
          <option ng-repeat="type in $ctrl.types" value="{{type.type}}">{{type.label}}</option>
        </select>
      </initiatives-form-group>

      <hr class="my-4" ng-if="$ctrl.props.type == 'project'">
      <initiatives-form-group
        for="budget"
        label="Budget"
        help-text="The amount of money allocated to the project"
        has-error="$ctrl.form.budget.$touched && $ctrl.form.budget.$invalid"
        ng-if="$ctrl.props.type === 'project'"
      >
        <input
          id="budget"
          name="budget"
          class="form-control form-control-danger col-sm-6"
          type="text"
          ng-model="$ctrl.props.budget"
          ng-blur="$ctrl.changeInitiative($ctrl.props)"
          ng-disabled="$ctrl.loading"
          usd
          required>
        <div ng-show="$ctrl.form.budget.$touched">
          <small ng-show="$ctrl.form.budget.$error.required" class="form-control-feedback">Required field</small>
        </div>
      </initiatives-form-group>
      <initiatives-form-group
        for="percentageComplete"
        label="Percentage Complete"
        help-text="How complete the initiative is currently."
        has-error="$ctrl.form.percentageComplete.$touched && $ctrl.form.percentageComplete.$invalid"
        ng-if="$ctrl.props.type === 'project'"
      >
        <select
          id="percentageComplete"
          class="form-control col-sm-6"
          ng-model="$ctrl.props.percentageComplete"
          ng-change="$ctrl.changeInitiative($ctrl.props)"
          ng-disabled="$ctrl.loading"
          required
        >
          <option ng-repeat="percent in $ctrl.percents" ng-value="percent">{{percent}}%</option>
        </select>

        <div ng-show="$ctrl.form.percentageComplete.$touched">
          <small ng-show="$ctrl.form.percentageComplete.$error.required" class="form-control-feedback">Required field</small>
        </div>
      </initiatives-form-group>

      <initiatives-form-group
        for="due_date"
        label="Due Date"
        help-text="The date the initiative is due to be completed."
        has-error="$ctrl.form.dueDate.$touched && $ctrl.form.dueDate.$invalid"
        ng-if="$ctrl.props.type === 'project'">
        <datepicker date-format="yyyy-MM-dd">
          <input
          id="due_date"
          name="dueDate"
          class="form-control form-control-danger col-sm-6"
          type="text"
          ng-model="$ctrl.props.dueDate"
          ng-blur="$ctrl.form.dueDate.$valid && $ctrl.changeInitiative($ctrl.props)"
          required>
        </datepicker>
        <div ng-show="$ctrl.form.dueDate.$touched">
          <small ng-show="$ctrl.form.dueDate.$error.pattern" class="form-control-feedback">
            Date must be in the format dd/mm/yyyy
          </small>
          <small ng-show="$ctrl.form.dueDate.$error.required" class="form-control-feedback">Required field</small>
        </div>
      </initiatives-form-group>

      <hr class="my-4" ng-if="$ctrl.props.type !== 'lite'">
      <initiatives-form-group
        for="links"
        label="Useful links"
        ng-if="$ctrl.props.type !== 'lite'">
        <link-adder props="$ctrl.props.links" />
      </initiatives-form-group>
      <hr class="my-4" ng-if="$ctrl.props.type !== 'lite'">
      <initiatives-form-group
        for="summary"
        label="Problem statement"
        help-text="A summary of the product and benefits (3-4 sentences). Cut the fat and don't make it seem like a spec. Assume this is the only thing people will read."
        ng-if="$ctrl.props.type !== 'lite'">
        <textarea
          id="problem"
          class="form-control markdown__textarea"
          rows="5"
          ng-model="$ctrl.props.summary"
          ng-blur="$ctrl.changeInitiative($ctrl.props)">
        </textarea>
        <small class="text-muted markdown__description"><strong>**bold**</strong> <i>*italics*</i> ~~<del>strike</del>~~ <code>&#39;code&#39;</code> &gt;quotes <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">more markdown</a></small>
      </initiatives-form-group>
      <initiatives-form-group
        for="problem"
        label="Impact of problem"
        help-text="Describe the problem the product solves (3-4 sentences)."
        ng-if="$ctrl.props.type !== 'lite'">
        <textarea
          id="problem"
          class="form-control markdown__textarea"
          rows="5"
          ng-model="$ctrl.props.problem"
          ng-blur="$ctrl.changeInitiative($ctrl.props)">
        </textarea>
        <small class="text-muted markdown__description"><strong>**bold**</strong> <i>*italics*</i> ~~<del>strike</del>~~ <code>&#39;code&#39;</code> &gt;quotes <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">more markdown</a></small>
      </initiatives-form-group>
      <initiatives-form-group
        for="solution"
        label="Solution"
        help-text="Describe how the product elegantly solves the problem (3-4 sentences)."
        ng-if="$ctrl.props.type !== 'lite'">
        <textarea
          id="solution"
          class="form-control markdown__textarea"
          rows="5"
          ng-model="$ctrl.props.solution"
          ng-blur="$ctrl.changeInitiative($ctrl.props)">
        </textarea>
        <small class="text-muted markdown__description"><strong>**bold**</strong> <i>*italics*</i> ~~<del>strike</del>~~ <code>&#39;code&#39;</code> &gt;quotes <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">more markdown</a></small>
      </initiatives-form-group>
      <initiatives-form-group
        for="benefits"
        label="Success"
        help-text="Describe how the product benefits it’s users (3-4 sentences)."
        ng-if="$ctrl.props.type !== 'lite'">
        <textarea
          id="benefits"
          class="form-control markdown__textarea"
          rows="5"
          ng-model="$ctrl.props.benefits"
          ng-blur="$ctrl.changeInitiative($ctrl.props)">
        </textarea>
        <small class="text-muted markdown__description"><strong>**bold**</strong> <i>*italics*</i> ~~<del>strike</del>~~ <code>&#39;code&#39;</code> &gt;quotes <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">more markdown</a></small>
      </initiatives-form-group>
      <hr class="my-4" ng-if="$ctrl.props.type !== 'lite'">
      <initiatives-form-group
        for="mobilisation-start"
        label="Mobilisation start"
        help-text="Enter the date when the initiative is starting."
        has-error="$ctrl.form.mobilisationStart.$touched && $ctrl.form.mobilisationStart.$invalid"
        ng-if="$ctrl.props.type !== 'lite'">

        <datepicker date-format="yyyy-MM-dd">
          <input
          id="mobilisation-start"
          name="mobilisationStart"
          class="form-control form-control-danger col-sm-6"
          type="text"
          ng-model="$ctrl.props.mobilisationStart"
          ng-blur="$ctrl.form.mobilisationStart.$valid && $ctrl.changeInitiative($ctrl.props)"
          required>
        </datepicker>
        <div ng-show="$ctrl.form.mobilisationStart.$touched">
          <small ng-show="$ctrl.form.mobilisationStart.$error.pattern" class="form-control-feedback">
            Date must be in the format dd/mm/yyyy
          </small>
          <small ng-show="$ctrl.form.mobilisationStart.$error.required" class="form-control-feedback">Required field</small>
        </div>
      </initiatives-form-group>
      <initiatives-form-group
        for="mobilisation-time"
        id="hashLinkForMetrics"
        label="Time to mobilise"
        help-text="How long should it take to ramp-up and kick-off the initiative?"
        ng-if="$ctrl.props.type !== 'lite'">
        <select
          id="mobilisation-time"
          class="form-control col-sm-6"
          ng-model="$ctrl.props.mobilisationTime"
          ng-change="$ctrl.changeInitiative($ctrl.props)">
          <option value="">-- Select --</option>
          <option value="1">1 month</option>
          <option value="2">2 months</option>
          <option value="3">3 months</option>
          <option value="4">4 months</option>
          <option value="5">5 months</option>
          <option value="6">6 months</option>
        </select>
      </initiatives-form-group>
      <hr class="my-4" ng-if="$ctrl.props.type !== 'lite'">
      <initiatives-form-group
        for="teamkpis"
        label="Technical Success Metrics"
        help-text="We measure technical performance along two main dimensions: throughput of code and stability of systems.
                <br><br>Throughput is measured by how frequently a team is able to deploy code to production and how fast it can move from committing code to haiving it run in production for users.
                <br><br>Stability is measured by how quickly the system can recover from downtime and how many changes succeed, versus how many fail."
        ng-if="$ctrl.props.type !== 'lite'"
      >
        <div class="pt-3 mt-3">
          <kpis
            props="$ctrl.props.kpis"
            type="technical"
            style="width:100%"
            on-click="$ctrl.editTeamKpi(kpi)"/>
        </div>
      </initiatives-form-group>

      <initiatives-form-group
        for="kpis"
        label="Business Success Metrics"
        help-text="It’s essential to measure and monitor how your service is performing so you can make sure it continues to meet user needs in a cost-effective and efficient way.
                <br><br>We’ve set out 4 key performance indicators (KPIs) as the standard set of measures.
                <br><br>These simple metrics help you identify the strengths and weaknesses of your service, and ways to improve its performance over time."
        ng-if="$ctrl.props.type !== 'lite'"
      >
        <div class="pt-3 mt-3">
          <kpis
            props="$ctrl.props.kpis"
            type="business"
            style="width:100%"
            on-click="$ctrl.editTeamKpi(kpi)"/>
        </div>
      </initiatives-form-group>
      
      <hr class="my-4">
      <div ng-repeat="type in ['team', 'service']" ng-if="type === 'team' || (type === 'service' && $ctrl.props.type !== 'lite')">
        <initiatives-form-group
          for="checkpoints"
          label="{{ type | capitalize }} checkpoints"
          help-text="Enable the {{ type }} health checks that you would like to review at each check-in. You can change these any time in the future. {{ $ctrl.licenses[type] }}">
          <div class="row mt-2">
            <div
              class="col-sm-6 mb-4"
              ng-repeat="(key, item) in $ctrl.getCheckpointsByType(type)">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  class="form-check-input"
                  ng-model="checkbox"
                  ng-init="!$ctrl.props.id && $ctrl.updateCheckpoints(item, $ctrl.props.checkpoints)"
                  ng-click="$ctrl.updateCheckpoints(item, $ctrl.props.checkpoints)"
                  ng-checked="$ctrl.isActive(item, $ctrl.props.checkpoints)">
                <span class="custom-control-indicator"></span>
                <strong class="custom-control-description">
                  {{ item.name }}
                </strong>
              </label>
              <small class="form-text text-muted pl-4">
                {{ item.description }}
              </small>
            </div>
          </div>
        </initiatives-form-group>
      </div>
      <hr class="my-4">
      <div ng-repeat="(id, value) in $ctrl.questionSet.list" ng-if="$ctrl.props.type !== 'lite'">
        <initiatives-form-group
            ng-if="!value.removed"
            for="checkpoints"
            label="{{ value.name }} checkpoints"
            help-text="{{ value.description }} ">
            <a ng-click="$ctrl.onEditQuestionSet(id)">
              <i class="fa fa-pencil"></i> Edit
            </a>
            <a ng-click="$ctrl.delete_list[id] = !$ctrl.delete_list[id]" style="margin-left: 5em;"
            >
              <i class="fa fa-trash"></i> Delete
            </a>
            <div class="popover popover-bottom popover-delete popover_delete_qs" role="tooltip" ng-if="$ctrl.delete_list[id]">
              <div class="popover-content">
                <p>Deleting this checkpoint will also delete all questions within it and their history.</p>
                Are you sure?
                <a href="#" ng-click="$ctrl.onRemoveQuestionSet(id)">Yes</a> /
                <a href="#" ng-click="$ctrl.delete_list[id]=false;">No</a>
              </div>
            </div>
            
            <div class="row mt-2">
              <div
                class="col-sm-6 mb-4"
                ng-repeat="(qid, question) in value.questions">
                <label class="custom-control custom-checkbox mb-0">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    ng-model="checkbox"
                    ng-click="$ctrl.updateCustomCheckpoints(id, qid)"
                    ng-checked="$ctrl.isActiveCustom(id, qid)"
                  >
                  <span class="custom-control-indicator"></span>
                  <strong class="custom-control-description">
                    {{ question.name }}
                  </strong>
                </label>
                <small class="form-text text-muted pl-4">
                  {{ question.description }}
                </small>
              </div>
            </div>
          </initiatives-form-group>
        </div>
      
      <hr class="my-4" ng-if="$ctrl.props.group.priorities">
      <initiatives-form-group
        for="priorities"
        label="Priorities"
        ng-if="$ctrl.props.group.priorities"
      >
        <div class="mt-2" style="margin-left:0; margin-right:0">
          <button type="button" class="btn btn-primary" ng-click="$ctrl.onSelectPriority()" ng-show="$ctrl.isPriorityAddEnabled">
            <i class="fa fa-pencil"></i> Add/Change
          </button>

          <priorities
              priorities="$ctrl.selPriorities"
              on-remove-priority="$ctrl.onRemovePriority(id)"
              is-edit-disabled="true"
          >
          </priorities>
        </div>
      </initiatives-form-group>
      
      <hr class="mb-4">

      <div class="clearfix">
        <div class="alert alert-danger" ng-if="$ctrl.error">
           <p>{{$ctrl.error}}</p>
        </div>
        <button
          type="button"
          class="btn btn-primary ml-2 float-right submit-button"
          ng-disabled="$ctrl.form.$invalid || $ctrl.loading"
          ng-click="$ctrl.onSubmit({ props: $ctrl.props })"
        >
          <i class='fa fa-spinner fa-spin ' ng-if="$ctrl.loading"/>
          {{ $ctrl.name }} Initiative
        </button>
        <button
          type="button"
          class="btn btn-danger float-right"
          ng-click="$ctrl.onCancel()">
          Cancel
        </button>
        <div>
          <button
            type="button"
            class="btn btn-danger float-left"
            ng-click="$ctrl.onAddQuestionSet()"
            >
            Add Checkpoint
          </button>
        </div>
      </div>
    </form>
    <change-owner-modal
      target="modal-add-member"
      owners="$ctrl.owners"
      on-change="$ctrl.onAddMember(owner)"
      on-query="$ctrl.fetchUsers(text, size)"
      is-of-query="$ctrl.isOfQuery(id)"
      is-owner="$ctrl.isMember(id)"
      loading="$ctrl.ownerLoading"
      on-invite="$ctrl.onInvite(email)"
      title="Add Member"
      button-title="Add"
    />
    <change-owner-modal
      target="modal-change-owner"
      owners="$ctrl.owners"
      on-change="$ctrl.onChangeOwner(owner)"
      on-query="$ctrl.fetchUsers(text, size)"
      is-of-query="$ctrl.isOfQuery(id)"
      is-owner="$ctrl.isOwner(id)"
      loading="$ctrl.ownerLoading"
      title="Change Owner"
      button-title="Change"
    />
    <kpis-modal
        kpi="$ctrl.selectedKpi"
        name="Edit"
        target="kpi-modal"
        on-submit="$ctrl.saveTeamKpi()"
        updating="$ctrl.updatingKpi"
      >
    </kpis-modal>
    <select-priority-modal
        target="select-priority-modal"
        group-priorities="$ctrl.selPriorities"
        on-submit="$ctrl.onAddPriority(id)"
    />
    <add-chk-modal
        target="add-chk-modal"
        data="$ctrl.questionSetForm"
    /> 
    `,

  controller($ngRedux, $filter, $location, $timeout) {
    'ngInject';

    const teamLicense = `<div class="license">This work is licensed under the <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a>, 
      with questions taken and adapted from from Atlassian's <a href="https://www.atlassian.com/team-playbook/health-monitor/project-teams" target="_blank">Health monitor for project teams</a></div>`;

    const serviceLicense = `<div class="license">This work is licensed under the <a href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/2/" target="_blank">Open Government Licence 2.0</a>, 
      with questions taken and adapted from the Australian Government's Digital Transformation Agency's <a href="https://www.dta.gov.au/standard/" target="_blank">Digital Service Standard</a>`;

    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      blur,
      mapState,
      mapDispatch,
      updateCheckpoints,
      getCheckpointsByType,
      isActive,
      isActiveCustom,
      onChangeOwner,
      getShortName,
      isOfQuery,
      isOwner,
      removeTeamMember,
      onAddMember,
      isMember,
      onClickChangeOwner,
      onClickAddTeamMember,
      editTeamKpi,
      saveTeamKpi,
      toTitleCase,
      onSelectPriority,
      setPriorities,
      onAddPriority,
      onRemovePriority,
      onAddQuestionSet,
      onEditQuestionSet,
      onRemoveQuestionSet,
      percents: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      updateCustomCheckpoints,
      questionSetForm: {
        questions: {},
        name: '',
        description: '',
      },
      delete_list: {},
      licenses: {
        team: teamLicense,
        service: serviceLicense,
      },
      onInvite,
    };

    return $ctrl;

    function $onInit() {
      if (!$ctrl.props) {
        $ctrl.props = {};
      }

      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);

      $ctrl.statuses = [...statuses];
      $ctrl.types = types
        .filter(t => t !== 'lite')
        .map(t => {
          return {
            label: toTitleCase(t),
            type: t,
          };
        });
      $ctrl.dateRegex = /\d{2}\/\d{2}\/\d{4}/;

      document.body.scrollTop = 0;

      // load custom checkpoints
      $ctrl.fetchQuestionSets();
    }

    function $onDestroy() {
      $('#kpi-modal').remove();

      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if (
        !$ctrl.isSetPriorities &&
        $ctrl.props.group &&
        $ctrl.props.group.priorities
      ) {
        $ctrl.isSetPriorities = true;
        $ctrl.setPriorities();
      }

      if ($ctrl.isUpdatingKpi && !$ctrl.updatingKpi && $ctrl.successKpi) {
        $('#kpi-modal').modal('hide');
        $ctrl.isUpdatingKpi = false;
      }
    }

    function mapState(state) {
      if (
        !$ctrl.isFirstLoaded &&
        $ctrl.props.kpis &&
        Object.keys($ctrl.props.kpis).length > 0
      ) {
        $ctrl.isFirstLoaded = true;
        const searchObject = $location.search();
        if (searchObject && searchObject.kpiId) {
          $(document).ready(function() {
            $ctrl.editTeamKpi($ctrl.props.kpis[searchObject.kpiId]);
          });
        }
      }

      if (!$ctrl.props.owner || $ctrl.props.owner.id === state.user.data.id) {
        $ctrl.props.owner = { ...state.user.data };
        $ctrl.props.creator = $ctrl.props.owner.id;
      }

      if (typeof $ctrl.props.team !== 'string') {
        if (typeof $ctrl.props.team !== 'object') {
          $ctrl.props.team = {};
          $ctrl.props.team[state.user.data.id] = state.user.data;
        } else if ($ctrl.props.team.hasOwnProperty(state.user.data.id)) {
          $ctrl.props.team[state.user.data.id] = state.user.data;
        }

        $ctrl.props.teamMembers = $ctrl.props.team;
      }

      return {
        checkpoints: { ...state.checkpoints.data },
        loading: state.initiative.loading,
        error: state.initiative.error,
        owners: state.users.data,
        ownerLoading: state.users.loading,
        updatingKpi: state.initiative.updatingKpi,
        successKpi: state.initiative.successKpi,
        questionSet: state.questionSet,
      };
    }

    function mapDispatch() {
      return {
        ...InivteActions,
        fetchCheckpoints,
        cancelCheckpoints,
        changeInitiative,
        addInitiativeCheckpoint,
        removeInitiativeCheckpoint,
        addInitiativeTag,
        removeInitiativeTag,
        fetchUsers,
        updateInitiativeKpi,
        fetchQuestionSets,
        saveQuestionSet,
      };
    }

    function updateCheckpoints(item, checkpoints) {
      if ($ctrl.isActive(item, checkpoints)) {
        $ctrl.removeInitiativeCheckpoint(item);
      } else {
        $ctrl.addInitiativeCheckpoint(item);
      }
    }

    function updateCustomCheckpoints(questionSetId, questionIndex) {
      const item = {
        ...$ctrl.questionSet.list[questionSetId],
        id:
          $ctrl.questionSet.list[questionSetId].user_id +
          '__' +
          questionSetId +
          '__' +
          questionIndex,
        // user_id/questionSetId/index
      };

      if ($ctrl.isActive(item, $ctrl.props.checkpoints)) {
        $ctrl.removeInitiativeCheckpoint(item);
      } else {
        $ctrl.addInitiativeCheckpoint(item);
      }
    }

    function isActiveCustom(questionSetId, questionIndex) {
      const { checkpoints } = $ctrl.props;
      const id =
        $ctrl.questionSet.list[questionSetId].user_id +
        '__' +
        questionSetId +
        '__' +
        questionIndex;
      if (checkpoints) {
        return checkpoints.find(i => i.id === id);
      } else {
        return false;
      }
    }

    function getCheckpointsByType(type) {
      const checkpoints = {};
      for (let key in $ctrl.checkpoints) {
        if ($ctrl.checkpoints[key].type === type) {
          checkpoints[key] = $ctrl.checkpoints[key];
        }
      }
      return checkpoints;
    }

    function isActive(item, checkpoints) {
      if (checkpoints) {
        return checkpoints.find(i => i.id === item.id);
      } else {
        return false;
      }
    }

    function onChangeOwner(owner) {
      const { creator } = $ctrl.props;
      $ctrl.props.owner = owner;
      $ctrl.props.creator = owner.id;

      $ctrl.removeTeamMember(creator);
      $ctrl.onAddMember(owner);
    }

    function isOfQuery(id) {
      return $ctrl.owners.filter(user => user.id === id).length > 0;
    }

    function isOwner(userId) {
      if (!$ctrl.props.owner) {
        return false;
      }
      return $ctrl.props.owner.id === userId;
    }

    function removeTeamMember(userId) {
      delete $ctrl.props.team[userId];
      if ($ctrl.props.teamMembers) {
        delete $ctrl.props.teamMembers[userId];
      }
    }

    function onAddMember(member) {
      if (typeof $ctrl.props.team === 'string') {
        $ctrl.props.solution =
          'Previous users: ' +
          $ctrl.props.team +
          '\r\r\r' +
          ($ctrl.props.solution ? $ctrl.props.solution : '');
        $ctrl.props.team = {};
        $ctrl.props.team[member.id] = member;
      } else {
        $ctrl.props.team[member.id] = member;
        $ctrl.props.teamMembers = $ctrl.props.team;
      }

      $ctrl.changeInitiative($ctrl.props);
    }

    function isMember(userId) {
      if (!$ctrl.props.owner) {
        return false;
      }
      return $ctrl.props.team.hasOwnProperty(userId);
    }

    function onClickChangeOwner() {
      $('#modal-change-owner').modal('show');
      $('#modal-change-owner').appendTo('body');
    }

    function onClickAddTeamMember() {
      $('#modal-add-member').modal('show');
      $('#modal-add-member').appendTo('body');
    }

    function editTeamKpi(kpi) {
      $ctrl.selectedKpi = { ...kpi };
      const data = [];
      if ($ctrl.selectedKpi.data) {
        let id = 0;
        for (const [key, value] of Object.entries($ctrl.selectedKpi.data)) {
          data.push({
            key: $filter('date')(key, 'yyyy-MM-dd'),
            value: parseInt(value),
            id: id++,
          });
        }
      }
      $ctrl.selectedKpi.data = data;
      $('#kpi-modal').modal('show');
      $('#kpi-modal').appendTo('body');
    }

    function saveTeamKpi() {
      $ctrl.isUpdatingKpi = true;
      const data = {};
      for (const value of $ctrl.selectedKpi.data) {
        data[new Date(value.key).getTime()] = value.value;
      }
      const id = $ctrl.selectedKpi.name.toLowerCase().replace(/ /g, '_');
      $ctrl.props.kpis[id].data = data;
      $ctrl.props.kpis[id].disabled = $ctrl.selectedKpi.disabled;

      $ctrl.updateInitiativeKpi($ctrl.props.id, { ...$ctrl.selectedKpi, data });
    }

    function onSelectPriority() {
      $('#select-priority-modal').modal('show');
    }

    function setPriorities() {
      if ($ctrl.props.priorities) {
        if (!$ctrl.props.group.priorities) {
          delete $ctrl.props.priorities;
          $ctrl.changeInitiative($ctrl.props);
          return;
        }
        const temp = angular.copy($ctrl.props.priorities);
        for (const [id, value] of Object.entries(temp)) {
          if (!$ctrl.props.group.priorities[id]) {
            delete $ctrl.props.priorities[id];
          }
        }
        $ctrl.changeInitiative($ctrl.props);
      }

      if (!$ctrl.props.group.priorities) {
        $ctrl.selPriorities = {};
        return;
      }

      $ctrl.selPriorities = angular.copy($ctrl.props.group.priorities);
      for (const [id, value] of Object.entries($ctrl.selPriorities)) {
        value.id = id;
        if (!($ctrl.props.priorities && $ctrl.props.priorities[id])) {
          value.isEnabled = true;
          if (!$ctrl.isPriorityAddEnabled) {
            $ctrl.isPriorityAddEnabled = true;
          }
        }
      }
    }

    function onAddPriority(id) {
      $('#select-priority-modal').modal('hide');

      if (!$ctrl.props.priorities) {
        $ctrl.props.priorities = {};
      }
      $ctrl.props.priorities[id] = true;

      $ctrl.changeInitiative($ctrl.props);
      $ctrl.selPriorities[id].isEnabled = false;
    }

    function onRemovePriority(id) {
      delete $ctrl.props.priorities[id];

      $ctrl.changeInitiative($ctrl.props);
      $ctrl.selPriorities[id].isEnabled = true;
      $ctrl.isPriorityAddEnabled = true;
    }

    function onAddQuestionSet() {
      $ctrl.questionSetForm = {
        questions: {},
        name: '',
        description: '',
      };
      $('#add-chk-modal').modal('show');
      $('#add-chk-modal').appendTo('body');
    }

    function onEditQuestionSet(id) {
      const q = JSON.parse(JSON.stringify($ctrl.questionSet.list[id]));
      $ctrl.questionSetForm = q;
      $('#add-chk-modal').modal('show');
      $('#add-chk-modal').appendTo('body');
      // $ctrl.questionSetForm
    }

    function onRemoveQuestionSet(id) {
      const data = JSON.parse(JSON.stringify($ctrl.questionSet.list[id]));

      for (let questionIndex in data.questions) {
        const item = {
          ...$ctrl.questionSet.list[id],
          id:
            $ctrl.questionSet.list[id].user_id +
            '__' +
            id +
            '__' +
            questionIndex,
        };
        $ctrl.removeInitiativeCheckpoint(item);
      }

      data.removed = true;
      $ctrl.saveQuestionSet(data);
    }

    function onInvite(email) {
      console.info('Invite User:', email, $ctrl.props.id);
      $ctrl.inviteUserByEmail({ email, initiative: $ctrl.props.id});
    }
  },
};
