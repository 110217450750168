import { saveQuestionSet } from 'logic/questionSet';
import { getRandomId } from 'utils/index';

export default {
  bindings: {
    target: '@',
    data: '<',
    edit: '<',
  },

  template: `
      <div ng-attr-id="{{$ctrl.target}}" class="modal fade {{ $ctrl.target }}" tabindex="-1" role="dialog">
      <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header p-4">
                <h3 class="modal-title">{{$ctrl.isEdit()?  'Edit' : 'Add' }} Question Set</h3>
            </div>
            <div class="modal-body p-2 pb-0">
                <div class="form-group">
                    <label for="name" class="col-form-label text-gray-dark">
                        Name
                    </label>
                    <input
                        ng-attr-id="{{$ctrl.target}}chk_name"
                        name="chk_name"
                        class="form-control form-control-danger"
                        ng-model="$ctrl.data.name"
                    ></input>
                </div>
            </div>
            <div class="modal-body p-2 pb-0">
                <div class="form-group">
                    <label for="name" class="col-form-label text-gray-dark">
                        Description
                    </label>
                    <textarea
                        ng-attr-id="{{$ctrl.target}}chk_desc"
                        name="chk_desc"
                        class="form-control form-control-danger"
                        ng-model="$ctrl.data.description"
                    >
                    </textarea>
                </div>
            </div>

            
            <div class="modal-body p-4 pb-0">
                <h4>Questions</h4>
                <hr>
                <div class="p-2">
                    <button class="btn btn-secondary" ng-click="$ctrl.onAddQuestion()"><i class="fa fa-plus"></i></button>
                </div>
                <div ng-repeat="key in $ctrl.getQuestionKeys()" style="margin-top: 0.5em;">
                    <div class='question_item'>
                        <div>
                            <label for="short_name" class="col-form-label text-gray-dark" style="font-size: 0.8em;">
                                Short Name
                            </label>
                            <input
                                class="form-control form-control-danger"
                                ng-model="$ctrl.data.questions[key].name"
                            ></input>
                        </div>
                        <div>
                            <label for="description" class="col-form-label text-gray-dark" style="font-size: 0.8em;">
                                Description
                            </label>
                            <input
                                class="form-control form-control-danger"
                                ng-model="$ctrl.data.questions[key].description"
                            ></input>
                        </div>
                        <button ng-click="$ctrl.onClickRemove(key)"
                          class="btn btn-secondary"
                          style="margin-top: 1em;"
                        ><i class="fa fa-trash"></i></button>
                        <div class="popover popover-bottom popover-delete popover_delete_q" role="tooltip" ng-if="$ctrl.delete_list[key]">
                          <div class="popover-content">
                            <p>Deleting this question will also delete its history.</p>
                            Are you sure?
                            <a href="#" ng-click="$ctrl.onRemoveQuestion(key)">Yes</a> /
                            <a href="#" ng-click="$ctrl.delete_list={}">No</a>
                          </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="p-2">
                <small ng-if="$ctrl.state.loading"><i class='fa fa-spinner fa-spin' /> Saving...</small>
                <small ng-if="$ctrl.state.error">{{$ctrl.state.error}}</small>
            </div>

            <div class="clearfix pt-2 submit-area">
                <button
                    type="button"
                    ng-click=""
                    class="btn btn-secondary"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-primary"
                    ng-disabled="!$ctrl.isValidForm()"
                    ng-click="$ctrl.onSubmit()"
                >
                    {{ $ctrl.isEdit()? 'Save': 'Add' }}
                </button>
            </div>
      </div>
  </div>`,

  controller($scope, $ngRedux) {
    'ngInject';
    const $ctrl = {
      $onInit,
      onAddQuestion,
      onRemoveQuestion,
      onClickRemove,
      onSubmit,
      isEdit,
      isValidForm,
      getQuestionKeys,
      mapState,
      mapDispatch,
      delete_list: {},
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
    }

    function isEdit() {
      return !!$ctrl.data.id;
    }

    function onAddQuestion() {
      if (!$ctrl.data.questions) {
        $ctrl.data.questions = {};
      }

      let order = -1;
      const keys = Object.keys($ctrl.data.questions);
      keys.forEach(key => {
        if ($ctrl.data.questions[key].order > order) {
          order = $ctrl.data.questions[key].order;
        }
      });
      order++;

      $ctrl.data.questions[getRandomId(3)] = {
        name: '',
        description: '',
        order,
      };
    }

    function onRemoveQuestion(index) {
      $ctrl.delete_list = {};
      delete $ctrl.data.questions[index];
    }

    function onClickRemove(index) {
      $ctrl.delete_list = {
        [index]: !$ctrl.delete_list[index],
      };
    }

    function onSubmit() {
      const { data } = $ctrl;
      $ctrl.saveQuestionSet(data);
    }

    function getQuestionKeys() {
      const { questions } = $ctrl.data;
      const keys = Object.keys(questions);
      const aryObj = keys.map(key => {
        return { ...questions[key], key };
      });

      aryObj.sort((a, b) => a.order - b.order);
      return aryObj.map(obj => obj.key);
    }

    function isValidForm() {
      const { data } = $ctrl;
      const { questions } = data;

      if (!questions || Object.keys(questions).length === 0) {
        return false;
      }

      for (let i in questions) {
        if (!questions[i] || !questions[i].description) {
          return false;
        }
      }

      if (!data.name || !data.description) {
        return false;
      }

      return true;
    }

    function mapState(state) {
      // Question set created
      if ($ctrl.state && !$ctrl.state.success && state.questionSet.success) {
        try {
          $('#add-chk-modal').modal('hide');
        } catch (e) {
          console.error(e);
        }
      }
      return { state: state.questionSet };
    }

    function mapDispatch() {
      return {
        saveQuestionSet,
      };
    }
  },
};
