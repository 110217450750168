/*
Created by Adam on 27/7/17
*/
import landing from './landing';
import teamCheckin from './teamCheckin';

import './landing.scss';

export default angular
  .module('App.landing', [])
  .component('landing', landing)
  .component('teamCheckin', teamCheckin).name;
