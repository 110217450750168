import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import store from 'store';

import { queryLocalUsers } from '../utils';

import { LOGOUT_SUCCESS } from './user';

import axios from 'axios';
import getFirebaseConfig from '../config/firebase';
const firebaseProjectId = getFirebaseConfig().projectId;

//region Action Types
export const FETCH = 'app/users/FETCH';
export const SUCCESS = 'app/users/SUCCESS';
export const ERROR = 'app/users/ERROR';
export const CANCEL = 'app/users/CANCEL';
export const WATCH_USERS = 'app/users/WATCH';
export const USER_ADDED = 'app/users/ADDED';
export const USER_CHANGED = 'app/users/CHANGED';
export const USER_REMOVED = 'app/users/REMOVED';
export const GET_USERS_PROFILE = 'app/users/GET_USERS_PROFILE';
// export const CHECK_EMAIL_REGISTERED = 'app/users/CHECK_EMAIL_REGISTERED';
//endregion

//region Initial State
export const initialState = {
  data: undefined,
  dic: {}, // user dic for instant search
  loading: false,
  error: false,
};
//endregion

//region Actions
export function fetchUsers(queryText, size) {
  return { type: FETCH, payload: { q: queryText, size: size || 10 } };
}

export function cancelUsers() {
  return { type: CANCEL };
}

export function watchUsers() {
  return { type: WATCH_USERS };
}

export function getUsersProfile(idsDictionary) {
  return { type: GET_USERS_PROFILE, payload: idsDictionary };
}

// export function checkEmailRegistered(payload) {
//   return { type: CHECK_EMAIL_REGISTERED, payload: { q: { email: payload } } };
// }
//endregion

//region Observable Logic
export const watchUsersLogic = createLogic({
  type: WATCH_USERS,
  warnTimeout: 0,
  process({ getState }, dispatch, done) {
    firebase
      .database()
      .ref('users')
      .on('child_added', snapshot => {
        const user = snapshot.val();
        dispatch({
          type: USER_ADDED,
          payload: {
            data: { ...user, id: snapshot.key },
          },
        });
      });
    firebase
      .database()
      .ref(`users`)
      .on('child_changed', snapshot => {
        const user = snapshot.val();
        dispatch({
          type: USER_CHANGED,
          payload: {
            data: { ...user, id: snapshot.key },
          },
        });
      });
    firebase
      .database()
      .ref(`users`)
      .on('child_removed', snapshot => {
        const user = snapshot.val();
        dispatch({
          type: USER_REMOVED,
          payload: {
            data: { ...user, id: snapshot.key },
          },
        });
      });
  },
});

export const usersFetchLogic = createLogic({
  latest: true,
  type: FETCH,
  cancelType: CANCEL,
  processOptions: {
    successType: SUCCESS,
    failType: ERROR,
  },
  process({ $http, action }) {
    if (!action.payload.q) {
      return { data: undefined };
    }

    return axios({
      method: 'POST',
      url: `https://us-central1-${firebaseProjectId}.cloudfunctions.net/queryUsers`,
      headers: {
        Authorization: store.get('Authorization'),
      },
      data: { ...action.payload, q: action.payload.q.toLowerCase() },
      json: true,
    }).then(response => {
      return { data: response.data.users };
    });
  },
});

export const usersGetProfile = createLogic({
  latest: true,
  type: GET_USERS_PROFILE,
  cancelType: CANCEL,
  processOptions: {
    successType: SUCCESS,
    failType: ERROR,
  },
  process({ $http, action }) {
    // Get user profiles from idsDictionary { id1: true, id2: true, ... }
    const idsDictionary = action.payload;
    const promises = [];
    for (let userId of Object.keys(idsDictionary)) {
      promises.push(
        firebase
          .database()
          .ref(`users/${userId}`)
          .once('value')
      );
    }

    return Promise.all(promises).then(snapshots => {
      let users = [];
      for (let i = 0; i < snapshots.length; i++) {
        users[i] = { ...snapshots[i].val(), id: snapshots[i].key };
      }
      return { data: users };
    });
  },
});

//endregion

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case GET_USERS_PROFILE:
      return {
        ...state,
        data: getUserArray(state.dic),
        loading: true,
      };

    case FETCH:
      return {
        ...state,
        data: queryLocalUsers(state.dic, payload),
        loading: true,
      };

    case SUCCESS:
      const { data } = payload;
      let isEmailRegistered = false;
      if (Array.isArray(data) && data.length > 0) {
        isEmailRegistered = true;
      }

      return {
        ...state,
        ...payload,
        dic: getUserDic(state, payload),
        loading: false,
        isEmailRegistered,
      };

    case ERROR:
      return {
        ...state,
        ...payload,
        loading: false,
        error: true,
      };

    case USER_CHANGED:
      if (Array.isArray(state.data)) {
        for (let i = 0; i < state.data.length; i++) {
          // Search the user with the same id
          if (state.data[i].id === action.payload.data.id) {
            state.data[i] = action.payload.data;
            return {
              ...state,
              dic: getUserDic(state, payload),
            };
          }
        }
      }
      return state;

    case USER_REMOVED:
      if (Array.isArray(state.data)) {
        const data = state.data;

        return {
          ...state,
          data: data.filter(user => user.id !== action.payload.data.id),
          dic: getUserDic(state, payload, true),
        };
      }
      return state;

    case USER_ADDED:
      if (Array.isArray(state.data)) {
        // See if it exists
        if (
          state.data.findIndex(user => user.id === action.payload.data.id) >= 0
        ) {
          return state;
        }
        const data = [...state.data, action.payload.data];
        return { ...state, data };
      } else {
        return { ...state, data: [action.payload.data] };
      }
      return state;

    case LOGOUT_SUCCESS:
      return {
        ...state,
        data: [],
        dic: {},
      };

    default:
      return state;
  }
}

//region Update User Dictionary
function getUserDic(state, payload, isRemoved = false) {
  let { dic } = state;
  const { data } = payload;

  if (!dic) {
    dic = {};
  }

  if (isRemoved) {
    if (Array.isArray(data)) {
      for (let user of data) {
        delete dic[user.id]; // register user
      }
    } else if (data && data.id) {
      delete dic[data.id];
    }
  } else {
    if (Array.isArray(data)) {
      for (let user of data) {
        dic[user.id] = user; // register user
      }
    } else if (data && data.id) {
      dic[data.id] = data;
    }
  }
  return { ...dic };
}

function getUserArray(dic) {
  if (!dic) {
    return [];
  }

  let initKeys = Object.keys(dic);
  let users = [];
  for (let i = 0; i < initKeys.length; i++) {
    let user = dic[initKeys[i]];
    user.id = initKeys[i];
    users[i] = user;
  }

  return users;
}
//endregion
