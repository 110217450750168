import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import store from 'store';
import axios from 'axios';

import getFirebaseConfig from '../config/firebase';
const firebaseProjectId = getFirebaseConfig().projectId;

import { DELETE_SUCCESS } from './group';

//region Action Types
export const FETCH = 'app/groups/FETCH';
export const SUCCESS = 'app/groups/SUCCESS';
export const ERROR = 'app/groups/ERROR';
export const CANCEL = 'app/groups/CANCEL';
export const WATCH_GROUPS = 'app/groups/WATCH';
//endregion

//region Initial State
export const initialState = {
  data: store.get('groups') || [],
  loading: false,
};
//endregion

//region Actions
export function fetchGroups() {
  return { type: FETCH };
}

export function cancelGroups() {
  return { type: CANCEL };
}

export function watchGroups() {
  return {
    type: WATCH_GROUPS,
  };
}
//endregion

//region Observable Logic
export const groupsFetch = createLogic({
  latest: true,
  type: FETCH,
  cancelType: CANCEL,
  processOptions: {
    successType: SUCCESS,
    failType: ERROR,
  },
  process({ $http, action }) {
    return axios({
      method: 'POST',
      url: `https://us-central1-${firebaseProjectId}.cloudfunctions.net/queryUserGroups`,
      headers: {
        Authorization: store.get('Authorization'),
      },
      json: true,
    }).then(response => {
      store.set('groups', response.data.groups);
      return { data: response.data.groups };
    });
  },
});
//endregion

//region Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH:
      return {
        ...state,
        loading: true,
      };

    case SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
      };

    case ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: payload.code,
      };

    case DELETE_SUCCESS: {
      let data = state.data;
      data = data.filter(g => {
        return g.id !== action.payload.data.id;
      });
      return {
        ...state,
        data: data,
      };
    }

    default:
      return state;
  }
}
//endregion
