export default {
  bindings: {
    graphData: '<',
    isIndividual: '<',
  },

  template: `
    <div 
      class="wallboard-card-fill"
      ng-class="{true:'wallboard-show-card-donut', false: 'wallboard-card-donut'}[$ctrl.isIndividual]"
    >
      <graph-donut-mobilisation
        graph-data="$ctrl.graphData"
        class="graph-donut-dark wallboard-card-fill">
      </graph-donut-mobilisation>
    </div>`,
};
