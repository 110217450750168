import html from './dashboard.html';
import { fetchGroup } from 'logic/group';
import { getShortName } from '../../../utils';
import { removeGroupUser, addGroupUser } from 'logic/group';
import { fetchInitiatives } from 'logic/initiatives';

export default {
  bindings: {},

  template: html,

  controller($ngRedux, $routeParams, $scope, $rootScope, $location, $timeout) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $doCheck,
      $onDestroy,
      mapState,
      mapDispatch,
      getShortName,
      chartType: 'all',
      onClickEdit,
      onClickBubble,
      onClickMembers,
      standardColor,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);

      $ctrl.group_name = $routeParams.name;
      $ctrl.fetchGroup($ctrl.group_name);
    }

    function $doCheck() {
      if ($ctrl.data.name) {
        $rootScope.title = `Init - Group: ${$ctrl.data.name}`;
      }
      if ($ctrl.success) {
        $scope.$broadcast('remove_member_event');
      }

      if (!$ctrl.groupLoaded && !$ctrl.loading && !$ctrl.fetchError) {
        $ctrl.groupLoaded = true;
        $ctrl.fetchInitiatives({
          group: $ctrl.data.id,
          type: 'project',
        });
      }
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function standardColor(color) {
      if (color === 'red') {
        return '#d52a59';
      }

      if (color === 'blue') {
        return '#0399cd';
      }

      if (color === 'green') {
        return '#acd036';
      }

      return color;
    }

    function mapState(state) {
      const initiatives = state.initiatives.data;
      const dicByPriority = {};
      const dicByCategory = {};
      const chartData = initiatives.map(initiative => {
        const priorities = _.get(initiative, 'group.priorities');
        const tags = initiative.tags || [];
        const localPriorities = initiative.priorities;
        let defaultColor;

        // put into dic by priorities
        if (localPriorities) {
          for (let key in localPriorities) {
            if (priorities && priorities[key]) {
              if (!defaultColor) {
                defaultColor = standardColor(priorities[key].color);
              }

              if (!Array.isArray(dicByPriority[key])) {
                dicByPriority[key] = [];
              }
              dicByPriority[key] = [
                ...dicByPriority[key],
                {
                  ...initiative,
                  color: standardColor(priorities[key].color),
                },
              ];
            }
          }

          tags.forEach(tag => {
            if (!dicByCategory[tag]) {
              dicByCategory[tag] = [];
            }
            dicByCategory[tag].push({
              ...initiative,
            });
          });
        }

        return {
          id: initiative.id,
          name: initiative.name,
          percentageComplete: initiative.percentageComplete,
          budget: parseInt(initiative.budget || 1000), // TODO: default budget should be displayed,
          type: initiative.type,
          // TODO: default color
          color: defaultColor || '#808080',
        };
      });
      console.info('Group:', state.group);
      console.info('Priority:', state.group);

      return {
        ...state.group,
        users: state.users.dic,
        user: state.user.data,
        loadingInitiatives: state.initiatives.loading,
        chartData,
        noInitiatives: chartData.length === 0,
        dicByPriority,
        dicByCategory,
      };
    }

    function mapDispatch() {
      return {
        fetchGroup,
        removeGroupUser,
        addGroupUser,
        fetchInitiatives,
      };
    }

    function onClickEdit() {
      $location.path(`/groups/${$ctrl.data.name}/edit`);
    }

    function onClickBubble(id) {
      $timeout(() => {
        $location.path(`/groups/${$ctrl.data.id}/initiatives/${id}`);
      });
    }

    function onClickMembers() {
      $timeout(() => {
        $location.path(`/groups/${$ctrl.data.name}/members`);
      });
    }
  },
};
