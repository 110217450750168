const keys = {
  production: 'https://80f8ce47e8704946ab9ea12894c26b99@sentry.io/258531',
  development: 'https://e5adbe4020894003ac33ae8bb40a3f90@sentry.io/258536',
};

export default function() {
  if (process.env.NODE_ENV === 'production') {
    return keys.production;
  } else {
    return keys.development;
  }
}
