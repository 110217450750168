export default {
  bindings: {
    item: '<',
    badges: '<',
  },

  template: `
    <div class="initiatives-list-badges">
      <div class="row">
        <div class="col-md-4" ng-if="$ctrl.item.type !== 'lite'">
          <h6 class="mb-4">Mobilisation Tasks</h6>
          <div class="pr-4">
            <graph-donut-mobilisation
              graph-data="$ctrl.item.graphData"
              class="graph-donut-light graph-donut-square">
            </graph-donut-mobilisation>
          </div>
        </div>
        <div class="col-md-8" ng-if="$ctrl.item.type !== 'lite'">
          <h6 class="mb-4">Badges</h6>
          <p
            class="text-muted"
            ng-hide="$ctrl.item.badges.length">
            No badges awarded.
          </p>
          <div class="row">
            <div class="col-sm-6 mb-2 d-flex align-items-center" ng-repeat="item in $ctrl.item.badges">
              <i class="fa fa-3x fa-star text-warning mr-3"></i>
              <small>{{ item.name }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>`,
};
