/**
 * Created by sasa on 28/9/17.
 */

import { fetchInitiative, cancelInitiative } from 'logic/initiative';

import { fetchQuestions, cancelQuestions } from 'logic/constants/questions';
import { fetchSurvey, cancelSurvey, updateSurvey } from 'logic/morale-survey';
import store from 'store';
import * as _ from 'lodash';
import * as Utils from '../../utils';

import html from './moraleSurvey.html';
import './moraleSurvey.scss';

export default {
  template: html,

  controller($ngRedux, $location, $routeParams, $scope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
      onValueChanged,
      onClickRadio,
      saveSurvey,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.fetchQuestions();
      // $ctrl.fetchInitiative($routeParams.id);
      $ctrl.fetchSurvey($routeParams.id, store.get('user'));
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      const questions = state.questions.data;
      const initiative = state.initiative.data;
      const scores = [...state.survey.data];
      const moraleSurvey = [];

      scores.forEach(i => {
        moraleSurvey.push({ value: i, editable: i < 1 });
      });

      const props = {
        props: {
          questions,
          moraleSurvey,
        },
        prevSurvey: angular.copy(moraleSurvey),
        fetching: state.questions.loading || state.survey.loading,
        success: state.questions.success && !state.survey.error,
        error: state.questions.error || state.survey.error,
        saving: state.survey.saving,
      };

      return props;
    }

    function mapDispatch() {
      return {
        fetchQuestions,
        cancelQuestions,
        fetchInitiative,
        cancelInitiative,
        fetchSurvey,
        cancelSurvey,
        updateSurvey,
      };
    }

    function onValueChanged(e) {
      $ctrl.saveSurvey();
    }

    function onClickRadio(index, title) {
      if (title === 'Strongly disagree') {
        $ctrl.props.moraleSurvey[index].value = 1;
      } else if (title === 'Disagree') {
        $ctrl.props.moraleSurvey[index].value = 2;
      } else if (title === 'Agree') {
        $ctrl.props.moraleSurvey[index].value = 3;
      } else if (title === 'Strongly agree') {
        $ctrl.props.moraleSurvey[index].value = 4;
      }

      $ctrl.saveSurvey();
    }

    function saveSurvey() {
      let survey = [];
      $ctrl.props.moraleSurvey.forEach((item, index) => {
        survey.push(item.value);

        if (item.value != $ctrl.prevSurvey[index].value) {
          $ctrl.savingIndex = index;
        }
      });
      $ctrl.updateSurvey($routeParams.id, store.get('user'), survey);
    }
  },
};
