export default {
  bindings: {
    graphData: '<',
    labelColor: '@',
  },

  template: `
    <graph-donut
      data="$ctrl.data"
      width="$ctrl.width"
      class="graph-donut-mobilisation">
    </graph-donut>`,

  controller($window) {
    'ngInject';
    const $ctrl = {
      $onInit,
    };

    return $ctrl;

    function $onInit() {
      if ($ctrl.graphData) {
        const { timespan, mobilisation } = $ctrl.graphData;
        $ctrl.data = [mobilisation, timespan];
      }
    }
  },
};
