import award from './award';
import badgeStatus from './badge-status';
import columns from './columns';
import graphDonut from './graph-donut';
import graphDonutMobilisation from './graph-donut-mobilisation';
import graphDonutCheckpoints from './graph-donut-checkpoints';
import graphLine from './graph-line';
import graphLineTotal from './graph-line-total';
import linkAdder from './link-adder';
import progressBar from './progress-bar';
import readMore from './read-more';
import tags from './tags';
import kpis from './kpis';
import group from './group';
import initiative from './initiative';
import initiativeTags from './initiative-tags';
import upVoteButton from './up-vote-button';
import bubbleChart from './bubble-chart';
import inputUsd from './input-usd';
import customSelect from './custom-select';
import './widgets.scss';
import './graphs.scss';

customSelect(angular);

export default angular
  .module('App.widgets', ['CustomSelect'])
  .component('award', award)
  .component('badgeStatus', badgeStatus)
  .component('columns', columns)
  .component('graphDonut', graphDonut)
  .component('graphDonutMobilisation', graphDonutMobilisation)
  .component('graphDonutCheckpoints', graphDonutCheckpoints)
  .component('graphLine', graphLine)
  .component('graphLineTotal', graphLineTotal)
  .component('linkAdder', linkAdder)
  .component('progressBar', progressBar)
  .component('readMore', readMore)
  .component('group', group)
  .component('initiative', initiative)
  .component('initiativeTags', initiativeTags)
  .component('tags', tags)
  .component('upVoteButton', upVoteButton)
  .component('kpis', kpis)
  .component('bubbleChart', bubbleChart)
  .directive('usd', inputUsd).name;
