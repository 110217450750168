import html from './priority-modal.html';
import { priorityColors } from 'constants/colors';

export default {
  bindings: {
    target: '@',
    onSubmit: '&',
    name: '@',
    loading: '<',
    error: '=',
    priority: '<',
  },

  template: html,

  controller() {
    'ngInject';
    console.info('Colors:', priorityColors);
    const $ctrl = {
      $onInit,
      onTitleChange,
      placeholderColors: priorityColors,
    };

    return $ctrl;

    function $onInit() {
      $(document).ready(function() {
        $(`#${$ctrl.target}`).on('hide.bs.modal', function() {
          $ctrl.addpriorityform.$setUntouched();
        });

        $(`#${$ctrl.target}`).on('show.bs.modal', function() {
          $ctrl.error = undefined;
        });
      });
    }

    function onTitleChange() {
      $ctrl.error = undefined;
    }
  },
};
