import {
  initInitiative,
  createInitiative,
  cancelInitiative,
} from 'logic/initiative';

import decorateInitiative from 'decorators/initiative';
import { injectKpis } from '../../utils';

export default {
  template: `
    <mobile-nav ng-model="$ctrl.mobileMenuChecked"></mobile-nav>
    <div class="page-wrapper" ng-class="{'slided-wrapper': $ctrl.mobileMenuChecked}">
      <header-site menu-opened="$ctrl.mobileMenuChecked"></header-site>
      <div class="container initiatives-new content-wrapper">
        <main class="main">
          <header>
            <h2>New initiative</h2>
          </header>
          <hr class="my-4">
          <section>
            <initiatives-form
              name="Create"
              props="$ctrl.props"
              on-submit="$ctrl.createInitiative(props)"
              on-cancel="$ctrl.onCancel()">
            </initiatives-form>
          </section>
        </main>
      </div>
    </div>`,

  controller($ngRedux, $location, $filter) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onCancel,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.initInitiative({
        mobilisationStart: $filter('date')(new Date(), 'yyyy-MM-dd'),
      });

      //$ctrl.props.mobilisationStart = $filter('date')(new Date(), 'yyyy-MM-dd');
    }

    function $onDestroy() {
      $ctrl.cancelInitiative();
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.success) {
        const { id } = decorateInitiative($ctrl.props);
        $location.path(`/initiatives/${id}`);
      }
    }

    function mapState(state) {
      const initiative = state.initiative.data;
      injectKpis(initiative, state.kpiTypes.data);
      return {
        props: initiative,
        success: state.initiative.success,
      };
    }

    function mapDispatch() {
      return {
        initInitiative,
        createInitiative,
        cancelInitiative,
      };
    }

    function onCancel() {
      $location.path(`/initiatives`);
    }
  },
};
