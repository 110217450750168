import wallboard from './wallboard';
import wallboardHeader from './header';
import wallboardActions from './actions';
import wallboardCard from './card';
import wallboardCardAwards from './card-awards';
import wallboardCardCheckpoints from './card-checkpoints';
import wallboardCardPerformance from './card-performance';
import wallboardShow from './show';
import wallboardCardPitch from './card-pitch';
import wallboardCardBadge from './card-badge';
import wallboardCardTeamMember from './card-team-member';
import wallboardCardProblem from './card-problem';
import './wallboard.scss';

export default angular
  .module('App.wallboard', [])
  .component('wallboard', wallboard)
  .component('wallboardHeader', wallboardHeader)
  .component('wallboardActions', wallboardActions)
  .component('wallboardCard', wallboardCard)
  .component('wallboardCardAwards', wallboardCardAwards)
  .component('wallboardCardCheckpoints', wallboardCardCheckpoints)
  .component('wallboardCardPerformance', wallboardCardPerformance)
  .component('wallboardShow', wallboardShow)
  .component('wallboardCardPitch', wallboardCardPitch)
  .component('wallboardCardBadge', wallboardCardBadge)
  .component('wallboardCardTeamMember', wallboardCardTeamMember)
  .component('wallboardCardProblem', wallboardCardProblem).name;
