export default {
  bindings: {
    value: '<',
  },

  template: `
    <div class="progress">
      <div
        class="progress-bar"
        style="width:{{ $ctrl.value }}%"
        role="progressbar"
        aria-valuenow="{{ $ctrl.value }}"
        aria-valuemin="0"
        aria-valuemax="100">
      </div>
    </div>`,
};
