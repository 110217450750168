/**
 * Created by ilya on 8/4/17.
 */
import { each, compact, pickBy, identity } from 'lodash';
import { fetchInitiatives, cancelInitiatives } from 'logic/initiatives';
import * as Utils from 'utils';

import html from './item.html';

export default {
  bindings: {
    view: '@',
    activity: '<',
  },

  template: html,

  controller($ngRedux) {
    'ngInject';
    const $ctrl = {
      $onInit,
      mapState,
      mapDispatch,
      getTimeOffset,
      getDate,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
    }

    function mapState(state) {
      return {
        mobs: state.awards.mobilisations,
        badges: state.awards.badges,
        checkpoints: state.checkpoints.data,
      };
    }

    function mapDispatch() {
      return {};
    }

    function getTimeOffset(activity) {
      const date = new Date();

      let min = Math.floor((date.getTime() - activity.created) / 60000);
      return Utils.getDurationStr(min);
    }

    function getDate(isoDate) {
      const date = new Date(isoDate);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
  },
};
