/**
 * Created by sasa on 28/9/17.
 */

import store from 'store';
import * as _ from 'lodash';
import * as Utils from '../../utils';

import { fetchQuestions, cancelQuestions } from 'logic/constants/questions';
import {
  fetchSurveyResult,
  cancelSurveyResult,
} from 'logic/morale-survey-result';

export default {
  template: `
    <div class="content-wrapper">
      <div class="container" ng-if="$ctrl.fetching">
          <div class="main text-center">
              <h4>
                  <i class='fa fa-spinner fa-spin'></i>
                  Loading Survey Result...
              </h4>
          </div>
      </div>
      <div class="container" ng-if="!$ctrl.fetching && $ctrl.error">
          <div class="main text-center">
              <h4>Failed to load survey result!</h4>
          </div>
      </div>
      <div class="container initiatives-checkin" ng-if="$ctrl.success">
      <main class="main">
          <header>
              <h1 class="h2 my-0">
                  Team Morale Result
              </h1>
          </header>
          <hr class="my-4">
          <h4 class="mr-2">The combined deviations for the 8 survey questions together</h4>
          <div class="card card-block mb-2">
            <small class="mb-2"><span class="font-weight-bold" >Average:</span> <span class="font-weight-bold text-danger">{{$ctrl.avgForAllQuestions}}</span></small>
            <small class="mb-2"><span class="font-weight-bold" >Median:</span> <span class="font-weight-bold text-danger">{{$ctrl.madForAllQuestions}}</span></small>
            <small class="mb-2"><span class="font-weight-bold" >Standard deviation:</span> <span class="font-weight-bold text-danger">{{$ctrl.stdDeviationForAllQuestions}}</span></small>
          </div>

          <h4 class="mr-2">Individually average for each of the 8 survey questions</h4>
          <div class="card card-block mb-2">
            <small class="mb-2" ng-repeat="(index, item) in $ctrl.questions"><span class="font-weight-bold" >{{ item.question }}:</span> <span class="font-weight-bold text-danger">{{$ctrl.avgForEachQuestion[index]}}</span></small>
          </div>

          <h4 class="mr-2">Individually median for each of the 8 survey questions</h4>
          <div class="card card-block mb-2">
            <small class="mb-2" ng-repeat="(index, item) in $ctrl.questions"><span class="font-weight-bold" >{{ item.question }}:</span> <span class="font-weight-bold text-danger">{{$ctrl.madForEachQuestion[index]}}</span></small>
          </div>

          <h4 class="mr-2">Individually standard deviation for each of the 8 survey questions</h4>
          <div class="card card-block mb-2">
            <small class="mb-2" ng-repeat="(index, item) in $ctrl.questions"><span class="font-weight-bold" >{{ item.question }}:</span> <span class="font-weight-bold text-danger">{{$ctrl.stdDeviationForEachQuestion[index]}}</span></small>
          </div>

          <h4 class="mr-2">The combined deviations for each users</h4>
          <div class="card card-block mb-2">
            <div class="groupShow__avatar mb-2" ng-repeat="(userId, userData ) in $ctrl.dataForUsers">
                <img
                    class="survey-avatar mb-2"
                    ng-attr-id="{{ 'user-image-' + userId }}"
                    ng-if="userData.photoURL"
                    ng-src="{{userData.photoURL}}"
                    data-toggle="tooltip"
                    data-animation="false"
                    data-placement="bottom"
                    title="{{userData.name && userData.name.length > 0 ? userData.name : userData.email}}"/>
                <span
                    class="survey-avatar mb-2"
                    ng-attr-id="{{ 'user-image-' + userId }}"
                    ng-if="!userData.photoURL"
                    data-toggle="tooltip"
                    data-animation="false"
                    data-placement="bottom"
                    title="{{userData.name && userData.name.length > 0 ? userData.name : 'Anonymous'}}">
                    <i class='fa fa-user'></i>
                </span>
                <span>{{userData.name && userData.name.length > 0 ? userData.name : 'Anonymous'}}</span>
                <div class="card card-block">
                  <small class="mb-2"><span class="font-weight-bold" >Average:</span> <span class="font-weight-bold text-danger">{{userData.average}}</span></small>
                  <small class="mb-2"><span class="font-weight-bold" >Median:</span> <span class="font-weight-bold text-danger">{{userData.median}}</span></small>
                  <small class="mb-2"><span class="font-weight-bold" >Standard deviation:</span> <span class="font-weight-bold text-danger">{{userData.std_deviation}}</span></small>
                </div>
            </div>
          </div>
      </main>
      </div>
  </div>`,

  controller($ngRedux, $location, $routeParams, $scope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.fetchQuestions();
      $ctrl.fetchSurveyResult();
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
      $ctrl.cancelQuestions();
      $ctrl.cancelSurveyResult();
    }

    function mapState(state) {
      const props = {
        ...state.surveyResult.data,
        questions: state.questions.data,
        fetching: state.questions.loading || state.surveyResult.loading,
        success: state.questions.success && state.surveyResult.success,
        error: state.questions.error || state.surveyResult.error,
      };

      return props;
    }

    function mapDispatch() {
      return {
        fetchQuestions,
        cancelQuestions,
        fetchSurveyResult,
        cancelSurveyResult,
      };
    }
  },
};
