// Basic wrapper to implement the default bootstrap toggle elements.
// Currently only applies to tooltip
export default function($timeout) {
  'ngInject';
  function isTouchDevice() {
    return 'ontouchstart' in window;
  }

  return {
    restrict: 'A',

    link($scope, $element, $attrs) {
      if ($attrs.touch === 'false' && isTouchDevice()) {
        return;
      }

      switch ($attrs.toggle) {
        case 'tooltip':
          $timeout(() => $element.tooltip());
          $scope.$on('$destroy', () => $element.tooltip('dispose'));
          break;
      }
    },
  };
}
