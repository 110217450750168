export default {
  bindings: {
    graphData: '<',
    isIndividual: '<',
  },

  template: `
    <div class="wallboard-card-checkpoints wallboard-card-fill row">
      <div
        class="wallboard-card-fill col-6"
        ng-class="{true:'wallboard-show-card-donut', false: 'wallboard-card-donut'}[$ctrl.isIndividual]"
        ng-repeat="type in ['team', 'service']">
        <graph-donut-checkpoints
          graph-data="$ctrl.graphData"
          label-color="white"
          type="type"
          class="wallboard-card-fill"
          is-individual="$ctrl.isIndividual">
        </graph-donut-checkpoints>
      </div>
    </div>`,
};
