import colors from 'constants/colors';

export default {
  bindings: {
    graphData: '<',
    labelColor: '@',
    type: '<',
    isIndividual: '<',
  },

  template: `
    <graph-donut
      data="$ctrl.data"
      label="$ctrl.label"
      width="$ctrl.width"
      class="graph-donut-checkpoints">
    </graph-donut>`,

  controller($window) {
    'ngInject';
    const $ctrl = {
      $onInit,
    };

    return $ctrl;

    function $onInit() {
      const isDisplay = $window.innerWidth > 1920;

      if ($ctrl.graphData) {
        $ctrl.data = [$ctrl.graphData[$ctrl.type]];
      }
      $ctrl.width = isDisplay ? 30 : 10;

      if ($ctrl.isIndividual) {
        $ctrl.width = 30;
      }

      $ctrl.label = {
        name: $ctrl.type,
        fill: colors[$ctrl.labelColor] || colors.black,
        dy: '.35em',
        textAnchor: 'middle',
      };
    }
  },
};
