import * as firebase from 'firebase';
import { fetchUser, updateProfile } from 'logic/user';
import { fetchGroups } from 'logic/groups';
import { fetchInitiatives, cancelInitiatives } from 'logic/initiatives';
import * as Utils from 'utils';
import store from 'store';
import html from './profile.html';

export default {
  template: html,

  controller($ngRedux, $location, $scope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
      onCancel,
      getTimeOffset: Utils.getTimeOffset,
      onUpdateProfile,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.fetchUser(store.get('user'));
      $ctrl.fetchGroups();
      $ctrl.fetchInitiatives({ star: true });
    }

    function $onDestroy() {
      $ctrl.cancelInitiatives();
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      let user = firebase.auth().currentUser;
      let facebookLinked = false;
      let googleLinked = false;
      let githubLinked = false;
      if (user && user.providerData) {
        for (let i = 0; i < user.providerData.length; i++) {
          if (user.providerData[i].providerId === 'github.com') {
            githubLinked = true;
          } else if (user.providerData[i].providerId === 'google.com') {
            googleLinked = true;
          } else if (user.providerData[i].providerId === 'facebook.com') {
            facebookLinked = true;
          }
        }
      }

      return {
        props: state.user.data,
        groups: state.groups,
        loading: state.user.loading,
        success: state.user.updateProfileSuccess,
        initiatives: state.initiatives,
        githubLinked,
        facebookLinked,
        googleLinked,
      };
    }

    function mapDispatch() {
      return {
        fetchUser,
        updateProfile,
        fetchGroups,
        fetchInitiatives,
        cancelInitiatives,
      };
    }

    function onCancel() {
      $location.path('/initiatives');
    }

    function onUpdateProfile() {
      $ctrl.updateProfile($scope.userPhoto);
    }
  },
};
