import {
  curveCardinal,
  extent,
  line,
  scaleLinear,
  scaleTime,
  select,
  timeParse,
} from 'd3';

import { objectToArray, checkSingleDataPoint } from '../../utils';

export default {
  transclude: true,

  bindings: {
    data: '<',
    margin: '<',
    onPopulate: '&',
    show: '<',
  },

  template: `
    <ng-transclude></ng-transclude>
    <div id="{{ $ctrl.id }}" class="graph">
      <svg ng-if="$ctrl.data">
        <g transform="translate(0,5)"></g>
        <path 
          class="line" fill="none" stroke-linejoin="round" stroke-linecap="round" stroke-width="2"
          ng-attr-d="{{$ctrl.getGraphPath()}}"
        >
        </path>
      </svg>
      <div ng-if="!$ctrl.data ">
        <small class="d-block text-muted mb-2">No graph data</small>
        <button class="btn btn-primary btn-sm" ng-click="$ctrl.onPopulate()" ng-if="!$ctrl.show">Begin populating</button>
      </div>      
    </div>
    `,

  controller($timeout, $element, $scope) {
    `ngInject`;

    const MARGIN_DEFAULT = {
      top: 5,
      right: 0,
      bottom: 5,
      left: 0,
    };

    const $ctrl = {
      $onInit,
      checkSingleDataPoint,
      getGraphPath,
      getGraphDimensions,
    };

    return $ctrl;

    function $onInit() {}

    function getGraphPath() {
      const data = Array.isArray($ctrl.data)
        ? $ctrl.data
        : objectToArray($ctrl.data, 'date', 'value');
      if (data.length === 0) {
        return null;
      }
      const margin = Object.assign({}, MARGIN_DEFAULT, $ctrl.margin);
      const dim = $ctrl.getGraphDimensions($element);

      const graphWidth = +dim.width - margin.left - margin.right;
      const graphHeight = +dim.height - margin.top - margin.bottom;

      const parseTime = timeParse('%d-%b-%y');
      let parsedData = data.map(d => {
        const ndate = parseInt(d.date);
        if (ndate.toString() === d.date) {
          return { date: new Date(ndate), value: +d.value };
        } else {
          return { date: new Date(d.date), value: +d.value };
        }
      });

      if ($ctrl.checkSingleDataPoint(data)) {
        parsedData = [
          {
            date: new Date(parsedData[0].date.getTime() - 1),
            value: parsedData[0].value,
          },
          ...parsedData,
        ];
      }

      parsedData = parsedData.sort((a, b) => {
        return a.date.getTime() - b.date.getTime();
      });

      const xAxis = scaleTime().range([0, graphWidth]);
      const yAxis = scaleLinear().range([graphHeight, 0]);

      xAxis.domain(extent(parsedData, d => d.date));
      yAxis.domain(extent(parsedData, d => d.value));
      const d3Line = line()
        .curve(curveCardinal)
        .x(d => xAxis(d.date))
        .y(d => yAxis(d.value));

      return d3Line(parsedData);
    }

    function getGraphDimensions($el) {
      const width = $el.width();
      const height = $el.height();
      if ($ctrl.checkSingleDataPoint($ctrl.data)) {
        return { width, height: height / 2 };
      } else {
        return { width, height };
      }
    }
  },
};
