import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';

// Action Types
export const FETCH_AWARDS = 'app/awards/FETCH';
export const SUCCESS_AWARDS = 'app/awards/SUCCESS';
export const ERROR_AWARDS = 'app/awards/ERROR';
export const CANCEL_AWARDS = 'app/awards/CANCEL';

// Initial State
export const initialState = {
  badges: {},
  mobilisations: {},
  loading: false,
};

// Actions
export function fetchAwards() {
  return { type: FETCH_AWARDS };
}

export function cancelAwards() {
  return { type: CANCEL_AWARDS };
}

// Observable Logic
export const awardsFetch = createLogic({
  latest: true,
  type: FETCH_AWARDS,
  cancelType: CANCEL_AWARDS,
  processOptions: {
    successType: SUCCESS_AWARDS,
    failType: ERROR_AWARDS,
  },
  process(/*{ $http }*/) {
    return firebase
      .database()
      .ref('constants/awards')
      .once('value')
      .then(snapshot => {
        const awards = snapshot.val();
        let badges = {};
        let mobilisations = {};

        if (awards.badges) {
          for (let i = 0; i < awards.badges.length; i++) {
            if (awards.badges[i]) {
              badges[awards.badges[i].id] = awards.badges[i];
            }
          }
        }

        if (awards.mobilisations) {
          for (let i = 0; i < awards.mobilisations.length; i++) {
            if (awards.mobilisations[i]) {
              mobilisations[awards.mobilisations[i].id] =
                awards.mobilisations[i];
            }
          }
        }
        return {
          badges,
          mobilisations,
        };
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_AWARDS:
      return {
        ...state,
        loading: true,
      };

    case SUCCESS_AWARDS:
      return {
        ...state,
        badges: { ...payload.badges },
        mobilisations: { ...payload.mobilisations },
        loading: false,
      };

    case ERROR_AWARDS:
      return {
        ...state,
        ...payload,
        loading: false,
      };

    default:
      return state;
  }
}
