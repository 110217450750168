export default {
  bindings: {
    props: '<',
  },

  template: `
    <div
      class="form-group"
      ng-repeat="link in $ctrl.props track by $index">
      <div class="input-group">
        <input
          class="form-control"
          type="text"
          placeholder="Link"
          ng-model="link.href">
        <input
          class="form-control"
          type="text"
          placeholder="Title"
          ng-model="link.title">
        <span class="input-group-btn">
          <button class="btn btn-danger" type="button" ng-click="$ctrl.onRemove($index)">
            <i class="fa fa-times-circle"></i>
          </button>
        </span>
      </div>
    </div>
    <div class="input-group">
      <input
        id="links"
        class="form-control"
        type="text"
        placeholder="Link"
        ng-model="$ctrl.link.href">
      <input
        id="links"
        class="form-control"
        type="text"
        placeholder="Title"
        ng-model="$ctrl.link.title">
      <span class="input-group-btn">
        <button class="btn btn-primary" type="button" ng-click="$ctrl.onAdd()">
          <i class="fa fa-plus-circle"></i>
        </button>
      </span>
    </div>
    <small ng-show="$ctrl.error" class="text-danger form-text">
      Please provide a link
    </small>`,

  controller() {
    const $ctrl = {
      onAdd,
      onRemove,
      sanitizeHref,
    };

    return $ctrl;

    function onAdd() {
      const link = { ...$ctrl.link };
      link.href = $ctrl.sanitizeHref(link.href);

      $ctrl.error = link.href === '';

      if ($ctrl.error) {
        return;
      }

      $ctrl.props = $ctrl.props || [];
      $ctrl.props.push(link);

      $ctrl.link = { href: '', title: '' };
    }

    function onRemove($index) {
      $ctrl.props.splice($index, 1);
    }

    function sanitizeHref(href) {
      href = href.trim();

      if (href !== '' && !href.match(/^[a-zA-Z]+:\/\//)) {
        href = `http://${href}`;
      }

      return href;
    }
  },
};
