import compileProvider from './compile-provider';
import httpEndpointInterceptor from './http-endpoint-interceptor';
import httpPaginationInterceptor from './http-pagination-interceptor';
import httpProvider from './http-provider';
import locationProvider from './location-provider';
import ngReduxLogicProvider from './ng-redux-logic-provider';
import routeProvider from './route-provider';

export default angular
  .module('App.config', [])
  .factory('httpEndpointInterceptor', httpEndpointInterceptor)
  .factory('httpPaginationInterceptor', httpPaginationInterceptor)
  .config(httpProvider)
  .config(compileProvider)
  .config(locationProvider)
  .config(routeProvider)
  .config(ngReduxLogicProvider)
  .run(ngReduxLogic => 'ngInject').name; // Run ngReduxLogic on load
