import { get } from 'lodash';

export default {
  bindings: {
    state: '<',
  },

  template: `<span class="{{ $ctrl.className }}"></span>`,

  controller() {
    const $ctrl = {
      $onChanges,
      getBadge,
    };

    return $ctrl;

    function $onChanges(changes) {
      const index = get(changes, 'state.currentValue', 0);
      const badge = getBadge(index);

      $ctrl.className = getClassName(badge, $ctrl.classNames);
    }

    function getBadge(index) {
      const badges = ['bg-faded', 'bg-danger', 'bg-warning', 'bg-success'];

      return badges[index];
    }

    function getClassName(badge, classNames) {
      const classes = ['rounded-circle', 'circle--sm', 'd-inline-block'];

      classes.push(badge);

      return classes.join(' ');
    }
  },
};
