import { createLogicMiddleware } from 'redux-logic';
import { rootReducer, rootLogic } from 'logic';

// To expose the $http dependency for redux-logic we need to create a custom provider.
export default function ngReduxLogicProvider($provide, $ngReduxProvider) {
  'ngInject';
  $provide.provider('ngReduxLogic', () => {
    return {
      $get($http) {
        'ngInject';
        const logic = createLogicMiddleware(rootLogic, {
          $http,
        });
        const middleware = [logic];
        const enhancers = [];

        // Add logger and devtools in development
        if (process.env.NODE_ENV === 'development') {
          const { logger } = require('redux-logger');
          middleware.push(logger);

          const {
            devToolsEnhancer,
          } = require('redux-devtools-extension/developmentOnly');
          enhancers.push(devToolsEnhancer());
        }
        $ngReduxProvider.createStoreWith(rootReducer, middleware, enhancers);
      },
    };
  });
}
