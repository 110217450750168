/**
 * Created by ilya on 8/4/17.
 */
import * as firebase from 'firebase';
import { createLogic } from 'redux-logic';
import axios from 'axios';
import store from 'store';

import { FETCH_ACTIVITIES_COUNT } from '../config/consts';
import {
  filterActivities,
  isFilteredActivity,
  compareActivities,
} from '../utils';
import getFirebaseConfig from '../config/firebase';

// Action Types
export const WATCH_ADDED = 'app/activities/WATCH_ADDED';
export const WATCH = 'app/activities/WATCH';

export const ERROR = 'app/activities/ERROR';
export const CANCEL = 'app/activities/CANCEL';
export const SET_RECENT_ACTIVITIES = 'app/activities/SET_RECENT_ACTIVITIES';
export const ADD_ACTIVITY = 'app/activities/ADD_ACTIVITY';
export const FETCH_MORE_ACTIVITIES = 'app/activities/FETCH_MORE_ACTIVITIES';
export const FETCH_MORE_ACTIVITIES_SUCCESS =
  'app/activities/FETCH_MORE_ACTIVITIES_SUCCESS';
export const UPDATE_TIME = 'app/activities/UPDATE_TIME';
export const SET_FILTER = 'app/activities/SET_FILTER';
export const INIT_RECENT_ACTIVITIES_COUNT =
  'app/activities/INIT_RECENT_ACTIVITIES_COUNT';

const firebaseProjectId = getFirebaseConfig().projectId;

export const initialState = {
  data: [],
  filteredData: [],
  loading: false,
  error: false,
  recent: null,
  time: new Date().getTime(),
  filter: null,
  recentActivities: 0,
  more: true,
};

export const watchActivities = () => {
  return {
    type: WATCH,
  };
};

export const watchAdded = (recentActivityId = '') => {
  return {
    type: WATCH_ADDED,
    payload: { recentActivityId },
  };
};

const setRecentActivities = (activities, more) => {
  return {
    type: SET_RECENT_ACTIVITIES,
    payload: {
      activities,
      more,
    },
  };
};

const addActivity = activity => {
  return {
    type: ADD_ACTIVITY,
    payload: {
      activity,
    },
  };
};

export const fetchMoreActivities = () => {
  return {
    type: FETCH_MORE_ACTIVITIES,
  };
};

export const setFilter = filter => {
  return {
    type: SET_FILTER,
    payload: filter,
  };
};

export const initRecentActivitiesCount = () => {
  return {
    type: INIT_RECENT_ACTIVITIES_COUNT,
  };
};

const fetchRecentActivities = (userId, callback) => {
  firebase
    .database()
    .ref('user_activities')
    .child(userId)
    // TODO: filter by id
    .orderByKey()
    .limitToLast(FETCH_ACTIVITIES_COUNT + 1)
    .once(
      'value',
      snapshot => {
        const activities = [];
        snapshot.forEach(childSnapshot => {
          const activity = childSnapshot.val();
          if (typeof activity === 'object') {
            activity.id = childSnapshot.key;
            activities.unshift(activity);
          }
        });
        let validActivities;
        let more = false;
        if (activities.length > FETCH_ACTIVITIES_COUNT) {
          validActivities = activities.slice(0, FETCH_ACTIVITIES_COUNT);
          more = true;
        } else {
          validActivities = activities;
        }

        callback(null, validActivities, more);
      },
      err => {
        callback(err);
      }
    );
};

// General Activities
const fetchMoreActivities_ = (userid, recentId, callback) => {
  firebase
    .database()
    .ref('user_activities')
    .child(userid)
    // TODO: filter by id
    .orderByKey()
    .endAt(recentId)
    .limitToLast(FETCH_ACTIVITIES_COUNT + 2) // 1 for recentId 1 for more
    .once(
      'value',
      snapshot => {
        const activities = [];
        snapshot.forEach(childSnapshot => {
          const activity = childSnapshot.val();
          if (typeof activity === 'object' && childSnapshot.key != recentId) {
            activity.id = childSnapshot.key;
            activities.unshift(activity);
          }
        });
        let validActivities;
        let more = false;
        if (activities.length > FETCH_ACTIVITIES_COUNT) {
          validActivities = activities.slice(0, FETCH_ACTIVITIES_COUNT);
          more = true;
        } else {
          validActivities = activities;
        }

        callback(null, validActivities, more);
      },
      err => {
        callback(err);
      }
    );
};

const fetchMoreFilteredActivites_ = (filter, callback) => {
  axios({
    method: 'POST',
    url: `https://us-central1-${firebaseProjectId}.cloudfunctions.net/queryActivities`,
    headers: {
      Authorization: store.get('Authorization'),
    },
    data: filter,
    json: true,
  })
    .then(response => {
      if (response.data && response.data.success) {
        callback(null, response.data.activities);
      } else {
        callback('Failed to query activities', null);
      }
    })
    .catch(e => {
      Raven.captureExceptionEx(e);
      callback(e, null);
    });
};

const watchAddedActivity = (userid, recentActivityId = '', callback) => {
  firebase
    .database()
    .ref('user_activities')
    .child(userid)
    .orderByKey()
    .startAt(recentActivityId)
    .on('child_added', snapshot => {
      const activity = snapshot.val();
      activity.id = snapshot.key;
      callback(null, activity);
    });
};

export const watchActivitiesLogic = createLogic({
  type: WATCH_ADDED,
  cancelType: CANCEL,
  warnTimeout: 0,
  process({ getState, action }, dispatch, done) {
    const { id } = getState().user.data;
    const { recentActivityId } = action.payload;
    watchAddedActivity(id, recentActivityId, (err, newActivity) => {
      if (err) {
        console.info('Watch Added Activity Error:', err);
      } else {
        dispatch(addActivity(newActivity));
      }
    });
  },
});

export const activitiesLogic = createLogic({
  type: WATCH,
  warnTimeout: 0,
  process({ getState }, dispatch, done) {
    const { id } = getState().user.data;
    fetchRecentActivities(id, (err, activities, more) => {
      if (err) {
        done();

        // start watch logic again
        dispatch(watchActivities());
      } else {
        let recentActivityId;
        if (activities.length > 0) {
          recentActivityId = activities[0].id;
        }

        setInterval(() => {
          console.info('Fetch New Activities');
          dispatch({
            type: UPDATE_TIME,
          });
        }, 1000 * 60); // 1min
        dispatch(setRecentActivities(activities, more));
        dispatch(watchAdded(recentActivityId));
        done();
      }
    });
  },
});

export const fetchMoreActivitiesLogic = createLogic({
  type: [FETCH_MORE_ACTIVITIES, SET_FILTER], // SET_FILTER wants to fetch more activities
  warnTimeout: 0,
  process({ getState }, dispatch, done) {
    const state = getState().activities;
    const { id } = getState().user.data;

    if (state.filter) {
      // fetch more filtered activities
      // get the last id
      let filter = { ...state.filter };
      const currentActivities = state.filteredData;
      let recentId = String.fromCharCode(255); // Firebase id prefix - should not be an empty string
      if (Array.isArray(currentActivities) && currentActivities.length > 0) {
        recentId = currentActivities[currentActivities.length - 1].id;
      }
      filter.size = FETCH_ACTIVITIES_COUNT + 1;
      filter.recentId = recentId;
      filter.sort = [
        {
          id: 'asc',
        },
      ];
      fetchMoreFilteredActivites_(filter, (err, activities, more) => {
        if (err) {
          // TODO: should warn about the error
          console.warn('Failed to get more filtered activities:', err);
        } else {
          let validActivities;
          let more = false;
          if (activities.length > FETCH_ACTIVITIES_COUNT) {
            validActivities = activities.slice(0, FETCH_ACTIVITIES_COUNT);
            more = true;
          } else {
            validActivities = activities;
          }

          dispatch({
            type: FETCH_MORE_ACTIVITIES_SUCCESS,
            payload: {
              activities: validActivities,
              filter: state.filter,
              more,
            },
          });
        }
      });
    } else {
      // No filters
      const currentActivities = state.data;

      if (Array.isArray(currentActivities) && currentActivities.length > 0) {
        fetchMoreActivities_(
          id,
          currentActivities[currentActivities.length - 1].id,
          (err, activities, more) => {
            dispatch({
              type: FETCH_MORE_ACTIVITIES_SUCCESS,
              payload: {
                activities,
                more,
              },
            });
            done();
          }
        );
      } else {
        fetchRecentActivities(id, (err, activities) => {
          dispatch(setRecentActivities(activities));
          done();
        });
      }
    }
  },
});

export const initRecentActivitiesLogic = createLogic({
  type: INIT_RECENT_ACTIVITIES_COUNT,
  cancelType: CANCEL,
  process({ action, getState }, dispatch, done) {
    const { id } = getState().user.data;

    firebase
      .database()
      .ref('users/' + id + '/recentActivities')
      .set(0)
      .then(() => {
        done();
      })
      .catch(e => {
        Raven.captureExceptionEx(e);
        console.warn('Failed to reset recentActivities of user:' + id);
        done();
      });
  },
});

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  let newState;

  switch (type) {
    case UPDATE_TIME: {
      return {
        ...state,
        time: new Date().getTime(),
      };
    }

    case FETCH_MORE_ACTIVITIES:
    case WATCH: {
      return { ...state, loading: true };
    }

    case SET_RECENT_ACTIVITIES:
      newState = {
        ...state,
        data: payload.activities,
        loading: false,
      };
      if (!state.filter) {
        newState.more = payload.more;
      }
      return newState;

    case FETCH_MORE_ACTIVITIES_SUCCESS: {
      if (payload.filter) {
        // filtered activities
        if (JSON.stringify(payload.filter) == JSON.stringify(state.filter)) {
          // Same Filter
          return {
            ...state,
            filteredData: [...state.filteredData, ...payload.activities],
            loading: false,
            more: payload.more,
          };
        } else {
          cosole.info('Different Filter Activities arrived:');
          console.info('Current Filter:', state.filter);
          console.info('Fetched Filter:', payload.filter);
          return state;
        }
      } else {
        newState = {
          ...state,
          data: [...state.data, ...payload.activities],
          loading: false,
          more: payload.more,
        };
        if (!state.filter) {
          newState.more = payload.more;
        }
        return newState;
      }
    }

    case INIT_RECENT_ACTIVITIES_COUNT:
      return { ...state, recentActivities: 0 };

    case ADD_ACTIVITY:
      // TODO: should apply filter and add if it passes the filter
      newState = { ...state, loading: false };
      let bExist = false;
      let bFilteredExist = false;
      let i;
      if (Array.isArray(newState.data)) {
        for (i = 0; i < newState.data.length; i++) {
          if (newState.data[i].id === payload.activity.id) {
            newState.data[i] = payload.activity;
            bExist = true;
            break;
          }
        }
        if (!bExist) {
          let count = state.recentActivities;
          if (isNaN(count)) {
            count = 0;
          }
          if (window.location.pathname !== '/activity') {
            count = count + 1;
            newState.recentActivities = count;
          } else {
            newState.recentActivities = 0;
          }
        }

        if (isFilteredActivity(payload.activity, state.filter)) {
          for (i = 0; i < newState.filteredData.length; i++) {
            if (newState.filteredData[i].id === payload.activity.id) {
              newState.filteredData[i] = payload.activity;
              bFilteredExist = true;
              break;
            }
          }
          if (!bFilteredExist) {
            newState.filteredData = [payload.activity, ...state.filteredData];
            newState.filteredData.sort(compareActivities);
          }
        }

        if (!bExist) {
          newState.data = [payload.activity, ...state.data];
          newState.data.sort(compareActivities);
        }
      } else {
        newState.data = [payload.activity];
        return newState;
      }
      return newState;

    case SET_FILTER:
      newState = {
        ...state,
        filter: payload,
        filteredData: filterActivities(state.data, payload),
        loading: true,
        more: true,
      };
      return newState;

    default:
      return state;
  }
}
