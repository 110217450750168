import * as d3 from 'd3';
import uuidv1 from 'uuid/v1';
import { hashStringToInt } from 'utils';

const colors = [
  '#255255',
  '#f1b149',
  '#6a5b72',
  '#8a699c',
  '#320949',
  '#3f0f69',
  '#99ccff',
  '#434857',
  '#70afab',
  '#205e5b',
  '#9966ff',
  '#66023c',
  '#00cccc',
  '#ff6666',
  '#ff3366',
  '#ff99cc',
  '#ffccff',
  '#66ccff',
  '#33ffff',
  '#00ffff',
  '#00ccff',
  '#9966cc',
  '#33ffcc',
  '#33cccc',
  '#33ccff',
  '#ccff99',
  '#99ff99',
  '#ffff99',
  '#ff6699',
  '#ea5959',
  '#456672',
  '#e3b587',
  '#f1b149',
  '#ea5959',
  '#f98b60',
  '#ffc057',
  '#b30753',
  '#00387f',
  '#0070ff',
  '#f7d0cb',
  '#313131',
];

export default {
  transclude: true,

  bindings: {
    data: '<',
    radius: '<',
    title: '@',
    colorField: '@',
    radiusField: '@',
    fixedColorField: '@',
    onClickBubble: '&',
  },

  template: `
    <ng-transclude></ng-transclude>
    <div id="{{$ctrl.divId}}" ng-style="$ctrl.divStyle">
      <h4 style="text-align: center;">{{$ctrl.title}}</h4>
      <svg id="{{$ctrl.svgId}}">
      </svg>
    </div>
    `,

  controller($timeout, $element, $scope) {
    `ngInject`;

    const MARGIN_DEFAULT = {
      top: 5,
      right: 0,
      bottom: 5,
      left: 0,
    };

    const $ctrl = {
      $onInit,
      $postLink,
      bubbleChart,
    };

    return $ctrl;

    function $onInit() {
      $ctrl.divStyle = {
        width: `${$ctrl.radius * 2}px`,
        height: `${$ctrl.radius * 2 + 30}px`,
        margin: '8px',
      };
      const id = uuidv1();
      $ctrl.divId = 'bubbleChart-' + id;
      $ctrl.svgId = 'bubbleChartSvg-' + id;
    }

    function $postLink() {
      $timeout(() => {
        const chart = bubbleChart()
          .width($ctrl.radius * 2)
          .height($ctrl.radius * 2);
        d3.select('#' + $ctrl.divId)
          .data([{}, ...($ctrl.data || [])])
          .call(chart);
      });
    }

    function bubbleChart() {
      let width = 960,
        height = 960,
        maxRadius = 6,
        columnForColors = $ctrl.colorField,
        columnForFixedColor = $ctrl.fixedColorField,
        columnForRadius = $ctrl.radiusField;

      function chart(selection) {
        let data = selection.enter().data();
        let div = selection,
          svg = div.select('#' + $ctrl.svgId);
        svg.attr('width', width).attr('height', height);

        let tooltip = selection
          .append('div')
          .style('position', 'relative')
          .style('visibility', 'hidden')
          .style('color', 'white')
          .style('padding', '8px')
          .style('background-color', '#626D71')
          .style('border-radius', '6px')
          .style('text-align', 'center')
          .style('font-family', 'monospace')
          .style('width', '400px')
          .text('');

        let simulation = d3
          .forceSimulation(data)
          .force('charge', d3.forceManyBody().strength([-50]))
          .force('x', d3.forceX())
          .force('y', d3.forceY())
          .on('tick', ticked);

        function ticked(e) {
          node
            .attr('cx', function(d) {
              return d.x;
            })
            .attr('cy', function(d) {
              return d.y;
            });
        }

        // let colorCircles = d3.scaleOrdinal(d3.schemeCategory10);
        let scaleRadius = d3
          .scaleLinear()
          .domain([
            d3.min(data, function(d) {
              return +d[columnForRadius];
            }),
            d3.max(data, function(d) {
              return +d[columnForRadius];
            }),
          ])
          .range([10, 20]);
        svg
          .append('circle')
          .attr('r', $ctrl.radius)
          .attr('cx', $ctrl.radius)
          .attr('cy', $ctrl.radius)
          .attr('fill', 'rgba(200,200,200,0.5)');

        let node = svg
          .selectAll('.bubble')
          .data(data)
          .enter()
          .append('circle')
          .attr('class', 'bubble')
          .attr('r', function(d) {
            return scaleRadius(d[columnForRadius]);
          })
          .style('fill', function(d) {
            const length = colors.length;
            if (columnForFixedColor) {
              return d[columnForFixedColor];
            } else {
              return colors[
                hashStringToInt(d[columnForColors]) % colors.length
              ];
            }
          })
          .attr('transform', 'translate(' + [width / 2, height / 2] + ')')
          .on('mouseover', function(d) {
            tooltip.html(
              d.name +
                '<br>' +
                d[columnForColors] +
                '% completed' +
                '<br>' +
                'Budget:' +
                '$' +
                d[columnForRadius] +
                '<br>'
            );
            return tooltip.style('visibility', 'visible');
          })
          .on('mousemove', function() {
            const rect = selection._groups[0][0].getBoundingClientRect();
            return tooltip
              .style(
                'top',
                -($ctrl.radius * 2 - (d3.event.pageY - rect.y)) + 'px'
              )
              .style('left', d3.event.pageX - rect.x + 10 + 'px');
          })
          .on('mouseout', function() {
            return tooltip.style('visibility', 'hidden');
          })
          .on('click', data => {
            $ctrl.onClickBubble({
              id: data.id,
            });
          });
      }

      chart.width = function(value) {
        if (!arguments.length) {
          return width;
        }
        width = value;
        return chart;
      };

      chart.height = function(value) {
        if (!arguments.length) {
          return height;
        }
        height = value;
        return chart;
      };

      chart.columnForColors = function(value) {
        if (!arguments.columnForColors) {
          return columnForColors;
        }
        columnForColors = value;
        return chart;
      };

      chart.columnForRadius = function(value) {
        if (!arguments.columnForRadius) {
          return columnForRadius;
        }
        columnForRadius = value;
        return chart;
      };

      return chart;
    }
  },
};
