export default {
  transclude: true,

  bindings: {
    props: '=',
    active: '<',
    small: '<',
  },

  template: `
    <div class="award d-flex align-items-center mb-3">
      <i
        class="fa fa-check-circle fa-3x text-gray-lighter mr-2"
        ng-class="{
          'text-success': $ctrl.active
        }">
      </i>
      <div class="">
        <badge-status status="$ctrl.props.status"></badge-status>
        <strong ng-class="{ 'small': $ctrl.small }">{{ $ctrl.props.name }}</strong><br>
        <ng-transclude/>
      </div>
    </div>`,
};
