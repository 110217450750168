import colors from 'constants/colors';

export default {
  bindings: {
    target: '@',
    onSubmit: '&',
    groupPriorities: '<',
  },

  template: `<div ng-attr-id="{{$ctrl.target}}" class="modal fade {{ $ctrl.target }} kpiModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header p-4">
        <h3 class="h4 modal-title">Add Priority</h3>
      </div>
      <div class="modal-body p-4 pb-0">
        <form
            id="$ctrl.selectpriorityform"
            name="$ctrl.selectpriorityform"
            ng-submit="$ctrl.onSubmit({ id: $ctrl.priority.id })"
            novalidate>

          <div class="row">
            <label class="col-sm-4 col-form-label text-gray-dark">Priority</label>
            <div class="col-sm-4">
              <select
                  class="form-control"
                  ng-model="$ctrl.priority"
                  ng-options="obj as obj.title disable when !obj.isEnabled for (key, obj) in $ctrl.groupPriorities"
              >
                <option value="" disabled selected>Please select priority to add</option>
              </select>
            </div>
          </div>
          
          <div class="priorities__members" ng-show="$ctrl.priority">
            <div class="priorities__avatar">
              <label class="priorities__shorttitle" ng-style="{background: $ctrl.colors[$ctrl.priority.color]}">{{ $ctrl.priority.shorttitle}}</label>
              <label class="priorities__headline">{{ $ctrl.priority.headline}}</label>
              <label class="priorities__subheading">{{ $ctrl.priority.subheading}}</label>
            </div>
          </div>

          <div class="clearfix mt-5">
            <div class="alert alert-danger" ng-if="$ctrl.error">
              <p>{{$ctrl.error}}</p>
            </div>
            <button
                type="submit"
                class="btn btn-primary ml-2 float-right"
                ng-disabled="!$ctrl.priority"
            >
              <i class='fa fa-spinner fa-spin ' ng-if="$ctrl.loading"/>
              Add Priority
            </button>
            <button
                type="button"
                class="btn btn-danger float-right"
                data-dismiss="modal">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>`,

  controller($scope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      colors,
    };

    return $ctrl;

    function $onInit() {
      $(document).ready(function() {
        $(`#${$ctrl.target}`).on('hide.bs.modal', function() {
          $ctrl.priority = undefined;
        });
      });
    }
  },
};
