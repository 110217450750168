import html from './form.html';
import { changeGroup } from 'logic/group';
import { getShortName } from '../../../utils';
import '../show/show.scss';

export default {
  bindings: {
    onSubmit: '&',
    onCancel: '&',
    name: '@',
    props: '<',
  },

  template: html,

  controller($ngRedux) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onAdd,
      getShortName,
      onRemoveUser,
      onCreate,
      onAddPriority,
      onCreatePriority,
      onRemovePriority,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.props = { users: {}, priorities: {} };
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.user && !$ctrl.props.users[$ctrl.user.id]) {
        const { id } = $ctrl.user;
        $ctrl.props.users[id] = $ctrl.user;
        $ctrl.changeGroup($ctrl.props);
      }

      if ($ctrl.success) {
        for (let [id, user] of Object.entries($ctrl.users)) {
          angular.element(`#remove-button-${id}`).tooltip('hide');
          angular.element(`#user-image-${id}`).tooltip('hide');
        }
      }
    }

    function mapState(state) {
      return {
        loading: state.group.loading,
        error: state.group.error,
        groupUsers: state.group.data.users,
        users: state.users.dic,
        user: state.user.data,
      };
    }

    function mapDispatch() {
      return {
        changeGroup,
      };
    }

    function onAdd(id) {
      $ctrl.props.users[id] = $ctrl.users[id];
      $ctrl.changeGroup($ctrl.props);
    }

    function onRemoveUser(selUserId) {
      angular.element(`#remove-button-${selUserId}`).tooltip('dispose');
      angular.element(`#user-image-${selUserId}`).tooltip('dispose');

      delete $ctrl.props.users[selUserId];
      $ctrl.changeGroup($ctrl.props);
    }

    function onCreate() {
      const props = angular.copy($ctrl.props);
      for (let [userId, userData] of Object.entries(props.users)) {
        props.users[userId] = true;
      }
      $ctrl.onSubmit({ props });
    }

    function onAddPriority() {
      $('#priority-modal').modal('show');
      $('#priority-modal').appendTo('body');
    }

    function onCreatePriority(priority) {
      $ctrl.updatingPriority = true;
      for (const [, value] of Object.entries($ctrl.props.priorities)) {
        if (value.title === priority.title) {
          $ctrl.updatingPriority = false;
          $ctrl.errorPriority = 'The title already exists.';
          return;
        }
      }

      $ctrl.updatingPriority = false;
      $('#priority-modal').modal('hide');
      $ctrl.props.priorities[new Date().getTime()] = priority;
      $ctrl.changeGroup($ctrl.props);
    }

    function onRemovePriority(id) {
      delete $ctrl.props.priorities[id];
      $ctrl.changeGroup($ctrl.props);
    }
  },
};
