export default {
  bindings: {
    props: '<',
  },

  template: `
    <a href="#" class="d-block">
      <i
        ng-class="$ctrl.props ? 'text-success' : 'text-gray-lighter'"
        class="fa fa-3x fa-check-circle">
      </i>
    </a>`,

  controller: function($element, $timeout, $filter) {
    `ngInject`;

    const $ctrl = {
      $onDestroy,
      $postLink,
      getContent,
    };

    return $ctrl;

    function $onDestroy() {
      $element.find('a').popover('dispose');
    }

    function $postLink() {
      $timeout(initPopover);
    }

    function initPopover() {
      if (!$ctrl.props) {
        return;
      }

      $element.find('a').popover({
        html: true,
        content: $ctrl.getContent,
        trigger: 'focus',
        placement: 'top',
      });
    }

    function getContent() {
      const { completionDate, note, link } = $ctrl.props;

      if (!completionDate && !note && !link) {
        return 'No details found.';
      }

      const content = [];

      if (completionDate) {
        const dateify = $filter('date');
        content.push(
          `<b>Completed:</b> ${dateify(completionDate, 'dd/MM/yyyy')}`
        );
      }

      if (note) {
        content.push(`<b>Note:</b> ${note}`);
      }

      if (link) {
        const linkify = $filter('linkify');
        content.push(`<a href="${linkify(link)}" target="_blank">Link</a>`);
      }

      return content.join('<br>');
    }
  },
};
