export default {
  bindings: {
    item: '<',
    onSubmit: '&',
    onCancel: '&',
  },

  template: `
    <div class="popover popover-bottom initiatives-popover" role="tooltip">
      <div class="popover-arrow"></div>
      <div class="popover-content">
        <form ng-submit="$ctrl.onSubmit({ item: $ctrl.model })">
          <div class="form-group">
            <label class="font-weight-bold">Completion Date</label>
            <input ng-model="$ctrl.model.completionDate" type="date" class="form-control">
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Link</label>
            <input ng-model="$ctrl.model.link" type="text" class="form-control" value="{{ item.id }}">
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Note</label>
            <textarea ng-model="$ctrl.model.note" class="form-control" rows="4"></textarea>
          </div>
          <div class="form-group mb-2">
            <button
              type="submit"
              class="btn btn-primary">
              <span ng-hide="$ctrl.item.active">Check</span>
              <span ng-show="$ctrl.item.active">Save</span>
            </button>
            <button
              type="button"
              ng-click="$ctrl.onInit(); $ctrl.onCancel()"
              class="btn btn-secondary">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>`,

  controller() {
    const $ctrl = {
      $onInit,
    };

    return $ctrl;

    function $onInit() {
      $ctrl.model = { ...$ctrl.item };
    }
  },
};
