import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import store from 'store';
import axios from 'axios';

import getFirebaseConfig from '../config/firebase';

const firebaseProjectId = getFirebaseConfig().projectId;

export const SAVE_QUESTION_SET = 'app/checkpoints/SAVE_QUESTION_SET';
export const SAVE_QUESTION_SET_SUCCESS =
  'app/checkpoints/SAVE_QUESTION_SET_SUCCESS';
export const SAVE_QUESTION_SET_ERROR =
  'app/checkpoints/SAVE_QUESTION_SET_ERROR';
export const SAVE_QUESTION_SET_CANCEL =
  'app/checkpoints/SAVE_QUESTION_SET_CANCEL';

export const FETCH_QUESTION_SETS = 'app/checkpoints/FETCH_QUESTION_SETS';
export const FETCH_QUESTION_SETS_SUCCESS =
  'app/checkpoints/FETCH_QUESTION_SETS_SUCCESS';
export const FETCH_QUESTION_SETS_ERROR =
  'app/checkpoints/FETCH_QUESTION_SETS_ERROR';
export const FETCH_QUESTION_SETS_CANCEL =
  'app/checkpoints/FETCH_QUESTION_SETS_CANCEL';

// Initial State
export const initialState = {
  data: {},
  list: {},
  loading: false,
  success: false,
  error: '',
};

export function saveQuestionSet(payload) {
  return { type: SAVE_QUESTION_SET, payload };
}

export function fetchQuestionSets() {
  return { type: FETCH_QUESTION_SETS };
}

export const saveQuestionSetLogic = createLogic({
  latest: true,
  type: SAVE_QUESTION_SET,
  cancelType: SAVE_QUESTION_SET_CANCEL,
  processOptions: {
    successType: SAVE_QUESTION_SET_SUCCESS,
    failType: SAVE_QUESTION_SET_ERROR,
  },
  process({ $http, action }) {
    let questionSet = action.payload;
    let url = `https://us-central1-${firebaseProjectId}.cloudfunctions.net/saveQuestionSet`;

    return axios
      .post(url, questionSet, {
        headers: {
          Authorization: store.get('Authorization'),
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if (response.status === 200) {
          return { data: response.data };
        } else {
          throw response.data;
        }
      });
  },
});

export const fetchQuestionSetsLogic = createLogic({
  latest: true,
  type: FETCH_QUESTION_SETS,
  cancelType: FETCH_QUESTION_SETS_CANCEL,
  warnTimeout: 0,
  process({ $http, action, getState }, dispatch) {
    let url = `https://us-central1-${firebaseProjectId}.cloudfunctions.net/loadGroupQuestionSets`;

    return axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: store.get('Authorization'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: FETCH_QUESTION_SETS_SUCCESS,
            payload: response.data,
          });
        } else {
          throw response.data;
        }
      });

    // Only load user's question sets
    /*
    firebase
      .database()
      .ref(`question_sets`)
      .child(getState().user.data.id)
      // TODO: it should watch the list
      .once(
        'value',
        snapshot => {
          const questionSets = snapshot.val();
          dispatch({
            type: FETCH_QUESTION_SETS_SUCCESS,
            payload: questionSets,
          });
        },
        error => {
          console.error(error);
        }
      );*/
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SAVE_QUESTION_SET:
      return {
        ...state,
        loading: true,
        error: '',
        success: false,
      };

    case SAVE_QUESTION_SET_SUCCESS:
      return {
        ...state,
        data: { ...payload.data },
        list: { ...state.list, [payload.data.id]: payload.data },
        loading: false,
        success: true,
      };

    case SAVE_QUESTION_SET_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: 'Failed to add question set',
      };

    case FETCH_QUESTION_SETS_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };

    default:
      return state;
  }
}
