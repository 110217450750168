import html from './show.html';
import { fetchGroup } from 'logic/group';
import { getShortName } from '../../../utils';
import { removeGroupUser, addGroupUser } from 'logic/group';

import './show.scss';

export default {
  bindings: {},

  template: html,

  controller($ngRedux, $routeParams, $scope, $rootScope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $doCheck,
      $onDestroy,
      mapState,
      mapDispatch,
      getShortName,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);

      $ctrl.group_name = $routeParams.name;
      $rootScope.title = `Init - Group: ${$ctrl.group_name}`;
      $ctrl.fetchGroup($ctrl.group_name);
    }

    function $doCheck() {
      if ($ctrl.success) {
        $scope.$broadcast('remove_member_event');
      }
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      return {
        ...state.group,
        users: state.users.dic,
        user: state.user.data,
      };
    }

    function mapDispatch() {
      return {
        fetchGroup,
        removeGroupUser,
        addGroupUser,
      };
    }
  },
};
