import list from './list';
import show from './show/show';
import edit from './edit/edit';
import addMember from './show/add-member';
import groupNew from './new';
import groupForm from './new/form';
import members from './show/members';
import priorities from './new/priorities';
import priorityModal from './new/priority-modal';
import dashboard from './dashboard';

export default angular
  .module('App.groups', [])
  .component('groupList', list)
  .component('groupShow', show)
  .component('groupEdit', edit)
  .component('addMember', addMember)
  .component('groupNew', groupNew)
  .component('groupForm', groupForm)
  .component('priorities', priorities)
  .component('priorityModal', priorityModal)
  .component('groupDashboard', dashboard)
  .component('members', members).name;
