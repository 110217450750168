const demoKpis = require('../../constants/demo_kpis.json');

export default {
  bindings: {
    props: '<',
  },

  template: `
    <div class="initiatives-list-performance" ng-if="$ctrl.props.type !== 'lite'">
      <h5>Technical Success Metrics</h5>
      <div class="row mt-3" ng-if="!$ctrl.isAllKpiDataEmpty('technical')">
        <div class="col-sm-3" ng-repeat="item in $ctrl.props.kpis track by $index" ng-if="item.type === 'technical' && !item.disabled">
          <h6 class="mb-2 graph-line-text">{{ item.name }}</h6>
          <graph-line-total
            props="item"
            on-populate="$ctrl.onPopulate(kpi)"
          >
          </graph-line-total>
        </div>
      </div>
      
      <div class="empty-kpi-area row mt-3" ng-if="$ctrl.isAllKpiDataEmpty('technical')">
        <div class="col-sm-3 dummy-graphs" ng-repeat="item in $ctrl.demoKpis track by $index" ng-if="item.type === 'technical'">
          <h6 class="mb-2 graph-line-text">{{ item.name }}</h6>
          <graph-line-total
            props="item"
          >
          </graph-line-total>
        </div>
        <div class="begin-populating">
          <strong>Example graph data</strong>
          <a ng-href="/initiatives/{{ $ctrl.props.id }}/edit#hashLinkForMetrics" class="btn btn-primary btn-sm">Begin populating</a>
        </div>
      </div>
      
      <h5 class="mt-5">Business Success Metrics</h5>
      <div class="row mt-3" ng-if="!$ctrl.isAllKpiDataEmpty('business')">
        <div class="col-sm-3" ng-repeat="item in $ctrl.props.kpis" ng-if="item.type === 'business' && !item.disabled">
          <h6 class="mb-2 graph-line-text">{{ item.name }}</h6>
          <graph-line-total 
            props="item"
          >
          </graph-line-total>
        </div>
      </div>
      
      <div class="empty-kpi-area row mt-3" ng-if="$ctrl.isAllKpiDataEmpty('business')">
        <div class="col-sm-3 dummy-graphs" ng-repeat="item in $ctrl.demoKpis track by $index" ng-if="item.type === 'business'">
          <h6 class="mb-2 graph-line-text">{{ item.name }}</h6>
          <graph-line-total
            props="item"
          >
          </graph-line-total>
        </div>
        <div class="begin-populating">
          <strong>Example graph data</strong>
          <a ng-href="/initiatives/{{ $ctrl.props.id }}/edit#hashLinkForMetrics" class="btn btn-primary btn-sm">Begin populating</a>
        </div>
      </div>
      
    </div>`,
  controller($location) {
    'ngInject';
    const $ctrl = {
      isAllKpiDataEmpty,
      onPopulate,
      demoKpis,
    };

    return $ctrl;

    function isAllKpiDataEmpty(type) {
      const { kpis } = $ctrl.props;
      if (!kpis || typeof kpis !== 'object') {
        return true;
      }

      const ids = Object.keys(kpis);
      let id;
      for (let i in ids) {
        id = ids[i];
        if (kpis[id].type === type && kpis[id].data) {
          return false;
        }
      }
      return true;
    }

    function onPopulate(kpi) {
      $location
        .url(`/initiatives/${$ctrl.props.id}/edit#hashLinkForMetrics`)
        .search({ kpiId: kpi.id });
    }
  },
};
