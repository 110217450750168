export const white = '#fff';
export const black = '#000';
export const red = '#d52a59';
export const orange = '#f0ad4e';
export const yellow = '#fcb651';
export const green = '#acd036';
export const blue = '#0399cd';
export const teal = '#58b582';
export const pink = '#ff5b77';
export const purple = '#613d7c';
export const grayDark = '#292b2c';
export const grayDarkAlt = '#3a3d44';
export const gray = '#464a4c';
export const grayMid = '#636c72';
export const grayLight = '#c3c6c9';
export const grayLighter = '#eceeef';
export const grayLightest = '#f7f7f9';

export const priorityColors = [
  '#255255',
  '#f1b149',
  '#6a5b72',
  '#8a699c',
  '#320949',
  '#3f0f69',
  '#99ccff',
  '#434857',
  '#70afab',
  '#205e5b',
  '#9966ff',
  '#66023c',
  '#00cccc',
  '#ff6666',
  '#ff3366',
  '#ff99cc',
  '#ffccff',
  '#66ccff',
  '#33ffff',
  '#00ffff',
  '#00ccff',
  '#9966cc',
  '#33ffcc',
  '#33cccc',
  '#33ccff',
  '#ccff99',
  '#99ff99',
  '#ffff99',
  '#ff6699',
  '#ea5959',
  '#456672',
  '#e3b587',
  '#f98b60',
  '#ffc057',
  '#b30753',
  '#00387f',
  '#0070ff',
  '#f7d0cb',
  '#313131',
];

export default {
  white,
  black,
  red,
  orange,
  yellow,
  green,
  blue,
  teal,
  pink,
  purple,
  grayDark,
  grayDarkAlt,
  gray,
  grayMid,
  grayLight,
  grayLighter,
  grayLightest,
};
