import {
  fetchInitiative,
  cancelInitiative,
  starInitiative,
} from 'logic/initiative';
import { getRemovedMarkdownString } from 'utils';

import { getUsersProfile } from 'logic/users';

import { injectStars } from '../../utils/index';
import decorateInitiative from 'decorators/initiative';

import html from './show-summary.html';

export default {
  template: html,

  controller($ngRedux, $routeParams, $timeout) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onStarInitiative,
      setPriorities,
      starCount: 0,
      getRemovedMarkdownString
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);

      $ctrl.fetchInitiative($routeParams.id);
    }

    function $onDestroy() {
      $ctrl.cancelInitiative();
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      // if ($ctrl.props.name) {
      // $rootScope.title = `Init - Initiative: ${$ctrl.props.name}`;
      // }

      if (!$ctrl.isSetPriorities && $ctrl.props.priorities) {
        $ctrl.isSetPriorities = true;
        $ctrl.setPriorities();
      }
    }

    function mapState(state) {
      const initiative = angular.copy(state.initiative.data);

      const mobilisations = { ...state.awards.mobilisations };

      const props = decorateInitiative(initiative, mobilisations);

      injectStars(props, state);

      $('#staredUserNames').attr('data-original-title', props.staredUserNames);
      if (
        $ctrl.props &&
        $ctrl.props.starCount !== props.starCount &&
        $ctrl.getUsersProfile &&
        props.stars
      ) {
        const dicUsers = {};
        for (let userId of Object.keys(props.stars)) {
          if (!state.users.dic[userId]) {
            dicUsers[userId] = true;
          }
        }
        $timeout(() => {
          $ctrl.getUsersProfile(dicUsers);
        });
      }

      return {
        props,
        fetching: state.initiative.fetching,
        fetchError: state.initiative.fetchError,
        users: state.users.dic,
      };
    }

    function mapDispatch() {
      return {
        fetchInitiative,
        cancelInitiative,
        starInitiative,
        getUsersProfile,
      };
    }

    function onStarInitiative() {
      $ctrl.starInitiative(!$ctrl.props.isStared);
    }

    function setPriorities() {
      $ctrl.selPriorities = {};

      if (!$ctrl.props.group.priorities) {
        return;
      }
      const temp = angular.copy($ctrl.props.priorities);
      for (const [id, value] of Object.entries(temp)) {
        if ($ctrl.props.group.priorities[id]) {
          $ctrl.selPriorities[id] = $ctrl.props.group.priorities[id];
        }
      }
    }
  },
};
