import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import store from 'store';

// Action Types
export const FETCH = 'app/checkpoints/FETCH';
export const SUCCESS = 'app/checkpoints/SUCCESS';
export const ERROR = 'app/checkpoints/ERROR';
export const CANCEL = 'app/checkpoints/CANCEL';

// Initial State
export const initialState = {
  data: {},
  loading: false,
  success: false,
};

// Actions
export function fetchCheckpoints() {
  return { type: FETCH };
}

export function cancelCheckpoints() {
  return { type: CANCEL };
}

// Observable Logic
export const checkpointsFetch = createLogic({
  latest: true,
  type: FETCH,
  cancelType: CANCEL,
  processOptions: {
    successType: SUCCESS,
    failType: ERROR,
  },
  process(/*{ $http }*/) {
    //return $http.get(`/checkpoints`);
    return firebase
      .database()
      .ref('constants/checkpoints')
      .once('value')
      .then(snapshot => {
        let ret = {};
        let checkpoints = snapshot.val();
        if (!checkpoints) {
          checkpoints = [];
        }
        for (let i = 0; i < checkpoints.length; i++) {
          if (checkpoints[i]) {
            ret[checkpoints[i].id] = checkpoints[i];
          }
        }
        return {
          data: ret,
        };
      });
  },
});

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH:
      return {
        ...state,
        loading: true,
      };

    case SUCCESS:
      return {
        ...state,
        data: { ...payload.data },
        loading: false,
        success: true,
      };

    case ERROR:
      return {
        ...state,
        ...payload,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
}
