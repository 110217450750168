export default {
  bindings: {
    text: '<',
    limit: '<',
  },

  template: `
    <p class="init-intro-text">
      {{ $ctrl.text | limitTo: $ctrl.noLimit ? null : $ctrl.limit }}
      <a
        href="#"
        class="init-intro-link"
        ng-show="$ctrl.text.length > $ctrl.limit"
        ng-click="$ctrl.noLimit = !$ctrl.noLimit">
        <span ng-hide="$ctrl.noLimit">Show more</span>
        <span ng-show="$ctrl.noLimit">Show less</span>
      </a>
    </p>`,
};
