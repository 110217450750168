/**
 * Created by ilya on 7/11/17.
 */
import * as _ from 'lodash';
const removeMd = require('remove-markdown');
import axios from 'axios';
import randomstring from 'randomstring';

import getFirebaseConfig from '../config/firebase';
import { sendEvent } from '../analytics';

const firebaseProjectId = getFirebaseConfig().projectId;

import { INFORMAL, RED, GREEN, AMBER } from '../config/consts';

export function indexOfMax(arr) {
  if (arr.length === 0) {
    return -1;
  }

  var max = arr[0];
  var maxIndex = 0;

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      maxIndex = i;
      max = arr[i];
    }
  }

  return maxIndex;
}

export function calcVotePercent(ballot, checkpointId) {
  const noVote = [
    { count: 0, percent: 0 },
    { count: 0, percent: 0 },
    { count: 0, percent: 0 },
  ];
  if (!ballot.votes || !ballot.votes[checkpointId]) {
    return noVote;
  }
  let red = 0,
    amber = 0,
    green = 0;

  if (ballot.votes[checkpointId].redvotes >= 0) {
    red = ballot.votes[checkpointId].redvotes;
  }

  if (ballot.votes[checkpointId].ambervotes >= 0) {
    amber = ballot.votes[checkpointId].ambervotes;
  }

  if (ballot.votes[checkpointId].greenvotes >= 0) {
    green = ballot.votes[checkpointId].greenvotes;
  }

  const totalVotes = red + amber + green;

  if (totalVotes === 0) {
    return noVote;
  } else {
    return [
      { count: red, percent: Math.round((red / totalVotes) * 100) },
      { count: amber, percent: Math.round((amber / totalVotes) * 100) },
      { count: green, percent: Math.round((green / totalVotes) * 100) },
    ];
  }
}

/** @return ragIndex INFORMAL, RED, AMBER, GREEN
 *
 */
export function calcRag(vote) {
  if (!vote) {
    return 0;
  }
  let maxIndex;
  if (vote.redvotes === 0 && vote.ambervotes === 0 && vote.greenvotes === 0) {
    maxIndex = INFORMAL;
  } else if (
    vote.redvotes === vote.ambervotes &&
    vote.ambervotes === vote.greenvotes
  ) {
    // All votes are the same
    maxIndex = AMBER;
  } else if (
    vote.redvotes === vote.ambervotes &&
    vote.ambervotes !== 0 &&
    vote.redvotes > vote.greenvotes
  ) {
    maxIndex = RED;
  } else if (
    vote.ambervotes === vote.greenvotes &&
    vote.greenvotes !== 0 &&
    vote.ambervotes > vote.redvotes
  ) {
    maxIndex = AMBER;
  } else if (
    vote.redvotes === vote.greenvotes &&
    vote.greenvotes !== 0 &&
    vote.redvotes > vote.ambervotes
  ) {
    maxIndex = AMBER;
  } else {
    maxIndex =
      indexOfMax([vote.redvotes, vote.ambervotes, vote.greenvotes]) + 1; // RAG values are 1 greater than index(RED: 1, AMBER: 2, GREEN: 3)
  }
  return maxIndex;
}

/**
 * @return boolean if all users voted for
 * */

export function allVoted(ballot, checkpointId) {
  if (!ballot) {
    return false;
  }
  // no votes
  if (!ballot.votes || !ballot.votes[checkpointId]) {
    return false;
  }

  const { uservotes } = ballot.votes[checkpointId];

  // Nobody voted
  if (!uservotes) {
    return false;
  }

  // no voters joined
  if (!ballot.voters) {
    return false;
  }

  for (let userid in ballot.voters) {
    if (
      isNaN(uservotes[userid]) ||
      (uservotes[userid] < INFORMAL || uservotes[userid] > GREEN)
    ) {
      return false;
    }
  }

  return true;
}

export function getVotedUsersCount(ballot, checkpointId) {
  if (!ballot) {
    return 0;
  }
  // no votes
  if (!ballot.votes || !ballot.votes[checkpointId]) {
    return 0;
  }

  const { uservotes } = ballot.votes[checkpointId];

  // Nobody voted
  if (!uservotes) {
    return 0;
  }

  // no voters joined
  if (!ballot.voters) {
    return 0;
  }

  let count = 0;
  for (let checkpointId in ballot.voters) {
    if (
      uservotes[checkpointId] >= INFORMAL ||
      uservotes[checkpointId] <= GREEN
    ) {
      count = count + 1;
    }
  }
  return count;
}

export function canBeClosed(ballot) {
  if (!ballot) {
    return false;
  }

  if (!Array.isArray(ballot.checkpoints)) {
    return false;
  }

  // nobody voted yet
  if (!ballot.votes) {
    return false;
  }

  for (let index in ballot.checkpoints) {
    var chkId = ballot.checkpoints[index].id;
    if (chkId && !ballot.votes[chkId]) {
      return false;
    }

    if (ballot.votes[chkId] && !ballot.votes[chkId].closed) {
      return false;
    }
  }
  return true;
}

export function getJoinedUsersCount(ballot) {
  if (!ballot) {
    return 0;
  }

  const { voters } = ballot;
  if (typeof voters !== 'object') {
    return 0;
  }

  return Object.keys(voters).length;
}

export function isNextCheckpointAvailable(ballot) {
  if (!ballot) {
    return false;
  }

  if (!Array.isArray(ballot.checkpoints)) {
    return false;
  }

  if (ballot.closed) {
    return false;
  }

  const { activeCheckpoint } = ballot;
  let checkpoints = [];
  if (!Array.isArray(ballot.checkpoints)) {
    checkpoints = [];
  } else {
    checkpoints = [...ballot.checkpoints];
  }

  const activeCP = checkpoints.find(c => c.id == activeCheckpoint);

  let activeCheckpointClosed = true;
  if (activeCP) {
    if (ballot.votes && ballot.votes[activeCP.id]) {
      activeCheckpointClosed = !!ballot.votes[activeCP.id].closed;
    } else {
      activeCheckpointClosed = false;
    }
  }

  for (let chkId in ballot.votes) {
    // any other checkpoint not closed yet: it can be set as active
    if (
      ballot.votes &&
      ballot.votes[chkId] &&
      chkId != ballot.activeCheckpoint &&
      !ballot.votes[chkId].closed
    ) {
      return activeCheckpointClosed;
    }
  }

  if (ballot.votes) {
    return false;
  } else {
    return activeCheckpointClosed;
  }
}

export function getVotedCheckpointsCount(ballot) {
  if (!ballot) {
    return 0;
  }

  if (!ballot.votes) {
    return 0;
  }

  let count = 0;
  for (let chkId in ballot.votes) {
    if (ballot.votes[chkId] && ballot.votes[chkId].closed) {
      count++;
    }
  }
  return count;
}

export function getTotalCheckpointsCount(ballot) {
  if (!ballot) {
    return 0;
  }

  if (!Array.isArray(ballot.checkpoints)) {
    return 0;
  }
  return ballot.checkpoints.length;
}

export function getActiveCheckpointIndex(ballot, activeCheckpoint) {
  if (!ballot || !Array.isArray(ballot.checkpoints) || !activeCheckpoint) {
    return 0;
  }

  let type_checkpoints = ballot.checkpoints
    .filter(c => c.type == activeCheckpoint.type)
    .map(c => `${c.id}`); // Convert id to string

  if (type_checkpoints.length === 0) {
    return 0;
  }

  let { closed_checkpoints } = ballot;
  if (!closed_checkpoints) closed_checkpoints = {};

  // count the closed checkpoints
  let closed_count = 0;
  for (let c in closed_checkpoints) {
    if (type_checkpoints.indexOf(c) > -1) {
      closed_count++;
    }
  }

  return closed_count + 1;
}

export function getTotalCheckpointsCountByType(ballot, type) {
  if (!ballot) {
    return 0;
  }

  if (!Array.isArray(ballot.checkpoints)) {
    return 0;
  }

  const checkpoints = ballot.checkpoints.filter(c => c.type == type);
  if (!checkpoints) {
    return 0;
  }
  return checkpoints.length;
}

export function getAvatarColor(ballot, userId) {
  if (!ballot) {
    return '';
  }
  if (ballot.anonymous) {
    return '';
  }
  if (ballot.oneByOne) {
    /*- When a the ballot is `closed=true` display the avatars of the all users who were joined to the ballot and have them with a blue tick.*/
    if (ballot.closed) {
      return 'voted'; // should show blue
    }

    /*- When `activeCheckpoint == NULL` and ballot is `closed=false` display the avatars of the users who have joined the ballot.*/
    if (
      (!ballot.activeCheckpoints || ballot.activeCheckpoints.length === 0) &&
      !ballot.closed
    ) {
      return '';
    }

    /*- When a `activeCheckpoint != NULL` and `votes\{checkpoint-id}\closed=true` and `private=false` display the avatars of the users who have joined the ballot.
     Add a red, amber or green tick to the users who have voted for the active checkpoint, corresponding to how they voted.
     No tick for the users who did not submit a vote on the active checkpoint.*/
    if (
      Array.isArray(ballot.activeCheckpoints) &&
      ballot.activeCheckpoints.length > 0 &&
      ballot.activeCheckpointClosed &&
      !ballot.private // Non private
    ) {
      // return true // should show red, amber, green according to the vote, blue for not submitted
      if (
        ballot.votes &&
        ballot.votes[ballot.activeCheckpoint] &&
        ballot.votes[ballot.activeCheckpoint].uservotes
      ) {
        const vote = ballot.votes[ballot.activeCheckpoint].uservotes[userId];
        if (isNaN(vote) || (vote < INFORMAL || vote > GREEN)) {
          // not voted yet
          return ''; // No color
        } else if (vote === INFORMAL) {
          return 'voted';
        } else if (vote === RED) {
          return 'voted red';
        } else if (vote === AMBER) {
          return 'voted amber';
        } else if (vote === GREEN) {
          return 'voted green';
        } else {
          return '';
        }
      } else {
        return 'voted'; // user not voted yet
      }
    }

    /*- When a `activeCheckpoint != NULL` and `votes\{checkpoint-id}\closed=true` and `private=false` display the avatars of the users who have joined the ballot.
     Add a red, amber or green tick to the users who have voted for the active checkpoint, corresponding to how they voted.
     No tick for the users who did not submit a vote on the active checkpoint.*/
    if (
      Array.isArray(ballot.activeCheckpoints) &&
      ballot.activeCheckpoints.length > 0 &&
      ballot.activeCheckpointClosed &&
      ballot.private // Non private
    ) {
      // return true // should show red, amber, green according to the vote, blue for not submitted
      if (
        ballot.votes &&
        ballot.votes[ballot.activeCheckpoint] &&
        ballot.votes[ballot.activeCheckpoint].uservotes
      ) {
        const vote = ballot.votes[ballot.activeCheckpoint].uservotes[userId];
        if (isNaN(vote) || (vote < INFORMAL || vote > GREEN)) {
          // not voted yet
          return ''; // No color
        } else if (
          vote === INFORMAL ||
          vote === RED ||
          vote === AMBER ||
          vote === GREEN
        ) {
          return 'voted';
        }
      } else {
        return ''; // user not voted yet
      }
    }

    /*- When a `activeCheckpoint != NULL` and `votes\{checkpoint-id}\closed=false` display the avatars of the users who have joined the ballot.
     Add a blue tick to the users who have submitted a vote for the active checkpoint.
     No tick for the users who have not submitted a vote yet.*/
    if (
      Array.isArray(ballot.activeCheckpoints) &&
      ballot.activeCheckpoints.length > 0 &&
      !ballot.activeCheckpointClosed
    ) {
      // should show blue for voted users and nothing for non voted users
      if (
        ballot.votes &&
        ballot.votes[ballot.activeCheckpoint] &&
        ballot.votes[ballot.activeCheckpoint].uservotes
      ) {
        const vote = ballot.votes[ballot.activeCheckpoint].uservotes[userId];
        if (isNaN(vote) || (vote < INFORMAL || vote > GREEN)) {
          // not voted yet
          return '';
        } else if (
          vote === INFORMAL ||
          vote === RED ||
          vote === AMBER ||
          vote === GREEN
        ) {
          return 'voted';
        }
      } else {
        return ''; // user not voted yet
      }
    }

    return ''; // otherwise do not show avatars
  } else {
    //
    if (ballot.closed) {
      return 'voted'; // show blue tick
    } else {
      return '';
    }
  }
}

export function shouldShowAvatar(ballot) {
  if (!ballot) {
    // invalid ballot
    return false;
  }

  // - When `anonymous=true` avatars are never shown
  if (ballot.anonymous) {
    return false;
  }

  if (ballot.oneByOne) {
    /* - When a the ballot is `closed=true` display the avatars of the all users who were joined to the ballot and have them with a blue tick.*/
    if (ballot.closed) {
      return true;
    }

    if (
      (!ballot.activeCheckpoints || ballot.activeCheckpoints.length === 0) &&
      !ballot.closed
    ) {
      return true;
    }

    /*- When a `activeCheckpoint != NULL` and `votes\{checkpoint-id}\closed=true` and `private=false` display the avatars of the users who have joined the ballot.
     Add a red, amber or green tick to the users who have voted for the active checkpoint, corresponding to how they voted.
     No tick for the users who did not submit a vote on the active checkpoint.*/
    if (
      Array.isArray(ballot.activeCheckpoints) &&
      ballot.activeCheckpoints.length > 0 &&
      ballot.activeCheckpointClosed /* &&
      !ballot.private // Non private*/
    ) {
      return true; // should show red, amber, green according to the vote, blue for not submitted
    }

    /*- When a `activeCheckpoint != NULL` and `votes\{checkpoint-id}\closed=false` display the avatars of the users who have joined the ballot.
     Add a blue tick to the users who have submitted a vote for the active checkpoint.
     No tick for the users who have not submitted a vote yet.*/
    if (
      Array.isArray(ballot.activeCheckpoints) &&
      ballot.activeCheckpoints.length > 0 &&
      !ballot.activeCheckpointClosed
    ) {
      return true; // should show blue for voted users and nothing for non voted users
    }
  } else {
    return !!ballot.closed; // if closed show, not: do not show
  }
}

export function getShortName(displayName) {
  if (
    displayName === undefined ||
    displayName === null ||
    displayName.length == 0
  ) {
    return '';
  } else {
    let splits = displayName.split(' ');
    if (splits.length > 1) {
      let shortName = '';
      let s = [];
      for (let i = 0; i < splits.length; i++) {
        if (splits[i] && splits[i].length > 1) {
          s.push(splits[i]);
        }
      }
      if (s.length == 1) {
        shortName = s[0][0] + s[0][1];
      } else {
        for (let i = 0; i < s.length; i++) {
          shortName += s[i][0];
        }
      }
      return shortName.toUpperCase();
    } else {
      if (splits[0].length > 1) {
        return (splits[0][0] + splits[0][1]).toUpperCase();
      }
      return splits[0][0].toUpperCase();
    }
  }
}

export function getImpedimentVoteCount(impediment) {
  if (!impediment) {
    return 0;
  }

  if (!impediment.votes) {
    return 0;
  }

  let votes = 0;

  for (let key in impediment.votes) {
    votes += impediment.votes[key].vote;
  }

  return votes;
}

export function queryLocalImpediments(data, filter) {
  if (!data) {
    return [];
  }

  if (!filter.benefits) {
    filter.benefits = [];
  }

  if (!filter.initiatives) {
    filter.initiatives = [];
  }

  var sortedResult;

  var sorting = filter.sorting;

  var { benefits, initiatives } = filter;

  var impedimenttKeys = Object.keys(data);
  var impediments = [];
  for (var i = 0; i < impedimenttKeys.length; i++) {
    var impediment = data[impedimenttKeys[i]];
    impediment.id = impedimenttKeys[i];
    if (!Array.isArray(impediment.objects)) {
      impediment.objects = [];
    }
    impediments[i] = impediment;
  }

  if (!sorting) {
    sorting = 'created_date';
  }

  if (benefits && benefits.length > 0) {
    impediments = impediments.filter(item => {
      for (let i = 0; i < benefits.length; i++) {
        if (item.benefit === benefits[i].name) {
          return true;
        }
      }
      return false;
    });
  }

  console.info('initiative', JSON.stringify(initiatives));

  if (initiatives && initiatives.length > 0) {
    impediments = impediments.filter(item => {
      if (!item.objects) {
        return false;
      }
      for (let i = 0; i < initiatives.length; i++) {
        let initiative = initiatives[i];
        for (let j = 0; j < item.objects.length; j++) {
          let object = item.objects[j];
          if (
            object.type === 'Initiative' &&
            initiative.id === object.object.id
          ) {
            return true;
          }
        }
      }
      return false;
    });
  }

  var sortedResult = impediments.sort((item1, item2) => {
    if (sorting === 'created_date') {
      return (
        (isNaN(item2['created_date']) ? 0 : item2['created_date']) -
        (isNaN(item1['created_date']) ? 0 : item1['created_date'])
      );
    } else if (sorting === 'MOST_INITIATIVE_AFFECTED') {
      if (!item1.objects) {
        item1.objects = [];
      }
      if (!item2.objects) {
        item2.objects = [];
      }
      return item1.objects.length - item2.objects.length;
    } else if (sorting === 'UNRESOLVED_FOR_LONGEST') {
      var date1 = item1.created_date;
      var date2 = item2.created_date;

      var unresolvedDays1 = 0;
      var unresolvedDays2 = 0;

      if (!item1.resolved) {
        unresolvedDays1 = dateDiffInTime(date1);
      }

      if (!item2.resolved) {
        unresolvedDays2 = dateDiffInTime(date2);
      }

      return unresolvedDays1 - unresolvedDays2;
    } else if (sorting === 'VOTES') {
      if (!item1.votes) {
        item1.votes = {};
      }
      if (!item2.votes) {
        item2.votes = {};
      }
      return getImpedimentVoteCount(item1) > getImpedimentVoteCount(item2);
    }
  });

  sortedResult = sortedResult.reverse();

  return sortedResult;
}

export function dateDiffInTime(date) {
  var date1 = new Date(date);
  var date2 = new Date();
  var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  return timeDiff;
}

export function dateDiffInDays(date) {
  var date1 = new Date(date);
  var date2 = new Date();
  var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
}

export function queryLocalInitiatives(data, query) {
  if (!data) {
    return [];
  }

  if (query.sort) {
    [query._sort, query._order] = query.sort;
  }

  var initKeys = Object.keys(data);
  var initiatives = [];
  for (var i = 0; i < initKeys.length; i++) {
    var initiative = data[initKeys[i]];
    initiative.id = initKeys[i];
    if (!Array.isArray(initiative.links)) {
      initiative.links = [];
    }
    initiatives[i] = initiative;
  }

  /*
   q:"bes"
   status:"Draft"
   _order:"ASC"
   _sort:"name"
   */

  // filter by status
  var result = initiatives;
  if (query.status) {
    result = result.filter(function(initiative) {
      return initiative.status === query.status;
    });
  }

  if (query.group) {
    result = result.filter(function(initiative) {
      if (!initiative.group) {
        return false;
      }
      return initiative.group.id === query.group;
    });
  }

  // filter by query
  if (query.q) {
    query.q = query.q.toLowerCase();
    result = result.filter(function(initiative) {
      var keys = Object.keys(initiative);
      for (var i = 0; i < keys.length; i++) {
        if (
          typeof initiative[keys[i]] === 'string' ||
          Array.isArray(initiative[keys[i]])
        ) {
          if (
            String(initiative[keys[i]])
              .toLocaleLowerCase()
              .indexOf(query.q) >= 0
          ) {
            return true;
          }
        }
      }
      return false;
    });
  }

  // sort by
  var sortedResult;
  var _order = query._order;
  var _sort = query._sort;

  if (!_sort) {
    _sort = 'created_date';
  }

  if (!_order) {
    if (_sort == 'created_date') {
      _order = 'DESC';
    } else {
      _order = 'ASC';
    }
  }

  sortedResult = result.sort((item1, item2) => {
    if (_sort === 'created_date') {
      return (
        (isNaN(item2[_sort]) ? 0 : item2[_sort]) -
        (isNaN(item1[_sort]) ? 0 : item1[_sort])
      );
    } else {
      if (!item1[_sort] && item2[_sort]) {
        return 1;
      } else if (!item1[_sort] && !item2[_sort]) {
        return 0;
      } else if (item1[_sort] && !item2[_sort]) {
        return -1;
      }
      return item1[_sort]
        .toLowerCase()
        .localeCompare(item2[_sort].toLowerCase());
    }
  });

  if (_order == 'DESC') {
    return sortedResult.reverse();
  } else {
    return sortedResult;
  }
}

export function getCheckpointCount(ballot /* or initiative*/, type) {
  let checkpoints = [];
  if (Array.isArray(ballot.checkpoints)) {
    checkpoints = ballot.checkpoints;
  }

  return checkpoints.filter(chkp => {
    return chkp.type === type;
  }).length;
}

/*
 * @param initiative - initiative object
 * @param - checkin number
 * */

export function isCheckInAllowed(initiative, checkin) {
  let checkins = {};
  let ballot_status = {};

  if (initiative && initiative.rags) {
    checkins = initiative.rags;
  }

  if (initiative && initiative.ballot_status) {
    ballot_status = initiative.ballot_status;
  }

  if (checkin >= 1) {
    if (checkin == 1) {
      return true; // first checkin is always allowed to show
    } else {
      // the previous checkin is not submitted -  do not allow the current checkin
      // if the current ballot is opened or closed - allow
      return !!checkins[checkin - 1] || ballot_status[checkin] !== undefined;
    }
  } else {
    return false; // checkin is invalid
  }
}

/*
 * @param initiative - Initiative object
 * @param - checkpointId Checkpoint ID
 * */

export function getCheckpointRag(initiative, checkin, checkpointId) {
  if (!initiative || !initiative.rags || !initiative.rags[checkin]) {
    return undefined; // initiative or rags is invliad
  }
  return initiative.rags[checkin][checkpointId];
}

export function setRagValue(initiative, checkin, checkpointId, value) {
  if (!initiative) {
    console.err('Empty Initiative!');
    return;
  }

  let rags = {};
  if (initiative.rags) {
    rags = initiative.rags;
  }

  if (typeof rags[checkin] !== 'object') {
    rags[checkin] = {};
  }
  rags[checkin][checkpointId] = value;
  initiative.rags = rags;
}

export function arrayToObject(arr) {
  if (!Array.isArray(arr)) {
    return arr;
  }

  const obj = {};
  for (let i = 0; i < arr.length; i++) {
    if (
      arr[i] != undefined &&
      arr[i] != undefined &&
      arr[i] != null &&
      arr[i] != null
    ) {
      obj[i] = arr[i];
    }
  }
  return obj;
}

export function extractOnlyIDs(checkpoints) {
  if (Array.isArray(checkpoints)) {
    const newCheckpoints = checkpoints
      .map(chk => {
        return {
          id: chk.id,
        };
      })
      .filter(chk => {
        return chk.id !== undefined && chk.id !== null;
      });
    if (newCheckpoints.length > 0) {
      return newCheckpoints;
    } else {
      return null;
    }
  }
  return null;
}

export function injectCheckpoints(initiative, dicCheckpoints, votes) {
  if (!initiative || !initiative.id) {
    return;
  }

  if (votes) {
  } else {
    if (Array.isArray(initiative.checkpoints)) {
      let id;
      for (let i = 0; i < initiative.checkpoints.length; i++) {
        id = initiative.checkpoints[i].id;
        if (dicCheckpoints[id]) {
          initiative.checkpoints[i] = dicCheckpoints[id];
          initiative.checkpoints[i].id = id;
        } else {
          // console.info('Invalid checkpoint of index:', i, ', id:', id);
        }
      }
    } else {
      initiative.checkpoints = [];
    }
  }
}

export function injectCustomCheckpoints(initiative, list) {
  if (!initiative || !initiative.id || !list) {
    return;
  }
  if (Array.isArray(initiative.checkpoints)) {
    let id;
    const checkpoints = [];
    for (let i = 0; i < initiative.checkpoints.length; i++) {
      id = initiative.checkpoints[i].id.toString();
      if (id.indexOf('__') > -1) {
        // custom checkpoint
        const paths = id.split('__');
        // path[0]// user id
        let chk = _.get(list, [paths[1], 'questions', paths[2]]);

        if (chk) {
          chk.id = id;
          chk.type = list[paths[1]].name;
          checkpoints[i] = chk;
        } else {
          checkpoints[i] = initiative.checkpoints[i];
        }
      } else {
        checkpoints[i] = initiative.checkpoints[i]; // normal checkpoint
      }
    }
    initiative.checkpoints = checkpoints;
  } else {
    initiative.checkpoints = [];
  }
}

export function injectKpis(initiative, dicKpis) {
  if (!initiative) {
    return;
  }

  if (typeof initiative.kpis !== 'object') {
    initiative.kpis = {};
  }

  for (let id in dicKpis) {
    if (!initiative.kpis[id]) {
      initiative.kpis[id] = { ...dicKpis[id] };
    } else {
      initiative.kpis[id] = { ...dicKpis[id], ...initiative.kpis[id] };
    }
  }
}

export function injectStars(initiative, state) {
  if (!initiative) {
    return;
  }

  initiative.isStared =
    initiative.stars && initiative.stars[state.user.data.id];
  let starCount = 0;
  let staredUserNames = '';
  if (initiative.stars) {
    starCount = Object.keys(initiative.stars).length;

    for (let userId of Object.keys(initiative.stars)) {
      if (
        state.users.dic &&
        state.users.dic[userId] &&
        state.users.dic[userId].name
      ) {
        staredUserNames = `${staredUserNames}${state.users.dic[userId].name}, `;
      }
    }
    if (staredUserNames.length > 0) {
      staredUserNames = staredUserNames.slice(0, -2);
    }

    // $('#staredUserNames').attr('data-original-title', staredUserNames);
    // if ($ctrl.getUsersProfile) {
    //   $ctrl.getUsersProfile(props.stars);
    // }
  }
  initiative.starCount = starCount;
  initiative.staredUserNames = staredUserNames;
}

export function timeConvert(UNIX_timestamp) {
  if (!UNIX_timestamp) {
    return '';
  }

  var a = new Date(UNIX_timestamp);
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'Sepember',
    'October',
    'November',
    'December',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = month + ' ' + year;
  if (date === 1) {
    time = date + 'st ' + time;
  } else if (date == 2) {
    time = date + 'nd ' + time;
  } else if (date == 3) {
    time = date + 'rd ' + time;
  } else {
    time = date + 'th ' + time;
  }
  return time;
}

export function isBallotOpened(initiative) {
  if (
    !initiative ||
    !initiative.ballot_status ||
    !initiative.checkin ||
    !initiative.checkin.edit
  ) {
    return false;
  }
  return (
    initiative &&
    initiative.ballot_status &&
    initiative.ballot_status[initiative.checkin.edit] === true
  );
}

export const isFilteredActivity = (activity, filter) => {
  if (filter && filter.initiativeId) {
    if (activity.initiativeId !== filter.initiativeId) {
      return false;
    } else {
      return true;
    }
  }
  return true;
};

export function filterActivities(data = [], filter) {
  if (!Array.isArray(data)) {
    return [];
  }
  if (filter) {
    return data.filter(activity => {
      return isFilteredActivity(activity, filter);
    });
  } else {
    return data;
  }
}

export function getDurationStr(min) {
  if (min <= 0) {
    return 'seconds ago';
  }

  const hrs = Math.floor(min / 60);
  const days = Math.floor(hrs / 24);
  const weeks = Math.floor(days / 7);
  min = min % 60;

  let weekStr = '';
  let dayStr = '';
  let hrStr = '';
  let minStr = '';

  if (weeks > 0) {
    if (weeks == 1) {
      weekStr = '1 week ';
    } else {
      weekStr = `${weeks} weeks `;
    }
    return `${weekStr}ago`;
  }

  if (days % 7 > 0) {
    if (days % 7 == 1) {
      dayStr = '1 day ';
    } else {
      dayStr = `${days % 7} days `;
    }
    return `${dayStr}ago`;
  }

  if (hrs % 24 > 0) {
    if (hrs % 24 == 1) {
      hrStr = '1 hour ';
    } else {
      hrStr = `${hrs % 24} hours `;
    }
  }

  if (min % 60 > 0) {
    if (min % 60 == 1) {
      minStr = '1 minute ';
    } else {
      minStr = `${min % 60} minutes `;
    }
  }
  const agoStr = hrStr + minStr;
  if (agoStr == '') {
    return 'now';
  } else {
    return agoStr + 'ago';
  }
}

export function compareActivities(a, b) {
  if (a.id < b.id) return 1;
  if (a.id > b.id) return -1;
  return 0;
}

export function compareInitiativesByName(a, b) {
  if (a.name < b.name) return 1;
  if (a.name > b.name) return -1;
  return 0;
}

export function queryLocalUsers(data, query) {
  if (!data || !query || !query.q) {
    return [];
  }

  let initKeys = Object.keys(data);
  let users = [];
  for (let i = 0; i < initKeys.length; i++) {
    let user = data[initKeys[i]];
    user.id = initKeys[i];
    users[i] = user;
  }

  /*
   q: "aaa", size: 10 }
   */

  let result = users;
  // filter by query
  let queryText = query.q;
  let { size } = query;
  queryText = queryText.toLowerCase();
  result = result.filter(function(user) {
    if (
      new RegExp(`\\b${queryText}`).test(
        String(user['name']).toLocaleLowerCase()
      ) ||
      new RegExp(`.*${queryText}.*`).test(
        String(user['email']).toLocaleLowerCase()
      )
    ) {
      return true;
    }

    return false;
  });
  if (result.length > size) {
    return result.slice(0, size);
  }

  return result;
}

export function getTimeOffset(created) {
  const date = new Date();

  let min = Math.floor((date.getTime() - created) / 60000);
  return getDurationStr(min);
}

export function objectToArray(obj, keyName, valueName) {
  if (!obj) {
    return [];
  }
  return Object.keys(obj).map(function(key) {
    const elem = {};
    elem[keyName] = key;
    elem[valueName] = obj[key];
    return elem;
  });
}

export function checkSingleDataPoint(data) {
  if (typeof data === 'object' && Object.keys(data).length === 1) {
    return true;
  } else {
    return false;
  }
}

export function XOR(a, b) {
  return (a || b) && !(a && b);
}

export function filterInitiativeKpis(initiative) {
  if (initiative.kpis) {
    if (typeof initiative.kpis !== 'object') {
      delete initiative.kpis;
      return;
    }
    const keys = Object.keys(initiative.kpis);
    if (keys.length > 0) {
      const kpis = {};
      for (let kpiId of keys) {
        kpis[kpiId] = {
          disabled: !!initiative.kpis[kpiId].disabled,
        };
        if (initiative.kpis[kpiId].data) {
          kpis[kpiId].data = initiative.kpis[kpiId].data;
        }
      }
      initiative.kpis = kpis;
    }
  }
}

export function getGroupFromArray(groups, id) {
  if (!groups) {
    return null;
  }
  for (let i = 0; i < groups.length; i++) {
    if (groups[i].id === id) {
      return groups[i];
    }
  }
  return null;
}

export function getRagPercents(initiative, checkpoints, type) {
  const ragPercents = [0, 0, 0];
  let checkin;
  // Get last checkin
  if (Array.isArray(initiative.rags)) {
    checkin = initiative.rags.length - 1;
  }

  if (!initiative || !initiative.rags || isNaN(checkin)) {
    return ragPercents;
  }
  const _rags = initiative.rags[checkin];

  if (!_rags) {
    return ragPercents;
  }

  if (!Array.isArray(initiative.checkpoints)) {
    return ragPercents;
  }

  // Filter by initiative checkpoints
  const rags = {};

  // only select rags by initiative checkpoints
  for (let chk of initiative.checkpoints) {
    if (chk.id) {
      rags[chk.id] = _rags[chk.id];
    }
  }

  if (typeof rags === 'object') {
    for (let key in rags) {
      if (
        checkpoints[key] &&
        checkpoints[key].type === type &&
        rags[key] >= 1 &&
        rags[key] <= 3
      ) {
        ragPercents[rags[key] - 1]++;
      }
    }
  }

  const total = ragPercents[0] + ragPercents[1] + ragPercents[2];
  if (total === 0) {
    return [0, 0, 0];
  } else {
    return ragPercents.map(r => (r / total) * 100);
  }
}

export function getRemovedMarkdownString(string) {
  return removeMd(string);
}

export function toTitleCase(str) {
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
}

export function arrayToString(data) {
  if (!data) {
    return '';
  }
  if (!Array.isArray(data)) {
    return data;
  }
  return data.join(',');
}

export function arrayFromString(str) {
  if (!str) {
    return [];
  }
  return str.split(',');
}

export function hashStringToInt(str) {
  if (typeof str !== 'string') {
    if (isNaN(str)) {
      return 0;
    } else {
      return str;
    }
  }

  if (str.length === 0) {
    return 0;
  }

  const length = str.length;
  let sum = 0;
  for (let i = 0; i < length; i++) {
    sum = sum + str.charCodeAt(i);
  }
  return sum;
}

export function isEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function roundHalfEven(number) {
  const int = Math.round(number);
  if (number - int === 0.5 || int - number === 0.5) {
    if (int % 2 === 0) {
      return int;
    } else {
      return int > 0 ? int - 1 : int + 1;
    }
  } else {
    return int;
  }
}

// DEPRECATED: no longer using Intercom
export function initIntercom(
  Authorization,
  APP_ID,
  current_user_email,
  current_user_name,
  current_user_id
) {
  if (process.env.IS_TEST) {
    return;
  }
  axios
    .post(
      `https://us-central1-${firebaseProjectId}.cloudfunctions.net/getIntercomHash`,
      {},
      {
        headers: {
          Authorization,
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      const { hash } = response.data;
      window.intercomSettings = {
        app_id: APP_ID,
        name: current_user_name, // Full name
        email: current_user_email, // Email address
        user_id: current_user_id, // current_user_id
        user_hash: hash,
        alignment: 'left',
        Product: 'init/loggedIn',
      };
      (function() {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', intercomSettings);
        } else {
          var d = document;
          var i = function() {
            i.c(arguments);
          };
          i.q = [];
          i.c = function(args) {
            i.q.push(args);
          };
          w.Intercom = i;
          function l() {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/' + APP_ID;
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          }
          if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    })
    .catch(e => {
      console.error('Failed to setup intercom:', e);
    });
}

// DEPRECATED: no longer using Intercom
export function initIntercomLoggedOut(APP_ID) {
  if (process.env.IS_TEST) {
    return;
  }
  window.intercomSettings = {
    app_id: APP_ID,
    Product: 'init/loggedOut',
  };
  (function() {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', intercomSettings);
    } else {
      var d = document;
      var i = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;
      function l() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + APP_ID;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}

export function getOpenCheckpointCount(ballot) {
  if (!ballot) return 0;

  const { checkpoints, votes, activeCheckpoint } = ballot;
  if (!Array.isArray(checkpoints)) {
    return 0;
  }
  let count = 0;

  if (!votes) {
    return checkpoints.length;
  }

  checkpoints.forEach(chk => {
    if (
      (!votes[chk.id] || !votes[chk.id].closed) &&
      activeCheckpoint != chk.id
    ) {
      // exclude the active checkpoint
      count++;
    }
  });
  return count;
}

export function getChkTypes(checkpoints) {
  if (!Array.isArray(checkpoints)) {
    return [];
  }
  const dic = {};
  checkpoints.forEach(chk => {
    if (chk.type) {
      dic[chk.type] = true;
    }
  });
  return Object.keys(dic);
}

export function getCustomChkDic(list) {
  // { type: description }
  const result = {};
  if (!list) {
    return result;
  }
  for (let key in list) {
    if (list[key].name) {
      result[list[key].name] = list[key];
    }
  }
  return result;
}

export function getRandomId(len) {
  return randomstring.generate({
    length: len,
    charset: 'ABCDEFGHIJKLMNPQRSTUVWXYZ1234567890',
  });
}
