import store from 'store';
import html from './terms.html';

export default {
  template: html,

  controller($ngRedux) {
    `ngInject`;

    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      scrollToTop,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.scrollToTop();
      $ctrl.name = 'Terms';
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if (store.get('user')) {
        $ctrl.isLoggedIn = true;
      } else {
        $ctrl.isLoggedIn = false;
      }
    }

    function mapState(state) {
      return {
        props: state.user.data,
      };
    }

    function mapDispatch() {
      return {
        //landing,
      };
    }

    function scrollToTop() {
      const divLogo = document.getElementById('landing-logo');
      divLogo.scrollIntoView(true);
    }
  },
};
