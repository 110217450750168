export default {
  bindings: {
    members: '<',
  },

  template: `
    <div class="p-4 pb-0" style="flex: 1;">
      <div class="row">
        <div class="col-sm-4 col-md-3 col-lg-2 mb-2" ng-repeat="(key, item) in $ctrl.members">
          <div class="award d-flex mb-3">  
            <img class="avatar" ng-if="item.photoURL" ng-src="{{item.photoURL}}" />
            <div class="" ng-if="!item.photoURL"><i class="fa fa-user" aria-hidden="true"></i></div>
            {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
        `,
};
