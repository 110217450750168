import html from './edit.html';
import {
  changeCategories,
  fetchGroup,
  updateGroup,
  addGroupPriority,
} from 'logic/group';
import store from 'store';
import { priorityColors } from 'constants/priorities';

export default {
  bindings: {
    view: '@',
  },

  template: html,

  controller($ngRedux, $routeParams, $location, $rootScope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $doCheck,
      $onDestroy,
      mapState,
      mapDispatch,
      onCancel,
      onUpdatePriority,
      onEditPriority,
      onRemovePriority,
      arrayToString,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);

      $ctrl.group_name = $routeParams.name;
      $ctrl.fetchGroup($ctrl.group_name);
    }

    function $doCheck() {
      if ($ctrl.updatingPriority && $ctrl.success && !$ctrl.error) {
        $ctrl.updatingPriority = false;
        $('#priority-edit-modal').modal('hide');
      }

      if ($ctrl.data.name) {
        $rootScope.title = `Init - Group: ${$ctrl.data.name}`;
      }

      const userId = store.get('user');
      if (
        $ctrl.data &&
        $ctrl.data.creator &&
        $ctrl.data.creator.id !== userId
      ) {
        $location.path('/groups');
      }

      if ($ctrl.updateSuccess) {
        $location.path(`/groups/${$ctrl.group_name}`);
      }
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      return {
        ...state.group,
      };
    }

    function mapDispatch() {
      return {
        fetchGroup,
        updateGroup,
        addGroupPriority,
        changeCategories,
      };
    }

    function onCancel() {
      $location.path('/groups');
    }

    function onEditPriority(isCreate, priority, id) {
      $ctrl.priorityModalTitle = isCreate ? 'Create' : 'Update';
      if (priority) {
        $ctrl.priorityUpdate = priority;
      } else {
        $ctrl.priorityUpdate = { color: priorityColors[0].value };
      }
      $ctrl.priorityIDUpdate = id;

      $('#priority-edit-modal').modal('show');
      $('#priority-edit-modal').appendTo('body');
    }

    function onUpdatePriority(priority) {
      if ($ctrl.priorityModalTitle === 'Create') {
        $ctrl.updatingPriority = true;
        if ($ctrl.data.priorities) {
          for (const [, value] of Object.entries($ctrl.data.priorities)) {
            if (value.title === priority.title) {
              $ctrl.updatingPriority = false;
              $ctrl.errorPriority = 'The title already exists.';
              return;
            }
          }
        }

        $ctrl.addGroupPriority(priority);
      } else {
        $ctrl.data.priorities[$ctrl.priorityIDUpdate] = priority;
        $('#priority-edit-modal').modal('hide');
      }
    }

    function onRemovePriority(id) {
      delete $ctrl.data.priorities[id];
    }

    function arrayToString(data) {
      if (!data) {
        return '';
      }
      if (!Array.isArray(data)) {
        return data;
      }
      return data.join(',');
    }
  },
};
