export default {
  transclude: true,

  bindings: {
    type: '@',
    name: '<',
    active: '<',
    small: '<',
  },

  template: `
    <div class="award d-flex mb-3">
      <i
        class="fa fa-3x text-gray-lighter mr-2"
        ng-class="{
          'fa-star': $ctrl.type === 'badge',
          'fa-check-circle': $ctrl.type === 'mobilisation',
          'text-warning': $ctrl.active && $ctrl.type === 'badge',
          'text-success': $ctrl.active && $ctrl.type === 'mobilisation'
        }">
      </i>
      <div class="">
        <strong ng-class="{ 'small': $ctrl.small }">{{ $ctrl.name }}</strong><br>
        <ng-transclude/>
      </div>
    </div>`,
};
