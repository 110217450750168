export default {
  bindings: {
    checkpoints: '<',
    checkpointsAll: '<',
    checkin: '<',
    completion: '<',
    timespan: '<',
    rags: '<',
    type: '<',
  },

  template: `
    <div class="initiatives-list-checkpoint">
      <div class="row mt-1">
        <div
          class="col-6 col-md-3"
          ng-repeat="(type, checkpoints) in $ctrl.checkpoints"
          ng-if="type === 'team'">
          <div ng-if="checkpoints.length > 0">
            <h6 class="mb-3 mb-md-4">{{ type | capitalize }}</h6>
            <div class="list-dots">
              <span
                class="list-dot"
                title="{{ $ctrl.checkpointsAll[checkpoint.id].name }}"
                data-original-title="{{ $ctrl.checkpointsAll[checkpoint.id].name }}"
                data-toggle="tooltip"
                data-placement="top"
                data-animation="false"
                ng-repeat="checkpoint in checkpoints">
                <checkpoint
                  class-names="list-inline-item"
                  state="$ctrl.rags[$ctrl.checkin][checkpoint.id]"
                >
                </checkpoint>
              </span>
            </div>
          </div>
        </div>
        
        <div
          class="col-6 col-md-3"
          ng-repeat="(type, checkpoints) in $ctrl.checkpoints"
          ng-if="$ctrl.type !== 'lite' && type === 'service'">
          <div ng-if="checkpoints.length > 0">
            <h6 class="mb-3 mb-md-4">{{ type | capitalize }}</h6>
            <div class="list-dots">
              <span
                class="list-dot"
                title="{{ $ctrl.checkpointsAll[checkpoint.id].name }}"
                data-original-title="{{ $ctrl.checkpointsAll[checkpoint.id].name }}"
                data-toggle="tooltip"
                data-placement="top"
                data-animation="false"
                ng-repeat="checkpoint in checkpoints">
                <checkpoint
                  class-names="list-inline-item"
                  state="$ctrl.rags[$ctrl.checkin][checkpoint.id]"
                >
                </checkpoint>
              </span>
            </div>
          </div>
        </div>
        
        <div class="col-md-3 completion" ng-if="$ctrl.type === 'project'">
          <h6>Timespan</h6>
          <div class="row d-flex align-items-center mb-3 mb-md-0">
            <p class="completion-stat display-4 col-4 col-md-12">
              {{ $ctrl.timespan.percent }}%
            </p>
            <div class="completion-progress col-8 col-md-12">
              <p class="mb-2 mb-md-3">
                <progress-bar value="$ctrl.timespan.percent">
                </progress-bar>
              </p>
              <p class="mb-0 mb-md-3">
                {{ $ctrl.timespan.complete }}
                of
                {{ $ctrl.timespan.days }}
                days
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3 completion" ng-if="$ctrl.type === 'project' && $ctrl.completion.remaining > 0">
          <h6>Completion</h6>
          <div class="row d-flex align-items-center">
            <p class="completion-stat display-4 col-4 col-md-12">
              {{ $ctrl.completion.percent }}%
            </p>
            <div class="completion-progress col-8 col-md-12">
              <p class="mb-2 mb-md-3">
                <progress-bar value="$ctrl.completion.percent">
                </progress-bar>
              </p>
              <p class="mb-0 mb-md-3">
                {{ $ctrl.completion.remaining }} days left
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>`,
};
