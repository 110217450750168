import { createLogic } from 'redux-logic';
import { omit, pickBy, isEmpty } from 'lodash';
import axios from 'axios';
import qs from 'qs';
import * as firebase from 'firebase';
import store from 'store';

import { LOGOUT } from './user';
import { queryLocalInitiatives } from '../utils';

import { DELETE_SUCCESS } from './initiative';
import { LOGOUT_SUCCESS } from './user';
// Action Types
export const FETCH = 'app/initiatives/FETCH';
export const SUCCESS = 'app/initiatives/SUCCESS';
export const ERROR = 'app/initiatives/ERROR';
export const CANCEL = 'app/initiatives/CANCEL';
export const WATCH_INITIATIVES = 'app/initiatives/WATCH';
export const INITIATIVE_ADDED = 'app/initiatives/ADDED';
export const INITIATIVE_CHANGED = 'app/initiatives/CHANGED';
export const INITIATIVE_REMOVED = 'app/initiatives/REMOVED';
export const LOCAL_SEARCH_SUCCESS = 'app/initiatives/LOCAL_SEARCH_SUCCESS';
export const STAR_INITIATIVES = 'app/initiatives/STAR_INITIATIVES';
export const UPDATE_SUCCESS = 'app/initiatives/UPDATE_SUCCESS';

// TODO: exported from ballot
export const OPEN_VOTING = 'app/ballot/OPEN_VOTING';

import getFirebaseConfig from '../config/firebase';

const firebaseProjectId = getFirebaseConfig().projectId;

// Initial State
export const initialState = {
  data: [],
  dic: store.get('initiatives'), // initiative dic for instant search
  loading: false,
  error: false,
};

// Actions
export function fetchInitiatives(
  payload = { status: '', q: '', sort: '', group: undefined }
) {
  return { type: FETCH, payload };
}

export function cancelInitiatives() {
  return { type: CANCEL };
}

export function watchInitiatives() {
  return {
    type: WATCH_INITIATIVES,
  };
}

export function starInitiatives(id, isStared) {
  return { type: STAR_INITIATIVES, payload: { id, isStared } };
}

export const watchInitiativesLogic = createLogic({
  type: WATCH_INITIATIVES,
  warnTimeout: 0,
  process({ getState }, dispatch, done) {
    firebase
      .database()
      .ref(`initiatives`) //      .limitToLast(1)
      .on('child_added', snapshot => {
        const initiative = snapshot.val();
        if (initiative && !Array.isArray(initiative.links)) {
          initiative.links = [];
        }
        dispatch({
          type: INITIATIVE_ADDED,
          payload: {
            data: { ...initiative, id: snapshot.key },
          },
        });
      });
    firebase
      .database()
      .ref(`initiatives`)
      .on('child_changed', snapshot => {
        const initiative = snapshot.val();
        if (initiative && !Array.isArray(initiative.links)) {
          initiative.links = [];
        }
        dispatch({
          type: INITIATIVE_CHANGED,
          payload: {
            data: { ...initiative, id: snapshot.key },
          },
        });
      });
    firebase
      .database()
      .ref(`initiatives`)
      .on('child_removed', snapshot => {
        const initiative = snapshot.val();
        if (initiative && !Array.isArray(initiative.links)) {
          initiative.links = [];
        }
        dispatch({
          type: INITIATIVE_REMOVED,
          payload: {
            data: { ...initiative, id: snapshot.key },
          },
        });
      });
  },
});

// Observable Logic
export const initiativesFetch = createLogic({
  latest: true,
  type: FETCH,
  cancelType: CANCEL,
  processOptions: {
    successType: SUCCESS,
    failType: ERROR,
  },
  transform({ action }, next) {
    let payload = pickBy(omit(action.payload, 'sort'));
    if (action.payload.sort) {
      [payload._sort, payload._order] = action.payload.sort;
    }
    next({ ...action, payload });
  },
  process({ $http, action }) {
    // return $http.get('/initiatives', { params: action.payload });

    return axios({
      method: 'GET',
      url: `https://us-central1-${firebaseProjectId}.cloudfunctions.net/queryInitiatives?${qs.stringify(
        action.payload
      )}`,
      headers: {
        Authorization: store.get('Authorization'),
      },
      json: true,
    })
      .then(response => {
        return response.data;
      })
      .then(initiatives => {
        return {
          data: initiatives,
        };
      });
  },
});

export const initiativesStar = createLogic({
  type: STAR_INITIATIVES,
  cancelType: CANCEL,
  processOptions: {
    successType: SUCCESS,
    failType: ERROR,
  },
  process({ action, getState }) {
    const { id, isStared } = action.payload;
    const userId = getState().user.data.id;

    const initiatives = angular.copy(getState().initiatives.data);
    let initiative;
    for (let init of initiatives) {
      if (init.id === id) {
        initiative = init;
        break;
      }
    }

    let stars = initiative.stars;
    if (!stars) {
      stars = {};
    }
    if (isStared) {
      stars[userId] = true;
    } else {
      delete stars[userId];
    }

    return firebase
      .database()
      .ref(`initiatives/${id}`)
      .update({ stars })
      .then(() => {
        initiative.stars = stars;

        return {
          data: [...initiatives],
        };
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  let dic;
  switch (type) {
    case FETCH:
      return {
        ...state,
        data: queryLocalInitiatives(state.dic, payload),
        loading: true,
      };

    case SUCCESS:
      dic = getInitiativeDic(state, payload);
      store.set('initiatives', dic);
      return {
        ...state,
        ...payload,
        dic,
        loading: false,
      };

    case ERROR:
      return {
        ...state,
        ...payload,
        loading: false,
        error: true,
      };

    case INITIATIVE_CHANGED:
      if (Array.isArray(state.data)) {
        for (let i = 0; i < state.data.length; i++) {
          // Search the initiative with the same id
          if (state.data[i].id === action.payload.data.id) {
            state.data[i] = action.payload.data;
            return {
              ...state,
              dic: getInitiativeDic(state, payload),
            };
          }
        }
      }
      return state;

    case DELETE_SUCCESS:
    case INITIATIVE_REMOVED:
      if (Array.isArray(state.data)) {
        const data = state.data;

        return {
          ...state,
          data: data.filter(
            initiative => initiative.id !== action.payload.data.id
          ),
          dic: getInitiativeDic(state, payload, true),
        };
      }
      return state;

    case INITIATIVE_ADDED:
      if (Array.isArray(state.data)) {
        // See if it exists
        if (
          state.data.findIndex(
            initiative => initiative.id === action.payload.data.id
          ) >= -1
        ) {
          return state;
        }
        const data = [...state.data, action.payload.data];
        return { ...state, data };
      } else {
        return { ...state, data: [action.payload] };
      }
      return state;

    case LOGOUT_SUCCESS:
      return {
        ...state,
        data: [],
        dic: {},
      };

    default:
      return state;
  }
}

// update the previous initiative dic
function getInitiativeDic(state, payload, isRemoved = false) {
  let { dic } = state;
  const { data } = payload;

  if (!dic) {
    dic = {};
  }

  if (isRemoved) {
    if (Array.isArray(data)) {
      for (let initiative of data) {
        delete dic[initiative.id]; // register initiative
      }
    } else if (data && data.id) {
      delete dic[data.id];
    }
  } else {
    if (Array.isArray(data)) {
      for (let initiative of data) {
        dic[initiative.id] = initiative; // register initiative
      }
    } else if (data && data.id) {
      dic[data.id] = data;
    }
  }
  return { ...dic };
}

//
