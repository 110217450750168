export default {
  bindings: {
    class: '@',
    name: '@',
    vote: '<',
    closed: '<',
    ngChecked: '<',
    ngDisabled: '<',
    ngModel: '<',
    onClick: '&',
  },

  template: `
    <label class="custom-control custom-radio {{$ctrl.ngDisabled ? 'custom-control-disabled' : ''}}">
      <input
        type="radio"
        class="custom-control-input"
        id="{{ $ctrl.name }}"
        name="{{ $ctrl.name }}"
        ng-checked="$ctrl.ngChecked"
        ng-disabled="$ctrl.ngDisabled"
        ng-click="$ctrl.onClick()"
      />
      <span
        class="custom-control-indicator {{($ctrl.closed && $ctrl.ngChecked) ? 'custom-control-indicator-show' : ''}} {{ $ctrl.class }}"
      >
      </span>
      <div class="aggregated-result" ng-if="$ctrl.vote">
        <div >{{ $ctrl.vote.count }}</div>
        <div >{{ $ctrl.vote.percent }}%</div>
      </div>
    </label>`,
};
