import { changeTags, linkInitiative, unLinkInitiative } from 'logic/impediment';
import { fetchInitiatives, cancelInitiatives } from 'logic/initiatives';
import { each, find, isEmpty, map, some, pickBy, identity } from 'lodash';
import { fetchImpedimentTypes } from 'logic/constants/impediments';
import html from './form.html';
import './form.scss';

export default {
  bindings: {
    onSubmit: '&',
    onRemove: '&',
    onResolve: '&',
    onCancel: '&',
    name: '@',
    props: '<',
    creating: '<',
  },

  template: html,

  controller($ngRedux) {
    'ngInject';

    const $ctrl = {
      $onInit,
      $onDestroy,
      blur,
      mapState,
      mapDispatch,
      onClickInitiative,
      arrayToString,
    };

    return $ctrl;

    function $onInit() {
      if (!$ctrl.props) {
        $ctrl.props = {};
      }

      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);

      $ctrl.fetchInitiatives({});
      $ctrl.fetchImpedimentTypes();
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      let initiatives = state.initiatives.data;
      let linkedObjects = $ctrl.props.objects;

      if (!linkedObjects) {
        linkedObjects = [];
      }

      let numberOfLinked = 0;

      each(initiatives, (initiative, key) => {
        initiative.active = false;
        linkedObjects.forEach(item => {
          if (item.object.id === initiative.id && item.type === 'Initiative') {
            initiative.active = true;
            numberOfLinked++;
          }
        });
      });

      let missedInitatives = linkedObjects.length - numberOfLinked;

      return {
        initiatives,
        benefitTypes: state.impedimentTypes.data.benefits,
        impedimentTypes: state.impedimentTypes.data.types,
        loadingImpedimentTypes: state.impedimentTypes.loading,
        loadingInitiatives:
          state.initiatives.data.length > 0 ? false : state.initiatives.loading,
        updating: state.impediment.updating,
        removing: state.impediment.removing,
        resolving: state.impediment.resolving,
        createSuccess: state.impediment.createSuccess,
        fetchSuccess: state.impediment.fetchSuccess,
        updateSuccess: state.impediment.updateSuccess,
        loading: state.impediment.loading,
        missedInitatives,
      };
    }

    function mapDispatch() {
      return {
        changeTags,
        fetchInitiatives,
        unLinkInitiative,
        linkInitiative,
        fetchImpedimentTypes,
      };
    }

    function onClickInitiative(item) {
      if (item.active) {
        $ctrl.unLinkInitiative(item);
      } else {
        $ctrl.linkInitiative(item);
      }
    }

    function arrayToString(data) {
      if (!data) {
        return '';
      }
      if (!Array.isArray(data)) {
        return data;
      }
      return data.join(',');
    }
  },
};
