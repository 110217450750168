export const priorityColors = [
  {
    description: 'Red',
    value: 'red',
  },
  {
    description: 'Blue',
    value: 'blue',
  },
  {
    description: 'Green',
    value: 'green',
  },
];
