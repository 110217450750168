import { createLogic } from 'redux-logic';
import { find } from 'lodash';
import * as firebase from 'firebase';
import store from 'store';

import { joinBallot } from './ballot';

import { TOKEN_REFRESH_TIME } from '../config/consts';

// Action Types
export const LOGIN = 'app/user/LOGIN';
export const LOGIN_SUCCESS = 'app/user/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/user/LOGIN_ERROR';

export const LOGIN_WITH_GOOGLE = 'app/user/LOGIN_WITH_GOOGLE';
export const LOGIN_WITH_GOOGLE_SUCCESS = 'app/user/LOGIN_WITH_GOOGLE_SUCCESS';
export const LOGIN_WITH_GOOGLE_ERROR = 'app/user/LOGIN_WITH_GOOGLE_ERROR';

export const LOGIN_WITH_GITHUB = 'app/user/LOGIN_WITH_GITHUB';
export const LOGIN_WITH_GITHUB_SUCCESS = 'app/user/LOGIN_WITH_GITHUB_SUCCESS';
export const LOGIN_WITH_GITHUB_ERROR = 'app/user/LOGIN_WITH_GITHUB_ERROR';

export const LOGIN_WITH_FACEBOOK = 'app/user/LOGIN_WITH_FACEBOOK';
export const LOGIN_WITH_FACEBOOK_SUCCESS =
  'app/user/LOGIN_WITH_FACEBOOK_SUCCESS';
export const LOGIN_WITH_FACEBOOK_ERROR = 'app/user/LOGIN_WITH_FACEBOOK_ERROR';

export const LOGOUT = 'app/user/LOGOUT';
export const LOGOUT_SUCCESS = 'app/user/LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'app/user/LOGOUT_FAILED';

export const FETCH = 'app/user/FETCH';
export const FETCH_SUCCESS = 'app/user/FETCH_SUCCESS';
export const FETCH_ERROR = 'app/user/FETCH_ERROR';

export const CANCEL = 'app/user/CANCEL';

export const FORGOT_PASSWORD = 'app/user/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'app/user/FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_SUCCESS = 'app/user/FORGOT_PASSWORD_SUCCESS';

export const CONFIRM_CODE = 'app/user/CONFIRM_CODE';
export const CONFIRM_CODE_ERROR = 'app/user/CONFIRM_CODE_ERROR';
export const CONFIRM_CODE_SUCCESS = 'app/user/CONFIRM_CODE_SUCCESS';

export const SIGN_UP = 'app/user/SIGN_UP';
export const SIGN_UP_ERROR = 'app/user/SIGN_UP_ERROR';
export const SIGN_UP_SUCCESS = 'app/user/SIGN_UP_SUCCESS';

export const INIT_RECENT_ACTIVITIES_COUNT =
  'app/user/INIT_RECENT_ACTIVITIES_COUNT';

export const UPDATE_PROFILE = 'app/user/UPDATE_PROFILE';

export const UPDATE_PROFILE_SUCCESS = 'app/user/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/user/UPDATE_PROFILE_ERROR';

export const SET_TEAM_NAME = 'app/user/SET_TEAM_NAME';

export const UPDATE_ANONYMOUS_DATA = 'app/user/UPDATE_ANONYMOUS_DATA';

// Initial State
export const initialState = {
  loading: false,
  error: false,
  fetchError: false,
  data: {
    id: null,
    name: '',
  },
  userPhoto: null,
  forgotEmail: null,
  forgotError: null,
  forgotLoading: false,
  forgotSuccess: false,
  confirmError: null,
  confirmLoading: false,
  confirmSuccess: false,
  signupError: null,
  signupLoading: false,
  signupSuccess: false,
  authChecked: false,
  pendingCred: null,
  updateProfileSuccess: false,
  teamName: null, // Used to make a lite initiative
};

export const setTeamName = teamName => {
  return {
    type: SET_TEAM_NAME,
    payload: {
      teamName,
    },
  };
};

// Handle the API response
const handleResponse = response => {
  try {
    const user = response.data[0];
    delete user.password;
    return user;
  } catch (e) {}
};

// Actions

export function signUp(email, password, name) {
  return {
    type: SIGN_UP,
    payload: {
      email,
      password,
      name,
    },
  };
}

export function forgotPassword(email) {
  return {
    type: FORGOT_PASSWORD,
    payload: {
      email,
    },
  };
}

export function submitConfirmCode(code, password) {
  return {
    type: CONFIRM_CODE,
    payload: {
      code,
      password,
    },
  };
}

export function updateProfile(userPhoto) {
  return { type: UPDATE_PROFILE, payload: { userPhoto } };
}

export function login(username, password) {
  return { type: LOGIN, payload: { username, password } };
}

export function loginWithGoogle() {
  return { type: LOGIN_WITH_GOOGLE };
}

export function loginWithGitHub() {
  return { type: LOGIN_WITH_GITHUB };
}

export function loginWithFacebook() {
  return { type: LOGIN_WITH_FACEBOOK };
}

export function logout() {
  return { type: LOGOUT };
}

export function fetchUser(id) {
  return { type: FETCH, payload: { id } };
}

export function updateAnonymousUserData(payload) {
  return { type: UPDATE_ANONYMOUS_DATA, payload };
}

export const updateAnonymousUserDataLogic = createLogic({
  latest: true,
  type: UPDATE_ANONYMOUS_DATA,
  cancelType: CANCEL,
  validate({ action }, allow, reject) {
    allow(action);
  },
  process({ $http, action, getState }, dispatch, done) {
    const { currentUser } = firebase.auth();
    const { data } = getState().ballot;

    firebase
      .database()
      .ref('/users')
      .child(currentUser.uid)
      .update(action.payload, err => {
        if (data.id) {
          dispatch(joinBallot(data.id));
        }
        if (err) {
          console.error(err);
        } else {
          done();
        }
      });
  },
});

// Observable Logic
export const userLogin = createLogic({
  latest: true,
  type: LOGIN,
  cancelType: CANCEL,
  validate({ action }, allow, reject) {
    const { username, password } = action.payload;

    if (username && password) {
      allow(action);
    } else {
      reject({
        type: LOGIN_ERROR,
        payload: { message: 'Email or Password is missing!' },
      });
    }
  },
  process({ $http, action, getState }, dispatch, done) {
    const email = action.payload.username;
    const { password } = action.payload;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(firebaseUser => {
        console.info('Firebase Login Successful!');
        store.set('user', firebaseUser.uid);
        const user = {
          name: firebaseUser.displayName,
          username: firebaseUser.email,
          email: firebaseUser.email,
          id: firebaseUser.uid,
        };
        const state = getState().user;
        // check if another social account is waiting to be linked
        if (state.pendingCred) {
          firebaseUser
            .linkAndRetrieveDataWithCredential(state.pendingCred)
            .then(() => {
              dispatch({ type: LOGIN_SUCCESS, payload: user });
              done();
            })
            .catch(e => {
              Raven.captureExceptionEx(e);
              dispatch({
                type: LOGIN_ERROR,
                payload: {
                  message: 'Mismatched accounts!',
                },
              });
              done();
            });
        } else {
          dispatch({ type: LOGIN_SUCCESS, payload: user });
          done();
        }
      })
      .catch(signInError => {
        Raven.captureExceptionEx(signInError);
        const { code, message } = signInError;
        dispatch({ type: LOGIN_ERROR, payload: { message } });
        done();
      });
  },
});

// Observable Logic for Google Authentication
export const loginWithGoogleLogic = createLogic({
  latest: true,
  type: LOGIN_WITH_GOOGLE,
  cancelType: CANCEL,
  warnTimeout: 0,
  process({ getState }, dispatch, done) {
    console.info('Google Signin');
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(cred => {
        const firebaseUser = cred.user;
        // This gives you a Google Access Token. You can use it to access the Google API.
        console.info('Firebase Login Successful!');
        store.set('user', firebaseUser.uid);

        const user = {
          name: firebaseUser.displayName,
          username: firebaseUser.email,
          email: firebaseUser.email,
          id: firebaseUser.id,
        };
        const state = getState().user;
        // check if another social account is waiting to be linked
        if (state.pendingCred) {
          firebaseUser
            .linkAndRetrieveDataWithCredential(state.pendingCred)
            .then(data => {
              dispatch({ type: LOGIN_WITH_GOOGLE_SUCCESS, payload: data.user });
              done();
            })
            .catch(e => {
              Raven.captureExceptionEx(e);
              dispatch({
                type: LOGIN_WITH_GOOGLE_ERROR,
                payload: {
                  message: 'Mismatched accounts!',
                },
              });
              done();
            });
        } else {
          dispatch({ type: LOGIN_SUCCESS, payload: user });
          done();
        }
      })
      .catch(error => {
        Raven.captureExceptionEx(error);

        console.info('Login With Google Error:', error);
        // existing different credentials
        if (error.code === 'auth/account-exists-with-different-credential') {
          const pendingCred = error.credential;
          // The provider account's email address.
          const email = error.email;
          firebase
            .auth()
            .fetchProvidersForEmail(email)
            .then(providers => {
              if (providers[0] === 'password') {
                dispatch({
                  type: LOGIN_WITH_GOOGLE_ERROR,
                  payload: {
                    message: `You have an existing account with a password. Sign-in now with ${email} and your password to continue.`,
                    pendingCred,
                  },
                });
                done();
              } else if (providers[0] === 'github.com') {
                dispatch({
                  type: LOGIN_WITH_GOOGLE_ERROR,
                  payload: {
                    message: `You have an existing account linked to GitHub. Sign-in now with your GitHub account (${email}) to continue.`,
                    pendingCred,
                  },
                });
                done();
              } else if (providers[0] === 'facebook.com') {
                dispatch({
                  type: LOGIN_WITH_GOOGLE_ERROR,
                  payload: {
                    message: `You have an existing account linked to Facebook. Sign-in now with your Facebook account (${email}) to continue.`,
                    pendingCred,
                  },
                });
                done();
              } else {
                dispatch({
                  type: LOGIN_WITH_GOOGLE_ERROR,
                  payload: {
                    message:
                      'Failed to login with Google, something unexpected happened. Sorry.',
                  },
                });
                done();
              }
            });
        } else {
          dispatch({
            type: LOGIN_WITH_GOOGLE_ERROR,
            payload: {
              message: 'Failed to login with Google!',
            },
          });
          done();
        }
      });
  },
});

// Observable Logic for Google Authentication
export const loginWithGitHubLogic = createLogic({
  latest: true,
  type: LOGIN_WITH_GITHUB,
  cancelType: CANCEL,
  /*  processOptions: {
    successType: LOGIN_WITH_GITHUB_SUCCESS,
    failType: LOGIN_WITH_GITHUB_ERROR,
  },*/
  process({ getState }, dispatch, done) {
    console.info('GitHub Signin');
    const provider = new firebase.auth.GithubAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(cred => {
        const firebaseUser = cred.user;
        // This gives you a Google Access Token. You can use it to access the Google API.
        console.info('Firebase Login Successful!');
        store.set('user', firebaseUser.uid);

        const user = {
          name: firebaseUser.displayName,
          username: firebaseUser.email,
          email: firebaseUser.email,
          id: firebaseUser.id,
        };
        const state = getState().user;
        // check if another social account is waiting to be linked
        if (state.pendingCred) {
          firebaseUser
            .linkAndRetrieveDataWithCredential(state.pendingCred)
            .then(data => {
              dispatch({ type: LOGIN_WITH_GITHUB_SUCCESS, payload: data.user });
              done();
            })
            .catch(e => {
              Raven.captureExceptionEx(e);
              dispatch({
                type: LOGIN_WITH_GITHUB_ERROR,
                payload: {
                  message: 'Mismatched accounts!',
                },
              });
              done();
            });
        } else {
          dispatch({ type: LOGIN_WITH_GITHUB_SUCCESS, payload: user });
          done();
        }
      })
      .catch(error => {
        Raven.captureExceptionEx(error);
        // existing different credentials
        if (error.code === 'auth/account-exists-with-different-credential') {
          const pendingCred = error.credential;
          // The provider account's email address.
          const email = error.email;
          firebase
            .auth()
            .fetchProvidersForEmail(email)
            .then(providers => {
              if (providers[0] === 'password') {
                dispatch({
                  type: LOGIN_WITH_GITHUB_ERROR,
                  payload: {
                    message: `You have an existing account with a password. Sign-in now with ${email} and your password to continue.`,
                    pendingCred,
                  },
                });
                done();
              } else if (providers[0] === 'google.com') {
                dispatch({
                  type: LOGIN_WITH_GITHUB_ERROR,
                  payload: {
                    message: `You have an existing account linked to Google. Sign-in now with your Google account (${email}) to continue.`,
                    pendingCred,
                  },
                });
                done();
              } else if (providers[0] === 'facebook.com') {
                dispatch({
                  type: LOGIN_WITH_GITHUB_ERROR,
                  payload: {
                    message: `You have an existing account linked to Facebook. Sign-in now with your Facebook account (${email}) to continue.`,
                    pendingCred,
                  },
                });
                done();
              } else {
                dispatch({
                  type: LOGIN_WITH_GITHUB_ERROR,
                  payload: {
                    message:
                      'Failed to login with GitHub, something unexpected happened. Sorry.',
                  },
                });
                done();
              }
            });
        } else {
          dispatch({
            type: LOGIN_WITH_GITHUB_ERROR,
            payload: {
              message: 'Failed to login with GitHub!',
            },
          });
          done();
        }
      });
  },
});

// Observable Logic for Google Authentication
export const loginWithFacebookLogic = createLogic({
  latest: true,
  type: LOGIN_WITH_FACEBOOK,
  cancelType: CANCEL,
  process({ getState }, dispatch, done) {
    console.info('Facebook Signin');
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(cred => {
        const firebaseUser = cred.user;
        // This gives you a Google Access Token. You can use it to access the Google API.
        console.info('Firebase Login Successful!');
        store.set('user', firebaseUser.uid);

        const user = {
          name: firebaseUser.displayName,
          username: firebaseUser.email,
          email: firebaseUser.email,
          id: firebaseUser.id,
        };
        const state = getState().user;
        // check if another social account is waiting to be linked
        if (state.pendingCred) {
          firebaseUser
            .linkAndRetrieveDataWithCredential(state.pendingCred)
            .then(data => {
              dispatch({
                type: LOGIN_WITH_FACEBOOK_SUCCESS,
                payload: data.user,
              });
              done();
            })
            .catch(error => {
              Raven.captureExceptionEx(error);
              dispatch({
                type: LOGIN_WITH_FACEBOOK_ERROR,
                payload: {
                  message: 'Mismatched accounts!',
                },
              });
              done();
            });
        } else {
          dispatch({ type: LOGIN_WITH_FACEBOOK_SUCCESS, payload: user });
          done();
        }
      })
      .catch(error => {
        Raven.captureExceptionEx(error);
        // existing different credentials
        if (error.code === 'auth/account-exists-with-different-credential') {
          const pendingCred = error.credential;
          // The provider account's email address.
          const email = error.email;
          firebase
            .auth()
            .fetchProvidersForEmail(email)
            .then(providers => {
              if (providers[0] === 'password') {
                dispatch({
                  type: LOGIN_WITH_FACEBOOK_ERROR,
                  payload: {
                    message: `You have an existing account with a password. Sign-in now with ${email} and your password to continue.`,
                    pendingCred,
                  },
                });
                done();
              } else if (providers[0] === 'google.com') {
                dispatch({
                  type: LOGIN_WITH_FACEBOOK_ERROR,
                  payload: {
                    message: `You have an existing account linked to Google. Sign-in now with your Google account (${email}) to continue.`,
                    pendingCred,
                  },
                });
                done();
              } else if (providers[0] === 'github.com') {
                dispatch({
                  type: LOGIN_WITH_FACEBOOK_ERROR,
                  payload: {
                    message: `You have an existing account linked to GitHub. Sign-in now with your GitHub account (${email}) to continue.`,
                    pendingCred,
                  },
                });
                done();
              } else {
                dispatch({
                  type: LOGIN_WITH_FACEBOOK_ERROR,
                  payload: {
                    message:
                      'Failed to login with Facebook, something unexpected happened. Sorry.',
                  },
                });
                done();
              }
            });
        } else {
          dispatch({
            type: LOGIN_WITH_FACEBOOK_ERROR,
            payload: {
              message: 'Failed to login with Facebook!',
            },
          });
          done();
        }
      });
  },
});

export const userLogout = createLogic({
  type: LOGOUT,
  cancelType: CANCEL,
  warnTimeout: 0,
  /*  processOptions: {
    successType: LOGOUT_SUCCESS,
  },*/
  process({}, dispatch, done) {
    store.set('user', null);
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            // Sign-out successful.
          });
          done();
        },
        error => {
          // Rarely happens
          console.info('Failed to sign out!', error);
          dispatch({
            type: LOGOUT_FAILED,
          });
          done();
          // An error happened.
        }
      );
    return initialState.data;
  },
});

export const userFetch = createLogic({
  latest: true,
  type: FETCH,
  cancelType: CANCEL,
  warnTimeout: 0,
  process({ $http, action }, dispatch, done) {
    firebase
      .database()
      .ref(`users/${action.payload.id}`)
      .on('value', snapshot => {
        if (snapshot.exists()) {
          const user = snapshot.val();
          user.id = action.payload.id;

          dispatch({ type: FETCH_SUCCESS, payload: user });
        } else {
          dispatch({ type: FETCH_ERROR });
        }
        done();
      });
  },
});

export const forgotPasswordLogic = createLogic({
  latest: true,
  type: FORGOT_PASSWORD,
  cancelType: CANCEL,
  process({ action }, dispatch, done) {
    firebase
      .auth()
      .sendPasswordResetEmail(action.payload.email)
      .then(() => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
        });
        done();
      })
      .catch(error => {
        Raven.captureExceptionEx(error);
        let errMsg;
        if (error && error.code === 'auth/invalid-email') {
          errMsg = 'Invalid Email';
        } else if (error && error.code === 'auth/user-not-found') {
          errMsg = 'No user with the email found!';
        } else {
          errMsg = 'Unexpected error occurred!';
        }

        dispatch({
          type: FORGOT_PASSWORD_ERROR,
          payload: {
            error: errMsg,
          },
        });
        done();
      });
  },
});

export const confirmCodeLogic = createLogic({
  latest: true,
  type: CONFIRM_CODE,
  cancelType: CANCEL,
  process({ action }, dispatch, done) {
    firebase
      .auth()
      .confirmPasswordReset(action.payload.code, action.payload.password)
      .then(() => {
        dispatch({
          type: CONFIRM_CODE_SUCCESS,
        });
        done();
      })
      .catch(error => {
        Raven.captureExceptionEx(error);
        let errMsg;
        if (error && error.code === 'auth/expired-action-code') {
          errMsg = 'Expired confirmation code!';
        } else if (error && error.code === 'auth/invalid-action-code') {
          errMsg = 'Invalid confirmation code!';
        } else if (error && error.code === 'auth/user-disabled') {
          errMsg = 'Failed to reset the password because the user is disabled!';
        } else if (error && error.code === 'auth/user-not-found') {
          errMsg = 'User not found!';
        } else if (error && error.code === 'auth/weak-password') {
          errMsg = 'Weak password!';
        } else {
          errMsg = 'Unexpected error occurred!';
        }

        dispatch({
          type: CONFIRM_CODE_ERROR,
          payload: {
            error: errMsg,
          },
        });
        done();
      });
  },
});

export const signUpLogic = createLogic({
  type: SIGN_UP,
  cancelType: CANCEL,
  process({ action, getState }, dispatch, done) {
    const user = getState().user.data;
    const { email, password } = action.payload;
    const { isAnonymous } = user;
    let signUpPromise;
    if (isAnonymous) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password
      );
      signUpPromise = firebase
        .auth()
        .currentUser.linkWithCredential(credential);
    } else {
      signUpPromise = firebase
        .auth()
        .createUserWithEmailAndPassword(
          action.payload.email,
          action.payload.password
        );
    }

    signUpPromise
      .then(firebaseUser => {
        const user = {
          name: action.payload.name
            ? action.payload.name
            : action.payload.email,
          username: firebaseUser.email,
          email: firebaseUser.email,
          id: firebaseUser.uid,
        };

        store.set('prevLoginDisplayName', user.name);

        // update the display name - no name ? use his email as the displayName
        firebaseUser.updateProfile({
          displayName: action.payload.name
            ? action.payload.name
            : firebaseUser.email,
        });

        firebase
          .database()
          .ref('users')
          .child(firebaseUser.uid)
          .set({ name: action.payload.name, email: action.payload.email });

        // Anonymous user should refresh the token manually
        if (isAnonymous) {
          debugger;
          firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then(idToken => {
              console.info('Auth Token:', idToken);
              store.set('Authorization', idToken);

              dispatch({ type: LOGIN_SUCCESS, payload: user });
              dispatch({
                type: SIGN_UP_SUCCESS,
              });
              if (window.tokenTimer) {
                clearInterval(tokenTimer);
              }
              // Should register the token timer
              window.tokenTimer = setInterval(() => {
                firebase
                  .auth()
                  .currentUser.getIdToken(true)
                  .then(refresthedToken => {
                    if (store.get('Authorization')) {
                      // valid auth token exists
                      console.info('Token Refreshed:', refresthedToken);
                      store.set('Authorization', refresthedToken);
                    }
                  });
              }, TOKEN_REFRESH_TIME);
              done();
            });
        } else {
          dispatch({ type: LOGIN_SUCCESS, payload: user });
          dispatch({
            type: SIGN_UP_SUCCESS,
          });
          done();
        }
      })
      .catch(error => {
        Raven.captureExceptionEx(error);
        let errMsg;
        if (error && error.code === 'auth/email-already-in-use') {
          errMsg = 'This email is already used!';
        } else if (error && error.code === 'auth/invalid-email') {
          errMsg = 'Invalid email address';
        } else if (error && error.code === 'auth/operation-not-allowed') {
          errMsg = 'Unable to sign up with email!';
        } else if (error && error.code === 'auth/weak-password') {
          errMsg =
            'Weak password! It should be at least 6 letters with a capital letter and a number.';
        } else {
          errMsg = 'Unexpected error occurred!';
        }

        dispatch({
          type: SIGN_UP_ERROR,
          payload: {
            error: errMsg,
          },
        });
        done();
      });
  },
});

export const updateProfileLogic = createLogic({
  type: UPDATE_PROFILE,
  cancelType: CANCEL,
  process({ action, getState }, dispatch, done) {
    const { id, name } = getState().user.data;
    debugger;
    store.set('prevLoginDisplayName', name);

    const userPhoto = action.payload.userPhoto;
    if (userPhoto) {
      let uploadTask = firebase
        .storage()
        .ref(new Date().getTime().toString())
        .put(userPhoto);
      uploadTask.on(
        'state_changed',
        function(snapshot) {},
        function(error) {
          dispatch({
            type: UPDATE_PROFILE_ERROR,
          });
        },
        function() {
          var photoURL = uploadTask.snapshot.downloadURL;
          firebase
            .database()
            .ref('users/' + id)
            .update({ name, photoURL }, err => {
              if (err) {
                dispatch({
                  type: UPDATE_PROFILE_ERROR,
                });
                console.warn('Failed to update user profile:' + id);
              } else {
                let updatedUser = getState().user.data;
                updatedUser.photoURL = photoURL;
                dispatch({
                  type: UPDATE_PROFILE_SUCCESS,
                  payload: { data: updatedUser },
                });
              }
              done();
            });
        }
      );
    } else {
      firebase
        .database()
        .ref('users/' + id)
        .update({ name }, err => {
          if (err) {
            dispatch({
              type: UPDATE_PROFILE_ERROR,
            });
            console.warn('Failed to update user profile:' + id);
          } else {
            let updatedUser = getState().user.data;
            dispatch({
              type: UPDATE_PROFILE_SUCCESS,
              payload: { data: updatedUser },
            });
          }
          done();
        });
    }
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN:
    case LOGIN_WITH_GOOGLE:
    case LOGIN_WITH_GITHUB:
    case LOGIN_WITH_FACEBOOK:
    case FETCH:
      return {
        ...state,
        loading: true,
        message: null,
        error: false,
        fetchError: false,
        teamName: '',
      };
    case LOGOUT:
      store.set('initiatives', null);
      return {
        ...state,
        loading: true,
        message: null,
        error: false,
        fetchError: false,
        teamName: '',
      };

    case LOGIN_SUCCESS:
    case LOGIN_WITH_GOOGLE_SUCCESS:
    case LOGIN_WITH_GITHUB_SUCCESS:
    case LOGIN_WITH_FACEBOOK_SUCCESS:
    case FETCH_SUCCESS:
      return {
        ...state,
        data: { ...payload },
        error: false,
        fetchError: false,
        loading: false,
        pendingCred: null, // remove the pending credential,
        authChecked: true,
      };
    case UPDATE_ANONYMOUS_DATA:
      return {
        ...state,
        data: { ...state.data, ...payload },
        updateProfileSuccess: true,
      };
    case LOGOUT_SUCCESS: {
      return { ...initialState, authChecked: true };
    }

    case LOGIN_ERROR:
    case LOGIN_WITH_GOOGLE_ERROR:
    case LOGIN_WITH_GITHUB_ERROR:
    case LOGIN_WITH_FACEBOOK_ERROR:
    case LOGOUT_FAILED:
      return {
        ...state,
        ...action.payload,
        error: true,
        loading: false,
      };

    case FETCH_ERROR:
      return {
        ...state,
        fetchError: true,
        loading: false,
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotEmail: action.payload.email,
        forgotError: '',
        forgotLoading: true,
        forgotSuccess: false,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotError: null,
        forgotLoading: false,
        forgotSuccess: true,
      };

    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotError: action.payload.error,
        forgotLoading: false,
        forgotSuccess: false,
      };

    case CONFIRM_CODE:
      return {
        ...state,
        confirmError: '',
        confirmLoading: true,
        confirmSuccess: false,
      };

    case CONFIRM_CODE_SUCCESS:
      return {
        ...state,
        confirmError: null,
        confirmLoading: false,
        confirmSuccess: true,
      };

    case CONFIRM_CODE_ERROR:
      return {
        ...state,
        confirmError: action.payload.error,
        confirmLoading: false,
        confirmSuccess: false,
      };

    case SIGN_UP:
      return {
        ...state,
        signupError: '',
        signupLoading: true,
        signupSuccess: false,
      };

    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signupError: null,
        signupLoading: false,
        signupSuccess: true,
      };

    case SIGN_UP_ERROR:
      return {
        ...state,
        signupError: action.payload.error,
        signupLoading: false,
        signupSuccess: false,
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        loading: true,
        updateProfileSuccess: false,
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        updateProfileSuccess: true,
      };

    case SET_TEAM_NAME:
      return {
        ...state,
        teamName: action.payload.teamName,
      };

    default:
      return state;
  }
}
