// Basic wrapper to implement the default bootstrap toggle elements.
// Currently only applies to tooltip
export default function($parse) {
  'ngInject';

  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var onChangeFunc = scope.$eval(attrs.onValueChange);
      element.bind('change', onChangeFunc);
    },
  };
}
