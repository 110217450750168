import store from 'store';

// Only enable debug info in development
export default function($compileProvider) {
  'ngInject';
  $compileProvider.debugInfoEnabled(
    process.env.NODE_ENV === 'development' ||
      store.get('debugInfoEnabled') === 'true'
  );
}
