import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';

import store from 'store';
import axios from 'axios';
import qs from 'qs';

import * as Utils from '../utils';

import getFirebaseConfig from '../config/firebase';
const firebaseProjectId = getFirebaseConfig().projectId;

//region Action Types
export const INIT = 'app/group/INIT';
export const ERROR = 'app/group/ERROR';
export const FETCH = 'app/group/FETCH';
export const FETCH_SUCCESS = 'app/group/SUCCESS';
export const FETCH_ERROR = 'app/group/FETCH_ERROR';
export const CANCEL = 'app/group/CANCEL';
export const ADD_GROUP_USER = 'app/group/ADD_GROUP_USER';
export const REMOVE_GROUP_USER = 'app/group/REMOVE_GROUP_USER';
export const ADD_GROUP_PRIORITY = 'app/group/ADD_GROUP_PRIORITY';
export const CHANGE_CATEGORIES = 'app/group/CHANGE_CATEGORIES';
export const CHANGE = 'app/group/CHANGE';
export const CREATE = 'app/group/CREATE';
export const REMOVE = 'app/group/REMOVE';
export const UPDATE = 'app/group/UPDATE';
export const CREATE_SUCCESS = 'app/group/CREATE_SUCCESS';
export const DELETE_SUCCESS = 'app/group/DELETE_SUCCESS';
export const UPDATE_SUCCESS = 'app/group/UPDATE_SUCCESS';
//endregion

//region Initial State
export const initialState = {
  loading: false,
  data: {},
};
//endregion

//region Actions
export function initGroup() {
  return { type: INIT };
}

export function fetchGroup(name) {
  return { type: FETCH, payload: { name } };
}

export function cancelGroup() {
  return { type: CANCEL };
}

export function addGroupUser(id) {
  return { type: ADD_GROUP_USER, payload: { userId: id } };
}

export function removeGroupUser(id) {
  return { type: REMOVE_GROUP_USER, payload: { userId: id } };
}

export function addGroupPriority(priority) {
  return { type: ADD_GROUP_PRIORITY, payload: priority };
}

export function changeGroup(payload) {
  return { type: CHANGE, payload };
}

export function changeCategories(payload) {
  return { type: CHANGE_CATEGORIES, payload };
}

export function updateGroup(payload) {
  return { type: UPDATE, payload };
}

export function createGroup(payload) {
  return { type: CREATE, payload };
}

export function removeGroup(payload) {
  return { type: REMOVE, payload };
}

//endregion

//region Observable Logic
export const groupFetch = createLogic({
  type: FETCH,
  cancelType: CANCEL,
  processOptions: {
    successType: FETCH_SUCCESS,
    failType: FETCH_ERROR,
  },
  process({ $http, action }) {
    return axios({
      method: 'POST',
      url: `https://us-central1-${firebaseProjectId}.cloudfunctions.net/getGroup`,
      headers: {
        Authorization: store.get('Authorization'),
      },
      data: action.payload,
      json: true,
    }).then(response => {
      response.data.group.categories = Utils.arrayFromString(
        response.data.group.categories
      );
      return { data: response.data.group };
    });
  },
});

export const updateGroupUserLogic = createLogic({
  type: [ADD_GROUP_USER, REMOVE_GROUP_USER],
  cancelType: CANCEL,
  process({ action, getState }, dispatch, done) {
    const groupID = getState().group.data.id;
    const groupName = getState().group.data.name;

    if (action.type === ADD_GROUP_USER) {
      const newUser = {};
      newUser[action.payload.userId] = true;

      return firebase
        .database()
        .ref(`groups/${groupID}/users`)
        .update(newUser)
        .then(() => {
          dispatch(fetchGroup(groupName));
          done();
        });
    } else if (action.type === REMOVE_GROUP_USER) {
      return firebase
        .database()
        .ref(`groups/${groupID}/users`)
        .child(action.payload.userId)
        .remove()
        .then(() => {
          dispatch(fetchGroup(groupName));
          done();
        });
    }
  },
});

export const updateGroupPriorityLogic = createLogic({
  type: [ADD_GROUP_PRIORITY],
  cancelType: CANCEL,
  process({ action, getState }, dispatch, done) {
    const groupID = getState().group.data.id;
    const groupName = getState().group.data.name;

    if (action.type === ADD_GROUP_PRIORITY) {
      const priority = action.payload;
      if (!getState().group.data.priorities) {
        getState().group.data.priorities = {};
      }

      const { priorities } = getState().group.data;

      const newPriorityKey = firebase
        .database()
        .ref(`groups/${groupID}`)
        .child('priorities')
        .push().key;

      priorities[newPriorityKey] = priority;
      dispatch({
        type: FETCH_SUCCESS,
        payload: { data: getState().group.data },
      });
      done();
    }
  },
});

export const groupCreateLogic = createLogic({
  type: CREATE,
  cancelType: CANCEL,
  warnTimeout: 0,
  process({ $http, action }, dispatch, done) {
    let group = { ...action.payload, priorities: {} };
    group.creator = store.get('user');
    const { priorities } = action.payload;

    axios({
      method: 'POST',
      url: `https://us-central1-${firebaseProjectId}.cloudfunctions.net/groupsByName`,
      headers: {
        Authorization: store.get('Authorization'),
      },
      data: action.payload,
      json: true,
    })
      .then(response => {
        if (response.data && !response.data.exists) {
          const newGroupKey = firebase
            .database()
            .ref()
            .child('groups')
            .push().key;

          for (const [, priority] of Object.entries(priorities)) {
            const newPriorityKey = firebase
              .database()
              .ref(`groups/${newGroupKey}`)
              .child('priorities')
              .push().key;

            group.priorities[newPriorityKey] = priority;
          }

          group.id = newGroupKey;
          firebase
            .database()
            .ref(`groups/${newGroupKey}`)
            .set({
              name: group.name,
              description: group.description,
              users: group.users ? group.users : [],
              created: firebase.database.ServerValue
                ? firebase.database.ServerValue.TIMESTAMP
                : 0,
              creator: group.creator,
              priorities: group.priorities,
            })
            .then(() => {
              dispatch({
                type: CREATE_SUCCESS,
                payload: { data: group },
              });
              done();
            });
        } else {
          dispatch({
            type: ERROR,
            payload: { error: 'Group name is already taken' },
          });
          done();
        }
      })
      .catch(e => {
        Raven.captureExceptionEx(e);
        dispatch({
          type: ERROR,
        });
        done();
      });
  },
});

export const groupRemoveLogic = createLogic({
  type: REMOVE,
  cancelType: CANCEL,
  processOptions: {
    successType: DELETE_SUCCESS,
    failType: ERROR,
  },
  process({ $http, action, getState }) {
    let key = action.payload;
    return firebase
      .database()
      .ref(`groups/${key}`)
      .remove()
      .then(() => {
        return {
          data: {
            id: key,
          },
        };
      });
  },
});

export const groupUpdateLogic = createLogic({
  type: UPDATE,
  cancelType: CANCEL,
  process({ $http, action, getState }, dispatch, done) {
    let { id } = action.payload;
    let group = action.payload;
    axios({
      method: 'POST',
      url: `https://us-central1-${firebaseProjectId}.cloudfunctions.net/groupsByName`,
      headers: {
        Authorization: store.get('Authorization'),
      },
      data: action.payload,
      json: true,
    })
      .then(response => {
        let exists = false;
        let groups = {};

        if (response.data) {
          exists = response.data.exists;
          groups = response.data.groups;
        }

        if (exists) {
          let keys = Object.keys(groups);
          for (let i = 0; i < keys.length; i++) {
            if (keys[i] === id) {
              exists = false;
              break;
            }
          }
        }
        if (!exists) {
          firebase
            .database()
            .ref(`groups/${id}`)
            .update({
              name: group.name,
              description: group.description,
              priorities: group.priorities || null,
              categories: Utils.arrayToString(group.categories),
            })
            .then(() => {
              dispatch({
                type: UPDATE_SUCCESS,
              });
              done();
            });
        } else {
          dispatch({
            type: ERROR,
            payload: { error: response.data.error },
          });
          done();
        }
      })
      .catch(e => {
        Raven.captureExceptionEx(e);
        dispatch({
          type: ERROR,
        });
        done();
      });
  },
});

//endregion

//region Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case INIT:
      return {
        ...initialState,
        data: {},
      };
    case ADD_GROUP_USER:
    case REMOVE_GROUP_USER:
    case ADD_GROUP_PRIORITY:
      return {
        ...state,
        updating: true,
      };

    case FETCH:
      return {
        ...state,
        data: {},
        loading: true,
        success: false,
        updateSuccess: false,
        updaingError: false,
      };

    case FETCH_SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false,
        updating: false,
        success: true,
        updateSuccess: false,
        updaingError: false,
      };

    case CREATE:
      return {
        ...state,
        loading: true,
        updating: false,
        success: false,
        error: null,
      };

    case UPDATE:
      return {
        ...state,
        loading: true,
        updating: true,
        success: false,
        updateSuccess: false,
        updaingError: false,
        error: null,
      };

    case CREATE_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        loading: false,
        updating: false,
        success: true,
      };
    }

    case UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: true,
        updaingError: false,
      };
    }

    case DELETE_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        loading: false,
        updating: false,
        success: true,
        updaingError: false,
      };
    }

    case ERROR:
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        error: error || 'An error occured while saving',
        updateSuccess: false,
        updaingError: true,
      };

    case FETCH_ERROR:
      if (action.payload && action.payload.code === 'PERMISSION_DENIED') {
        return {
          ...state,
          loading: false,
          success: false,
          fetchError: 'No permission to load the group!',
        };
      } else {
        return {
          ...state,
          loading: false,
          success: false,
          fetchError: 'Failed to load the group!',
        };
      }

    case CHANGE_CATEGORIES: {
      const data = state.data;
      data.categories = payload;
      return {
        ...state,
        data,
      };
    }

    case CHANGE:
      return {
        ...state,
        data: { ...payload },
      };

    default:
      return state;
  }
}
//endregion
