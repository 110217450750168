/**
 * Created by ilya on 7/3/17.
 */
export const INFORMAL = 0;
export const RED = 1;
export const AMBER = 2;
export const GREEN = 3;

export const FETCH_ACTIVITIES_COUNT = 6;
export const TOKEN_REFRESH_TIME = (1000 * 3600) / 2; // 30 mins
