/*
Created by Adam on 27/7/17
*/

import store from 'store';
import html from './landing.html';

import { fetchUser, logout, setTeamName } from 'logic/user';
import { createInitiative, initInitiative } from 'logic/initiative';
import { initBallot } from 'logic/ballot';

const isProd = process.env.NODE_ENV === 'production';

export default {
  template: html,

  controller($location, $ngRedux, $timeout) {
    `ngInject`;

    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      scrollToTop,
      onStartTeamCheckin,
      isProd,
      createTeamCheckin,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.scrollToTop();
      $ctrl.teamName = $ctrl.teamName_;
      $ctrl.setTeamName('');
      $ctrl.initInitiative();
      $ctrl.initBallot();
      if ($ctrl.teamName) {
        $ctrl.onStartTeamCheckin($ctrl.teamName);
      }
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.teamCheckinSuccess) {
        $ctrl.setTeamName('');
        $ctrl.teamName = '';
        $location.path(`initiatives/${$ctrl.initiative.id}/checkin/1`);
      }
      if ($ctrl.planTeamCheckin && $ctrl.checkpointsLoaded) {
        $ctrl.createTeamCheckin();
        $ctrl.planTeamCheckin = false;
      }
    }

    function onStartTeamCheckin(teamName) {
      if (!teamName) {
        $ctrl.emptyTeamName = true;
        return;
      }
      // I logged in
      $ctrl.teamName = teamName;
      $ctrl.isLoggedIn = !!store.get('user');
      if ($ctrl.isLoggedIn) {
        if ($ctrl.checkpoints) {
          $ctrl.createTeamCheckin();
        } else {
          $ctrl.planTeamCheckin = true;
        }
      } else {
        // not logged in
        $ctrl.setTeamName(teamName);
        $timeout(() => {
          $location.path('/register').search('teamName', teamName);
        }, 500);
      }
    }

    function createTeamCheckin() {
      const userId = store.get('user');
      $ctrl.createInitiative({
        name: $ctrl.teamName,
        type: 'lite',
        creator: userId,
        owner: userId,
      });
    }

    function mapState(state) {
      return {
        isLoggedIn: !!state.user.data.id,
        teamName_: state.user.teamName,
        props: state.user.data,
        initiative: state.initiative.data,
        creatingTeamCheckin: state.initiative.loading || state.ballot.updating,
        teamCheckinSuccess: state.initiative.success && state.ballot.success,
        initiativeError: state.initiative.error,
        ballotError: state.ballot.error,
        checkpointsLoaded: state.checkpoints.success,
      };
    }

    function mapDispatch() {
      return {
        //landing,
        createInitiative,
        logout,
        setTeamName,
        initInitiative,
        initBallot,
      };
    }

    function scrollToTop() {
      const divLogo = document.getElementById('top');
      divLogo.scrollIntoView(true);
    }
  },
};
