import {
  fetchImpediment,
  updateImpediment,
  removeImpediment,
  resolveImpediment,
} from '../../logic/impediment';

export default {
  template: `
    <mobile-nav ng-model="$ctrl.mobileMenuChecked"></mobile-nav>
    <div class="page-wrapper" ng-class="{'slided-wrapper': $ctrl.mobileMenuChecked}">
      <header-site menu-opened="$ctrl.mobileMenuChecked"></header-site>
      <div class="container impediments-new content-wrapper">
        <main class="main">
          <header>
            <h2><i class='fa fa-spinner fa-spin ' ng-if="$ctrl.loading"/> {{$ctrl.loading ? 'Loading impediment...' : 'Update impediment'}}</h2>
          </header>
          <hr class="my-4">
          <section>
            <impediments-form
              name="Update"
              props="$ctrl.props"
              creating="$ctrl.creating"
              on-submit="$ctrl.onUpdate(props)"
              on-remove="$ctrl.onRemove(props)"
              on-resolve="$ctrl.onResolve(props)"
              on-cancel="$ctrl.onCancel()">
            </impediments-form>
          </section>
        </main>
      </div>
    </div>`,

  controller($ngRedux, $location, $routeParams) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onCancel,
      onUpdate,
      onRemove,
      onResolve,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.fetchImpediment($routeParams.id);
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.removeSuccess) {
        $location.path(`/impediments`);
      }

      if ($ctrl.updateSuccess) {
        $location.path(`/impediments/${$routeParams.id}`);
      }
    }

    function mapState(state) {
      return {
        props: state.impediment.data,
        loading: state.impediment.loading,
        creating: state.impediment.creating,
        removing: state.impediment.removing,
        resolving: state.impediment.resolving,
        removeSuccess: state.impediment.removeSuccess,
        updateSuccess: state.impediment.updateSuccess,
      };
    }

    function mapDispatch() {
      return {
        fetchImpediment,
        updateImpediment,
        removeImpediment,
        resolveImpediment,
      };
    }

    function onCancel() {
      $location.path(`/impediments`);
    }

    function onRemove(props) {
      $ctrl.removeImpediment(props);
    }

    function onUpdate(props) {
      $ctrl.updateImpediment(props);
    }

    function onResolve(props) {
      $ctrl.resolveImpediment(props);
    }
  },
};
