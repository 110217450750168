export default {
  bindings: {
    setGroup: '<',
    removeGroup: '<',
    group: '<',
    groups: '<',
  },

  template: `
    <span class="groupWidget">
      
        <a ng-if="$ctrl.group" ng-href="/groups/{{ $ctrl.group.name }}" >
          <i class="fa fa-group" aria-hidden="true"></i>
          {{$ctrl.group.name}}
        </a>
        <a ng-if="!$ctrl.group" >
          <i class="fa fa-lock" aria-hidden="true"></i>
          Private
        </a>

        <div class="dropdown groupWidget__selector">

          <button class="btn-link groupWidget__toggle dropdown-toggle" type="button" id="chooseGroup" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span ng-if="$ctrl.group">Change/remove group</span>
            <span ng-if="!$ctrl.group">Assign to group</span>
          </button>

          <div class="dropdown-menu groupWidget__options" role="menu" aria-labelledby="chooseGroup">

            <div ng-repeat="(groupKey, groupData ) in $ctrl.groups">
              <a class="dropdown-item groupWidget__item" ng-click="$ctrl.setGroup(groupData)">
                <i ng-if="$ctrl.group.name && $ctrl.group.name === groupData.name" class="fa fa-check-circle-o"></i> 
                <i ng-if="!$ctrl.group.name || $ctrl.group.name !== groupData.name" class="fa fa-circle-o"></i> 
                {{groupData.name}}
              </a>
            </div>

            <div ng-if="$ctrl.group">
              <div class="dropdown-divider"></div>
              <a class="dropdown-item groupWidget__item" ng-click="$ctrl.removeGroup()">
                <i class="fa fa-close"></i> 
                Remove group (make private)
              </a>
            </div>

            <div class="dropdown-divider"></div>
            <a class="dropdown-item groupWidget__item" href="/groups/new">
              <i class="fa fa-plus"></i> 
              Create new group
            </a>

          </div>

        </div>

    </span>
    `,
};
