export default {
  bindings: {
    rags: '<',
    checkpoints: '<',
    checkpointsAll: '<',
    checkins: '<', // checkin status initiative.checkins
    checkin: '<',
    checkinUri: '<',
    type: '<',
    isBallotOpened: '<',
    timeConverter: '<',
  },

  template: `
    <table class="table table-responsive checkpoint-table m-0">
      <tr>
        <th class="text-left px-0 pt-0 border-0">
          Attributes
        </th>
        <th
          class="text-center pt-0 border-0"
          ng-repeat="item in [] | range:50 track by $index ">
          <a
            ng-class="{ 'text-gray-dark': $ctrl.checkin !== item }"
            ng-href="{{(item === 1 || $ctrl.rags[item - 1]) ? ($ctrl.checkinUri + '/' + item) : ''}}"
            data-toggle="tooltip" data-placement="top" title="{{(item === 1 || $ctrl.rags[item - 1]) ? ($ctrl.rags && $ctrl.rags[item] ? ($ctrl.checkins[item] ? $ctrl.timeConverter($ctrl.checkins[item].at ? $ctrl.checkins[item].at : $ctrl.checkins[item]) : '') : ($ctrl.isBallotOpened ? 'Live poll open' : 'click to check-in manually')) : ''}}"         
          > 
            {{ item }}
          </a>
        </th>
      </tr>
      <tr ng-repeat="item in $ctrl.checkpoints">
      
        <td class="text-left align-middle px-0 small col")>
          {{ $ctrl.checkpointsAll[item.id].name }}
        </td>
        <td class="text-center" ng-repeat="key in [] | range:50">
          <checkpoint state="$ctrl.rags[key][item.id]" />
        </td>
      </tr>
    </table>`,
};
