export default {
  bindings: {
    props: '<',
  },

  template: `
    <header class="initiatives-report-header">
      <columns>
        <column-left></column-left>
        <column-right class="row align-items-baseline">
          <div
            ng-repeat="award in $ctrl.props track by $index"
            class="col text-center">
            <b>{{ award.name }}</b>
          </div>
        </column-right>
      </columns>
    </header>`,
};
