export default {
  transclude: true,

  bindings: {
    onYes: '&',
    removing: '<',
    disabled: '<',
  },

  template: `
    <button 
      type="button"
      class="btn btn-primary float-left"
      ng-click="$ctrl.show = !$ctrl.show"
      ng-disabled="$ctrl.removing"
      ng-transclude
      >
      <i ng-if="!$ctrl.removing" class="fa fa-trash-o" aria-hidden="true"/><i ng-if="$ctrl.removing" class='fa fa-spinner fa-spin' /><span ng-if="!$ctrl.removing" class="hide-text-for-small">&nbsp;Delete</span> <span ng-if="$ctrl.removing" class="hide-text-for-small">&nbsp;Deleting</span>
    </button>

    <div class="popover popover-bottom popover-delete" role="tooltip" ng-class="{'show-popover': $ctrl.show}">
      <div class="popover-content">
        Are you sure?
        <a href="#" ng-click="$ctrl.show = false; $ctrl.onYes();">Yes</a> /
        <a href="#" ng-click="$ctrl.show = false">No</a>
      </div>
    </div>`,
};
