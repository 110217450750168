export default {
  bindings: {
    status: '<',
  },

  template: `
    <span
      class="badge badge--status"
      ng-class="{
        'badge-default': $ctrl.status === 'Draft',
        'badge-warning': $ctrl.status === 'Planning',
        'badge-primary': $ctrl.status === 'Development',
        'badge-success': $ctrl.status === 'Live',
      }">
      {{ $ctrl.status | statusName }}
    </span>`,
};
