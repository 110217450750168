import html from './add-member.html';
import { fetchUsers } from 'logic/users';
import * as InivteActions from 'logic/invite';
import { isEmail } from 'utils';

import * as _ from 'lodash';

export default {
  bindings: {
    onAddUser: '&',
  },

  template: html,

  controller($ngRedux, $routeParams) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onChange,
      isGroupUser,
      isOfQuery,
      onClear,
      onAdd,
      onInviteUser,
      isEmail,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.props = {};
      if ($routeParams.name) {
        $ctrl.fetchGroupInvites($routeParams.name);
      }
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.invite.success) {
        $ctrl.props.searchText = '';
      }
    }

    function mapState(state) {
      return {
        users: state.users.data,
        updating: state.group.updating,
        groupUsers: state.group.data.users,
        loading: state.users.loading,
        invite: state.invite,
        group: state.group,
      };
    }

    function mapDispatch() {
      return {
        ...InivteActions,
        fetchUsers,
      };
    }

    function onChange() {
      $ctrl.fetchUsers($ctrl.props.searchText);
    }

    function onAdd() {
      if ($ctrl.props.selUserId && $ctrl.isOfQuery()) {
        $ctrl.onAddUser({ userId: $ctrl.props.selUserId });
      }
    }

    function isGroupUser(userId) {
      if (!$ctrl.groupUsers) {
        return false;
      }
      return $ctrl.groupUsers.hasOwnProperty(userId);
    }

    function isOfQuery() {
      return (
        $ctrl.users.filter(user => user.id === $ctrl.props.selUserId).length > 0
      );
    }

    function onClear() {
      $ctrl.props.searchText = '';
      $ctrl.onChange();
    }

    function onInviteUser() {
      console.info('Invite User:', $ctrl.props.searchText);
      const groupId = _.get($ctrl.group, 'data.id');
      if (groupId) {
        $ctrl.inviteUserByEmail({ email: $ctrl.props.searchText, group: groupId});
      } else {
        throw {
          message: 'No group loaded!',
        };
      }
    }
  },
};
