/**
 * Created by ilya on 8/4/17.
 */
import { each, compact, pickBy, identity } from 'lodash';
import statuses from 'constants/statuses';
import sortings from 'constants/sortings';
import views from 'constants/views';
import * as Utils from '../../utils';
import decorateInitiative from 'decorators/initiative';

import {
  initRecentActivitiesCount,
  fetchMoreActivities,
  setFilter,
} from 'logic/activities';
import { fetchGroups } from 'logic/groups';
import { fetchInitiatives, cancelInitiatives } from 'logic/initiatives';
import { starInitiatives } from 'logic/initiatives';

import './home.scss';
import html from './home.html';

export default {
  bindings: {
    view: '@',
  },

  template: html,

  controller($ngRedux, $location, $routeParams, $httpParamSerializer) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
      mobileMenuChecked: false,
      onLoadMoreActivities,
      onSelectInitiative,
      getGroupUsersCount,
      getWallboardUrl,
      starInitiatives,
      getShortName: Utils.getShortName,
      fetch,
      setTag,
      params: { status: '', q: '', sort: 0, group: undefined },
      statuses: [...statuses],
      sortings: [...sortings],
      taskParamsFallback,
      taskParams,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.fetchGroups();
      $ctrl.fetchInitiatives();

      if ($routeParams.id) {
        $ctrl.initiativeId = $routeParams.id;
        $ctrl.showFilters = true;
        $ctrl.setFilter({
          initiativeId: $ctrl.initiativeId,
        });
      } else {
        $ctrl.initiativeId = 'all'; // default all initiatives
      }
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      const mobilisations = { ...state.awards.mobilisations };

      const params = $ctrl.taskParams(mobilisations);
      const userId = state.user.data.id;

      $ctrl.tasks = [];
      each(mobilisations, (task, key) => {
        if (params.indexOf(task.id) > -1) {
          $ctrl.tasks.push(task);
        }
      });

      const checkpoints = angular.copy(state.checkpoints.data);

      const processInitiative = (initiative, index) => {
        Utils.injectCheckpoints(initiative, checkpoints);
        Utils.injectKpis(initiative, state.kpiTypes.data);
        const prps = decorateInitiative(initiative, mobilisations);

        Utils.injectStars(prps, state);

        $(`#${prps.id}staredUserNames`).attr(
          'data-original-title',
          prps.staredUserNames
        );

        const originalProp = $ctrl.props
          ? $ctrl.props.filter(prop => prop.id === prps.id)
          : [];

        if (
          ((originalProp.length > 0 &&
            originalProp[0].starCount !== prps.starCount) ||
            originalProp.length === 0) &&
          $ctrl.getUsersProfile &&
          prps.stars
        ) {
          const dicUsers = {};
          for (let userId of Object.keys(prps.stars)) {
            if (!state.users.dic[userId]) {
              dicUsers[userId] = true;
            }
          }
          if (Object.keys(dicUsers).length > 0) {
            $timeout(() => {
              $ctrl.getUsersProfile(dicUsers);
            }, 50);
          }
        }

        return prps;
      };

      const initiatives = [...state.initiatives.data]
        // Filter only team initiatives
        .filter(
          initiative => initiative && initiative.team && initiative.team[userId]
        )
        .map(processInitiative);

      let query = $location.search();
      if (typeof query !== 'object') {
        query = {};
      }

      const { group } = query;
      if (state.groups.data.length > 0) {
        $ctrl.params.group = group;
      } else {
        $ctrl.params.group = '';
      }

      // Filter stared initiatives
      const starredInitiatives = [...state.initiatives.data]
        .filter(init => {
          return init.stars && init.stars[userId];
        })
        .map(processInitiative)
        .map(init => {
          return {
            ...init,
            teamPercents: Utils.getRagPercents(init, checkpoints, 'team'),
            servicePercents: Utils.getRagPercents(init, checkpoints, 'service'),
          };
        });

      // Filter user activites only
      const activities = state.activities.filter
        ? state.activities.filteredData
        : state.activities.data.filter(a => {
            return a.userId === state.user.data.id;
          });

      return {
        user: state.user.data,
        users: state.users.dic,
        checkpoints,
        groups: state.groups.data,
        loadingGroups: state.groups.loading,
        badges: { ...state.awards.badges },
        activities,
        initiatives,
        starredInitiatives,
        loadingInitiatives: state.initiatives.loading,
        loadingActivities: state.activities.loading,
        moreActivities: state.activities.more,
      };
    }

    function mapDispatch() {
      return {
        initRecentActivitiesCount,
        fetchMoreActivities,
        fetchInitiatives,
        setFilter,
        fetchGroups,
        starInitiatives,
      };
    }

    function onLoadMoreActivities() {
      $ctrl.fetchMoreActivities();
    }

    function onSelectInitiative() {
      if ($ctrl.initiativeId === 'all') {
        $ctrl.setFilter(null);
        $location.path('/activity');
      } else {
        /*$ctrl.setFilter({
          initiativeId: $ctrl.initiativeId,
        });*/
        $location.path('/activity/' + $ctrl.initiativeId);
      }
    }

    function taskParams(constMobilisations) {
      console.info('$location:', $location);
      let query = $location.search();
      if (typeof query !== 'object') {
        query = {};
      }
      let { tasks } = query;

      if (tasks) {
        tasks = compact(tasks.split(',').map(t => parseInt(t, 10)));
      }

      return tasks && tasks.length > 0
        ? tasks
        : $ctrl.taskParamsFallback(constMobilisations);
    }

    function taskParamsFallback(constMobilisations) {
      const mobilisations = [];
      each(constMobilisations, (mobilisation, key) => {
        mobilisations.push(mobilisation);
      });
      return mobilisations.slice(0, 4).map(a => a.id);
    }

    function getWallboardUrl(p) {
      let name = 'checkpoints';
      let params = angular.copy(p);
      if (!isNaN(params.sort) && params.sort >= 0) {
        params.sorting = sortings[params.sort].params[0];
        params.order = sortings[params.sort].params[1];
        delete params.sort;
      } else {
        params.sorting = 'created_date';
        params.order = 'DESC';
        delete params.sort;
      }

      let queryString = $httpParamSerializer(pickBy(params));

      if ($ctrl.active && $ctrl.active.wallboard) {
        name = $ctrl.active.name;
      }

      if (queryString) {
        queryString = `?${queryString}`;
      }

      return `/wallboard/${name}${queryString}`;
    }

    function getGroupUsersCount(group) {
      if (group && group.users) {
        return Object.keys(group.users).length;
      } else {
        return 0;
      }
    }

    function setTag(tag) {
      // setSearch(tag);
      // setPath();
    }
  },
};
