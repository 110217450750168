/**
 * Created by ilya on 8/4/17.
 */
import {
  initRecentActivitiesCount,
  fetchMoreActivities,
  setFilter,
} from 'logic/activities';
import { fetchInitiatives, cancelInitiatives } from 'logic/initiatives';

import './activity.scss';

import html from './list.html';

export default {
  bindings: {
    view: '@',
  },

  template: html,

  controller($ngRedux, $location, $routeParams) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
      mobileMenuChecked: false,
      onLoadMoreActivities,
      onSelectInitiative,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.initRecentActivitiesCount();
      $ctrl.params = { status: '', q: '', sort: '' };
      if (!$ctrl.initiatives || $ctrl.initiatives.length === 0) {
        $ctrl.fetchInitiatives($ctrl.params);
      }
      if ($routeParams.id) {
        $ctrl.initiativeId = $routeParams.id;
        $ctrl.showFilters = true;
        $ctrl.setFilter({
          initiativeId: $ctrl.initiativeId,
        });
      } else {
        $ctrl.initiativeId = 'all'; // default all initiatives
      }
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      // Sort initiatives by its name
      return {
        props: state.activities.filter
          ? state.activities.filteredData
          : state.activities.data,
        initiatives: state.initiatives.data,
        loadingInitiatives: state.initiatives.loading,
        loading: state.activities.loading,
        more: state.activities.more,
      };
    }

    function mapDispatch() {
      return {
        initRecentActivitiesCount,
        fetchMoreActivities,
        fetchInitiatives,
        setFilter,
      };
    }

    function onLoadMoreActivities() {
      $ctrl.fetchMoreActivities();
    }

    function onSelectInitiative() {
      if ($ctrl.initiativeId === 'all') {
        $ctrl.setFilter(null);
        $location.path('/activity');
      } else {
        /*$ctrl.setFilter({
          initiativeId: $ctrl.initiativeId,
        });*/
        $location.path('/activity/' + $ctrl.initiativeId);
      }
    }
  },
};
