import { getRemovedMarkdownString } from 'utils';

export default {
  bindings: {
    props: '<',
    star: '<',
    onStarInitiative: '&',
  },
  template: `
    <div class="homePage__starredInitiative">

      <h2 class="h5 mb-3">
        <badge-status class="mr-2" status="$ctrl.props.status" ng-if="$ctrl.props.type !== 'lite'"></badge-status>
        <a
          class="font-weight-light"
          title="{{ $ctrl.getRemovedMarkdownString($ctrl.props.pitch) }}"
          href="/initiatives/{{ $ctrl.props.id }}"
          data-toggle="tooltip"
          data-placement="bottom"
          data-animation="false"
          data-touch="false"
          data-original-title="{{ $ctrl.getRemovedMarkdownString($ctrl.props.pitch) }}"
          ng-class="{
            'text-muted': $ctrl.props.status === 'Draft',
            'text-warning': $ctrl.props.status === 'Planning',
            'text-primary': $ctrl.props.status === 'Development',
            'text-success': $ctrl.props.status === 'Live'
          }">{{ $ctrl.props.name }}</a>
      </h2>

      <div class="homePage__starredInfo">

        <div class="homePage__starredCheckpoints">
          <split-pill
            percents="$ctrl.props.teamPercents"
            type="'Team'">
          </split-pill>
          <split-pill
            percents="$ctrl.props.servicePercents"
            type="'Service'">
          </split-pill>
        </div>

        <div ng-if="$ctrl.star">
          <button
            ng-attr-id="{{$ctrl.props.id}}staredUserNames"
            class="btn btn-secondary btn-sm"
            data-toggle="tooltip"
            data-placement="bottom"
            data-animation="false"
            title="{{$ctrl.props.staredUserNames}}"
            ng-click="$ctrl.onStarInitiative({ id: $ctrl.props.id, isStared: !$ctrl.props.isStared })"
          >
            <i class="fa fa-star" aria-hidden="true" ng-class="{'text-warning' : $ctrl.props.isStared}"></i>&nbsp;{{$ctrl.props.starCount}}
          </button>
        </div>

      </div>

    </div>
    `,
  controller() {
    'ngInject';
    const $ctrl = {
      getRemovedMarkdownString,
    };

    return $ctrl;
  },
};
