import {
  fetchInitiative,
  updateInitiative,
  cancelInitiative,
} from 'logic/initiative';
import { injectKpis } from '../../utils/index';

export default {
  template: `
    <mobile-nav ng-model="$ctrl.mobileMenuChecked"></mobile-nav>
    <div class="page-wrapper" ng-class="{'slided-wrapper': $ctrl.mobileMenuChecked}">
      <header-site menu-opened="$ctrl.mobileMenuChecked"></header-site>
      <div class="container initiatives-new content-wrapper">
        <main class="main">
          <header>
            <h2>Edit initiative</h2>
          </header>
          <div class="container" ng-if="$ctrl.initiativeFetchError">
            <div class="main">
              <h4>{{$ctrl.initiativeFetchError}}</h4>
            </div>
          </div>
          <hr class="my-4">
          <h4 ng-if="$ctrl.fetching"><i class='fa fa-spinner fa-spin' ></i> Loading initiative...</h4>
          <section ng-if="!$ctrl.initiativeFetchError && !$ctrl.fetching">
            <initiatives-form
              name="Update"
              props="$ctrl.props"
              on-submit="$ctrl.updateInitiative(props)"
              on-cancel="$ctrl.redirectToShow()">
            </initiatives-form>
          </section>
        </main>
      </div>
    </div>`,

  controller($ngRedux, $location, $routeParams, $filter, $rootScope, $timeout) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      redirectToShow,
      jumpToKpi,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.fetchInitiative($routeParams.id);
      $ctrl.hash = $location.hash();
    }

    function $onDestroy() {
      $ctrl.cancelInitiative();
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if (!$ctrl.isFetchedInitiative && $ctrl.props && $ctrl.props.name) {
        $ctrl.isFetchedInitiative = true;
        $rootScope.title = `Init - Edit Initiative: ${$ctrl.props.name}`;
      }
      if ($ctrl.success && $ctrl.redirect) {
        $ctrl.redirectToShow();
      }
    }

    function jumpToKpi() {
      if ($ctrl.hash) {
        const kpiDiv = document.getElementById($ctrl.hash);
        if (kpiDiv) {
          kpiDiv.scrollIntoView(true);
        }
      }
    }

    function mapState(state) {
      const checkpoints = { ...state.checkpoints.data };

      const initiative = state.initiative.data;

      if (initiative.mobilisationStart) {
        initiative.mobilisationStart = $filter('date')(
          new Date(initiative.mobilisationStart),
          'yyyy-MM-dd'
        );
      }

      if (initiative.dueDate) {
        initiative.dueDate = $filter('date')(
          new Date(initiative.dueDate),
          'yyyy-MM-dd'
        );
      }

      let team = angular.copy(initiative.team);
      if (typeof team === 'string') {
        team.replace(', ', ',');
        let members = team.split(',');
        team = [];
        for (let i = 0; i < members.length; i++) {
          team.push({ name: members[i] });
        }
      }

      initiative.teamMembers = team;
      const kpiTypes = angular.copy(state.kpiTypes.data);
      injectKpis(initiative, kpiTypes);

      //When an initiative is loaded
      if ((!$ctrl.props || $ctrl.props.id) && initiative.id) {
        $timeout($ctrl.jumpToKpi, 1000, true);
      }

      return {
        fetching: state.initiative.fetching,
        props: initiative,
        checkpoints,
        success: state.initiative.success,
        redirect: state.initiative.redirect,
        initiativeFetchError: state.initiative.fetchError,
      };
    }

    function mapDispatch() {
      return {
        fetchInitiative,
        updateInitiative,
        cancelInitiative,
      };
    }

    function redirectToShow() {
      $location.url(`/initiatives/${$routeParams.id}`);
    }
  },
};
