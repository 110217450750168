import {
  fetchInitiative,
  deleteInitiative,
  cancelInitiative,
  addInitiativeAward,
  removeInitiativeAward,
  removeGroup,
  addGroup,
  starInitiative,
} from 'logic/initiative';
import { openVoting, initBallot } from 'logic/ballot';
import { updateInitiativeKpi } from 'logic/initiative';
import { getUsersProfile } from 'logic/users';
import { fetchQuestionSets } from 'logic/questionSet';
import { getRemovedMarkdownString } from 'utils';

import { each, find, isEmpty, map, some, pickBy, identity } from 'lodash';

import decorateInitiative from 'decorators/initiative';
import {
  isCheckInAllowed,
  injectCheckpoints,
  injectCustomCheckpoints,
  injectKpis,
  timeConvert,
  isBallotOpened,
  injectStars,
  getChkTypes,
  getCustomChkDic,
} from '../../utils/index';

import { fetchGroups } from 'logic/groups';

import html from './show.html';

const demoKpis = require('../../constants/demo_kpis.json');

export default {
  template: html,

  controller($location, $ngRedux, $routeParams, $rootScope, $timeout, $filter) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      redirectToIndex,
      onStartCheckIn,
      onClickCheckedIn,
      isCheckInAllowed,
      timeConvert,
      isAllKpiDataEmpty,
      onPopulate,
      saveKpi,
      demoKpis,
      onStarInitiative,
      setPriorities,
      starCount: 0,
      isTeamHidden: true,
      isServiceHidden: true,
      getRemovedMarkdownString
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);

      $ctrl.initBallot();
      $ctrl.fetchInitiative($routeParams.id);
      $ctrl.fetchGroups();
      $ctrl.fetchQuestionSets();
      $ctrl.checkinUri = `/initiatives/${$routeParams.id}/checkin`;
      $ctrl.oneByOne = true;
      $ctrl.public = true;
      $ctrl.named = true;
      $ctrl.isLivePoll = true;
      $ctrl.showPopover = false;
    }

    function $onDestroy() {
      $ctrl.cancelInitiative();
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if (!$ctrl.isSetPriorities && $ctrl.props.priorities) {
        $ctrl.isSetPriorities = true;
        $ctrl.setPriorities();
      }

      if ($ctrl.isUpdatingKpi && !$ctrl.updatingKpi && $ctrl.successKpi) {
        $('#kpi-modal').modal('hide');
        $ctrl.isUpdatingKpi = false;
        $ctrl.fetchInitiative($routeParams.id);
      }

      if ($ctrl.props.name) {
        $rootScope.title = `Init - Initiative: ${$ctrl.props.name}`;
      }

      if ($ctrl.success && $ctrl.redirect) {
        $ctrl.redirectToIndex();
      }

      if ($ctrl.ballotUpdated && !$ctrl.redirectedToCheckIn) {
        $ctrl.redirectedToCheckIn = true;
        $location.path(`${$ctrl.checkinUri}/${$ctrl.props.checkin.edit}`);
      } else if (
        $ctrl.ballotError === 'Live poll is already closed' &&
        !$ctrl.redirectedToCheckIn
      ) {
        $location.path(`${$ctrl.checkinUri}/${$ctrl.props.checkin.edit}`);
        $ctrl.redirectedToCheckIn = true;
      }
    }

    function redirectToIndex() {
      $location.path(`/initiatives`);
    }

    function onStartCheckIn() {
      console.info('oneByOne:', $ctrl.oneByOne);
      console.info('anonymous:', !$ctrl.named);
      console.info('private:', !$ctrl.public);
      console.info('isLivePoll:', $ctrl.isLivePoll);

      $ctrl.showPopover = false;

      if ($ctrl.isLivePoll) {
        // Live Poll
        // private = false, anonymous = false, oneByOne = false
        $ctrl.openVoting(
          $routeParams.id,
          $ctrl.props.checkin.edit,
          !$ctrl.public,
          !$ctrl.named,
          $ctrl.oneByOne
        );
      } else {
        // go to check in screen
        $location.path(`${$ctrl.checkinUri}/${$ctrl.props.checkin.edit}`);
      }
    }

    function onClickCheckedIn() {
      const initiative = this.props;

      // check if it is a live poll
      if (
        initiative.ballot_status &&
        typeof initiative.ballot_status[$ctrl.props.checkin.edit] === 'boolean'
      ) {
        $location.path(`${$ctrl.checkinUri}/${$ctrl.props.checkin.edit}`);
      } else if (
        initiative.checkins &&
        initiative.checkins[$ctrl.props.checkin.edit]
      ) {
        // already checked in
        $location.path(`${$ctrl.checkinUri}/${$ctrl.props.checkin.edit}`);
      } else {
        $ctrl.showPopover = !$ctrl.showPopover;
      }
    }

    function isAllKpiDataEmpty(type) {
      const { kpis } = $ctrl.props;
      if (!kpis || typeof kpis !== 'object') {
        return true;
      }

      const ids = Object.keys(kpis);
      let id;
      for (let i in ids) {
        id = ids[i];
        if (kpis[id].type === type && kpis[id].data) {
          return false;
        }
      }
      return true;
    }

    function mapState(state) {
      const awards = {
        mobilisations: state.awards.mobilisations,
        badges: state.awards.badges,
      };

      const initiative = angular.copy(state.initiative.data);
      const checkpoints = angular.copy(state.checkpoints.data);
      const kpiTypes = angular.copy(state.kpiTypes.data);

      injectCheckpoints(initiative, checkpoints);
      injectCustomCheckpoints(initiative, state.questionSet.list);
      const chkTypes = initiative ? getChkTypes(initiative.checkpoints) : [];

      const dicCheckpoints = { ...checkpoints };
      if (initiative && initiative.checkpoints) {
        initiative.checkpoints.forEach(c => {
          dicCheckpoints[c.id] = c;
        });
      }

      injectKpis(initiative, kpiTypes);

      const customChkDic = getCustomChkDic(state.questionSet.list);

      const mobilisations = { ...state.awards.mobilisations };

      const props = decorateInitiative(initiative, mobilisations);

      // TODO: should filter disabled bages here
      each(awards, (award, type) => {
        each(award, (item, key) => {
          if (item) {
            const { id, name, isDisabled } = item;
            const data = { ...find(props[type], { name }) };
            const active = !isEmpty(data);

            if (data.completionDate) {
              data.completionDate = new Date(data.completionDate);
            } else {
              data.completionDate = new Date();
            }

            awards[type][key] = {
              ...data,
              id,
              name,
              key: type,
              active,
              isDisabled,
            };
          }
        });
      });

      let team = angular.copy(props.team);
      if (typeof team === 'string') {
        team.replace(', ', ',');
        let members = team.split(',');
        team = [];
        for (let i = 0; i < members.length; i++) {
          team.push({ name: members[i] });
        }
      }

      props.teamMembers = team;

      injectStars(props, state);

      $('#staredUserNames').attr('data-original-title', props.staredUserNames);
      if (
        $ctrl.props &&
        $ctrl.props.starCount !== props.starCount &&
        $ctrl.getUsersProfile &&
        props.stars
      ) {
        const dicUsers = {};
        for (let userId of Object.keys(props.stars)) {
          if (!state.users.dic[userId]) {
            dicUsers[userId] = true;
          }
        }
        $timeout(() => {
          $ctrl.getUsersProfile(dicUsers);
        });
      }

      if ($ctrl.props && $ctrl.props.checkpointsGrouped) {
        if (
          $ctrl.props.checkpointsGrouped.team &&
          $ctrl.props.checkpointsGrouped.team.length > 0
        ) {
          $ctrl.isTeamHidden = false;
        }

        if (
          $ctrl.props.checkpointsGrouped.service &&
          $ctrl.props.checkpointsGrouped.service.length > 0
        ) {
          $ctrl.isServiceHidden = false;
        }
      }
      console.info('awards', awards);

      return {
        props,
        awards,
        chkTypes,
        customChkDic,
        checkpoints: dicCheckpoints,
        groups: state.groups.data,
        isBallotOpened: isBallotOpened(props),
        success: state.initiative.success,
        updatingKpi: state.initiative.updatingKpi,
        successKpi: state.initiative.successKpi,
        redirect: state.initiative.redirect,
        loading: state.initiative.loading && state.groups.loading,
        fetching: state.initiative.fetching,
        fetchError: state.initiative.fetchError,
        updatingBallot: state.ballot.updating,
        ballotUpdated: $ctrl.updatingBallot && state.ballot.updated,
        ballotError: state.ballot.error,
        users: state.users.dic,
      };
    }

    function mapDispatch() {
      return {
        fetchInitiative,
        deleteInitiative,
        cancelInitiative,
        addInitiativeAward,
        removeInitiativeAward,
        openVoting,
        initBallot,
        removeGroup,
        addGroup,
        fetchGroups,
        updateInitiativeKpi,
        starInitiative,
        getUsersProfile,
        fetchQuestionSets,
      };
    }

    function onPopulate(kpi) {
      $ctrl.selectedKpi = { ...kpi };
      const data = [];
      if ($ctrl.selectedKpi.data) {
        let id = 0;
        for (const [key, value] of Object.entries($ctrl.selectedKpi.data)) {
          data.push({
            id: id++,
            key: $filter('date')(key, 'yyyy-MM-dd'),
            value: parseInt(value),
          });
        }
      }
      $ctrl.selectedKpi.data = data;
      $('#kpi-modal').modal('show');
    }

    function saveKpi() {
      $ctrl.isUpdatingKpi = true;
      const data = {};
      for (const value of $ctrl.selectedKpi.data) {
        data[new Date(value.key).getTime()] = value.value;
      }

      $ctrl.updateInitiativeKpi($ctrl.props.id, { ...$ctrl.selectedKpi, data });
    }

    function onStarInitiative() {
      $ctrl.starInitiative(!$ctrl.props.isStared);
    }

    function setPriorities() {
      $ctrl.selPriorities = {};

      if (!$ctrl.props.group || !$ctrl.props.group.priorities) {
        return;
      }
      const temp = angular.copy($ctrl.props.priorities);
      for (const [id, value] of Object.entries(temp)) {
        if ($ctrl.props.group.priorities[id]) {
          $ctrl.selPriorities[id] = $ctrl.props.group.priorities[id];
        }
      }
    }
  },
};
