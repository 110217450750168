import { checkSingleDataPoint, XOR } from '../../utils';

export default {
  transclude: true,

  bindings: {
    props: '<',
    margin: '<',
    show: '<',
    onPopulate: '&',
  },

  template: `
    <ng-transclude></ng-transclude>
    <p class="graph-line-total" ng-if="$ctrl.props.data">
      <span class="text-gray-light">
        {{ $ctrl.getLatestValue($ctrl.props.data) }} {{ $ctrl.props.unit }}
      </span>
      <i ng-if="$ctrl.getValueChanged($ctrl.props.data) > 0" class="fa fa-arrow-up"
        ng-class="{'text-danger': !$ctrl.props.reverse, 'text-success': $ctrl.props.reverse}"
      ></i>
      <i ng-if="$ctrl.getValueChanged($ctrl.props.data) < 0" class="fa fa-arrow-down text-success"
        ng-class="{'text-success': !$ctrl.props.reverse, 'text-danger': $ctrl.props.reverse}"
      ></i>
      <i ng-if="$ctrl.getValueChanged($ctrl.props.data) === 0" class="fa fa-minus text-muted"></i>
      <span ng-class="{'text-success': $ctrl.props.reverse ? $ctrl.getValueChanged($ctrl.props.data) > 0 : $ctrl.getValueChanged($ctrl.props.data) < 0,
                       'text-danger': $ctrl.props.reverse ? $ctrl.getValueChanged($ctrl.props.data) < 0 : $ctrl.getValueChanged($ctrl.props.data) > 0,
                       'text-muted': $ctrl.getValueChanged($ctrl.props.data) ===	0}">
        {{  $ctrl.getValueChanged($ctrl.props.data) === 0 ? 'no change' : ($ctrl.getValueChanged($ctrl.props.data) > 0 ? $ctrl.getValueChanged($ctrl.props.data) : -1 * $ctrl.getValueChanged($ctrl.props.data)) }} {{ $ctrl.getValueChanged($ctrl.props.data) !== 0 ? $ctrl.props.unit : '' }}
      </span>
    </p>
    <div class="graph-line-total-wrapper">
      <graph-line
        data="$ctrl.props.data"
        margin="$ctrl.margin"
        show="$ctrl.show"
        class="d-block graph-line"
        on-populate="$ctrl.onClickPopulate()">
      </graph-line>
    </div>`,

  controller($timeout, $element) {
    `ngInject`;

    const $ctrl = {
      getValueChanged,
      getLatestValue,
      onClickPopulate,
      checkSingleDataPoint,
      XOR,
    };

    return $ctrl;

    function getValueChanged(data) {
      if (!data) {
        return 0;
      }
      let keys = Object.keys(data);
      if (keys.length < 2) {
        return 0;
      }
      keys.sort();
      let v1 = data[keys[keys.length - 1]];
      let v2 = data[keys[keys.length - 2]] ? data[keys[keys.length - 2]] : 0;

      return v1 - v2;
    }

    function getLatestValue(data) {
      if (!data) {
        return 0;
      }
      let keys = Object.keys(data);
      if (keys.length === 0) {
        return 0;
      }
      keys.sort();
      return data[keys[keys.length - 1]];
    }

    function onClickPopulate() {
      $ctrl.onPopulate({ kpi: $ctrl.props });
    }
  },
};
