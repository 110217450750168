import './split_pill.scss';

export default {
  bindings: {
    percents: '<',
    type: '<',
  },
  template: `
    <div>
      <div class="splitPill" ng-class="'splitPill--' + $ctrl.count">
        <span class="splitPill__red" ng-if="$ctrl.percents[0] > 0" ng-style="$ctrl.styleRed"></span>
        <span class="splitPill__yellow" ng-if="$ctrl.percents[1] > 0" ng-style="$ctrl.styleYellow"></span>
        <span class="splitPill__green" ng-if="$ctrl.percents[2] > 0" ng-style="$ctrl.styleGreen"></span>
      </div>
      <div>
        {{$ctrl.type}}
      </div>
    </div>
    `,
  controller() {
    'ngInject';
    const $ctrl = {
      $onInit,
      styleRed: {},
      styleYellow: {},
      styleGreen: {},
    };
    return $ctrl;

    function $onInit() {
      const { percents } = $ctrl;
      let count = 0;
      $ctrl.styleRed = {
        width: `${$ctrl.percents[0]}%`,
      };
      $ctrl.styleYellow = {
        left: `${$ctrl.percents[0]}%`,
        width: `${$ctrl.percents[1]}%`,
      };
      $ctrl.styleGreen = {
        width: `${$ctrl.percents[2]}%`,
      };

      for (let i = 0; i < $ctrl.percents.length; i++) {
        if ($ctrl.percents[i] > 0) {
          count++;
        }
      }
      $ctrl.count = count;
    }
  },
};
