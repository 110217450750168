export default {
  bindings: {
    onPrevious: '&',
    onNext: '&',
  },

  template: `
    <nav class="wallboard-actions d-flex align-items-center">
      <a href="#" ng-click="$ctrl.onPrevious()" class="previous"><i class="fa fa-chevron-left"></i></a>
      <a href="#" ng-click="$ctrl.onNext()" class="next ml-auto"><i class="fa fa-chevron-right"></i></a>
    </nav>`,
};
