export default {
  transclude: true,

  bindings: {
    onYes: '&',
    disabled: '<',
  },

  template: `
    <button class="btn btn-danger btn-sm"
      ng-click="$ctrl.show = !$ctrl.show"
      ng-disabled="$ctrl.disabled"
      ng-transclude
      >
      <i class="fa fa-trash-o" aria-hidden="true"></i><span class="hide-text-for-small">&nbsp;Delete</span>
    </button>

    <div class="popover popover-bottom popover-delete" role="tooltip" ng-class="{'show-popover': $ctrl.show}">
      <div class="popover-content">
        Are you sure?
        <a href="#" ng-click="$ctrl.show = false; $ctrl.onYes();">Yes</a> /
        <a href="#" ng-click="$ctrl.show = false">No</a>
      </div>
    </div>`,
};
