import html from './new.html';

import { initGroup, createGroup, cancelGroup } from 'logic/group';

export default {
  template: html,

  controller($ngRedux, $location) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onCancel,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.initGroup();
    }

    function $onDestroy() {
      $ctrl.cancelGroup();
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.success) {
        let { name } = $ctrl.props;
        $location.path(`/groups/${name}`);
      }
    }

    function mapState(state) {
      return {
        props: state.group.data,
        success: state.group.success,
      };
    }

    function mapDispatch() {
      return {
        initGroup,
        createGroup,
        cancelGroup,
      };
    }

    function onCancel() {
      $location.path(`/groups`);
    }
  },
};
