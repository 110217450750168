import moment from 'moment';

export default {
  bindings: {
    kpi: '<',
    name: '@',
    target: '@',
    onSubmit: '&',
    updating: '<',
  },

  template: `
    <div ng-attr-id="{{$ctrl.target}}" class="modal fade {{ $ctrl.target }} kpiModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-4">
            <h3 class="h4 modal-title">{{ $ctrl.kpi.name }}</h3>
            <div class="switch-toggle">
              <label for="switch">Disable this metric</label>
              <input type="checkbox" id="switch" ng-model="$ctrl.kpi.disabled"/>
              <label for="switch" class="toggle">toggle</label>
            </div>
          </div>
          <div class="modal-body p-4 pb-0">
            <form
              name="$ctrl.kpimodalform"
              ng-submit="$ctrl.onSubmit()">
          
              <div
                class="kpiModal__row"
                ng-repeat="data in $ctrl.kpi.data"
              >
                <div
                  class="kpiModal__column kpiModal__date"
                  ng-class="{ 'has-danger': ($ctrl.kpimodalform['date-{{data.id}}'].$touched && $ctrl.kpimodalform['date-{{data.id}}'].$invalid) || $ctrl.errorObj['date-{{data.id}}'].$invalidDate }"
                >
                  <label>Date</label>
                  <div class="kpiModal__input">
                    <datepicker date-format="yyyy-MM-dd">
                      <input
                        ng-attr-name="{{ 'date-' + data.id }}"
                        class="form-control"
                        type="text"
                        ng-model="data.key"
                        date-set="{{$ctrl.today}}"
                        ng-change="$ctrl.checkValidDate(data)"
                        ng-blur="$ctrl.onBlurDate(data)"
                        readonly
                        required>
                    </datepicker>
                    <div>
                      <small ng-show="$ctrl.kpimodalform['date-{{data.id}}'].$touched && $ctrl.kpimodalform['date-{{data.id}}'].$error.required" class="form-control-feedback">Required field</small>
                      <small ng-show="!($ctrl.kpimodalform['date-{{data.id}}'].$touched && $ctrl.kpimodalform['date-{{data.id}}'].$error.required) && $ctrl.errorObj['date-{{data.id}}'].$invalidDate" class="form-control-feedback">
                        {{$ctrl.errorObj['date-' + data.id].$invalidDate}}
                      </small>
                    </div>
                  </div>
                </div>
                  
                <div
                  class="kpiModal__column kpiModal__value"
                  ng-class="{ 'has-danger': $ctrl.kpimodalform['value-{{data.id}}'].$touched && $ctrl.kpimodalform['value-{{data.id}}'].$invalid }"
                >
                  <div class="kpiModal__input">
                    <input
                      ng-attr-name="{{ 'value-' + data.id }}"
                      class="form-control"
                      type="number"
                      ng-model="data.value"
                      required>
                    <div ng-show="$ctrl.kpimodalform['value-{{data.id}}'].$touched">
                      <small ng-show="$ctrl.kpimodalform['value-{{data.id}}'].$error.required" class="form-control-feedback">Required field</small>
                    </div>
                  </div>
                  <label>{{ $ctrl.kpi.unit }}</label>
                  <button
                      type="button"
                      title="remove"
                      class="ml-3 btn btn-danger"
                      ng-click="$ctrl.onRemove(data)"
                  >
                    <i class='fa fa-trash-o'/>
                  </button>
                </div>
              </div>
            
              <button
                type="button"
                class="mt-3 btn btn-primary"
                ng-click="$ctrl.onAdd()"
              >
                  <i class='fa fa-plus'/>&nbsp;
                  Add
              </button>
                  
              <hr class="my-4">
          
              <div class="clearfix mt-4">
                  <div class="alert alert-danger float-left" ng-if="$ctrl.error">
                      <p>{{$ctrl.error}}</p>
                  </div>
                  <button
                      type="button"
                      class="btn btn-danger ml-2 float-right"
                      data-dismiss="modal">
                      Cancel
                  </button>
                  <button
                    type="submit"
                    class="btn btn-success float-right"
                    ng-disabled="!$ctrl.checkValidForm()"
                  >
                      <i class='fa fa-spinner fa-spin' ng-if="$ctrl.updating"/>
                      Save
                  </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>`,
  controller($filter) {
    'ngInject';
    const $ctrl = {
      $onInit,
      onAdd,
      onRemove,
      today: new Date().toISOString(),
      checkValidDate,
      checkValidForm,
      onBlurDate,
    };

    return $ctrl;

    function $onInit() {
      $ctrl.errorObj = {};
      $(document).ready(function() {
        $(`#${$ctrl.target}`).on('hidden.bs.modal', function() {
          $ctrl.errorObj = {};
        });
      });
    }

    function onAdd() {
      const expectKey = $filter('date')(new Date(), 'yyyy-MM-dd');

      // unique ID generating
      let randomnumber;
      while (1 > 0) {
        randomnumber = Math.ceil(Math.random() * 500);
        if (
          $ctrl.kpi.data.filter(data => data.id === randomnumber).length > 0
        ) {
          continue;
        }

        $ctrl.kpi.data.push({
          key: expectKey,
          value: 0,
          id: randomnumber,
        });
        break;
      }

      const invalidDate =
        $ctrl.kpi.data.filter(data => data.key === expectKey).length > 1;

      $ctrl.errorObj[`date-${randomnumber}`] = {
        $invalidDate: invalidDate && 'Already exists',
      };
    }

    function onRemove(data) {
      delete $ctrl.errorObj[`date-${data.id}`];
      $ctrl.kpi.data.splice($ctrl.kpi.data.indexOf(data), 1);
    }

    function onBlurDate(data) {
      const date = moment(data.key, 'YYYY-MM-DD');
      if (date.isValid()) {
        data.key = date.format('YYYY-MM-DD');
      }
    }

    function checkValidDate(data) {
      let invalidDate = !moment(data.key, 'YYYY-MM-DD').isValid();

      if (!invalidDate) {
        const expectKey = moment(data.key, 'YYYY-MM-DD').format('YYYY-MM-DD');
        invalidDate =
          $ctrl.kpi.data.filter(data => data.key === expectKey).length > 1;
        if (!$ctrl.errorObj[`date-${data.id}`]) {
          $ctrl.errorObj[`date-${data.id}`] = {};
        }
        $ctrl.errorObj[`date-${data.id}`].$invalidDate = invalidDate;
      } else {
        if (!$ctrl.errorObj[`date-${data.id}`]) {
          $ctrl.errorObj[`date-${data.id}`] = {};
        }
        $ctrl.errorObj[`date-${data.id}`].$invalidDate = 'Invalid date';
      }

      if (!invalidDate) {
        $ctrl.kpi.data.sort((a, b) => {
          const aDate = moment(a.key, 'YYYY-MM-DD');
          const bDate = moment(b.key, 'YYYY-MM-DD');
          return aDate.diff(bDate) > 0;
        });
      }
    }

    function checkValidForm() {
      if (!$ctrl.kpi) {
        return true;
      }
      if ($ctrl.kpimodalform.$invalid) {
        return false;
      }
      for (let data of $ctrl.kpi.data) {
        if (
          $ctrl.errorObj[`date-${data.id}`] &&
          $ctrl.errorObj[`date-${data.id}`].$invalidDate
        ) {
          return false;
        }
      }
      return true;
    }
  },
};
