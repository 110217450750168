/**
 * Created by ilya
 */
import { each, compact, pickBy, identity } from 'lodash';
import statuses from 'constants/statuses';
import sortings from 'constants/sortings';
import views from 'constants/views';
import * as Utils from 'utils';
import decorateInitiative from 'decorators/initiative';

import * as InivteActions from 'logic/invite';
import html from './invitation.html';

export default {
  bindings: {
    view: '@',
  },

  template: html,

  controller($ngRedux) {
    'ngInject';
    const $ctrl = {
      onSubmit,
      onInitInvite,
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
      email: '',
      isEmail: Utils.isEmail,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      return { props: state.invite };
    }

    function mapDispatch() {
      return {
        ...InivteActions,
      };
    }

    function onSubmit(email) {
      $ctrl.inviteUserByEmail( { email} );
    }

    function onInitInvite() {
      $ctrl.email = '';
      $ctrl.initInvite();
    }
  },
};
