/**
 * Created by ilya on 6/6/17.
 */
import { fetchBallot, cancelBallot, fetchBallotWithCode } from 'logic/ballot';
import { createInitiative } from 'logic/initiative';
import { updateAnonymousUserData, fetchUser } from 'logic/user';

import { createVote } from 'logic/vote';
import store from 'store';
import * as _ from 'lodash';

import decorateInitiative from 'decorators/initiative';

import { INFORMAL, RED, GREEN, AMBER } from '../../config/consts';
import * as Utils from '../../utils';

import './checkin.scss';

export default {
  template: `
    <div class="content-wrapper ballot">
      <div ng-if="$ctrl.success && !$ctrl.props.closed && $ctrl.isAnonymous && !$ctrl.isAnonymousJoined">
        <div class="container">
          <div class="main ballot text-center" ng-if="$ctrl.anonymous.loading">
            <h4>Loading...</h4>
          </div>
          <div class="main ballot" ng-if="!$ctrl.anonymous.loading">
            <form
              name="$ctrl.anonymousform"
              ng-submit="$ctrl.onJoinAnonymousUser($ctrl.anonymous.name)"
              ng-show="$ctrl.anonymous.isFirstLogin || $ctrl.anonymous.isChangeName"
            >
              <div class="form-group" ng-class="{ 'has-danger': $ctrl.anonymousform.name.$touched && $ctrl.anonymousform.name.$invalid }">

                <fieldset>
                  <label for="name">
                    To join this check-in, please enter your name
                  </label>
                  <input
                    id="name"
                    name="name"
                    class="form-control form-control-danger"
                    type="text"
                    ng-model="$ctrl.anonymous.name"
                    placeholder="{{$ctrl.anonymous.placeholder}}"
                    pattern=".{3,}"
                    required>
                  <div ng-show="$ctrl.anonymousform.name.$touched">
                    <small ng-show="$ctrl.anonymousform.name.$error.required" class="form-control-feedback">Required field</small>
                    <small ng-show="$ctrl.anonymousform.name.$error.pattern" class="form-control-feedback">Required at least 3 characters</small>
                  </div>
                </fieldset>

                <fieldset>
                  <button
                    type="submit"
                    class="btn btn-primary my-2"
                    ng-disabled="$ctrl.anonymousform.$invalid"
                  >
                    Join
                  </button>
                </fieldset>
              </div>
            </form>

            <div ng-show="!$ctrl.anonymous.isFirstLogin && !$ctrl.anonymous.isChangeName">
              <label>Are you still</label>
              <div>
                <label class="font-weight-bold">{{$ctrl.anonymous.placeholder}}</label>
              </div>

              <button
                type="button"
                class="btn btn-primary my-2"
                ng-click="$ctrl.onJoinAnonymousUser($ctrl.anonymous.placeholder)"
              >
                Yes, join check-in
              </button>

              <button
                type="button"
                class="btn btn-outline-primary my-2"
                ng-click="$ctrl.anonymous.isChangeName = !$ctrl.anonymous.isChangeName"
              >
                No, change name
              </button>
            </div>
          </div>
        </div>
      </div>

      <div ng-if="!$ctrl.isAnonymous || $ctrl.isAnonymousJoined || ($ctrl.success && $ctrl.props.closed)">
        <div ng-if="!$ctrl.isLoggedIn">
          <div class="main ballot text-center">
            <h4>Loading...</h4>
          </div>
        </div>
        <div ng-if="$ctrl.isLoggedIn">
          <div class="container" ng-if="$ctrl.fetching">
            <div class="main ballot text-center">
              <h4>Loading...</h4>
            </div>
          </div>
          <div class="container" ng-if="!$ctrl.fetching && !$ctrl.success">
            <div class="main ballot text-center">
              <h4>Failed to load checkpoints!</h4>
            </div>
          </div>
          <div class="container initiatives-checkin" ng-if="$ctrl.success">
            <main class="main ballot clearfix">
              <header>
                <h2 class="h2 mt-0 mb-1 d-flex align-items-center">
                  {{$ctrl.props.initiativeName}}
                  <!--<badge-status class="d-flex ml-2" status="$ctrl.props.initiativeStatus"></badge-status>-->
                </h2>
              </header>
              <hr class="mt-2 mb-3">
              <form ng-submit="$ctrl.onSubmit()">
                <section>
                  <div
                    class="d-flex justify-content-start align-items-center flex-wrap mb-2"
                    ng-if="$ctrl.shouldShowAvatar($ctrl.props)"
                  >
                    <div
                      ng-repeat="voter in $ctrl.props.voters"
                      class="d-flex align-items-center voter mb-2 mr-2 py-1 px-2"
                      ng-class="$ctrl.getAvatarColor($ctrl.props, voter.id)"
                    >
                      <i class="fa fa-user-circle-o mr-1" aria-hidden="true"></i>
                      <small>{{voter.name}}</small>
                    </div>
                  </div>
                  <!-- Hide intro when no active checkpoint is selected -->
                  <small
                    class="check-intro d-block mb-4 text-muted"
                    ng-hide="$ctrl.props.oneByOne && $ctrl.props.activeCheckpoint !== undefined"
                    >
                    <p>
                      Now is the time introspectively assess how well your team is working together. Be brutally honest and get into the warts and all to understand what's not working well. It's not all doom and gloom, though: you'll uncover good things too, and exchange some high-fives. You'll walk away knowing where your weak spots are, armed with ideas to help build those muscles.
                    </p>
                    <p>
                      Please assess your team health strictly within the context of the initiative you're working on. For this, there are no right or wrong answers, and everyone's opinion is equal.
                    </p>
                  </small>
                </section>
                <!-- Normal ballot checkin section -->
                <section
                  ng-repeat="type in $ctrl.chkTypes"
                  ng-if="!$ctrl.props.oneByOne || ($ctrl.props.oneByOne && $ctrl.props.closed)"
                >
                  <div
                    ng-if="$ctrl.getCheckpointCount($ctrl.props, type)"
                  >
                    <header id="{{ type }}">
                      <h2 class="h4">{{ type | capitalize }} Checkpoint</h2>
                    </header>
                    <hr class="my-4">
                  </div>
                  <div ng-repeat="item in $ctrl.props.checkpoints">
                    <div ng-if="item.type == type">
                      <div class="row mb-1">
                        <div class="col-md-7 col-lg-8">
                          <h3 class="h6">{{ item.name }}</h3>
                          {{ $ctrl.checkpoints[item.id].description }}
                        </div>
                        <div class="col-md-5 col-lg-4 pl-4 pt-3">
                          <div class="row">
                            <div
                              class="col-4 text-center"
                              ng-repeat="class in ['red', 'amber', 'green']">
                              <checkpoint-radio
                                name="rag-{{ item.id }}"
                                class="checkpoint-radio {{ class }}"
                                vote="$ctrl.props.closed ? $ctrl.votes[item.id][$index] : null"
                                closed="$ctrl.props.closed"
                                on-click="$ctrl.onClick(item.id, $index + 1)"
                                ng-checked="($ctrl.props.oneByOne ? $ctrl.calcRag($ctrl.props.votes[item.id]) : $ctrl.vote[item.id]) === $index + 1"
                                ng-disabled="$ctrl.props.closed"
                              >
                              </checkpoint-radio>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Hide for now by false && -->
                      <div class="my-2 mx-3" ng-show="false && $ctrl.checkpoints[item.id].ragexamples && $ctrl.checkpoints[item.id].ragexamples.length > 0 && !$ctrl.props.hide_legend">
                        <a
                          href=""
                          ng-click="$ctrl.onClickShowHideLegend(item.id)"
                        >
                          <i class="fa fa-question-circle" aria-hidden="true"></i> <small>{{ $ctrl.legends[item.id] ? 'Hide': 'Show' }} legend</small>
                        </a>
                        <div class="collapse mt-2" id="collapseExample" ng-class="{show: $ctrl.legends[item.id]}">
                          <div class="card card-block">
                            <small class="mb-2"><span class="font-weight-bold text-danger">Sad red:</span> {{$ctrl.checkpoints[item.id].ragexamples[1]}}</small>
                            <small class="mb-2"><span class="font-weight-bold text-warning">Neutral amber:</span> {{$ctrl.checkpoints[item.id].ragexamples[2]}}</small>
                            <small><span class="font-weight-bold text-success">Happy green:</span> {{$ctrl.checkpoints[item.id].ragexamples[3]}}</small>
                          </div>
                        </div>
                      </div>
                      <hr class="my-4">
                    </div>
                  </div>
                </section>

                <!-- One by one Ballot -->

                <section ng-if="$ctrl.props.oneByOne && !$ctrl.props.closed">
                  <div ng-if="!$ctrl.props.activeCheckpoints">
                    <small class="text-muted mb-4">
                      <p>Some checkpoints use questions sourced or adapted from:</p>
                      <ul>
                        <li>
                          Atlassian's <a href="https://www.atlassian.com/team-playbook/health-monitor/project-teams" target="_blank">Health monitor for project teams</a>, which is licenced under the <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a>, or
                        </li>
                        <li class="license">
                          The Australian Government's Digital Transformation Agency's <a href="https://www.dta.gov.au/standard/" target="_blank">Digital Service Standard</a>, which is licenced under the <a href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/2/" target="_blank">Open Government Licence 2.0</a>
                        </li>
                      </ul>
                    </small>
                    <i class="fa fa-spinner fa-spin" aria-hidden="true" ></i>
                    The facilator will reveal the first checkpoint once everyone has joined...
                  </div>
                  <div ng-repeat="item in $ctrl.props.activeCheckpoints">
                    <header id="$ctrl.checkpoints[item.id].type">
                      <!-- ({{$ctrl.getActiveCheckpointIndex($ctrl.props, item)}}/{{$ctrl.getTotalCheckpointsCountByType($ctrl.props, item.type)}}) -->
                      <h2 class="h4 font-weight-normal">{{ $ctrl.checkpoints[item.id].type | capitalize }} Checkpoint</h2>
                    </header>
                    <hr class="mt-2 mb-3">
                    <div class="row mb-1" ng-class="{closedCheckpoint: $ctrl.alreadyVoted || $ctrl.props.votes[item.id].closed}">
                      <div class="col-md-7 col-lg-8 checkpointDescription">
                        <h3 class="h6">{{ item.name }}</h3>
                        {{ item.description }}
                      </div>
                      <div class="col-md-5 col-lg-4 pl-4 pt-3">
                        <div class="row">
                          <div
                            class="col-4 text-center"
                            ng-repeat="class in ['red', 'amber', 'green']"
                          >
                            <checkpoint-radio
                              name="rag-{{ item.id }}"
                              class="checkpoint-radio {{ class }}"
                              vote="$ctrl.props.votes[item.id].closed ? $ctrl.votes[item.id][$index] : null"
                              closed="$ctrl.alreadyVoted || $ctrl.props.votes[item.id].closed"
                              on-click="$ctrl.onClickOneByOne(item.id, $index + 1)"
                              ng-checked="($ctrl.props.votes[item.id].closed ? $ctrl.calcRag($ctrl.props.votes[item.id]): $ctrl.vote[item.id]) === $index + 1"
                              ng-disabled="$ctrl.alreadyVoted || $ctrl.props.activeCheckpointClosed"
                            >
                            </checkpoint-radio>
                          </div>
                        </div>
                      </div>
                      <!-- Hide for now -->
                      <div class="my-2 mx-3" ng-show="false && $ctrl.checkpoints[item.id].ragexamples && $ctrl.checkpoints[item.id].ragexamples.length > 0 && !$ctrl.props.hide_legend">
                        <a
                          href=""
                          ng-click="$ctrl.onClickShowHideLegend(item.id)"
                        >
                          <i class="fa fa-question-circle" aria-hidden="true"></i> <small>{{ $ctrl.legends[item.id] ? 'Hide': 'Show' }} legend</small>
                        </a>
                        <div class="collapse mt-2" id="collapseExample" ng-class="{show: $ctrl.legends[item.id]}">
                          <div class="card card-block">
                            <small class="mb-2"><span class="font-weight-bold text-danger">Sad red:</span> {{item.ragexamples[1]}}</small>
                            <small class="mb-2"><span class="font-weight-bold text-warning">Neutral amber:</span> {{item.ragexamples[2]}}</small>
                            <small><span class="font-weight-bold text-success">Happy green:</span> {{item.ragexamples[3]}}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- <div class="fixed-bottom"> -->
                <button
                  type="submit"
                  class="btn btn-primary submit-bt float-right"
                  ng-hide="$ctrl.props.oneByOne"
                  ng-disabled="$ctrl.voting"
                  ng-if="$ctrl.props && !$ctrl.props.closed"
                >
                  <i class="fa fa-spinner fa-spin" ng-if="$ctrl.voting"></i>
                  Vote
                </button>

                <button
                  type="button"
                  class="btn btn-primary float-right"
                  ng-if="$ctrl.isAnonymous && $ctrl.props.oneByOne && $ctrl.alreadyVoted && !$ctrl.voting && $ctrl.props.closed"
                  ng-click="$ctrl.onRegister()"
                >
                  Register Now
                </button>

                <div class="voteNotification" ng-if="$ctrl.props.oneByOne && ($ctrl.voting || $ctrl.alreadyVoted) && !$ctrl.props.closed">
                  <i class="fa fa-check" ng-if="!$ctrl.voting"></i>
                  <i class="fa fa-spinner fa-spin" ng-if="$ctrl.voting"></i>
                  <span>{{ $ctrl.voting ? 'Voting...' : 'Voted'}} </span>
                </div>

                <div class="text-danger">{{$ctrl.votingError}}</div>
              </form>
            </main>
          </div>
        </div>
      </div>
    </div>
    <enter-name-modal
      target="modal-enter-name"
      on-submit="$ctrl.onTeamNameEntered(name)"
      on-cancel="$ctrl.onTeamNameCancel()"
    />
    `,

  controller($ngRedux, $location, $routeParams, $rootScope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onClick,
      onClickOneByOne,
      onSubmit,
      onClickShowHideLegend,
      shouldShowAvatar: Utils.shouldShowAvatar,
      getAvatarColor: Utils.getAvatarColor,
      calcRag: Utils.calcRag,
      getShortName: Utils.getShortName,
      getTotalCheckpointsCountByType: Utils.getTotalCheckpointsCountByType,
      getActiveCheckpointType: Utils.getActiveCheckpointType,
      getActiveCheckpointIndex: Utils.getActiveCheckpointIndex,
      getCheckpointCount: Utils.getCheckpointCount,
      onCreateCheckin,
      checkinOwnerName: '',
      onTeamNameEntered,
      onTeamNameCancel,
      onJoinAnonymousUser,
      onRegister,
    };

    return $ctrl;

    function $onInit() {
      $ctrl.userid = store.get('user');
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.checkin = $routeParams.checkin;

      // Fetch ballot first
      if ($routeParams.code) {
        $ctrl.fetchBallotWithCode($routeParams.code, true);
      } else if ($routeParams.id) {
        $ctrl.fetchBallot($routeParams.id);
      }

      if ($ctrl.isAnonymous) {
        $ctrl.anonymous = { loading: true };
        $ctrl.fetchUser($ctrl.userid);
      } else {
        if ($ctrl.isLoggedIn) {
        } else {
          // Ballot fetch is pending -  will be done after user is logged in in $docheck
          $ctrl.pendingBallot = true;
        }
      }

      $ctrl.vote = {};
      $ctrl.legends = {};
    }

    function $onDestroy() {
      $ctrl.cancelBallot();
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.isAnonymous && $ctrl.anonymous.loading && !$ctrl.userFetching) {
        $ctrl.anonymous.loading = false;
        const prevLoginDisplayName = store.get('prevLoginDisplayName');
        if (prevLoginDisplayName) {
          $ctrl.anonymous.isFirstLogin = false;
          $ctrl.anonymous.placeholder = prevLoginDisplayName;
        } else {
          $ctrl.anonymous.isFirstLogin = true;
        }
      }

      if ($ctrl.pendingJoin && $ctrl.updateAnonymousDataSuccess) {
        $ctrl.pendingJoin = false;
        $ctrl.isAnonymousJoined = true;
      }

      if ($ctrl.pendingBallot && $ctrl.isLoggedIn) {
        $ctrl.fetchBallot($routeParams.id);
        $ctrl.pendingBallot = false;
      }

      if ($ctrl.props.initiativeName) {
        $rootScope.title = `Init - Initiative: ${$ctrl.props.initiativeName}`;
      }

      // ballot is loaded and vote object is not initialised
      if ($ctrl.votingSuccess && !$ctrl.props.oneByOne) {
        // only if the ballot is not the oneByOne ballot
        // go to landing page
        $location.path('/');
      }
    }

    function mapState(state) {
      const ballot = state.ballot.data;
      const checkpoints = state.checkpoints.data;
      const vote = {};

      // Inject only normal checkpoin
      Utils.injectCheckpoints(ballot, checkpoints);
      const dicCheckpoints = { ...checkpoints };
      if (ballot && ballot.checkpoints) {
        ballot.checkpoints.forEach(c => {
          dicCheckpoints[c.id] = c;
        });
      }

      const chkTypes = ballot ? Utils.getChkTypes(ballot.checkpoints) : [];

      const props = {
        props: { ...ballot },
        isLoggedIn: !!state.user.data.id,
        updateAnonymousDataSuccess: state.user.updateProfileSuccess,
        userId: state.user.data.id,
        isAnonymous: state.user.data.isAnonymous,
        userFetching: state.user.loading,
        userError: state.user.fetchError,
        userName: state.user.data.name,
        checkpoints: dicCheckpoints,
        success: state.ballot.success,
        fetching: state.ballot.loading,
        voting: state.vote.creating,
        votingSuccess: state.vote.success,
        votingError: state.vote.error,
        chkTypes,
      };

      // ballot is closed: calc the final rags value
      if (ballot && ballot.closed && ballot.votes) {
        for (let key in ballot.votes) {
          vote[key] = Utils.calcRag(ballot.votes[key]);
        }
        props.vote = vote;
      }

      // activeCheckpoints

      if (ballot && ballot.oneByOne) {
        const { activeCheckpoint } = ballot;
        if (!Array.isArray(ballot.checkpoints)) {
          ballot.checkpoints = [];
        }
        const activeCP = ballot.checkpoints.find(c => c.id == activeCheckpoint);
        if (activeCP) {
          props.props.activeCheckpoints = [activeCP];
          // calculate vote percent
          props.votes = {};
          props.votes[activeCP.id] = Utils.calcVotePercent(ballot, activeCP.id);

          if (ballot.votes && ballot.votes[activeCP.id]) {
            props.props.activeCheckpointClosed = !!ballot.votes[activeCP.id]
              .closed;
          } else {
            props.props.activeCheckpointClosed = false;
          }

          if (
            ballot.votes &&
            ballot.votes[activeCP.id] &&
            ballot.votes[activeCP.id].uservotes
          ) {
            const vote = ballot.votes[activeCP.id].uservotes[$ctrl.userid];
            props.alreadyVoted = vote >= INFORMAL && vote <= GREEN;
            if (props.alreadyVoted) {
              props.vote = {};
              props.vote[activeCP.id] = vote; // Load my vote index
            }
          } else {
            props.alreadyVoted = false;
          }
        } else {
          props.alreadyVoted = false;
        }
      } else if (ballot && !ballot.oneByOne) {
        // not oneByOne ballot
        props.alreadyVoted = !!(ballot.voters && ballot.voters[$ctrl.userid]);
      }

      // calculate percent - normal ballot
      const votes = {}; // { id: {redvotes, ambervotes, greenvotes, informalvotes}}
      if (ballot && ballot.closed && ballot.votes) {
        for (let i = 0; i < ballot.checkpoints.length; i++) {
          votes[ballot.checkpoints[i].id] = Utils.calcVotePercent(
            ballot,
            ballot.checkpoints[i].id
          );
        }
        props.votes = votes;
      }

      return props;
    }

    function mapDispatch() {
      return {
        fetchBallot,
        fetchBallotWithCode,
        cancelBallot,
        createVote,
        createInitiative,
        updateAnonymousUserData,
        fetchUser,
      };
    }

    function onClick(itemId, ragIndex) {
      // only if ballot is not closed
      if (!$ctrl.props.closed) {
        if ($ctrl.vote[itemId] === ragIndex) {
          delete $ctrl.vote[itemId];
        } else {
          $ctrl.vote[itemId] = ragIndex;
        }
      }
    }

    function onClickOneByOne(itemId, ragIndex) {
      if (!$ctrl.props.closed) {
        if ($ctrl.vote[itemId] === ragIndex) {
          delete $ctrl.vote[itemId];
        } else {
          $ctrl.vote[itemId] = ragIndex;
        }
      }
      $ctrl.onSubmit();
      $ctrl.alreadyVoted = true;
    }

    function onSubmit() {
      // set -1 for unselected checkpoints
      const vote = { ...$ctrl.vote };

      if ($ctrl.props.oneByOne) {
        if ($ctrl.props.activeCheckpointClosed) {
          // disable vote if active checkpoint is closed
          return;
        }

        if (
          $ctrl.props.activeCheckpoints[0] &&
          !vote[$ctrl.props.activeCheckpoint]
        ) {
          // active checkpoint is not voted
          vote[$ctrl.props.activeCheckpoint] = INFORMAL;
        }
      } else {
        // not oneByOne ballot
        for (let itemKey in $ctrl.props.checkpoints) {
          if (isNaN(vote[$ctrl.props.checkpoints[itemKey].id])) {
            vote[$ctrl.props.checkpoints[itemKey].id] = INFORMAL;
          }
        }
      }

      // $ctrl.props.activeCheckpoint - only valid in oneByOne ballot
      $ctrl.createVote(
        $ctrl.props.id,
        $ctrl.props.activeCheckpoint,
        vote,
        $ctrl.props.oneByOne
      );
    }

    function onClickShowHideLegend(checkpoint_id) {
      $ctrl.legends[checkpoint_id] = !$ctrl.legends[checkpoint_id];
    }

    function onCreateCheckin() {
      $('#modal-enter-name').modal('show');
      $('#modal-enter-name').appendTo('body');
    }

    function onTeamNameEntered(teamName) {
      if (teamName) {
        $('#modal-enter-name').modal('hide');
        $ctrl.createInitiative({
          name: teamName,
          type: 'lite',
          creator: $ctrl.userId,
          owner: $ctrl.userId,
        });
      }
    }

    function onTeamNameCancel() {
      $('#modal-enter-name').modal('hide');
    }

    function onJoinAnonymousUser(name) {
      $ctrl.pendingJoin = true;
      store.set('prevLoginDisplayName', name);

      $ctrl.updateAnonymousUserData({ name });
    }

    function onRegister() {
      $location.path('/register');
    }
  },
};
