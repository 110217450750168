import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';

// Action Types
export const FETCH_QUESTIONS = 'app/questions/FETCH';
export const SUCCESS_QUESTIONS = 'app/questions/SUCCESS';
export const ERROR_QUESTIONS = 'app/questions/ERROR';
export const CANCEL_QUESTIONS = 'app/questions/CANCEL';

// Initial State
export const initialState = {
  questions: {},
  loading: false,
};

// Actions
export function fetchQuestions() {
  return { type: FETCH_QUESTIONS };
}

export function cancelQuestions() {
  return { type: CANCEL_QUESTIONS };
}

// Observable Logic
export const questionsFetch = createLogic({
  latest: true,
  type: FETCH_QUESTIONS,
  cancelType: CANCEL_QUESTIONS,
  processOptions: {
    successType: SUCCESS_QUESTIONS,
    failType: ERROR_QUESTIONS,
  },
  process(/*{ $http }*/) {
    return firebase
      .database()
      .ref('constants/questions')
      .once('value')
      .then(snapshot => {
        const questions = snapshot.val();
        return {
          questions,
        };
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_QUESTIONS:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case SUCCESS_QUESTIONS:
      return {
        ...state,
        data: [...payload.questions],
        loading: false,
        success: true,
      };

    case ERROR_QUESTIONS:
      return {
        ...state,
        ...payload,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
}
