import { getRemovedMarkdownString } from 'utils';

export default {
  bindings: {
    props: '<',
    shorterName: '<',
    showTags: '<',
    hideStar: '<',
    onClickTag: '&',
    onStarInitiative: '&',
  },
  template: `
    <h2 class="h5 mb-3">
      <badge-status class="mr-2" status="$ctrl.props.status" ng-if="$ctrl.props.type !== 'lite'"></badge-status>
      <a
        class="font-weight-light"
        title="{{ $ctrl.getRemovedMarkdownString($ctrl.props.pitch) }}"
        href="/initiatives/{{ $ctrl.props.id }}"
        data-toggle="tooltip"
        data-placement="bottom"
        data-animation="false"
        data-touch="false"
        data-original-title="{{ $ctrl.getRemovedMarkdownString($ctrl.props.pitch) }}"
        ng-class="{
          'text-muted': $ctrl.props.status === 'Draft',
          'text-warning': $ctrl.props.status === 'Planning',
          'text-primary': $ctrl.props.status === 'Development',
          'text-success': $ctrl.props.status === 'Live'
        }">{{ $ctrl.props.name }}</a>
    </h2>
    <div class="mb-3">

      <div class="productOwner">
        <div class="productOwner__avatar">
          <img class="rounded-circle d-inline-block avatar" ng-if="$ctrl.props.owner.photoURL" ng-src="{{$ctrl.props.owner.photoURL}}" />
          <span class="rounded-circle d-inline-block avatar" ng-if="!$ctrl.props.owner.photoURL">{{$ctrl.shorterName($ctrl.props.userInfo.name)}}</span>
        </div>
        <div class="productOwner__text">
          <div>{{ $ctrl.props.owner.name && $ctrl.props.owner.name.length > 0 ? $ctrl.props.owner.name : $ctrl.props.owner.email }}</div>
          <small class="d-block text-muted">Product Owner</small>
        </div>
      </div>

      <div ng-if="$ctrl.props.team" class="teamCount">
        <i class="fa fa-users" aria-hidden="true"></i>
        <div ng-if="$ctrl.getTeamMembersCount($ctrl.props.team) > 0">
          {{$ctrl.getTeamMembersCount($ctrl.props.team)}} team member<span ng-if="$ctrl.getTeamMembersCount($ctrl.props.team) > 1">s<span>
        </div>
        <div ng-if="$ctrl.getTeamMembersCount($ctrl.props.team) == 0">
          No team members
        </div>
      </div>
      
    </div>
    <tags ng-show="$ctrl.showTags" props="$ctrl.props.tags" margins="true" compact="true" size="sm" on-click="$ctrl.onClickTag({ tag: tag })" ng-if="$ctrl.props.type !=='lite'"/>
    <div>
        <button
          ng-attr-id="{{$ctrl.props.id}}staredUserNames"
          ng-if="!$ctrl.hideStar"
          class="btn btn-secondary btn-sm"
          data-toggle="tooltip"
          data-placement="bottom"
          data-animation="false"
          title="{{$ctrl.props.staredUserNames}}"
          ng-click="$ctrl.onStarInitiative({ id: $ctrl.props.id, isStared: !$ctrl.props.isStared })"
        >
          <i class="fa fa-star" aria-hidden="true" ng-class="{'text-warning' : $ctrl.props.isStared}"></i>&nbsp;{{$ctrl.props.starCount}}
        </button>
    </div>`,
  controller() {
    'ngInject';
    const $ctrl = {
      getTeamMembersCount,
      getRemovedMarkdownString,
    };

    return $ctrl;

    function getTeamMembersCount(team) {
      if (typeof team === 'object') {
        return Object.keys(team).length;
      } else {
        return 0;
      }
    }
  },
};
