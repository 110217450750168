import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import store from 'store';

export const INIT = 'app/impediment/INIT';
export const CREATE = 'app/impediment/CREATE';
export const FETCH = 'app/impediment/FETCH';
export const CHANGE_TAGS = 'app/impediment/CHANGE_TAGS';
export const LINK_OBJECT = 'app/impediment/LINK';
export const UNLINK_OBJECT = 'app/impediment/UNLINK';
export const UPDATE = 'app/impediment/UPDATE';
export const REMOVE = 'app/impediment/REMOVE';
export const RESOLVE = 'app/impediment/RESOLVE';
export const UP_VOTE = 'app/impediment/UP_VOTE';
export const DOWN_VOTE = 'app/impediment/DOWN_VOTE';
export const CREATE_SUCCESS = 'app/impediment/CREATE_SUCCESS';
export const CREATE_ERROR = 'app/impediment/CREATE_ERROR';
export const UPDATE_SUCCESS = 'app/impediment/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'app/impediment/UPDATE_ERROR';
export const FETCH_SUCCESS = 'app/impediment/FETCH_SUCCESS';
export const FETCH_ERROR = 'app/impediment/FETCH_ERROR';
export const REMOVE_SUCCESS = 'app/impediment/REMOVE_SUCCESS';
export const REMOVE_ERROR = 'app/impediment/REMOVE_ERROR';
export const RESOLVE_SUCCESS = 'app/impediment/RESOLVE_SUCCESS';
export const RESOLVE_ERROR = 'app/impediment/RESOLVE_ERROR';
export const CANCEL = 'app/impediment/CANCEL';

export const initialState = {
  creating: false,
  loading: false,
  updating: false,
  redirect: false, // This needs to move to middleware
  error: '',
  data: {},
};

// Actions
export function initImpediment() {
  return { type: INIT };
}

export function resolveImpediment(payload) {
  return { type: RESOLVE, payload };
}

export function upVoteImpediment(payload) {
  return { type: UP_VOTE, payload };
}

export function downVoteImpediment(payload) {
  return { type: DOWN_VOTE, payload };
}

export function createImpediment(payload) {
  return { type: CREATE, payload: angular.copy(payload) };
}

export function fetchImpediment(payload) {
  return { type: FETCH, payload };
}

export function removeImpediment(payload) {
  return { type: REMOVE, payload };
}

export function updateImpediment(payload) {
  return { type: UPDATE, payload };
}

export function changeTags(payload) {
  return { type: CHANGE_TAGS, payload };
}

export function linkInitiative(payload) {
  return {
    type: LINK_OBJECT,
    payload: { object: payload, type: 'Initiative' },
  };
}

export function unLinkInitiative(payload) {
  return {
    type: UNLINK_OBJECT,
    payload: { object: payload, type: 'Initiative' },
  };
}

export const impedimentFetch = createLogic({
  latest: true,
  type: FETCH,
  cancelType: CANCEL,
  processOptions: {
    successType: FETCH_SUCCESS,
    failType: FETCH_ERROR,
  },
  transform({ action }, next) {
    next(action);
  },
  process({ $http, action }) {
    if (!action.payload) {
      const error = 'No impediment exits';
      done();
      throw { error };
      return;
    }
    return firebase
      .firestore()
      .collection(`impediments`)
      .doc(action.payload)
      .get()
      .then(docRef => {
        let res = docRef.data();
        res.id = docRef.id;
        return {
          data: res,
        };
      });
  },
});

export const impedimentCreate = createLogic({
  latest: true,
  type: CREATE,
  cancelType: CANCEL,
  processOptions: {
    successType: CREATE_SUCCESS,
    failType: CREATE_ERROR,
  },
  transform({ action }, next) {
    next(action);
  },
  process({ $http, action }) {
    if (!action.payload) {
      const error = 'No impediment exist';
      throw { error };
      return;
    }
    let timestamp = firebase.firestore.FieldValue
      ? firebase.firestore.FieldValue.serverTimestamp()
      : null;
    return firebase
      .firestore()
      .collection('impediments')
      .add({
        ...action.payload,
        creator: store.get('user'),
        created_date: timestamp,
      })
      .then(docRef => {
        return {
          ...action.payload,
          id: docRef.id,
        };
      });
  },
});

export const impedimentRemove = createLogic({
  latest: true,
  type: REMOVE,
  cancelType: CANCEL,
  processOptions: {
    successType: REMOVE_SUCCESS,
    failType: REMOVE_ERROR,
  },
  process({ action }) {
    if (!action.payload) {
      const error = 'No impediment exist';
      throw { error };
    }
    return firebase
      .firestore()
      .collection('impediments')
      .doc(action.payload.id)
      .delete()
      .then(() => {
        return {
          data: action.payload,
        };
      });
  },
});

export const impedimentUpdate = createLogic({
  latest: true,
  type: [UPDATE, RESOLVE, UP_VOTE, DOWN_VOTE],
  cancelType: CANCEL,
  processOptions: {
    successType: UPDATE_SUCCESS,
    failType: UPDATE_ERROR,
  },
  transform({ action, getState }, next) {
    const payload = angular.copy(action.payload);
    let timestamp = firebase.firestore.FieldValue
      ? firebase.firestore.FieldValue.serverTimestamp()
      : null;
    if (action.type === RESOLVE) {
      payload.resolved = true;
      payload.resolvedDate = timestamp;
    }
    if (action.type === UP_VOTE || action.type === DOWN_VOTE) {
      const userData = getState().user.data;
      if (!payload.votes) {
        payload.votes = {};
      }
      if (!payload.votes[userData.id]) {
        payload.votes[userData.id] = {
          vote: 0,
          voterName: userData.name,
          voterPhoto: userData.photoURL,
        };
      }

      if (action.type === UP_VOTE) {
        if (payload.votes[userData.id].vote < 1) {
          payload.votes[userData.id].vote += 1;
        }
      }

      if (action.type === DOWN_VOTE) {
        if (payload.votes[userData.id].vote >= 0) {
          payload.votes[userData.id].vote -= 1;
        }
      }
    }
    next({ ...action, payload });
  },
  process({ action }) {
    if (!action.payload) {
      const error = 'No impediment exist';
      throw { error };
    }
    return firebase
      .firestore()
      .collection('impediments')
      .doc(action.payload.id)
      .update({
        ...action.payload,
      })
      .then(() => {
        return {
          ...action.payload,
        };
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case INIT: {
      return {
        ...initialState,
        data: {},
      };
    }

    case CREATE: {
      return {
        ...state,
        createSuccess: false,
        creating: true,
        loading: false,
      };
    }

    case DOWN_VOTE:
    case UP_VOTE: {
      return {
        ...state,
      };
    }

    case FETCH: {
      return {
        ...state,
        data: {},
        fetchSuccess: false,
        removeSuccess: false,
        createSuccess: false,
        resolveSuccess: false,
        updateSuccess: false,
        creating: false,
        loading: true,
        removing: false,
      };
    }

    case UPDATE: {
      return {
        ...state,
        updateSuccess: false,
        updating: true,
        creating: false,
        loading: false,
      };
    }

    case REMOVE: {
      return {
        ...state,
        removeSuccess: false,
        removing: true,
      };
    }

    case RESOLVE: {
      return {
        ...state,
        updateSuccess: false,
        resolving: true,
      };
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        fetchSuccess: true,
        creating: false,
        loading: false,
        data: action.payload.data,
      };
    }

    case CREATE_SUCCESS: {
      return {
        ...state,
        createSuccess: true,
        creating: false,
        loading: false,
        data: action.payload,
      };
    }

    case CREATE_ERROR: {
      return {
        ...state,
        createSuccess: false,
        creating: false,
        loading: false,
        error: action.payload,
        data: {},
      };
    }

    case UPDATE_SUCCESS: {
      return {
        ...state,
        updateSuccess: true,
        creating: false,
        loading: false,
        updating: false,
        resolving: false,
        data: action.payload,
      };
    }

    case UPDATE_ERROR: {
      return {
        ...state,
        updateSuccess: false,
        creating: false,
        loading: false,
        updating: false,
        error: action.payload,
        resolving: false,
        data: {},
      };
    }

    case REMOVE_SUCCESS: {
      return {
        ...state,
        removeSuccess: true,
        removing: false,
        data: {},
      };
    }

    case REMOVE_ERROR: {
      return {
        ...state,
        removeSuccess: false,
        removing: false,
        data: {},
      };
    }

    case LINK_OBJECT: {
      const data = state.data;
      const { type, object } = payload;

      if (!Array.isArray(data.objects)) {
        data.objects = [];
      }

      data.objects = data.objects.filter(
        item =>
          item.type !== type ||
          (item.type === type && item.object.id !== object.id)
      );
      data.objects.push({
        type,
        object: {
          id: object.id,
          name: object.name,
          status: object.status,
          creator: object.creator,
        },
      });

      return {
        ...state,
        data,
        loading: false,
      };
    }

    case UNLINK_OBJECT: {
      const data = state.data;
      const { type, object } = payload;

      if (!Array.isArray(data.objects)) {
        data.objects = [];
      }

      data.objects = data.objects.filter(
        item =>
          item.type !== type ||
          (item.type === type && item.object.id !== object.id)
      );

      return {
        ...state,
        data,
      };
    }

    case CHANGE_TAGS: {
      const data = state.data;
      data.tags = payload;
      return {
        ...state,
        data,
      };
    }

    default:
      return state;
  }
}
