export default {
  bindings: {
    target: '@',
    owners: '<',
    onChange: '&',
    onQuery: '&',
    isOfQuery: '&',
    isOwner: '&',
    loading: '<',
    title: '@',
    buttonTitle: '@',
    onInvite: '&'
  },

  template: `
    <div ng-attr-id="{{$ctrl.target}}" class="modal fade {{ $ctrl.target }}" tabindex="-1" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header p-4">
                <h3 class="modal-title">{{$ctrl.title}}</h3>
            </div>
            <div class="modal-body p-4 pb-0">
                <div class="form-group">
                    <label for="name" class="col-form-label text-gray-dark">
                        Search by name, or enter an email
                    </label>
                    <input
                        ng-attr-id="{{$ctrl.target}}searchQuery"
                        name="searchQuery"
                        class="form-control form-control-danger"
                        ng-model="$ctrl.props.searchQuery"
                        ng-change="$ctrl.onQuery({text: $ctrl.props.searchQuery, size: 10})"
                    >

                </div>

                <div ng-if="$ctrl.props.searchQuery">

                    <div class="d-flex justify-content-between my-2">
                        <small ng-if="$ctrl.owners.length >= 0" class="d-block font-italic text-muted">
                            <span ng-if="$ctrl.owners.length >= 10">Top</span>
                            <span ng-if="$ctrl.owners.length > 0" ng-bind="$ctrl.owners.length"></span>
                            <span ng-if="$ctrl.owners.length > 0">
                            match<span ng-if="$ctrl.owners.length == 0 || $ctrl.owners.length > 1">es</span>
                            <span ng-if="$ctrl.owners.length < 10"> found</span> </span>
                        </small>
                        <small ng-if="$ctrl.loading"><i class='fa fa-spinner fa-spin' /> Fetching results...</small>
                        <div ng-if="!$ctrl.loading && $ctrl.owners.length == 0 && $ctrl.isEmail($ctrl.props.searchQuery)">
                          <span>
                            User <b>{{$ctrl.props.searchQuery}}</b> is not in Init.  Would you like to invite him/her to join?
                          </span>
                          <button 
                            type="button" 
                            class="btn btn-primary" 
                            data-dismiss="modal"
                            ng-click="$ctrl.onClickInvite()"
                          >
                              Invite
                          </button>
                        </div>
                    </div>
  
                  <div ng-repeat="user in $ctrl.owners" class="groupShow__memberRepeater">
                      <label class="custom-control groupShow__memberItem" ng-class="$ctrl.isOwner({ id: user.id }) ? 'custom-checkbox' : 'custom-radio'">
                          <input
                                  type="radio"
                                  class="custom-control-input"
                                  name="Users"
                                  ng-model="$ctrl.props.selUser"
                                  ng-value="user"
                                  ng-if="!$ctrl.isOwner({ id: user.id })"
                          />
                          <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  ng-model="checkbox"
                                  ng-checked="true"
                                  ng-if="$ctrl.isOwner({ id: user.id })"
                                  disabled>
  
                          <div class="custom-control-indicator"></div>
                          <div class="groupShow__userName" ng-if="user.name" style="user-select: none;">{{ user.name }}</div>
                          <!--div class="groupShow__userEmail" style="user-select: none;">{{ user.email }}</div-->
                      </label>
                  </div>
                 </div>

                <div class="clearfix pt-2">
                    <div class="alert alert-danger" ng-if="$ctrl.error">
                        <p>{{$ctrl.error}}</p>
                    </div>
                    <button 
                        type="button"
                        class="btn btn-primary ml-2 float-right" 
                        ng-click="$ctrl.onChange({ owner: $ctrl.props.selUser })"
                        ng-disabled="$ctrl.owners.length < 1 || !$ctrl.props.selUser || !$ctrl.isOfQuery({ id: $ctrl.props.selUser.id })"
                        data-dismiss="modal">
                        {{$ctrl.buttonTitle}}
                    </button>
                    <button type="button" class="btn btn-danger float-right" data-dismiss="modal">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>`,

  controller($scope) {
    'ngInject';

    const $ctrl = {
      $onInit,
      isEmail,
      onClickInvite
    };

    function isEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    return $ctrl;

    function onClickInvite() {
      $ctrl.onInvite({ email: $ctrl.props.searchQuery})
    }
    function $onInit() {
      $(document).ready(function() {
        $(`#${$ctrl.target}`).on('hide.bs.modal', function() {
          $ctrl.props = undefined;
        });

        $(`#${$ctrl.target}`).on('shown.bs.modal', function() {
          $(`#${$ctrl.target}searchQuery`).focus();
        });
      });
    }
  },
};
