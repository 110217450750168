import { api } from 'settings';

export default () => {
  return {
    request(config) {
      const url = api.endpoint + config.url;
      return { ...config, url };
    },
  };
};
