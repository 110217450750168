import html from './priorities.html';
import colors from 'constants/colors';

import './priorities.scss';

export default {
  bindings: {
    priorities: '<',
    onRemovePriority: '&',
    onEditPriority: '&',
    isEditDisabled: '<',
    isRemoveDisabled: '<',
  },

  template: html,

  controller() {
    'ngInject';
    const $ctrl = {
      onRemoveButtonClicked,
      colors,
    };

    return $ctrl;

    function onRemoveButtonClicked(id) {
      angular.element(`#remove-button-${id}`).tooltip('dispose');
      $ctrl.onRemovePriority({ id });
    }
  },
};
