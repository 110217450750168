export default {
  bindings: {
    loading: '<',
    paused: '<',
    headerTitle: '<',
    views: '<',
    hideToggle: '<',
    onClick: '&',
    onToggle: '&',
    isShow: '<',
    quering: '<',
  },

  template: `
    <header class="wallboard-header border-bottom text-center text-sm-left d-sm-flex align-items-center justify-content-between">
      <h1 class="h1 display-4 mb-2 mb-sm-0">
        {{ $ctrl.headerTitle }}
      </h1>
      <ul
        class="list-unstyled d-flex justify-content-center mb-0" 
        ng-class="$ctrl.isShow ? 'wallboard-show-list-unstyled' : 'wallboard-list-unstyled'">
        <div class="h2 mr-3 mt-2 align-self-center" ng-show="$ctrl.quering">
          <i class='fa fa-spinner fa-spin'></i>
        </div>
        <li
          class="h1"
          ng-if="!$ctrl.hideToggle"
          ng-click="$ctrl.onToggle()">
          <i class="fa" ng-class="$ctrl.paused ? 'fa-play' : 'fa-pause'"></i>
        </li>
        <li
          ng-repeat="item in $ctrl.views"
          ng-click="$ctrl.onClick({ name: item.name })"
          ng-if="!item.hidden"
          class="h1">
          <i class="fa" ng-class="item.active ? 'fa-circle' : $ctrl.isShow ? 'fa-circle-o' : 'fa-circle-thin'"></i>
        </li>
      </ul>
      <span
        class="wallboard-header-progress"
        ng-class="{ loading: $ctrl.loading }">
      </span>
    </header>`,
};
