import { arc, interpolate, pie, select } from 'd3';

export default {
  bindings: {
    data: '<',
    label: '<',
    icon: '<',
    width: '<',
  },

  template: `
    <div id="{{ $ctrl.id }}" class="graph-donut"></div>`,

  controller($timeout, $element) {
    `ngInject`;

    const $ctrl = {
      $onInit,
      $postLink,
      renderDonutGraph,
    };

    return $ctrl;

    function $onInit() {
      $ctrl.id = `graph-donut-${Math.round(Math.random() * 100000)}`;
    }

    function $postLink() {
      const { id, data, label, icon, width } = $ctrl;

      if (data) {
        $timeout(() => $ctrl.renderDonutGraph(id, data, label, icon, width));
      }
    }

    function renderDonutGraph(id, dataset, label, icon, pieWidth = null) {
      const $el = $element.find(`#${id}`);
      const diameter = Math.min($el.width(), $el.height());
      const radius = diameter / 2;
      const gap = 3;

      if (diameter < 0) {
        return;
      }

      pieWidth = pieWidth || Math.round(diameter / 10);

      const svg = select(`#${id}`)
        .append('svg')
        .attr('width', diameter)
        .attr('height', diameter);

      dataset.forEach((data, i) => {
        const outerRadius = radius - (Number(pieWidth) + gap) * i;
        const innerRadius = outerRadius - pieWidth;

        const d3Arc = arc()
          .outerRadius(outerRadius)
          .innerRadius(innerRadius)
          .padAngle('0.02')
          .cornerRadius('2');

        const d3Pie = pie()
          .value(d => d.value)
          .sort(null)
          .padAngle(0.02);

        svg
          .append('g')
          .attr('class', 'arcs')
          .attr('transform', `translate(${radius},${radius})`)
          .selectAll('path')
          .data(d3Pie(data))
          .enter()
          .append('path')
          .attr('d', d3Arc)
          .attr('fill', d => d.data.color)
          .attr('class', d => d.data.className)
          .transition()
          .duration(1000 - 250 * i)
          .attrTween('d', d => {
            const interpolateArc = interpolate(
              {
                startAngle: 0,
                endAngle: 0,
              },
              d
            );

            return t => d3Arc(interpolateArc(t));
          });

        // Check
        const iconPadding = pieWidth * 0.2;
        const iconLeft = diameter / 2 + 3;
        const iconOffset = radius - outerRadius + iconPadding / 2;

        svg
          .append('g')
          .attr('class', 'icons')
          .selectAll('icons')
          .data(data)
          .enter()
          .filter(d => d.icon)
          .append('g')
          .attr('transform', d => {
            const scale = (pieWidth - iconPadding) / d.icon.height;
            return `translate(${iconLeft},${iconOffset}) scale(${scale},${scale})`;
          })
          .append('path')
          .attr('d', d => d.icon.path)
          .style('fill', '#fff');

        svg
          .append('g')
          .attr('class', 'labels')
          .attr('transform', `translate(${radius},${radius})`)
          .selectAll('labels')
          .data(data)
          .enter()
          .filter(d => d.label)
          .append('text')
          .attr('class', d => d.label.className)
          .attr('fill', d => d.label.color)
          .attr('font-size', d => {
            const fontPercentage = d.label.fontPercentage || 12;
            return (diameter * fontPercentage) / 100;
          })
          .attr('y', d => {
            if (d.label.offsetPercentage) {
              return (diameter * d.label.offsetPercentage) / 100;
            } else {
              return 0;
            }
          })
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text(d => d.label.text);

        if (i > 0) {
          return;
        }

        if (label) {
          svg
            .select('g')
            .append('text')
            .attr('class', 'label')
            .attr('dy', label.dy)
            .attr('fill', label.fill)
            .attr('text-anchor', label.textAnchor)
            .text(label.name);
        }
      });
    }
  },
};
