export default [
  {
    name: 'pitch',
    active: false,
  },
  {
    name: 'problem',
    active: false,
  },
  {
    name: 'checkpoints',
    active: false,
  },
  {
    name: 'mobilisation-tasks',
    active: false,
  },
  {
    name: 'performance',
    active: false,
  },
  {
    name: 'badges',
    active: false,
  },
  {
    name: 'team-member',
    active: false,
  },
];
