import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import store from 'store';
import axios from 'axios';
import getFirebaseConfig from '../config/firebase';
const firebaseProjectId = getFirebaseConfig().projectId;
// Action Types
export const FETCH_SURVEY_RESULT = 'app/survey/result/FETCH/RESULT';
export const SUCCESS_SURVEY_RESULT = 'app/survey/result/SUCCESS';
export const ERROR_SURVEY_RESULT = 'app/survey/result/ERROR';
export const CANCEL_SURVEY_RESULT = 'app/survey/result/CANCEL';

// Initial State
export const initialState = {
  data: {},
  loading: false,
};

// Actions

export function fetchSurveyResult() {
  return { type: FETCH_SURVEY_RESULT };
}

export function cancelSurveyResult() {
  return { type: CANCEL_SURVEY_RESULT };
}

// Observable Logic

export const surveyResultFetch = createLogic({
  latest: true,
  type: FETCH_SURVEY_RESULT,
  cancelType: CANCEL_SURVEY_RESULT,
  process({ action }, dispatch, done) {
    axios({
      method: 'POST',
      url: `https://us-central1-${firebaseProjectId}.cloudfunctions.net/getSurveyResult`,
      headers: {
        Authorization: store.get('Authorization'),
      },
      data: {},
      json: true,
    })
      .then(response => {
        dispatch({
          type: SUCCESS_SURVEY_RESULT,
          payload: { data: response.data },
        });
        done();
      })
      .catch(e => {
        Raven.captureExceptionEx(e);
        dispatch({
          type: ERROR_SURVEY_RESULT,
        });
        done();
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SURVEY_RESULT:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        saving: false,
      };

    case SUCCESS_SURVEY_RESULT:
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: false,
        success: true,
        saving: false,
      };

    case ERROR_SURVEY_RESULT:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        saving: false,
      };

    default:
      return state;
  }
}
