import * as _ from 'lodash';
import * as firebase from 'firebase';

import {
  fetchInitiative,
  cancelInitiative,
  updateInitiative,
  fetchInitiativeWithBallot,
  setRagValue,
  removeCheckin,
} from 'logic/initiative';

import {
  openVoting,
  closeVoting,
  nextCheckpoint,
  setVoteVisibility,
} from 'logic/ballot';
import { fetchQuestionSets } from 'logic/questionSet';

import { sendEvent } from 'analytics';

import decorateInitiative from 'decorators/initiative';
import {
  allVoted,
  getJoinedUsersCount,
  getVotedUsersCount,
  canBeClosed,
  isNextCheckpointAvailable,
  calcRag,
  getVotedCheckpointsCount,
  getTotalCheckpointsCount,
  getTotalCheckpointsCountByType,
  getActiveCheckpointIndex,
  getAvatarColor,
  shouldShowAvatar,
  getShortName,
  getCheckpointCount,
  isCheckInAllowed,
  getCheckpointRag,
  injectCheckpoints,
  roundHalfEven,
  getOpenCheckpointCount,
  injectCustomCheckpoints,
  getChkTypes,
  calcVotePercent,
} from '../../utils';

import { INFORMAL, RED, GREEN, AMBER } from '../../config/consts';

import './checkin.scss';

export default {
  template: `
    <mobile-nav ng-model="$ctrl.mobileMenuChecked"></mobile-nav>
    <div class="page-wrapper" ng-class="{'slided-wrapper': $ctrl.mobileMenuChecked}">
      <header-site></header-site>
      <div class="content-wrapper">
        <div class="container" ng-if="$ctrl.fetching">
          <div class="main text-center">
            <h4>Loading checkpoints...</h4>
          </div>
        </div>
        <div class="container" ng-if="$ctrl.initiativeFetchError">
          <div class="main">
            <h4>{{$ctrl.initiativeFetchError}}</h4>
          </div>
        </div>
        <div class="container initiatives-checkin" ng-if="!$ctrl.fetching && !$ctrl.initiativeFetchError && !$ctrl.isCheckInAllowed($ctrl.props, $ctrl.checkin)">
          <main class="main clearfix">
            <header style="text-align: center;">
              <h4>Invalid Checkin!</h4>
            </header>
          </main>
        </div>
        <div class="container initiatives-checkin" ng-if="!$ctrl.fetching && !$ctrl.initiativeFetchError && $ctrl.isCheckInAllowed($ctrl.props, $ctrl.checkin)">
          <main class="main clearfix">
            <header>
              <h1 class="h2 mt-0 mb-1 d-flex align-items-center header-link">
                <a href="/initiatives/{{ $ctrl.props.id }}">{{$ctrl.props.name}}</a>
                <!--<badge-status class="d-flex ml-2" status="$ctrl.props.status"></badge-status>-->
              </h2>
              <small ng-if="$ctrl.props.ballot && $ctrl.props.ballot.id && !$ctrl.props.ballot.oneByOne">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                You have initiated a live poll.
              </small>
              <small ng-if="!$ctrl.props.ballot">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                You have initiated a manual check-in, make your selection and click finish to save.
              </small>
              <small ng-if="$ctrl.props.ballot && $ctrl.props.ballot.id && $ctrl.props.ballot.oneByOne">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                You're running a team check-in. Once the team members have joined you can start the session.
              </small>
            </header>
            <form ng-submit="$ctrl.onSubmit()">
              <section class="action-panel" ng-if="$ctrl.props.ballot && $ctrl.props.ballot.id">
                <div class="action-panel-left" ng-if="$ctrl.props.ballot && !($ctrl.props.ballot.closed)">
                  <div class="share-flex">
                    <div>
                      <div class="d-block">Join at <a href="http://init.team" target="_blank"><strong>init.team</strong></a> with code <strong>{{$ctrl.props.ballot.code}}</strong></div>
                    </div>
                  </div>
                </div>
                <div class="action-panel-right">
                  <!--Normal ballot-->
                  <small class="d-block mb-1" ng-if="!$ctrl.props.ballot.oneByOne">{{$ctrl.props.ballot.votesCount}} voted</small>
                  <!--oneByOne ballot-->
                  <small class="d-block mb-1" ng-if="$ctrl.props.ballot.oneByOne">{{$ctrl.props.ballot.votersCount}} user(s) joined</small>
                  <small class="d-block mb-1" ng-if="$ctrl.props.ballot.oneByOne">{{$ctrl.getVotedCheckpointsCount($ctrl.props.ballot)}} of {{$ctrl.getTotalCheckpointsCount($ctrl.props.ballot)}} checkpoint(s) voted</small>
                  <!--  ng-if="!$ctrl.props.ballot.closed && $ctrl.canBeClosed($ctrl.props.ballot)" -->

                  <button
                    type="button"
                    ng-class="(!$ctrl.props.ballot || $ctrl.props.ballot.closed) ? 'btn btn-primary' : 'btn btn-danger'"
                    ng-click="$ctrl.onClickVote()"
                    ng-disabled="$ctrl.updatingBallot || $ctrl.fetchingBallot"
                    ng-if="!$ctrl.props.ballot.closed"
                  >
                    <i class="fa fa-stop-circle-o" aria-hidden="true" ng-if="!$ctrl.updatingBallot"/></i>
                    <i class='fa fa-spinner fa-spin ' ng-if="$ctrl.updatingBallot"/>
                    {{ $ctrl.props.ballot.closed ? 'Re-open' : 'Complete' }} check-in
                  </button>
                  <small class="text-danger d-block text-right mt-2" ng-if="$ctrl.ballotError">
                    {{$ctrl.ballotError}}
                  </small>
                </div>
              </section>
              <section>
                <div
                  class="d-flex justify-content-start align-items-center flex-wrap mb-2"
                  ng-if="$ctrl.shouldShowAvatar($ctrl.props.ballot)"
                >
                  <div
                    class="d-flex align-items-center voter mb-2 mr-2 py-1 px-2"
                    ng-repeat="voter in $ctrl.props.ballot.voters"
                    ng-class="$ctrl.getAvatarColor($ctrl.props.ballot, voter.id)"
                  >
                    <i class="fa fa-user-circle-o mr-1" aria-hidden="true"></i>
                    <small>{{voter.name}}</small>
                  </div>
                </div>
                <small
                  class="check-intro d-block my-4 text-muted"
                  ng-if="$ctrl.props.ballot.oneByOne ? ($ctrl.props.ballot.activeCheckpoint === undefined) : true"
                >
                  <p>
                    Now is the time introspectively assess how well your team is working together. Be brutally honest and get into the warts and all to understand what's not working well. It's not all doom and gloom, though: you'll uncover good things too, and exchange some high-fives. You'll walk away knowing where your weak spots are, armed with ideas to help build those muscles.
                  </p>
                  <p>
                    Please assess your team health strictly within the context of the initiative you're working on. For this, there are no right or wrong answers, and everyone's opinion is equal.
                  </p>
                </small>
              </section>

              <!-- Normal ballot, manual checkin, oneByOne closed-->
              <section
                ng-repeat="type in $ctrl.chkTypes"
                ng-if="!$ctrl.props.ballot.oneByOne || ($ctrl.props.ballot.oneByOne && $ctrl.props.ballot.closed)"
              >
                <div
                  ng-if="$ctrl.getCheckpointCount($ctrl.props, type) > 0"
                >
                  <header
                    id="{{ type }}"
                  >
                    <h2 class="h4 font-weight-normal">{{ type | capitalize }} Checkpoint</h2>
                  </header>
                  <hr class="mt-3 mb-4">
                </div>
                <div ng-repeat="item in $ctrl.props.checkpoints">
                  <div ng-if="item.type == type">
                  <div class="row mb-1">
                    <div class="col-md-7 col-lg-8">
                      <h3 class="h6">{{ $ctrl.checkpoints[item.id].name }}</h3>
                      {{ $ctrl.checkpoints[item.id].description }}
                    </div>
                    <div class="col-md-5 col-lg-4 pt-3">
                      <div
                        class="row"
                      >
                        <div
                          class="col-4 text-center"
                          ng-repeat="class in ['red', 'amber', 'green']">
                          <checkpoint-radio
                            name="rag-{{ item.id }}"
                            vote="$ctrl.props.ballot.closed ? ($ctrl.props.votes[item.id][$index]) : null"
                            class="checkpoint-radio {{ class }}"
                            closed="$ctrl.props.ballot.closed"
                            on-click="$ctrl.onClickRag(item.id, $index)"
                            ng-checked="$ctrl.getCheckpointRag($ctrl.props, $ctrl.checkin, item.id) === $index + 1"
                            ng-disabled="$ctrl.props.ballot && $ctrl.props.ballot.id"
                          >
                          </checkpoint-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div ng-show="item.ragexamples && item.ragexamples.length > 0 && !$ctrl.props.ballot.hide_legend">
                    <a
                      href=""
                      ng-click="$ctrl.onClickShowHideLegend(item.id)"
                    >
                      <i class="fa fa-question-circle" aria-hidden="true"></i> <small>{{ $ctrl.legends[item.id] ? 'Hide': 'Show' }} legend</small>
                    </a>
                    <div class="collapse mt-2" id="collapseExample" ng-class="{show: $ctrl.legends[item.id]}">
                      <div class="card card-block">
                        <small class="mb-2"><span class="font-weight-bold text-danger">Sad red:</span> {{item.ragexamples[1]}}</small>
                        <small class="mb-2"><span class="font-weight-bold text-warning">Neutral amber:</span> {{item.ragexamples[2]}}</small>
                        <small><span class="font-weight-bold text-success">Happy green:</span> {{item.ragexamples[3]}}</small>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end align-items-center mb-2">
                    <!-- && allVoted($ctrl.props.ballot, item.id) : Use this check if all users voted -->
                    <!-- Not all users voted -->
                    <!-- && (getVotedUsersCount($ctrl.props.ballot, item.id) > 0): If more than one users voted -->
                    <a
                      href=""
                      ng-if="!$ctrl.props.ballot.closed && $ctrl.props.ballot.oneByOne && !$ctrl.allVoted($ctrl.props.ballot, item.id) && $ctrl.props.ballot.votes[item.id].uservotes"
                      ng-click="$ctrl.onClickShowResults(item.id)"
                      class="btn btn-success mr-2"
                    >
                      {{$ctrl.props.ballot.votes[item.id].closed ? 'Re-open voting and hide results' : 'End voting and show results'}}
                    </a>
                    <!--<a
                      href=""
                      ng-click="$ctrl.nextCheckpoint($ctrl.props.ballot.id, item.id)"
                      ng-disabled="$ctrl.updatingBallot"
                      ng-hide="$ctrl.props.ballot.activeCheckpoint == item.id"
                      ng-if="!$ctrl.props.ballot.closed && $ctrl.props.ballot.oneByOne && !$ctrl.allVoted($ctrl.props.ballot, item.id)"
                      class="btn btn-primary"
                    >
                      Set as active
                      <i class="fa fa-angle-right" aria-hidden="true" ng-if="!($ctrl.updatingBallot && ($ctrl.activeCheckpoint == item.id))"></i>
                      <i class='fa fa-spinner fa-spin' ng-if="$ctrl.updatingBallot && ($ctrl.activeCheckpoint == item.id)" />
                    </a>-->
                  </div>
                  <div class="text-success text-right small" ng-if="$ctrl.props.ballot.oneByOne">
                    <div ng-if="$ctrl.props.ballot.votersCount > 0">
                      Voted: {{$ctrl.getVotedUsersCount($ctrl.props.ballot, item.id)}}/{{$ctrl.props.ballot.votersCount}}
                    </div>
                  </div>
                  <hr class="my-4">

                  <!--<div class="text-success text-right" ng-if="$ctrl.props.ballot.oneByOne && $ctrl.props.ballot.votes[item.id].closed">Completed</h4>-->
                  </div>
                </div>
              </section>
              <!-- oneByOne ballot-->

              <section
                ng-if="$ctrl.props.ballot.oneByOne && !$ctrl.props.ballot.closed"
              >
                <div ng-repeat="item in $ctrl.props.ballot.activeCheckpoints" ng-class="{ activeCheckpoint: true, closedCheckpoint: $ctrl.props.ballot.oneByOne && $ctrl.props.ballot.votes[item.id].closed }">
                  <header id="item.type">
                    <!-- ({{$ctrl.getActiveCheckpointIndex($ctrl.props.ballot, item)}}/{{$ctrl.getTotalCheckpointsCountByType($ctrl.props.ballot, item.type)}}) -->
                    <h2 class="h4 font-weight-normal">{{ item.type | capitalize }} Checkpoint</h2>
                  </header>
                  <hr class="mt-3 mb-4">
                  <div class="row mb-1">
                    <div class="col-md-7 col-lg-8">
                      <h3 class="h6">{{ $ctrl.checkpoints[item.id].name }}</h3>
                      {{ $ctrl.checkpoints[item.id].description }}
                    </div>
                    <div class="col-md-5 col-lg-4 pt-3">
                      <div class="row">
                        <div
                          class="col-4 text-center"
                          ng-repeat="class in ['red', 'amber', 'green']">
                          <checkpoint-radio
                            name="rag-{{ item.id }}"
                            vote="$ctrl.props.ballot.votes[item.id].closed ? ($ctrl.props.votes[item.id][$index]) : null"
                            class="checkpoint-radio {{ class }}"
                            closed="$ctrl.props.ballot.votes[item.id].closed"
                            on-click="$ctrl.onClick(rag, ($index + 1))"
                            ng-checked="!!$ctrl.props.ballot.votes[item.id].closed && $ctrl.calcRag($ctrl.props.ballot.votes[item.id]) === $index + 1"
                            ng-disabled="$ctrl.props.ballot && !$ctrl.props.ballot.closed"
                          >
                          </checkpoint-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div ng-show='item.ragexamples && item.ragexamples.length > 0 && !$ctrl.props.ballot.hide_legend'>
                  <a
                    href=""
                    ng-click="$ctrl.onClickShowHideLegend(item.id)"
                  >
                    <i class="fa fa-question-circle" aria-hidden="true"></i> <small>{{ $ctrl.legends[item.id] ? 'Hide': 'Show' }} legend</small>
                  </a>
                  </div>
                  <div class="collapse mt-2" id="collapseExample" ng-class="{show: $ctrl.legends[item.id]}">
                    <div class="card card-block">
                      <small class="mb-2"><span class="font-weight-bold text-danger">Sad red:</span> {{item.ragexamples[1]}}</small>
                      <small class="mb-2"><span class="font-weight-bold text-warning">Neutral Amber:</span> {{item.ragexamples[2]}}</small>
                      <small><span class="font-weight-bold text-success">Happy green:</span> {{item.ragexamples[3]}}</small>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end align-items-center mb-2">
                    <!-- && allVoted($ctrl.props.ballot, item.id) : Use this check if all users voted -->
                    <!-- Not all users voted -->
                    <!-- && (getVotedUsersCount($ctrl.props.ballot, item.id) > 0): If more than one users voted -->
                    <button
                      ng-if="!$ctrl.props.ballot.closed && $ctrl.props.ballot.oneByOne && !$ctrl.allVoted($ctrl.props.ballot, item.id) && $ctrl.props.ballot.votes[item.id].uservotes && !$ctrl.props.ballot.votes[item.id].closed"
                      ng-click="$ctrl.onClickShowResults(item.id)"
                      ng-disabled="$ctrl.updatingVisibility"
                  
                      class="btn btn-success mr-2"
                      data-toggle="tooltip" data-placement="bottom" data-animation="false" title="pause check-in and show results"
                    >
                      <i class='fa fa-spinner fa-spin' ng-if="$ctrl.updatingVisibility"/>
                      Show results
                    </button>
                    <button
                      ng-if="!$ctrl.props.ballot.closed && $ctrl.props.ballot.oneByOne && !$ctrl.allVoted($ctrl.props.ballot, item.id) && $ctrl.props.ballot.votes[item.id].uservotes && $ctrl.props.ballot.votes[item.id].closed"
                      ng-click="$ctrl.onClickShowResults(item.id)"
                      ng-disabled="$ctrl.updatingVisibility"
                  
                      class="btn btn-success mr-2"
                      data-toggle="tooltip" data-placement="bottom" data-animation="false" title="hide results and resume check-in"
                    >
                      <i class='fa fa-spinner fa-spin' ng-if="$ctrl.updatingVisibility"/>
                      Hide results
                    </button>
                    <!-- <a
                      href=""
                      ng-click="$ctrl.nextCheckpoint($ctrl.props.ballot.id, item.id)"
                      ng-disabled="$ctrl.updatingBallot"
                      ng-hide="$ctrl.props.ballot.activeCheckpoint == item.id"
                      ng-if="!$ctrl.props.ballot.closed && $ctrl.props.ballot.oneByOne && !$ctrl.allVoted($ctrl.props.ballot, item.id)"
                      class="btn btn-primary"
                    >
                      Set as active
                      <i class="fa fa-angle-right" aria-hidden="true" ng-if="!($ctrl.updatingBallot && ($ctrl.activeCheckpoint == item.id))"></i>
                      <i class='fa fa-spinner fa-spin' ng-if="$ctrl.updatingBallot && ($ctrl.activeCheckpoint == item.id)" />
                    </a> -->
                  </div>
                  <!-- <div class="text-success text-right small" ng-if="$ctrl.props.ballot.oneByOne">
                    <div ng-if="$ctrl.props.ballot.votersCount > 0">
                      Voted: {{$ctrl.getVotedUsersCount($ctrl.props.ballot, item.id)}}/{{$ctrl.props.ballot.votersCount}}
                    </div>
                  </div> -->
                  <!-- <div class="text-success text-right small mb-2" ng-if="$ctrl.props.ballot.oneByOne && $ctrl.props.ballot.votes[item.id].closed">Completed</h4> -->
                </div>
              </section>
              <!--<hr class="my-4" ng-if="$ctrl.props.ballot.oneByOne">-->
              <checkin-delete-button removing="$ctrl.removingCheckin" on-yes="$ctrl.onClickRemoveCheckin()" ng-if="($ctrl.props.ballot.id && !$ctrl.props.ballot.oneByOne) || $ctrl.props.ballot.closed" class="initiativeHeader__deleteContainer"></checkin-delete-button>
              <button
                type="button"
                class="btn btn-primary float-right"
                ng-disabled="$ctrl.updating"
                ng-if="$ctrl.props.ballot.id && !$ctrl.props.ballot.oneByOne"
                ng-click="$ctrl.onClickVote()"
              >
                <i class='fa fa-spinner fa-spin' ng-if="$ctrl.updating" />
                Close voting and show results
              </button>
              <button
                type="submit"
                class="btn btn-primary float-right"
                ng-disabled="$ctrl.updating"
                ng-if="!$ctrl.props.ballot.id"
              >
                <i class='fa fa-spinner fa-spin' ng-if="$ctrl.updating" />
                Submit manual check-in
              </button>
              <button
                type='button'
                class="btn btn-primary float-right"
                style="margin-right: 8px;"
                ng-if="$ctrl.props.ballot.oneByOne && !$ctrl.props.ballot.closed && $ctrl.getOpenCheckpointCount($ctrl.props.ballot) > 0"
                ng-click="$ctrl.nextCheckpoint($ctrl.props.ballot.id)"
                ng-disabled="$ctrl.updatingBallot"
                data-toggle="tooltip" data-placement="bottom" data-animation="false" title="complete this checkpoint and move to the next"
              >
                {{$ctrl.props.ballot.oneByOne && $ctrl.props.ballot.activeCheckpoints.length == 0 ? 'First checkpoint' : 'Continue'}}
                <i class='fa fa-spinner fa-spin' ng-if="$ctrl.updatingBallot && !$ctrl.updatingVisibility"/>
                <i class="fa fa-arrow-right" aria-hidden="true" ng-if="!$ctrl.updatingBallot || $ctrl.updatingVisibility"></i>
              </button>
            </form>
            <p class="text-danger">{{$ctrl.ballotError}}</p>
          </main>
        </div>
      </div>
    </div>
    `,

  controller($ngRedux, $location, $routeParams, $timeout, $rootScope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      $doCheck,
      mapState,
      mapDispatch,
      onClickVote,
      onClickRag,
      onSubmit,
      onClickShowHideLegend,
      onClickShowResults,
      calcRag,
      allVoted,
      getVotedUsersCount,
      canBeClosed,
      getJoinedUsersCount,
      getShortName,
      isNextCheckpointAvailable,
      getVotedCheckpointsCount,
      getTotalCheckpointsCount,
      getTotalCheckpointsCountByType,
      getActiveCheckpointIndex,
      shouldShowAvatar,
      getAvatarColor,
      getCheckpointCount,
      isCheckInAllowed,
      getCheckpointRag,
      onClickRemoveCheckin,
      getOpenCheckpointCount,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.checkin = $routeParams.checkin;
      $ctrl.fetchInitiativeWithBallot($routeParams.id, $routeParams.checkin);
      // Fetch custom Question Set
      $ctrl.fetchQuestionSets();

      $ctrl.legends = {};
      $ctrl.results = {};
      $rootScope.title = `Init - Checkin ${$ctrl.checkin} Initiative`;
    }

    function $onDestroy() {
      $ctrl.cancelInitiative();
      $ctrl.unsubscribe();
    }

    function $doCheck() {
      if ($ctrl.props) {
        if ($ctrl.props.name) {
          $rootScope.title = `Init - Checkin ${$ctrl.checkin} Initiative: ${
            $ctrl.props.name
          }`;
        }

        // If this checkin is not a ballot checkin
        if ($ctrl.props.ballot && !$ctrl.props.ballot.id && $ctrl.success) {
          $location.path(`/initiatives/${$routeParams.id}`);
        }
      }

      if ($ctrl.successRemoveCheckin) {
        $timeout(function() {
          $location.path(`/initiatives/${$routeParams.id}`);
        }, 1);
      }
    }

    function mapState(state) {
      const votes = {};
      const checkpoints = { ...state.checkpoints.data };

      const mobilisations = { ...state.awards.mobilisations };

      const initiative = angular.copy(state.initiative.data);

      injectCheckpoints(initiative, checkpoints);
      injectCustomCheckpoints(initiative, state.questionSet.list);
      const chkTypes = initiative ? getChkTypes(initiative.checkpoints) : [];

      const dicCheckpoints = { ...checkpoints };
      if (initiative && initiative.checkpoints) {
        initiative.checkpoints.forEach(c => {
          dicCheckpoints[c.id] = c;
        });
      }

      if (initiative) {
        for (let i = 0; i < initiative.checkpoints.length; i++) {
          const ballot = state.ballot.data;
          let totalVotes = 0;
          if (ballot && (ballot.closed || ballot.oneByOne) && ballot.votes) {
            for (let i = 0; i < ballot.checkpoints.length; i++) {
              votes[ballot.checkpoints[i].id] = calcVotePercent(
                ballot,
                ballot.checkpoints[i].id
              );
            }
          } else {
            votes[initiative.checkpoints[i].id] = ['', '', ''];
          }
        }
      }

      let activeCheckpoints = [];
      let activeCheckpointClosed = false;

      // active checkpoints
      const ballot = state.ballot.data;

      // fix ballot url
      // if (process.env.NODE_ENV === 'development') {
      //   if (ballot) {
      //     ballot.URL = `http://localhost:8080/ballots/${ballot.id}`
      //   }
      // }

      injectCheckpoints(ballot, checkpoints);

      if (ballot && ballot.oneByOne) {
        const { activeCheckpoint } = ballot;
        if (!Array.isArray(ballot.checkpoints)) {
          ballot.checkpoints = [];
        }
        const activeCP = ballot.checkpoints.find(c => c.id == activeCheckpoint);

        if (activeCP) {
          activeCheckpoints = [activeCP];
          if (ballot.votes && ballot.votes[activeCP.id]) {
            activeCheckpointClosed = !!ballot.votes[activeCP.id].closed;
          } else {
            activeCheckpointClosed = false;
          }
        }
      }

      const votersCount = getJoinedUsersCount(state.ballot.data);

      return {
        user: state.user.data,
        props: {
          ...decorateInitiative(initiative, mobilisations),
          ballot: {
            ...state.ballot.data,
            votersCount,
            activeCheckpoints,
            activeCheckpointClosed,
          },
          votes,
        },
        checkpoints: dicCheckpoints,
        success: state.initiative.success,
        fetching: state.initiative.fetching,
        initiativeFetchError: state.initiative.fetchError,
        updating: state.initiative.updating,
        updatingBallot: state.ballot.updating,
        updatingVisibility: state.ballot.updatingVisibility,
        fetchingBallot: state.ballot.loading,
        ballotError: state.ballot.error,
        activeCheckpoint: state.ballot.nextCheckpoint,
        successRemoveCheckin: state.initiative.successRemoveCheckin,
        removingCheckin: state.initiative.removingCheckin,
        chkTypes: chkTypes,
      };
    }

    function mapDispatch() {
      return {
        fetchInitiative,
        cancelInitiative,
        updateInitiative,
        fetchInitiativeWithBallot,
        openVoting,
        closeVoting,
        nextCheckpoint,
        setVoteVisibility,
        setRagValue,
        removeCheckin,
        fetchQuestionSets,
      };
    }

    // index: 0, 1, 2 => RAG: 1, 2, 3
    function onClickRag(checkpointId, ragIndex) {
      // get previous rag value: one of 0, 1, 2, 3
      const ragValue = $ctrl.getCheckpointRag(
        $ctrl.props,
        $ctrl.checkin,
        checkpointId
      );

      if (ragValue === ragIndex + 1) {
        // already checked in => Should be set  to  INFORMAL
        $ctrl.setRagValue($ctrl.checkin, checkpointId, INFORMAL);
      } else {
        $ctrl.setRagValue($ctrl.checkin, checkpointId, ragIndex + 1);
      }
    }

    function onClickVote() {
      if (!$ctrl.props.ballot || $ctrl.props.ballot.closed) {
        $ctrl.openVoting($routeParams.id, $routeParams.checkin);
      } else {
        $ctrl.closeVoting($routeParams.id, $routeParams.checkin);
      }
    }

    function onClickShowHideLegend(checkpoint_id) {
      $ctrl.legends[checkpoint_id] = !$ctrl.legends[checkpoint_id];
    }

    function onClickShowResults(checkpoint_id) {
      const bShow = _.get(this.props.ballot, [
        'votes',
        checkpoint_id,
        'closed',
      ]);
      $ctrl.setVoteVisibility(this.props.ballot.id, checkpoint_id, !bShow);
    }

    function onClickRemoveCheckin() {
      $ctrl.removeCheckin($routeParams.checkin, $routeParams.id);
    }

    function onSubmit() {
      if (
        $ctrl.props.ballot_status &&
        typeof $ctrl.props.ballot_status[$routeParams.checkin] === 'boolean'
      ) {
        // This is a ballot checkin
        // go to initiative page without any notice
        $location.path(`/initiatives/${$routeParams.id}`);
      } else {
        // Not a ballot checkin - update initiative manually
        // ballot is now closed
        const updatedInitiative = { ...$ctrl.props };
        let checkins = updatedInitiative.checkins;
        if (!checkins) {
          checkins = {};
        }
        if (!checkins[$routeParams.checkin]) {
          checkins[$routeParams.checkin] = {
            by: $ctrl.user.id,
            at: firebase.database.ServerValue.TIMESTAMP,
          };
          updatedInitiative.checkins = checkins;
        }

        // Register INFORMAL votes
        const { checkpoints } = $ctrl.props;
        if (Array.isArray(checkpoints)) {
          for (let i = 0; i < checkpoints.length; i++) {
            if (
              checkpoints[i] &&
              checkpoints[i].id !== undefined &&
              checkpoints[i].id !== null &&
              getCheckpointRag(
                updatedInitiative,
                $ctrl.checkin,
                checkpoints[i].id
              ) == undefined
            ) {
              setRagValue(
                updatedInitiative,
                $ctrl.checkin,
                checkpoints[i].id,
                INFORMAL
              );
            }
          }
        }

        sendEvent('checkin', 'manual', updatedInitiative.type, 1, {
          number: $routeParams.checkin,
        });

        $ctrl.updateInitiative(updatedInitiative);
      }
    }
  },
};
