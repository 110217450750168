import html from './members.html';
import { getShortName } from '../../../utils';

import './show.scss';

export default {
  bindings: {
    members: '<',
    creator: '<',
    user: '<',
    onRemoveUser: '&',
    isRemoveDisabled: '<',
  },

  template: html,

  controller($scope) {
    'ngInject';
    const $ctrl = {
      $onInit,
      getShortName,
      onRemoveButtonClicked,
    };

    return $ctrl;

    function $onInit() {
      $scope.$on('remove_member_event', function(e) {
        if ($ctrl.members) {
          for (let [id, user] of Object.entries($ctrl.members)) {
            angular.element(`#remove-button-${id}`).tooltip('hide');
            angular.element(`#user-image-${id}`).tooltip('hide');
          }
        }
      });
    }

    function onRemoveButtonClicked(selUserId) {
      angular.element(`#remove-button-${selUserId}`).tooltip('dispose');
      angular.element(`#user-image-${selUserId}`).tooltip('dispose');
      $ctrl.onRemoveUser({ userId: selUserId });
    }
  },
};
