/**
 * Created by ilya on 6/7/17.
 */
export const development = {
  apiKey: 'AIzaSyByq4TkWDeRhz9v3iXRcMmGoyNn-ItTO-8',
  authDomain: 'init-dev-a7563.firebaseapp.com',
  databaseURL: 'https://init-dev-a7563.firebaseio.com',
  projectId: 'init-dev-a7563',
  storageBucket: 'init-dev-a7563.appspot.com',
  messagingSenderId: '1080437331409',
};

export const development2 = {
  apiKey: 'AIzaSyDtA17Si4synYXQcBevl2xEw0-mjKyyMGI',
  authDomain: 'init-dev2.firebaseapp.com',
  databaseURL: 'https://init-dev2.firebaseio.com',
  projectId: 'init-dev2',
  storageBucket: 'init-dev2.appspot.com',
  messagingSenderId: '61728470354',
};

export const production = {
  apiKey: 'AIzaSyC7l-daDeMdgEwTLJadQC29mVefA29TKUU',
  authDomain: 'init.orchestrated.io',
  databaseURL: 'https://init-b03e5.firebaseio.com',
  projectId: 'init-b03e5',
  storageBucket: 'init-b03e5.appspot.com',
  messagingSenderId: '877245700685',
};

export const productionDomain = 'init.orchestrated.io';

export default function() {
  console.info('Env:', process.env.NODE_ENV);
  if (process.env.NODE_ENV === 'development' || !process.env.NODE_ENV) {
    return development;
  } else if (process.env.NODE_ENV === 'production') {
    return production;
  }
}
