/**
 * Created by ilya on 6/6/17.
 */
import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import axios from 'axios';
import store from 'store';
import * as _ from 'lodash';

import getFirebaseConfig from '../config/firebase';
import { sendEvent } from '../analytics';

const firebaseProjectId = getFirebaseConfig().projectId;

export const FETCH_BALLOT = 'app/ballot/FETCH';
export const FETCH_BALLOT_WITH_CODE = 'app/ballot/FETCH_WITH_CODE';
export const FETCH_BALLOT_SUCCESS = 'app/ballot/FETCH_BALLOT_SUCCESS';
export const UPDATE_BALLOT = 'app/ballot/UPDATE';
export const UPDATE_BALLOT_SUCCESS = 'app/ballot/UPDATE_SUCCESS';
export const ERROR = 'app/ballot/ERROR';
export const CANCEL = 'app/ballot/CANCEL';
export const OPEN_VOTING = 'app/ballot/OPEN_VOTING';
export const CLOSE_VOTING = 'app/ballot/CLOSE_VOTING';
export const JOIN_BALLOT = 'app/ballot/JOIN_BALLOT';
export const INIT_BALLOT = 'app/ballot/INIT_BALLOT';
export const NEXT_CHECKPOINT = 'app/ballot/NEXT_CHECKPOINT';
export const NEXT_CHECKPOINT_ERROR = 'app/ballot/NEXT_CHECKPOINT_ERROR';

export const SET_VOTE_VISIBILITY = 'app/ballot/SET_VOTE_VISIBILITY';
export const SET_VOTE_VISIBILITY_SUCCESS =
  'app/ballot/SET_VOTE_VISIBILITY_SUCCESS';
export const SET_VOTE_VISIBILITY_ERROR = 'app/ballot/SET_VOTE_VISIBILITY_ERROR';

import { FETCH_WITH_BALLOT_SUCCESS, FETCH_WITH_BALLOT } from './initiative';
import { CREATE_VOTE_SUCCESS } from './vote';

// Initial State
export const initialState = {
  loading: false,
  updating: false,
  success: false,
  redirect: false, // This needs to move to middleware
  error: '',
  data: null,
  updatingVisibility: false,
};

// Voters can see the voting results
export const setVoteVisibility = (ballotId, checkpointId, bShow) => {
  return {
    type: SET_VOTE_VISIBILITY,
    payload: {
      ballotId: ballotId,
      checkpointId: checkpointId,
      bShow: bShow,
    },
  };
};

export function nextCheckpoint(ballotId, checkpointId) {
  return {
    type: NEXT_CHECKPOINT,
    payload: {
      ballotId,
      checkpointId,
    },
  };
}

export function initBallot() {
  return {
    type: INIT_BALLOT,
  };
}

export function fetchBallot(id, isJoining = true) {
  return {
    type: FETCH_BALLOT,
    payload: { id, isJoining },
  };
}

export function fetchBallotWithCode(code, isJoining = false) {
  return {
    type: FETCH_BALLOT_WITH_CODE,
    payload: { code, isJoining },
  };
}

export function joinBallot(id) {
  return {
    type: JOIN_BALLOT,
    payload: { id },
  };
}

export function joinBallotByCode(code) {
  return {
    type: JOIN_BALLOT,
    payload: { code },
  };
}

export function updateBallot(ballot) {
  return {
    type: UPDATE_BALLOT,
    payload: ballot,
  };
}
export function openVoting(
  initiative,
  checkin,
  isPrivate = false,
  anonymous = false,
  oneByOne = false
) {
  return {
    type: OPEN_VOTING,
    payload: {
      initiative,
      checkin,
      private: isPrivate,
      anonymous,
      oneByOne,
    },
  };
}

export function closeVoting(initiative, checkin) {
  return {
    type: CLOSE_VOTING,
    payload: {
      initiative,
      checkin,
    },
  };
}

export function cancelBallot() {
  return {
    type: CANCEL,
  };
}

const fetchBallotPromise = ballotId => {
  return axios
    .post(
      `https://us-central1-${firebaseProjectId}.cloudfunctions.net/loadBallot`,
      {
        ballotId,
      },
      {
        headers: {
          Authorization: store.get('Authorization'),
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(errResp => {
      if (errResp && errResp.data && errResp.error === 'no_ballot') {
        return null;
      } else {
        throw errResp;
      }
    });
};

const fetchBallotByCodePromise = ballotCode => {
  return axios
    .post(
      `https://us-central1-${firebaseProjectId}.cloudfunctions.net/loadBallot`,
      {
        ballotCode,
      },
      {
        headers: {
          Authorization: store.get('Authorization'),
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(errResp => {
      if (errResp && errResp.data && errResp.error === 'no_ballot') {
        return null;
      } else {
        throw errResp;
      }
    });
};

export const votingOpenClose = createLogic({
  latest: true,
  type: [OPEN_VOTING, CLOSE_VOTING],
  cancelType: CANCEL,
  warnTimeout: 0,
  /*processOptions: {
    successType: UPDATE_BALLOT_SUCCESS,
    failType: ERROR,
  },*/
  process({ action }, dispatch) {
    let url = `https://us-central1-${firebaseProjectId}.cloudfunctions.net/${
      action.type === OPEN_VOTING ? 'open' : 'close'
    }Voting`;
    console.info('Voting URL:', url);

    axios
      .post(
        url,
        {
          initiative: action.payload.initiative,
          checkin: action.payload.checkin,
          private: action.payload.private,
          anonymous: action.payload.anonymous,
          oneByOne: action.payload.oneByOne,
          lite: !!action.payload.lite,
        },
        {
          headers: {
            Authorization: store.get('Authorization'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          return response.data;
        } else {
          const err = {
            error: 'Failed to open/close voting',
          };
          if (response.data && response.data.error) {
            err.error = response.data.error;
          }

          dispatch({
            type: ERROR,
            payload: err,
          });
        }
      })
      .then(({ ballot, initiative }) => {
        dispatch({
          type: UPDATE_BALLOT_SUCCESS,
          payload: {
            data: { ballot, initiative },
            lite: action.payload.lite,
          },
        });
        if (action.type === OPEN_VOTING) {
          sendEvent('checkin', 'open', initiative.type, 1, {
            number: action.payload.checkin,
          });
        } else if (action.type === CLOSE_VOTING) {
          sendEvent('checkin', 'close', initiative.type, 1, {
            number: action.payload.checkin,
          });
          const { initiated_checkpoints } = ballot;
          if (initiated_checkpoints) {
            const chkDic = {};
            const checkpointIds = ballot.checkpoints.forEach(chk => {
              chkDic[chk.id] = true;
            });
            let incomplete = false;
            for (let id in chkDic) {
              if (!initiated_checkpoints[id]) {
                // checkin not started
                incomplete = true;
                break;
              }
            }
            sendEvent('checkin', 'close_ever_start', initiative.type, 1, {
              number: action.payload.checkin,
            });
            sendEvent(
              'checkin',
              incomplete ? 'close_incomplete' : 'close_complete',
              initiative.type,
              1,
              { number: action.payload.checkin }
            );
          } else {
            sendEvent('checkin', 'close_incomplete', initiative.type, 1, {
              number: action.payload.checkin,
            });
            sendEvent('checkin', 'close_never_start', initiative.type, 1, {
              number: action.payload.checkin,
            });
          }
        }
      })
      .catch(error => {
        Raven.captureExceptionEx(error);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch({
            type: ERROR,
            payload: {
              error: 'Something went wrong!',
            },
          });
        } else {
          dispatch({
            type: ERROR,
            payload: {
              error: 'Live poll failed!',
            },
          });
        }
      });
  },
});

export const ballotFetch = createLogic({
  type: FETCH_BALLOT,
  cancelType: CANCEL,
  warnTimeout: 0,
  process({ action, getState }, dispatch) {
    fetchBallotPromise(action.payload.id)
      .then(ballot => {
        console.info('Ballot:', ballot);
        if (ballot) {
          let alreadyJoined = false;
          const userState = getState().user;
          if (userState) {
            const user = userState.data;
            alreadyJoined =
              user && ballot && ballot.voters && ballot.voters[user.id];
          }
          if (
            ballot.oneByOne &&
            !ballot.closed &&
            action.payload.isJoining &&
            !alreadyJoined
          ) {
            dispatch(joinBallot(ballot.id, alreadyJoined));
          } else {
            dispatch({
              type: FETCH_BALLOT_SUCCESS,
              payload: {
                data: { ballot },
              },
            });
          }
        } else {
          const error = 'Sorry, no check-in exists.';
          dispatch({
            type: ERROR,
            payload: { error },
          });
        }
      })
      .catch(error => {
        Raven.captureExceptionEx(error);
        dispatch({
          type: ERROR,
          payload: 'Something went wrong!',
        });
        // Log errors for sentry
      });
  },
});

export const ballotFetchWithCode = createLogic({
  type: FETCH_BALLOT_WITH_CODE,
  cancelType: CANCEL,
  warnTimeout: 0,
  process({ action, getState }, dispatch, done) {
    fetchBallotByCodePromise(action.payload.code.toUpperCase())
      .then(dicBallot => {
        if (dicBallot) {
          const id = Object.keys(dicBallot)[0];
          const ballot = dicBallot[id];
          ballot.id = id;
          let alreadyJoined = false;
          const userState = getState().user;
          if (userState) {
            const user = userState.data;
            alreadyJoined =
              user && ballot && ballot.voters && ballot.voters[user.id];
          }
          if (
            ballot.oneByOne &&
            !ballot.closed &&
            action.payload.isJoining &&
            !alreadyJoined
          ) {
            dispatch(joinBallot(ballot.id));
          } else {
            dispatch({
              type: FETCH_BALLOT_SUCCESS,
              payload: {
                data: { ballot },
              },
            });
          }
        } else {
          const error = 'Sorry, no check-in exists for this code.';
          dispatch({
            type: ERROR,
            payload: { error },
          });
        }
        done();
      })
      .catch(error => {
        Raven.captureExceptionEx(error);
        dispatch({
          type: ERROR,
          payload: error,
        });
        done();
      });
  },
});

export const ballotJoin = createLogic({
  latest: true,
  type: JOIN_BALLOT,
  cancelType: CANCEL,
  processOptions: {
    successType: FETCH_BALLOT_SUCCESS,
    failType: ERROR,
  },
  process({ action, getState }) {
    console.info('Join ballot:', action.payload.id);

    const ballotData = {
      ballotid: action.payload.id, // pass ballot id
    };

    const user = getState().user;
    if (user && user.data && user.data.isAnonymous) {
      if (user.data.name) {
        ballotData.name = user.data.name;
      }
    }
    return axios
      .post(
        `https://us-central1-${firebaseProjectId}.cloudfunctions.net/joinBallot`,
        ballotData,
        {
          headers: {
            Authorization: store.get('Authorization'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          console.info('Checkin Join Event!');
          sendEvent(
            'checkin',
            'join',
            user.data.isAnonymous ? 'anonymous' : 'registered',
            1,
            {
              id: action.payload.id,
            }
          );
          return response.data;
        } else {
          const error = `Failed to join ballot:${action.payload.id}`;
          throw { error };
        }
      })
      .then(ballot => {
        return {
          data: { ballot },
        };
      });
  },
});

export const ballotWatch = createLogic({
  type: [FETCH_WITH_BALLOT, FETCH_BALLOT, FETCH_BALLOT_WITH_CODE],
  warnTimeout: 0,
  // TODO: warning! - do not remove done here!
  process({ action, getState }, dispatch, done) {
    console.info('Start Watching ballot!');
    let id;
    if (action.type === FETCH_BALLOT) {
      id = action.payload.id;
    } else if (action.type === FETCH_WITH_BALLOT) {
      id = `${action.payload.id}-${action.payload.checkin_id}`;
    }

    const { ballot } = getState();

    // Remove old listener
    if (ballot && ballot.ref && ballot.ref.off) {
      ballot.ref.off();
    }
    let firebaseQuery;

    if (action.type === FETCH_WITH_BALLOT || action.type === FETCH_BALLOT) {
      return firebase
        .database()
        .ref('ballots')
        .child(id)
        .on('value', snapshot => {
          const ballot = snapshot.val();
          if (ballot) {
            ballot.id = snapshot.key;
            dispatch({
              type: FETCH_BALLOT_SUCCESS,
              payload: {
                data: { ballot, ref: snapshot.ref },
              },
            });
          }
        });
    } else {
      // FETCH_WITH_CODE
      return firebase
        .database()
        .ref('ballots')
        .orderByChild('code')
        .equalTo(action.payload.code)
        .limitToFirst(1)
        .on('value', snapshot => {
          const ballots = snapshot.val();
          if (ballots) {
            const key = Object.keys(ballots)[0];
            const ballot = ballots[key];
            ballot.id = key;
            dispatch({
              type: FETCH_BALLOT_SUCCESS,
              payload: {
                data: { ballot, ref: snapshot.ref },
              },
            });
          }
        });
    }
  },
});

export const nextCheckpointLogic = createLogic({
  type: NEXT_CHECKPOINT,
  warnTimeout: 0,
  process({ action }, dispatch, done) {
    axios
      .post(
        `https://us-central1-${firebaseProjectId}.cloudfunctions.net/nextCheckpoint`,
        {
          ballotId: action.payload.ballotId,
          checkpointId: action.payload.checkpointId,
        },
        {
          headers: {
            Authorization: store.get('Authorization'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          const { ballot, closed_checkpoint } = response.data;
          const { initiated_checkpoints, closed_checkpoints } = ballot;

          dispatch({
            type: UPDATE_BALLOT_SUCCESS,
            payload: {
              data: {
                ballot,
              },
            },
          });

          // First Checkpoint is initiated
          if (
            initiated_checkpoints &&
            Object.keys(initiated_checkpoints).length === 1
          ) {
            let first_duration = 0;
            if (ballot.created_date) {
              // Old ballots does not contain created_date
              first_duration =
                initiated_checkpoints[Object.keys(initiated_checkpoints)[0]] -
                ballot.created_date;
            }

            if (closed_checkpoints && closed_checkpoint) {
              sendEvent(
                'first_checkpoint',
                'open',
                initiated_checkpoints[Object.keys(initiated_checkpoints)[0]],
                Math.round(first_duration / 60000),
                {
                  at: closed_checkpoints[closed_checkpoint],
                }
              );
            }
          }

          if (closed_checkpoint) {
            const { initiated_checkpoints, closed_checkpoints } = ballot;
            const duration =
              closed_checkpoints[closed_checkpoint] -
              initiated_checkpoints[closed_checkpoint];
            // TODO submit event here
            sendEvent(
              'checkpoint',
              'close',
              closed_checkpoint,
              Math.round(duration / 60000),
              {
                at: closed_checkpoints[closed_checkpoint],
              }
            );
          }
          if (ballot.activeCheckpoint) {
            sendEvent('checkpoint', 'open', ballot.activeCheckpoint, 1, {
              at: initiated_checkpoints[ballot.activeCheckpoint],
            });
          }
        } else {
          const error = 'Failed to set next checkpoint';
          throw { error };
        }
        done();
      })
      .catch(error => {
        Raven.captureExceptionEx(error);
        if (error && error.response && error.response.data) {
          dispatch({
            type: NEXT_CHECKPOINT_ERROR,
            payload: {
              error: error.response.data,
            },
          });
        } else {
          dispatch({
            type: NEXT_CHECKPOINT_ERROR,
            payload: {
              error: 'Failed to set the next Checkpoint!',
            },
          });
        }
        done();
      });
  },
});

export const setVoteVisibilityLogic = createLogic({
  type: SET_VOTE_VISIBILITY,
  warnTimeout: 0,
  process: ({ action, getState }, dispatch, done) => {
    const { ballotId, checkpointId, bShow } = action.payload;
    const ballot = { ...getState().ballot.data };
    axios
      .post(
        `https://us-central1-${firebaseProjectId}.cloudfunctions.net/setChkVotingResultVisible`,
        {
          ballotId,
          checkpointId,
          bShow: !!bShow,
        },
        {
          headers: {
            Authorization: store.get('Authorization'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: SET_VOTE_VISIBILITY_SUCCESS,
            payload: {
              data: { ballot: response.data },
            },
          });
        } else {
          dispatch({
            type: ERROR,
            payload: {
              error: 'Failed to update vote visibility!',
            },
          });
        }
        done();
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case INIT_BALLOT:
      return initialState;

    case FETCH_WITH_BALLOT_SUCCESS:
    case FETCH_BALLOT_SUCCESS:
      return {
        ...state,
        data: action.payload.data.ballot,
        ref: action.payload.data.ref, // Firebase Reference
        loading: false,
        success: true,
      };

    case FETCH_WITH_BALLOT:
    case FETCH_BALLOT_WITH_CODE:
    case FETCH_BALLOT:
      return {
        ...state,
        data: null,
        updated: false,
        loading: true,
        success: false,
        error: '',
      };

    case NEXT_CHECKPOINT:
      return {
        ...state,
        updating: true,
        updated: false,
        success: false,
        error: '',
        nextCheckpoint: action.payload.checkpointId,
      };

    case SET_VOTE_VISIBILITY:
      return {
        ...state,
        updating: true,
        updated: false,
        success: false,
        error: '',
        updatingVisibility: true,
      };

    case OPEN_VOTING:
    case CLOSE_VOTING:
    case UPDATE_BALLOT:
      if (payload.lite) {
        return {
          ...state,
          opening_lite: true,
          opening_lite_success: false,
          error: '',
        };
      } else {
        return {
          ...state,
          updating: true,
          updated: false,
          success: false,
          error: '',
        };
      }

    case SET_VOTE_VISIBILITY_SUCCESS:
      return {
        ...state,
        updating: false,
        success: true,
        updated: true,
        updatingVisibility: false,
        data: { ...state.data, ...action.payload.data.ballot },
      };

    case CREATE_VOTE_SUCCESS:
    case UPDATE_BALLOT_SUCCESS:
      if (payload.lite) {
        return {
          ...state,
          opening_lite: false,
          opening_lite_success: true,
          error: '',
        };
      } else {
        return {
          ...state,
          updating: false,
          success: true,
          updated: true,
          data: { ...state.data, ...action.payload.data.ballot },
        };
      }

    case NEXT_CHECKPOINT_ERROR:
    case ERROR:
      return {
        ...state,
        updating: false,
        loading: false,
        success: false,
        error: action.payload.error,
      };

    case CANCEL:
      return { ...initialState };

    default:
      return state;
  }
}
