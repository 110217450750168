import {
  initAndFetchImpediments,
  fetchImpediments,
  applyFilter,
  clearFilter,
  sortImpediments,
} from '../../logic/impediments';
import {
  removeImpediment,
  upVoteImpediment,
  downVoteImpediment,
} from '../../logic/impediment';
import { fetchInitiatives, cancelInitiatives } from 'logic/initiatives';
import { fetchImpedimentTypes } from 'logic/constants/impediments';
import sortings from 'constants/sortings_impediment';
import html from './list.html';
import { dateDiffInDays } from '../../utils';
import './list.scss';
import store from 'store';

export default {
  template: html,

  controller($ngRedux, $location) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
      onCancel,
      onClickFilterItem,
      numberOfFilters,
      clearFilters,
      sort,
      onClickItem,
      onRemove,
      onUpVote,
      onDownVote,
      dateDiffInDays,
      userId: store.get('user'),
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.initAndFetchImpediments();
      $ctrl.fetchInitiatives({});
      $ctrl.fetchImpedimentTypes();
      $ctrl.sortings = [...sortings];
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      let benefitTypes = angular.copy(state.impedimentTypes.data.benefits);
      let initiatives = angular.copy(state.initiatives.data);
      let filters = state.impediments.filters;

      if (filters.benefits) {
        for (let i in benefitTypes) {
          benefitTypes[i].checked = false;
          for (let j in filters.benefits) {
            if (filters.benefits[j].id === benefitTypes[i].id) {
              benefitTypes[i].checked = true;
            }
          }
        }
      }

      if (filters.initiatives) {
        for (let i in initiatives) {
          initiatives[i].checked = false;
          for (let j in filters.initiatives) {
            if (filters.initiatives[j].id === initiatives[i].id) {
              initiatives[i].checked = true;
            }
          }
        }
      }

      return {
        props: state.impediments.data,
        initiatives,
        benefitTypes,
        filters,
        loading: state.impediments.loading,
        success: state.impediments.success,
        loadingInitiatives:
          state.initiatives.data.length > 0 ? false : state.initiatives.loading,
        loadingImpedimentTypes: state.impedimentTypes.loading,
      };
    }

    function mapDispatch() {
      return {
        fetchImpediments,
        initAndFetchImpediments,
        fetchInitiatives,
        applyFilter,
        fetchImpedimentTypes,
        clearFilter,
        sortImpediments,
        removeImpediment,
        upVoteImpediment,
        downVoteImpediment,
      };
    }

    function onCancel() {
      $location.path(`/impediments`);
    }

    function onClickFilterItem(type, item) {
      $ctrl.applyFilter({ type, object: item });
    }

    function numberOfFilters() {
      if (!$ctrl.filters) {
        return 0;
      }

      if (!$ctrl.filters.benefits) {
        $ctrl.filters.benefits = [];
      }

      if (!$ctrl.filters.initiatives) {
        $ctrl.filters.initiatives = [];
      }

      return $ctrl.filters.benefits.length + $ctrl.filters.initiatives.length;
    }

    function sort(sorting) {
      $ctrl.sortImpediments(sorting);
    }

    function clearFilters() {
      $ctrl.clearFilter();
    }

    function onClickItem(impediment) {
      $location.path(`/impediments/${impediment.id}/edit`);
    }

    function onRemove(impediment) {
      $ctrl.removeImpediment(impediment);
    }

    function onUpVote(impediment) {
      $ctrl.upVoteImpediment(impediment);
    }

    function onDownVote(impediment) {
      $ctrl.downVoteImpediment(impediment);
    }
  },
};
