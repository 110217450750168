export default {
  bindings: {
    awards: '<',
    name: '@',
    target: '@',
    type: '@',
    add: '&',
    remove: '&',
  },

  template: `
    <div class="modal fade {{ $ctrl.target }}" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-4">
            <h3 class="modal-title">{{ $ctrl.name }}</h3>
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              Done
            </button>
          </div>
          <div class="modal-body p-4 pb-0">
            <div class="row">
              <div class="col-sm-6 col-lg-4 mb-2" ng-repeat="(key, item) in $ctrl.awards" ng-if="!item.isDisabled || item.active">
                <award
                  type="{{ $ctrl.type }}"
                  name="item.name"
                  active="item.active">
                  <span
                    ng-show="$ctrl.type === 'badge'"
                    class="award-actions">
                    <a
                      href="#"
                      ng-show="!item.active"
                      ng-click="$ctrl.add({ item: item })">
                      Award
                    </a>
                    <a
                      href="#"
                      ng-show="item.active"
                      ng-click="$ctrl.remove({ item: item })">
                      Remove
                    </a>
                  </span>
                  <span
                    ng-show="$ctrl.type === 'mobilisation'"
                    class="award-actions">
                    <span ng-hide="item.active">
                      <a
                        href="#"
                        ng-click="item.popover = !item.popover">
                        Check
                      </a>
                    </span>
                    <span ng-show="item.active">
                      <a
                        href="#"
                        ng-show="item.note"
                        title="{{ item.note }}"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-animation="false">
                        <i class="fa fa-file-text-o"></i>
                        Note
                      </a>
                      <a
                        ng-show="item.link"
                        ng-href="{{ item.link | linkify }}"
                        target="_blank">
                        <i class="fa fa-external-link"></i>
                        Link
                      </a>
                      <a href="#" ng-click="item.popover = !item.popover">
                        <i class="fa fa-pencil"></i>
                        Edit
                      </a>
                      <a
                        href="#"
                        ng-click="$ctrl.remove({ item: item })">
                        Uncheck
                      </a>
                    </span>
                    <initiatives-award-popover-form
                      ng-show="item.popover"
                      item="item"
                      model="model"
                      on-submit="$ctrl.add({ item: item })"
                      on-cancel="item.popover = false">
                    </initiatives-award-popover-form>
                  </span>
                </award>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`,
};
