export default {
  bindings: {
    onSubmit: '&',
    loading: '<',
  },

  template: `
    <div class="login-form">
      <form
        name="$ctrl.form" 
        ng-submit="$ctrl.onSubmit({ username: username, password: password })"
      >
        <div>
          <div class="login-fieldset">
            <input
              id="email"
              name="email"
              class="login-textinput"
              type="email"
              autocorrect="off"
              autocapitalize="none"
              spellcheck="false"
              ng-model="username"
              required />
            <label class="login-label" for="email">
              Email address
            </label>
          </div>
        </div>
        
        <div class="login-fieldset">
          <input
            id="password"
            class="login-textinput"
            type="password"
            ng-model="password"
            required
          />
          <label class="login-label" for="password">
            Password
          </label>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <button type="submit" class="btn btn-primary btn-lg">
            <i class='fa fa-spinner fa-spin ' ng-if="$ctrl.loading"></i>
            Sign in
          </button>
          <a href="/forgot-password" class="text-small">Forgot password?</a>
        </div>
      </form>
    </div>`,
};
