import html from './list.html';
import { fetchGroups } from 'logic/groups';
import { removeGroup } from 'logic/group';
import * as Utils from 'utils';
import store from 'store';

import './list.scss';

export default {
  bindings: {
    view: '@',
  },

  template: html,

  controller($ngRedux, $httpParamSerializer, $location) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      mapState,
      mapDispatch,
      getTimeOffset: Utils.getTimeOffset,
      onClickItem,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);

      $ctrl.fetchGroups();
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function mapState(state) {
      const userId = store.get('user');
      return {
        ...state.groups,
        userId,
      };
    }

    function mapDispatch() {
      return {
        fetchGroups,
        removeGroup,
      };
    }

    function onClickItem(group) {
      $location.path(`/groups/${group}/edit`);
    }
  },
};
