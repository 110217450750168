export default {
  bindings: {
    submitting: '<',
    onSubmit: '&',
  },

  template: `
    <div class="login-form">
      <form ng-submit="$ctrl.onSubmit({ username: username})">
        <div class="login-fieldset">
          <p>Forgot your password?<br>Enter your email address below and we'll send you an email with a link to reset it.</p>
        </div>
        <div class="login-fieldset">
          <input
            id="email"
            class="login-textinput"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            spellcheck="false"
            ng-model="username"
            required />
          <label class="login-label" for="email">
            Email address
          </label>
        </div>
        <button type="submit" class="btn btn-primary btn-lg">
          <i class='fa fa-spinner fa-spin ' ng-if="$ctrl.submitting"/>
          Submit
        </button>
        <a href="/login" class="text-small d-block mt-3">< Go back to sign in screen</a>
      </form>
    </div>`,
};
