export default {
  bindings: {
    kpis: '<',
    isIndividual: '<',
  },

  template: `
    <div class="row wallboard-card-row justify-content-between">
      <div
        class="col-sm-6 wallboard-card-line"
        ng-class="{false:'p-3'}[$ctrl.isIndividual]"
        ng-style="{padding: {true:'5%'}[$ctrl.isIndividual]}"
        ng-repeat="item in $ctrl.kpis track by $index"
        ng-if="item.type === 'business' && !item.disabled"
      >
        <div class="wallboard-card-line-wrapper">
          <graph-line-total props="item" margin="{ top: 50 }" show="true">
            <span class="h6 text-uppercase">
              {{ item.name }}
            </span>
          </graph-line-total>
        </div>
      </div>
    </div>`,
};
