import { fetchUser, logout } from 'logic/user';
import store from 'store';
import html from './header.html';

export default {
  transclude: true,

  bindings: {
    wallboardUrl: '@',
    menuOpened: '<',
  },

  template: html,

  controller($ngRedux) {
    'ngInject';
    const $ctrl = {
      $onInit,
      $onDestroy,
      onLogout,
      mapState,
      mapDispatch,
      onClickMenu,
    };

    return $ctrl;

    function $onInit() {
      const connect = $ngRedux.connect(
        $ctrl.mapState,
        $ctrl.mapDispatch()
      );
      $ctrl.unsubscribe = connect($ctrl);
      $ctrl.fetchUser(store.get('user'));
    }

    function $onDestroy() {
      $ctrl.unsubscribe();
    }

    function onLogout() {
      $ctrl.logout();
    }

    function onClickMenu() {
      $ctrl.menuOpened = !$ctrl.menuOpened;
    }

    function mapState(state) {
      return {
        props: state.user.data,
        recentActivities: state.activities.recentActivities,
      };
    }

    function mapDispatch() {
      return { fetchUser, logout };
    }
  },
};
