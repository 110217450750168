import impedimentsForm from './form';
import impedimentsNew from './new';
import impedimentsEdit from './edit';
import impedimentsList from './list';
import impedimentsShow from './show';
import selectInitiativeModal from './select-initiative-modal';

export default angular
  .module('App.impediments', [])
  .component('impedimentsNew', impedimentsNew)
  .component('impedimentsEdit', impedimentsEdit)
  .component('impedimentsForm', impedimentsForm)
  .component('impedimentsList', impedimentsList)
  .component('impedimentsShow', impedimentsShow)
  .component('selectInitiativeModal', selectInitiativeModal).name;
