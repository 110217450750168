import { each, kebabCase, groupBy, flatten, filter, sortBy } from 'lodash';
import moment from 'moment';
import colors from 'constants/colors';
import icons from 'constants/icons';

function getCheckin(initiative) {
  let current = 1;
  let edit = 1;

  if (initiative && initiative.rags) {
    const keys = Object.keys(initiative.rags || {});
    const ballotKeys = Object.keys(initiative.ballot_status || {});
    const max = Math.max(...keys, ...ballotKeys);
    if (max >= 0) {
      current = max;
    }
    edit = current;
    let { ballot_status } = initiative;
    if (!ballot_status) {
      ballot_status = {};
    }
    // The current checkin is submitted or it was removed by removing ballot
    if (
      (initiative.rags && initiative.rags[current]) ||
      ballot_status[edit] === false
    ) {
      edit++;
    }
  }
  return { current, edit };
}

function getTimespanData(startsAt, days = 0) {
  // if (startsAt && endsAt) {
  //   days = moment(endsAt).diff(startsAt, 'days');
  // }

  let complete = moment().diff(startsAt, 'days');

  var percent = 0;

  if (days > 0) {
    percent = Math.round((complete / days) * 100);
  }

  let remaining = days - complete;

  // startsAt date is in the future
  if (complete < 0) {
    complete = 0;
    percent = 0;
    remaining = days;
  }

  // complete date is in the past
  if (percent > 100) {
    complete = days;
    percent = 100;
    remaining = 0;
  }

  return { days, complete, percent: Math.round(percent), remaining };
}

function getMobilisationData(startsAt, endsAt, totalMobCount, mobCount) {
  let days = 0;

  if (startsAt && endsAt) {
    days = moment(endsAt).diff(startsAt, 'days');
  }

  let percent = 0;
  if (totalMobCount > 0) {
    percent = (mobCount / totalMobCount) * 100;
  }

  return {
    days,
    complete: mobCount,
    percent: Math.round(percent),
    remaining: totalMobCount - mobCount,
  };
}

function getCompletionData(startsAt, endsAt, percentageComplete) {
  var remaining = 0;

  if (startsAt && endsAt) {
    remaining = moment(endsAt).diff(startsAt, 'days');
  }

  const percent = isNaN(percentageComplete) ? 0 : percentageComplete;

  return { remaining, percent: Math.round(percent) };
}

function getRagData(type, checkpoints = [], rags = {}, checkin) {
  const dicRag = rags[checkin];
  if (!dicRag) {
    return [0, 0, 0, 0];
  }

  const dicChk = {};
  for (let i in checkpoints) {
    if (checkpoints[i].id) {
      dicChk[checkpoints[i].id] = checkpoints[i];
    }
  }

  return [0, 1, 2, 3].map(value => {
    return filter(dicRag, (rag, key) => {
      if (dicChk[key]) {
        return rag === value && dicChk[key].type === type;
      } else {
        return false;
      }
    }).length;
  });
}

function getGraphData(type, value) {
  switch (type) {
    case 'team':
    case 'service':
      return [
        { color: colors.gray, value: Math.max(value[0], 0.0001) },
        { color: colors.red, value: value[1] },
        { color: colors.yellow, value: value[2] },
        { color: colors.green, value: value[3] },
      ];
      break;
    case 'completion':
      return [
        {
          value: value.percent,
          icon: icons.check,
          className: 'completion-completed',
          label: {
            text: '', //`${value.percent}%`,
            color: colors.green,
            className: 'completion',
            offsetPercentage: 5, //-5,
          },
        },
        { value: 100 - value.percent, className: 'completion-remaining' },
      ];
      break;
    case 'timespan':
      return [
        {
          value: value.percent,
          icon: icons.clock_o,
          className: 'timespan-spent',
          label: {
            text:
              value.days > 0
                ? `${value.complete} days of ${value.days} days`
                : 'not started',
            color: colors.grayMid,
            className: 'timespan',
            fontPercentage: 6,
            offsetPercentage: 5,
          },
        },
        { value: 100 - value.percent, className: 'timespan-remaining' },
      ];
      break;

    case 'mobilisation':
      return [
        {
          value: value.percent,
          icon: icons.check,
          className: 'mobilisation-complete',
          label: {
            text: `${value.percent}%`,
            color: colors.green,
            className: 'timespan',
            // fontPercentage: 6,
            offsetPercentage: -5,
          },
        },
        { value: 100 - value.percent, className: 'timespan-remaining' },
      ];
      break;
  }
}

export default function decorateInitiative(item = {}, constMobilisations = {}) {
  const {
    id,
    name,
    checkpoints,
    mobilisations,
    mobilisationStart,
    mobilisationTime,
    created_date,
    dueDate,
    percentageComplete,
  } = item;

  console.info('constMobilisations:', constMobilisations);
  console.info('mobilisations:', mobilisations);
  console.info('mobilisationStart:', mobilisationStart);
  console.info('mobilisationTime:', mobilisationTime);

  // Calculate mobilisations
  const totalMobCount = Object.keys(constMobilisations).length;
  const mobCount = Array.isArray(mobilisations) ? mobilisations.length : 0;

  if (name === undefined) {
    return { ...item, checkpointsGrouped: { team: [], service: [] } };
  }

  const slug = kebabCase(id + name);
  const checkpointsGrouped = groupBy(checkpoints, 'type');
  if (!checkpointsGrouped.team) {
    checkpointsGrouped.team = [];
  }
  if (!checkpointsGrouped.service) {
    checkpointsGrouped.service = [];
  }

  const chkOrderGrouped = {};

  for (let key in checkpointsGrouped) {
    if (key !== 'team' && key !== 'service') {
      chkOrderGrouped[key] = sortBy(checkpointsGrouped[key], 'order');
    } else {
      chkOrderGrouped[key] = checkpointsGrouped[key];
    }
  }

  let teamMembers = 0;
  if (item.team) {
    const keys = Object.keys(item.team);
    teamMembers = keys.length;
  }

  const checkin = getCheckin(item);

  const timespan = getTimespanData(mobilisationStart, mobilisationTime * 30);
  const mobilisation = getMobilisationData(
    created_date,
    dueDate,
    totalMobCount,
    mobCount
  );

  const completion = getCompletionData(
    created_date,
    dueDate,
    percentageComplete
  );
  const team = getRagData('team', item.checkpoints, item.rags, checkin.current);
  const service = getRagData(
    'service',
    item.checkpoints,
    item.rags,
    checkin.current
  );

  const graphData = {
    team: getGraphData('team', team),
    service: getGraphData('service', service),
    completion: getGraphData('completion', completion),
    timespan: getGraphData('timespan', timespan),
    mobilisation: getGraphData('mobilisation', mobilisation),
  };

  return {
    ...item,
    slug,
    checkpointsGrouped: chkOrderGrouped,
    completion,
    timespan,
    checkin,
    teamMembers,
    graphData,
  };
}
