import { range, reject, pickBy, identity } from 'lodash';
import { createLogic } from 'redux-logic';
import * as firebase from 'firebase';
import store from 'store';
import axios from 'axios';
import * as _ from 'lodash';
import * as Utils from '../utils';

import getFirebaseConfig from '../config/firebase';
const firebaseProjectId = getFirebaseConfig().projectId;

// Action Types
export const INIT = 'app/initiative/INIT';
export const FETCH = 'app/initiative/FETCH';
export const FETCH_WITH_BALLOT = 'app/initiative/FETCH_WITH_BALLOT';
export const FETCH_WITH_BALLOT_SUCCESS =
  'app/initiative/FETCH_WITH_BALLOT_SUCCESS';
export const FETCH_SUCCESS = 'app/initiative/FETCH_SUCCESS';
export const FETCH_ERROR = 'app/initiative/FETCH_ERROR';
export const CREATE = 'app/initiative/CREATE';
export const CREATE_SUCCESS = 'app/initiative/CREATE_SUCCESS';
export const UPDATE = 'app/initiative/UPDATE';
export const UPDATE_SUCCESS = 'app/initiative/UPDATE_SUCCESS';
export const DELETE = 'app/initiative/DELETE';
export const DELETE_SUCCESS = 'app/initiative/DELETE_SUCCESS';
export const CHANGE = 'app/initiative/CHANGE';
export const ADD_AWARD = 'app/initiative/ADD_AWARD';
export const REMOVE_AWARD = 'app/initiative/REMOVE_AWARD';
export const ADD_CHECKPOINT = 'app/initiative/ADD_CHECKPOINT';
export const REMOVE_CHECKPOINT = 'app/initiative/REMOVE_CHECKPOINT';
export const ADD_TAG = 'app/initiative/ADD_TAG';
export const REMOVE_TAG = 'app/initiative/REMOVE_TAG';
export const ERROR = 'app/initiative/ERROR';
export const CANCEL = 'app/initiative/CANCEL';
export const SET_RAG_VALUE = 'app/initiative/SET_RAG_VALUE';
export const ADD_GROUP = 'app/initiative/ADD_GROUP';
export const REMOVE_GROUP = 'app/initiative/REMOVE_GROUP';
export const REMOVE_CHECKIN = 'app/initiative/REMOVE_CHECKIN';
export const REMOVE_CHECKIN_SUCCESS = 'app/initiative/REMOVE_CHECKIN/SUCCESS';
export const UPDATE_KPI = 'app/initiative/UPDATE_KPI';
export const UPDATE_KPI_SUCCESS = 'app/initiative/UPDATE_KPI_SUCCESS';
export const STAR_INITIATIVE = 'app/initiative/STAR_INITIATIVE';

// TODO: exported from ballot
export const OPEN_VOTING = 'app/ballot/OPEN_VOTING';

/** caution: it should be ballot/UPDATE_BALLOT_SUCCESS to prevent recursive importing */
const UPDATE_BALLOT_SUCCESS = 'app/ballot/UPDATE_SUCCESS';

// Initial State
export const initialState = {
  loading: false,
  updating: false,
  success: false,
  redirect: false, // This needs to move to middleware
  error: '',
  data: {
    status: 'Draft',
    type: 'persistent',
    tags: [],
    checkpoints: [],
    badges: [],
    mobilisations: [],
    links: [],
  },
};

// Actions
export function initInitiative(value = {}) {
  return { type: INIT, payload: value };
}

export function fetchInitiative(id) {
  return { type: FETCH, payload: { id } };
}

export function fetchInitiativeWithBallot(id, checkin_id) {
  return { type: FETCH_WITH_BALLOT, payload: { id, checkin_id } };
}

export function setRagValue(checkin, checkpointId, value) {
  return {
    type: SET_RAG_VALUE,
    payload: {
      checkin,
      checkpointId,
      value,
    },
  };
}

export function createInitiative(payload) {
  return { type: CREATE, payload };
}

export function updateInitiative(payload) {
  return { type: UPDATE, payload };
}

export function deleteInitiative(id) {
  return { type: DELETE, payload: id };
}

export function changeInitiative(payload) {
  return { type: CHANGE, payload };
}

export function addInitiativeAward(payload) {
  payload.createdAt = new Date().toISOString();
  return { type: ADD_AWARD, payload };
}

export function removeInitiativeAward(payload) {
  return { type: REMOVE_AWARD, payload };
}

export function addInitiativeTag(tag, data) {
  return { type: ADD_TAG, payload: { tag, data } };
}

export function removeInitiativeTag(payload) {
  return { type: REMOVE_TAG, payload };
}

export function addInitiativeCheckpoint(payload) {
  return { type: ADD_CHECKPOINT, payload };
}

export function removeInitiativeCheckpoint(payload) {
  return { type: REMOVE_CHECKPOINT, payload };
}

export function cancelInitiative() {
  return { type: CANCEL };
}

export function addGroup(group) {
  return { type: ADD_GROUP, payload: { group } };
}

export function removeGroup() {
  return { type: REMOVE_GROUP };
}

export function updateInitiativeKpi(id, kpi) {
  return { type: UPDATE_KPI, payload: { id, kpi } };
}

export function starInitiative(payload) {
  return { type: STAR_INITIATIVE, payload };
}

export function removeCheckin(checkin, initiativeId) {
  return { type: REMOVE_CHECKIN, payload: { checkin, initiativeId } };
}

// Observable Logic
export const initiativeFetch = createLogic({
  latest: true,
  type: FETCH,
  cancelType: CANCEL,
  processOptions: {
    successType: FETCH_SUCCESS,
    failType: FETCH_ERROR,
  },
  process({ $http, action }) {
    return firebase
      .database()
      .ref(`initiatives/${action.payload.id}`)
      .once('value')
      .then(snapshot => {
        const initiative = snapshot.val();
        if (initiative && !Array.isArray(initiative.links)) {
          initiative.links = [];
        }
        if (initiative.mobilisationStart) {
          initiative.mobilisationStart = new Date(initiative.mobilisationStart);
        }

        if (initiative.dueDate) {
          initiative.dueDate = new Date(initiative.dueDate);
        }

        if (
          initiative &&
          initiative.type !== 'lite' &&
          initiative.type !== 'persistent' &&
          initiative.type !== 'project'
        ) {
          initiative.type = 'persistent';
        }

        return {
          data: { ...initiative, id: action.payload.id },
        };
      });
  },
});

export const removeCheckinLogic = createLogic({
  latest: true,
  type: REMOVE_CHECKIN,
  cancelType: CANCEL,
  processOptions: {
    successType: REMOVE_CHECKIN_SUCCESS,
    failType: ERROR,
  },
  process({ $http, action }) {
    let checkin = action.payload.checkin;
    let initiativeId = action.payload.initiativeId;
    let url = `https://us-central1-${firebaseProjectId}.cloudfunctions.net/removeBallot`;

    return axios
      .post(
        url,
        {
          initiativeId,
          checkin,
        },
        {
          headers: {
            Authorization: store.get('Authorization'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          return { data: response.data.initiative };
        } else {
          throw response.data;
        }
      });
  },
});

export const initiativeFetchWithBallot = createLogic({
  latest: true,
  type: FETCH_WITH_BALLOT,
  cancelType: CANCEL,
  processOptions: {
    successType: FETCH_WITH_BALLOT_SUCCESS,
    failType: FETCH_ERROR,
  },
  process({ $http, action }) {
    const { id, checkin_id } = action.payload;
    const initiativePromise = firebase
      .database()
      .ref(`initiatives/${id}`)
      .once('value');
    const ballotPromise = firebase
      .database()
      .ref(`ballots/${id}-${checkin_id}`)
      .once('value');

    return Promise.all([initiativePromise, ballotPromise]).then(snapshots => {
      const initiative = snapshots[0].val();
      if (initiative && !Array.isArray(initiative.links)) {
        initiative.links = [];
      }
      if (initiative.mobilisationStart) {
        initiative.mobilisationStart = new Date(initiative.mobilisationStart);
      }
      if (initiative.dueDate) {
        initiative.dueDate = new Date(initiative.dueDate);
      }

      const ballot = snapshots[1].val();

      // if ballot is valid
      if (ballot) {
        ballot.id = snapshots[1].key;
      }

      return {
        data: { initiative: { ...initiative, id }, ballot },
      };
    });
  },
});

export const initiativeCreate = createLogic({
  latest: true,
  type: CREATE,
  cancelType: CANCEL,
  transform({ action, getState }, next) {
    const payload = { ...action.payload };
    if (payload.teamMembers) {
      delete payload.teamMembers;
    }
    const newPayload = angular.copy(payload);

    // inject team checkpoints for lite initiative

    if (newPayload.type === 'lite') {
      // lite initiative
      const allCheckpoints = getState().checkpoints.data;
      if (allCheckpoints) {
        const teamCheckpoints = [];
        for (let id in allCheckpoints) {
          if (allCheckpoints[id].type === 'team') {
            teamCheckpoints.push({
              id,
            });
          }
        }
        if (teamCheckpoints.length > 0) {
          newPayload.checkpoints = teamCheckpoints;
        }
      }
    } else {
      // Extract only id fom checkpoints
      const checkpoints = Utils.extractOnlyIDs(payload.checkpoints);
      if (checkpoints) {
        newPayload.checkpoints = checkpoints;
      } else {
        delete newPayload.checkpoints;
        console.info('No checkpoints for initiative:', action.payload);
      }
    }
    Utils.filterInitiativeKpis(newPayload);

    // fix initiative type
    if (!newPayload.type) {
      newPayload.type = 'persistent';
    }

    if (!action.payload || typeof action.payload === 'undefined') {
      next({ ...action, payload: null });
    } else {
      next({ ...action, payload: newPayload });
    }
  },
  process({ $http, action }, dispatch, done) {
    if (!action.payload) {
      dispatch({
        type: 'ERROR',
        payload: { error: 'Failed to create an initiative!' },
      });
      done();
      return;
    }

    if (
      !action.payload.checkpoints ||
      action.payload.checkpoints.length === 0
    ) {
      dispatch({
        type: 'ERROR',
        payload: { error: 'Failed to create an initiative!' },
      });
      done();
      return;
    }

    const newInitiativeKey = firebase
      .database()
      .ref()
      .child('initiatives')
      .push().key;
    firebase
      .database()
      .ref(`initiatives/${newInitiativeKey}`)
      .set({
        ...action.payload,
        created_date: firebase.database.ServerValue
          ? firebase.database.ServerValue.TIMESTAMP
          : 0,
      })
      .then(() => {
        dispatch({
          type: CREATE_SUCCESS,
          payload: {
            data: { ...action.payload, id: newInitiativeKey },
          },
        });
        if (action.payload.type === 'lite') {
          dispatch({
            type: OPEN_VOTING,
            payload: {
              initiative: newInitiativeKey,
              checkin: 1,
              private: false,
              anonymous: false,
              oneByOne: true,
              lite: true,
            },
          });
        }
        done();
      })
      .catch(e => {
        Raven.captureExceptionEx(e);
        dispatch({
          type: 'ERROR',
          payload: 'Failed to create an initiative!',
        });
      });
    //return $http.post(`/initiatives`, action.payload);
  },
});

export const initiativeUpdate = createLogic({
  latest: true,
  type: [UPDATE, ADD_AWARD, REMOVE_AWARD, REMOVE_TAG, REMOVE_GROUP, ADD_GROUP],
  cancelType: CANCEL,
  processOptions: {
    successType: UPDATE_SUCCESS,
    failType: ERROR,
  },
  transform({ action, getState }, next) {
    // Remove $$hashKey
    if (
      action.type !== REMOVE_GROUP &&
      (!action.payload || typeof action.payload === 'undefined')
    ) {
      next({ ...action, payload: null });
      return;
    }

    const payload = angular.copy(getState().initiative.data);

    if (payload.teamMembers) {
      delete payload.teamMembers;
    }

    if (payload.ballot_status) {
      // convert to object from array
      const { ballot_status } = payload;
      let newBallot_Status;
      if (Array.isArray(ballot_status)) {
        for (let i = 0; i < ballot_status.length; i++) {
          if (typeof ballot_status[i] === 'boolean') {
            if (!newBallot_Status) {
              newBallot_Status = {};
            }
            newBallot_Status[i] = ballot_status[i];
          }
        }
      }

      if (newBallot_Status) {
        payload.ballot_status = newBallot_Status;
      } else {
        delete payload.ballot_status;
      }
    }

    if (action.type === UPDATE) {
      // register checkins status
      const { checkins, rags } = action.payload;
      if (checkins && Object.keys(checkins).length > 0) {
        payload.checkins = checkins;
      }

      /* TODO: should be another action to register rags value
       **/
      payload.rags = rags;

      // Convert
      if (isNaN(payload.budget)) {
        delete payload.budget;
      } else {
        payload.budget = parseInt(payload.budget);
      }
    } else if (action.type === ADD_AWARD) {
      const award = angular.copy(action.payload);
      const { key } = action.payload;
      if (!Array.isArray(payload[key])) {
        payload[key] = [];
      }
      const index = _.findIndex(payload[key], { id: action.payload.id });
      if (index < 0) {
        // No Award found
        payload[key].push(award); // Push
      } else {
        payload[key][index] = award; // Update the existing award
      }
    } else if (action.type === REMOVE_AWARD) {
      const award = angular.copy(action.payload);
      const { key } = action.payload;

      if (!Array.isArray(payload[key])) {
        payload[key] = [];
      }

      payload[key] = payload[key].filter(item => {
        return item.id !== award.id;
      });
    } else if (action.type === REMOVE_GROUP) {
      payload.group = null;
    } else if (action.type === ADD_GROUP) {
      payload.group = {};
      payload.group.name = action.payload.group.name;
      payload.group.id = action.payload.group.id;
      payload.group.users = action.payload.group.users;
      if (action.payload.group.priorities) {
        payload.group.priorities = action.payload.group.priorities;
      }
    } else if (action.type === REMOVE_TAG) {
      if (!Array.isArray(payload.tags)) {
        payload.tags = [];
      }
      payload.tags = payload.tags.filter(tag => tag !== action.payload);
    }

    // remove undefined from checkins
    if (payload.checkpoints) {
      // Extract only id fom checkpoints
      const checkpoints = Utils.extractOnlyIDs(payload.checkpoints);
      if (checkpoints) {
        payload.checkpoints = checkpoints;
      } else {
        payload.checkpoints = null;
        console.info('No checkpoints for initiative:', action.payload);
      }
    }

    // remove undefined from rags value (Convert null contained array to object)
    if (payload.rags) {
      // convert to Firebase friendly
      let newRags;
      if (Array.isArray(payload.rags)) {
        newRags = Utils.arrayToObject(
          payload.rags.map(rag => {
            return Utils.arrayToObject(rag);
          })
        );
      } else if (typeof payload.rags == 'object') {
        newRags = {};
        Object.keys(payload.rags).map(function(key, index) {
          newRags[key] = Utils.arrayToObject(payload.rags[key]);
        });
      }
      payload.rags = newRags;
    } else {
      delete payload.rags;
    }

    // remove undefined from mobilisations
    if (Array.isArray(payload.mobilisations)) {
      const mobilisations = payload.mobilisations.map(mobilisation =>
        pickBy(mobilisation, identity)
      );
      payload.mobilisations = mobilisations;
    }

    // remove undefined from badges
    if (Array.isArray(payload.badges)) {
      const badges = payload.badges.map(badge => pickBy(badge, identity));
      payload.badges = badges;
    }

    if (Array.isArray(payload.checkpoints)) {
      const checkpoints = payload.checkpoints.map(checkpoint =>
        pickBy(checkpoint, identity)
      );
      payload.checkpoints = checkpoints;
    }

    if (Array.isArray(payload.checkins)) {
      payload.checkins = Utils.arrayToObject(payload.checkins);
    }

    Utils.filterInitiativeKpis(payload);

    next({ ...action, payload });
  },
  process({ action }) {
    if (!action.payload) {
      const error = 'No initiative exits';
      throw { error };
      return;
    }
    return firebase
      .database()
      .ref(`initiatives/${action.payload.id}`)
      .update(action.payload)
      .then(() => {
        return {
          data: { ...action.payload },
        };
      });
  },
});

export const initiativeDelete = createLogic({
  latest: true,
  type: DELETE,
  cancelType: CANCEL,
  processOptions: {
    successType: DELETE_SUCCESS,
    failType: ERROR,
  },
  process({ $http, action, getState }) {
    //return $http.delete(`/initiatives/${action.payload}`);
    const { initiative } = getState();

    return firebase
      .database()
      .ref(`initiatives/${action.payload}`)
      .remove()
      .then(() => {
        return {
          data: initiative.data,
        };
      });
  },
});

export const initiativeWatch = createLogic({
  type: [FETCH_WITH_BALLOT, FETCH],
  warnTimeout: 0,
  process({ action, getState }, dispatch, done) {
    console.info('Start Watching Initiative!');
    const id = action.payload.id;

    const { initiative } = getState();

    // Remove old listener
    if (initiative && initiative.ref && initiative.ref.off) {
      initiative.ref.off();
    }
    return firebase
      .database()
      .ref('initiatives')
      .child(id)
      .on('value', snapshot => {
        const initiative = snapshot.val();
        if (initiative) {
          initiative.id = snapshot.key;
          dispatch({
            type: FETCH_SUCCESS,
            payload: {
              data: initiative,
              ref: snapshot.ref,
            },
          });
          done();
        } else {
          done();
        }
      });
  },
});

export const initiativeUpdateKpi = createLogic({
  type: UPDATE_KPI,
  cancelType: CANCEL,
  processOptions: {
    successType: UPDATE_KPI_SUCCESS,
    failType: ERROR,
  },
  process({ $http, action, getState }) {
    const id = action.payload.kpi.name.toLowerCase().replace(/ /g, '_');

    return firebase
      .database()
      .ref(`initiatives/${action.payload.id}/kpis/${id}`)
      .update(action.payload.kpi)
      .then(() => {
        const payload = angular.copy(getState().initiative.data);
        payload.kpis[id] = action.payload.kpi;

        return {
          data: { ...payload },
        };
      });
  },
});

export const initiativeStar = createLogic({
  type: STAR_INITIATIVE,
  cancelType: CANCEL,
  processOptions: {
    successType: UPDATE_SUCCESS,
    failType: ERROR,
  },
  process({ $http, action, getState }) {
    const { id } = getState().initiative.data;
    const userId = getState().user.data.id;
    let stars = getState().initiative.data.stars;
    if (!stars) {
      stars = {};
    }
    if (action.payload) {
      stars[userId] = true;
    } else {
      delete stars[userId];
    }

    return firebase
      .database()
      .ref(`initiatives/${id}`)
      .update({ stars })
      .then(() => {
        const payload = angular.copy(getState().initiative.data);
        payload.stars = stars;

        return {
          data: { ...payload },
        };
      });
  },
});

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case INIT: {
      initialState.data.links = [];
      initialState.data.checkpoints = [];
      return {
        ...initialState,
        data: {
          ...initialState.data,
          ...payload,
        },
      };
    }

    case UPDATE_BALLOT_SUCCESS:
      if (action.payload.data.initiative) {
        return {
          ...state,
          data: action.payload.data.initiative,
        };
      } else {
        return state;
      }

    case FETCH_WITH_BALLOT:
    case FETCH:
      return {
        ...state,
        fetching: true,
        loading: true,
        success: false,
        redirect: false,
        fetchError: null,
        successRemoveCheckin: false,
        removingCheckin: false,
      };

    case UPDATE:
    case CREATE:
    case DELETE:
      return {
        ...state,
        loading: true,
        updating: true,
        success: false,
        redirect: true,
        successRemoveCheckin: false,
        error: null,
      };

    case UPDATE_KPI:
      return {
        ...state,
        updatingKpi: true,
        successKpi: false,
        error: null,
      };

    case UPDATE_KPI_SUCCESS:
      return {
        ...state,
        updatingKpi: false,
        successKpi: true,
      };

    case CHANGE:
      return {
        ...state,
        data: { ...payload },
      };

    case FETCH_WITH_BALLOT_SUCCESS:
      return {
        ...state,
        data: payload.data.initiative,
        fetching: false,
        loading: false,
      };

    case FETCH_SUCCESS:
      return {
        ...state,
        data: payload.data,
        ref: payload.ref, // Firebase Reference
        fetching: false,
        loading: false,
      };

    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case DELETE_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        loading: false,
        updating: false,
        success: true,
      };
    }

    case ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        updatingKpi: false,
        successKpi: false,
        error: 'An error occured while saving',
      };

    case FETCH_ERROR:
      if (action.payload && action.payload.code === 'PERMISSION_DENIED') {
        return {
          ...state,
          fetching: false,
          loading: false,
          success: false,
          fetchError: 'No permission to load the initiative!',
        };
      } else {
        return {
          ...state,
          fetching: false,
          loading: false,
          success: false,
          fetchError: 'Failed to load the initiative!',
        };
      }

    case ADD_CHECKPOINT: {
      const data = angular.copy(state.data);
      if (!data.checkpoints) {
        data.checkpoints = [];
      }
      data.checkpoints.push({ ...payload });
      return { ...state, data };
    }

    case REMOVE_CHECKPOINT: {
      const data = { ...state.data };
      data.checkpoints = reject(data.checkpoints, { id: payload.id });
      return { ...state, data };
    }

    case ADD_AWARD: {
      const data = { ...state.data };
      const { id, createdAt, key, name, completionDate, link, note } = payload;

      if (!Array.isArray(data[key])) {
        data[key] = [];
      }

      // Remove and add
      data[key] = data[key].filter(item => item.name !== name);
      data[key].push({ id, name, createdAt, completionDate, link, note });

      return {
        ...state,
        data,
        loading: true,
      };
    }

    case REMOVE_AWARD: {
      const data = { ...state.data };
      const { key, name } = payload;

      if (!Array.isArray(data[key])) {
        data[key] = [];
      }

      data[key] = data[key].filter(item => item.name !== name);

      return {
        ...state,
        data,
        loading: true,
      };
    }

    case ADD_TAG: {
      const { tag, data } = payload;
      data.tags = tag.split(',').map(v => v.trim());
      return { ...state, data };
    }

    case REMOVE_TAG: {
      const data = { ...state.data };
      if (!Array.isArray(data.tags)) {
        data.tags = [];
      }
      data.tags = data.tags.filter(tag => tag !== payload);
      return { ...state, data };
    }

    case SET_RAG_VALUE: {
      const data = { ...state.data };
      Utils.setRagValue(
        data,
        payload.checkin,
        payload.checkpointId,
        payload.value
      );
      return { ...state, data };
    }

    case ADD_GROUP: {
      const data = { ...state.data };
      return {
        ...state,
        data,
        loading: true,
      };
    }

    case REMOVE_GROUP: {
      const data = { ...state.data };
      return {
        ...state,
        data,
        loading: true,
      };
    }

    case REMOVE_CHECKIN: {
      return {
        ...state,
        removingCheckin: true,
        successRemoveCheckin: false,
      };
    }

    case REMOVE_CHECKIN_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        removingCheckin: false,
        successRemoveCheckin: true,
      };
    }

    default:
      return state;
  }
}
