export default () => {
  return {
    response(response) {
      const total = response.headers('X-Total-Count');

      if (total) {
        const { _page, _limit } = response.config.params;

        response.pagination = {
          page: _page,
          limit: _limit,
          total: Number(total),
          pages: Math.ceil(Number(total) / _limit),
        };
      }

      return response;
    },
  };
};
