export default {
  bindings: {
    props: '<',
  },

  template: `
    <span
      ng-class="$ctrl.size ? 'btn-' + $ctrl.size : 'btn-sm'"
      class="btn btn-secondary tag mr-1 mb-1"
      ng-repeat="item in $ctrl.props.objects">
      <span
        ng-if="item.type === 'Initiative'"
        class="text-primary">
        {{item.object.name}}
      </span> 
    </span>`,
};
